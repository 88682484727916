<template>
  <div class="posts-container">
    <Posting />
    <Post :GET_EVENT_POSTS="GET_EVENT_POSTS" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Posting from "./Posting.vue";
import Post from "./Post.vue";

export default {
  name: 'PostsContainer',
  components: {
    Posting,
    Post
  },
  computed: {
    ...mapGetters(['GET_EVENT_POSTS'])
  }
};
</script>

<style lang="scss" scoped>
.posts-container {
  width: 770px;
  max-width: 100%;
}
</style>
