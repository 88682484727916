<template>
  <div class="posting">
    <CreatePost 
      :event_id="$route.params.id"
      :from_event="true"
      :check="false"
      @getTrending="handleNewPost"
    />
  </div>
</template>

<script>
import CreatePost from '@/components/Home/NewsFeed/CreatePost.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    CreatePost
  },
  methods: {
    ...mapActions(['getEventPosts', 'addNewPost']),
    async handleNewPost(newPost) {
      try {
        await this.addNewPost({
          post: newPost,
          eventId: this.$route.params.id
        });
      } catch (error) {
        console.error('Error handling new post:', error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.posting {
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  padding: 10px 20px;
}
</style>
