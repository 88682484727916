<template>
  <div class="post-detail" v-show="item.id">

    <!-- Normal Post Start -->
    <div v-if="!post_type" class="row">

      <div class="col">
        <div class="news-feed-post-detail">
          <div class="news-feed-post-detail-list">
            <div class="news-feed-post-user-detail-first">
              <span
                @click="redirectWidgetsToProfilePage($event, item.user.username ? item.user.username : '')"
                class="news-feed-pic">
                <UserPicture
                  :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials">
              <div class="user_and_link">
                <h1
                  @click="redirectWidgetsToProfilePage($event, item && item.user && item.user.username ? item.user.username : '')">
                  {{ name }}
                </h1>
                <p class="">added a post</p>
                <span class="user_dot">
                  <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1" />
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                   <component :is="getSvg(item.access)" />
                </span>

                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div v-if="!is_edit_post">
                  <p class="full-content" @click="pushPostDetail(item.id)">
                    <span  v-html="getFullContent" > </span>
                  </p>
                  <div v-if="show_more_content">
                    <PostHashTags v-show="more_less === '...less'" :allTags="item.tags" class="mb-4" />
                    <span class="text_blue" @click="changeTextLength($event)">{{ more_less }}</span>
                  </div>
                  <PostHashTags v-else  :allTags="item.tags" />
              </div>
              <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                @click="stop_propagation($event)" />
              <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                Edit Post
              </button>

              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment :post="item" v-if="item.allow_comments === 1" :comments_count="comment_count"
                      :active_check="write_comment" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment" :item="item" :comment_check="comment_check" @comments_count="commentsCount"
            @addComment="addCommentInList" @commentToggle="toggleCommentSection" />
        </div>
      </div>
    </div>
    <!-- Normal Post End -->

    <!-- Activity Post Start -->
    <div v-if="isPostIsActivity" class="row">
      <div class="col">
        <div class="news-feed-post-detail">
          <div class="news-feed-post-detail-list">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials my-auto mx-auto">
              <div class="user_and_link">
                <h1
                  @click="redirectWidgetsToProfilePage($event, item && item.user && item.user.username ? item.user.username : '')">
                  {{ name }}
                </h1>
                <p class="">
                  is {{ post_type.toLowerCase() }}
                  {{ item.post_type_description.toLowerCase() }}
                </p>
                <!-- <p>@SarahHogan</p> -->
                <span class="user_dot">
                  <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1" />
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                   <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="w-100" v-if="item.full_content"  @click="pushPostDetail(item.id)">
                <div v-if="!is_edit_post">
                  <p class="full-content">
                    <span  v-html="getFullContent" > </span>
                  </p>
                  <div v-if="show_more_content">
                    <PostHashTags v-show="more_less === '...less'" :allTags="item.tags" class="mb-4" />
                    <span class="text_blue mb-3 d-inline-block" @click="changeTextLength($event)">{{ more_less }}</span>
                  </div>
                  <PostHashTags v-else  :allTags="item.tags" />

                  <span style="margin-left: 20px" v-if="getEmoji(item.post_type_description)">
                    <img width="60" :src="getEmoji(item.post_type_description)" />
                  </span>
                </div>

              </div>
              <div style="text-align: center" class="w-100" v-else @click="pushPostDetail(item.id)">
                <p v-if="!is_edit_post">
                  <span v-if="getEmoji(item.post_type_description)"><img width="60"
                      :src="getEmoji(item.post_type_description)" /></span>
                </p>
              </div>
              <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                @click="stop_propagation($event)" />
              <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                Edit Post
              </button>

              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Activity Post End -->

    <!-- Video Post Start -->
    <div v-if="post_type === 'video'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image">
          <div class="useinfo_photoimage">
            <div class="profile_content">
              <span v-if="user_picture" class="news-feed-pic"><img
                  :src="userPicture ? userPicture : user_picture" /></span>
              <span v-if="!user_picture" class="news-feed-pic"><img
                  :src="require(`@/assets/img/user-placeholder.png`)" /></span>
            </div>
            <div class="news-feed-pic-detials-photo-video" @click="pushPostDetail(item.id)">
              <div class="user_and_link">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  {{ name }}
                </h1>
                <p class="link_share">Shared a video</p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg></span>
                <p class="post-time">{{ time }}</p>

                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <!-- Content with hashtags -->
              <div v-if=" !is_edit_post">
                <p class="full-content">
                  <span v-html="getFullContent"></span>
                </p>
                <div v-if="show_more_content">
                    <PostHashTags v-show="more_less === '...less'" :allTags="item.tags" class="mb-4" />
                    <span class="text_blue" @click="changeTextLength($event)">{{ more_less }}</span>
                </div>
                <PostHashTags v-else  :allTags="item.tags" />
              </div>

              <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                @click="stop_propagation($event)" />
              <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                Edit Post
              </button>
              <div v-for="(video, index) in item.artwork_url" :key="index" class="for_video mb-2">
                <video :src="video.original_url" controls />
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" @comments_count="commentsCount"
            @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Video Post End -->

    <!-- Shared a Link Post Start -->
    <div v-if="post_type === 'sahred3'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image" @click="pushPostDetail(item.id)">
          <div class="useinfo_photoimage">
            <div class="profile_content">
              <span class="news-feed-pic"><img :src="require(`@/assets/img/pp.png`)" /></span>
            </div>
            <div class="news-feed-pic-detials-photo-video">
              <div class="user_and_link m_b-10">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  Sarah Hogan
                </h1>
                <p class="link_share">Shared a link</p>
                <span class="user_dot">
                  <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg></span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                  <svg fill="none" height="10" viewBox="0 0 12 10" width="12" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 9.5C5.25 9.5 4.5 9.5 4.5 8.75C4.5 8 5.25 5.75 8.25 5.75C11.25 5.75 12 8 12 8.75C12 9.5 11.25 9.5 11.25 9.5H5.25ZM8.25 5C8.84674 5 9.41903 4.76295 9.84099 4.34099C10.2629 3.91903 10.5 3.34674 10.5 2.75C10.5 2.15326 10.2629 1.58097 9.84099 1.15901C9.41903 0.737053 8.84674 0.5 8.25 0.5C7.65326 0.5 7.08097 0.737053 6.65901 1.15901C6.23705 1.58097 6 2.15326 6 2.75C6 3.34674 6.23705 3.91903 6.65901 4.34099C7.08097 4.76295 7.65326 5 8.25 5Z"
                      fill="#8B949F" />
                    <path clip-rule="evenodd"
                      d="M3.912 9.50032C3.80082 9.26618 3.74537 9.00948 3.75 8.75032C3.75 7.73407 4.26 6.68782 5.202 5.96032C4.73182 5.81545 4.24196 5.7446 3.75 5.75032C0.75 5.75032 0 8.00032 0 8.75032C0 9.50032 0.75 9.50032 0.75 9.50032H3.912Z"
                      fill="#8B949F" fill-rule="evenodd" />
                    <path
                      d="M3.375 5C3.87228 5 4.34919 4.80246 4.70083 4.45083C5.05246 4.09919 5.25 3.62228 5.25 3.125C5.25 2.62772 5.05246 2.15081 4.70083 1.79917C4.34919 1.44754 3.87228 1.25 3.375 1.25C2.87772 1.25 2.40081 1.44754 2.04917 1.79917C1.69754 2.15081 1.5 2.62772 1.5 3.125C1.5 3.62228 1.69754 4.09919 2.04917 4.45083C2.40081 4.80246 2.87772 5 3.375 5Z"
                      fill="#8B949F" />
                  </svg>
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="artist-timeline-description">
                Since i just played a Live-Set in a storm, on the 12th floor of
                a skyscraper without exterior walls, under extreme
                conditions.... <a href="javascript:;"> more</a>
              </div>
              <div class="thoms-post">
                <img :src="require(`@/assets/img/headphone.png`)" />
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <div class="artist-timeline-action">
                    <svg fill="none" height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" @comments_count="commentsCount"
            @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Shared a Link Post End -->

    <!-- Image Post Start -->
    <div v-if="post_type === 'images'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image" @click="pushPostDetail(item.id)">
          <div class="useinfo_photoimage">
            <div class="profile_content">
              <span class="news-feed-pic"><img :src="require(`@/assets/img/pp.png`)" /></span>
            </div>
            <div class="news-feed-pic-detials-photo-video">
              <div class="user_and_link m_b-10">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  Sarah Hogan
                </h1>
                <p class="link_share">
                  added an image
                  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                    <rect fill="#D1433A" height="16" rx="1" width="16" />
                    <ellipse cx="8.5" cy="6" fill="white" rx="3.5" ry="3" />
                    <rect fill="white" height="2" width="3" x="6" y="3" />
                    <rect fill="white" height="2" width="3" x="6" y="7" />
                    <rect fill="white" height="10" width="2" x="4" y="3" />
                    <rect fill="#D1433A" height="2" width="3" x="6" y="5" />
                    <circle cx="9" cy="6" fill="#D1433A" r="1" />
                  </svg>
                </p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                   <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="artist-timeline-description">
                Since i just played a Live-Set in a storm, on the 12th floor of
                a skyscraper without exterior walls, under extreme
                conditions.... <a href="javascript:;"> more</a>
              </div>
              <div class="thoms-post">
                <img :src="require(`@/assets/img/img-big.png`)" />
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <div class="artist-timeline-action">
                    <svg fill="none" height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Image Post End -->

    <!-- Image Post Start -->
    <div v-if="
      post_type === 'timeline_photo' ||
      post_type === 'profile_photo' ||
      post_type === 'cover_photo'
    " class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image">
          <div class="useinfo_photoimage">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials-photo-video">
              <div class="user_and_link m_b-10">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  {{ name }}
                </h1>
                <p class="link_share" v-if="
                  post_type === 'profile_photo' || post_type === 'cover_photo'
                ">
                  added a
                  {{
                      post_type === "cover_photo"
                        ? "cover photo"
                        : "profile photo"
                  }}
                </p>
                <p v-else-if="data_photos_length > 1" class="link_share">
                  added {{ data_photos_length }} photos
                </p>
                <p v-else-if="data_photos_length <= 1" class="link_share">
                  added an image
                </p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                 <component :is="getSvg(item.access)" />
                </span>

                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>

              <div v-if=" !is_edit_post && post_type !== 'profile_photo' && post_type !== 'cover_photo'">
                <p class="full-content">
                  <span  v-html="getFullContent" > </span>
                </p>
                <div v-if="show_more_content">
                    <PostHashTags v-show="more_less === '...less'" :allTags="item.tags" class="mb-4" />
                    <span class="text_blue" @click="changeTextLength($event)">{{ more_less }}</span>
                </div>
                <PostHashTags v-else  :allTags="item.tags" />
              </div>



              <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                @click="stop_propagation($event)" />
              <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                Edit Post
              </button>
              <!-- Image section -->
              <div v-if="data_photos && data_photos.length > 1" class="photo-row" :class="{'mt-4' : (post_type === 'profile_photo' || post_type === 'cover_photo')}"
                @click="open_post_image_detail(item.id)">
                <div v-for="(eachOp, index) in data_photos" :key="'photo_data_' + index" class="photo-col-1">
                  <img :src="eachOp.original_url" alt="" />
                </div>
              </div>
              <div v-if="data_photos && data_photos.length == 1" class="photo-row" :class="{'mt-4' : (post_type === 'profile_photo' || post_type === 'cover_photo')}"
                @click="open_post_image_detail(item.id)">
                <div v-for="(eachOp, index) in data_photos" :key="'photo_data_' + index" class="photo-timeline">
                  <img :src="eachOp.original_url" alt="" @error="onPostImageError" />
                </div>
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials for_two_row">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Album Post End -->

    <!-- Album Post Start -->
    <div v-if="post_type === 'album'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image">
          <div class="useinfo_photoimage">
            <div class="profile_content">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
              </span>
            </div>
            <div class="news-feed-pic-detials-photo-video">
              <div class="user_and_link m_b-10">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  {{ name }}
                </h1>
                <p class="link_share">
                  added {{ data_photos_length }} photos to album:
                  <span>{{ item.album.title.length > 12 ? item.album.title.slice(0, 12) + '...' : item.album.title }}</span>
                </p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                   <component :is="getSvg(item.access)" />
                </span>

                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <p class="full-content " v-if="!is_edit_post" >
                <span v-html="getFullContent"></span>
                <span v-if="item.full_content && item.full_content.length > 149" class="text_blue"  @click="changeTextLength($event)">{{more_less}}</span>
              </p>
              <input v-if="is_edit_post" v-model="edit_post" placeholder="Post" v-on:keyup="checkEnter" />
              <p v-if="is_edit_post" @click="cancelEditPost">Cancel</p>

              <div v-if="data_photos && data_photos.length" class="photo-row" @click="open_post_image_detail(item.id)">
                <div v-for="(eachOp, index) in data_photos" :key="'photo_data_' + index" class="photo-col-1">
                  <img :src="eachOp.original_url" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials for_two_row">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Album Post End -->

    <!-- Poll Post Start -->
    <div v-if="post_type === 'poll'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image">
          <div class="useinfo_photoimage">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials-photo-video-poll">
              <div class="news-feed-pic-detials-poll">
                <div class="user_and_link m_b-10">
                  <h1 @click="
                    redirectWidgetsToProfilePage(
                      $event,
                      item && item.user && item.user.username
                        ? item.user.username
                        : ''
                    )
                  ">
                    {{ name }}
                  </h1>
                  <p class="link_share">added a Poll</p>
                  <span class="user_dot">
                    <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                    </svg></span>
                  <p class="post-time">{{ time }}</p>
                  <span class="icon_globe">
                     <component :is="getSvg(item.access)" />
                  </span>

                  <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
                </div>
                <!-- Content with hashtags -->
                <div v-if="!is_edit_post" @click="pushPostDetail(item.id)">
                  <p class="full-content">
                    <span v-html="getFullContent"></span>
                  </p>
                  <div v-if="show_more_content">
                      <PostHashTags v-show="more_less === '...less'" :allTags="item.tags" class="mb-4" />
                      <span class="text_blue" @click="changeTextLength($event)">{{ more_less }}</span>
                  </div>
                  <PostHashTags v-else  :allTags="item.tags" />
                </div>


                <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                  @click="stop_propagation($event)" />
                <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                  Edit Post
                </button>
                <div class="poll-area" @click="pushPostDetail(item.id)">
                  <VoteItem v-for="(eachOp, index) in poll_options" :key="'poll_option_' + index" :option="eachOp"
                    :post="item" />
                </div>
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity mt-0">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Poll Post End -->

    <!-- Color Post Start -->
    <div v-if="post_type === 'coloured'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image">
          <div class="useinfo_photoimage">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials-photo-video">
              <div class="user_and_link m_b-10">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  {{ name }}
                </h1>
                <p class="link_share">added a background post</p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                     <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>

              <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                @click="stop_propagation($event)" />
              <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                Edit Post
              </button>
              <div :class="postContainPredefinedClass" :style="{
                  background: !postHasPredefinedClass
                    ? postContainPredefinedClass + ' !important'
                    : '',
                }" class="news-feed-pic-detials-posts flex-column px-2" @click="pushPostDetail(item.id)"
              >
                <h1 v-html="getColorPostFullContent" > </h1>
                <div v-if="show_more_content_color_post">
                  <PostHashTags v-show="more_less === '...less'" :allTags="item.tags" class="mb-2" />
                  <span class="text_blue" @click="changeTextLength($event)">{{ more_less }}</span>
                </div>
                <PostHashTags v-else  :allTags="item.tags" />
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Color Post End -->


    <!-- Check in post Start -->
    <div v-if="post_type === 'check_in'"  class="row">
      <div class="col">
        <div class="news-feed-post-detail">
          <div class="news-feed-post-detail-list">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials my-auto mx-auto">
              <div class="user_and_link">
                <h1
                  @click="redirectWidgetsToProfilePage($event, item && item.user && item.user.username ? item.user.username : '')">
                  {{ name }}
                </h1>
                <p class="">
                  is at
                  {{ item.post_type_description }}
                </p>
                <!-- <p>@SarahHogan</p> -->
                <span class="user_dot">
                  <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1" />
                  </svg>
                </span>
                <p class="post-time">{{ time }} </p>
                <span class="icon_globe">
                   <component :is="getSvg(item.access)" />
                </span>

                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="w-100" v-if="item.full_content" @click="pushPostDetail(item.id)" >
                <p class="full-content" v-if="!is_edit_post">
                  <span v-html="getFullContent"></span>
                  <span v-if="item.full_content && item.full_content.length > 149" class="text_blue"  @click="changeTextLength($event)">{{more_less}}</span>
                  <span style="margin-left: 20px" v-if="getEmoji(item.post_type_description)"><img width="60"
                      :src="getEmoji(item.post_type_description)" /></span>
                </p>
              </div>
              <div style="text-align: center" class="w-100" v-else @click="pushPostDetail(item.id)">
                <p v-if="!is_edit_post">
                  <span v-if="getEmoji(item.post_type_description)"><img width="60"
                      :src="getEmoji(item.post_type_description)" /></span>
                </p>
              </div>
              <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                @click="stop_propagation($event)" />
              <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                Edit Post
              </button>

              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Check in Post End -->

    <!-- Event Post Start -->
    <div v-if="post_type === 'events'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image" @click="pushPostDetail(item.id)">
          <div class="useinfo_photoimage">
            <div class="profile_content">
              <span class="news-feed-pic"><img :src="require(`@/assets/img/pp.png`)" /></span>
            </div>
            <div class="news-feed-pic-detials-photo-video-interset">
              <div class="user_and_link m_b-10">
                <h1>Sarah Hogan</h1>
                <p class="link_share">added an event</p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg></span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                  <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>

              <div class="artist-timeline-description">
                Since i just played a Live-Set in a storm, on the 12th floor of
                a skyscraper without exterior walls, under extreme
                conditions.... <a href="javascript:;"> more</a>
              </div>
              <div class="news-feed-pic-detials-event">
                <div class="news-feed-pic-detials-event-img">
                  <img :src="require(`@/assets/img/image4.png`)" />
                </div>
                <div class="event-name-detail">
                  <div class="event-name-detail-mains">
                    <div class="events-calender">
                      <p>MAY</p>
                      <span>28</span>
                    </div>
                    <div class="events-heading-sub-heading">
                      <h1>TIME:CODE presents Stephan Bodzin on Mars</h1>
                      <p>Albert Hall in Manchester</p>
                      <span>08:00 PM - May 29 05:00 AM</span>
                    </div>
                    <div class="user_ev">
                      <svg fill="none" height="12" viewBox="0 0 13 12" width="13" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" />
                        <path
                          d="M1.01197 10.9989C1.01197 10.9989 0.573983 8.51687 4.99598 8.42188C4.99598 8.42188 7.99595 8.42188 8.12995 8.42188C12.23 8.42188 12.276 10.9989 12.276 10.9989"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" />
                      </svg>
                      <span>000</span>
                    </div>
                  </div>
                  <div class="btns_ev_detail">
                    <div class="feed-btns-details">
                      <div class="news-feed-radio radio-awitch">
                        <input id="radio-one1" checked="checked" name="switch-one" type="radio" value="yes" />
                        <label for="radio-one1">Interested</label>
                        <input id="radio-two2" name="switch-one" type="radio" value="no" />
                        <label for="radio-two2">Going</label>
                      </div>
                    </div>
                    <div class="buy-tickets-btn">
                      <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 11.3759L1.24704 11.623L3.11533 13.4912L3.36238 13.7383L3.60944 13.4912C4.22635 12.8744 5.21598 12.8744 5.83285 13.4912C6.44976 14.1082 6.44976 15.0978 5.83285 15.7147L5.58579 15.9617L5.83285 16.2088L7.70114 18.0771L7.94818 18.3241L8.19523 18.0771L18.0771 8.19522L18.3241 7.94818L18.0771 7.70113L16.2088 5.83284L15.9618 5.58579L15.7147 5.83284C15.0978 6.44973 14.1082 6.44975 13.4913 5.83284C12.8744 5.21595 12.8744 4.22632 13.4913 3.60942L13.7383 3.36238L13.4913 3.11533L11.623 1.24705L11.3759 1L11.1289 1.24705L1.24704 11.1289L1 11.3759ZM1.98819 11.3759L8.28786 5.07626L9.02899 5.8174L9.5231 5.3233L8.78195 4.58217L11.3759 1.98819L12.7733 3.38554C12.1258 4.27258 12.1974 5.52718 12.9972 6.32693C13.7969 7.12666 15.0477 7.19432 15.9347 6.54696L17.3359 7.94818L14.7419 10.5422L14.0008 9.80103L13.5067 10.2951L14.2479 11.0362L7.94817 17.3359L6.54696 15.9347C7.19432 15.0477 7.12668 13.7969 6.32694 12.9972C5.52718 12.1974 4.27257 12.1258 3.38554 12.7733L1.98819 11.3759ZM10.0326 6.82102L10.5113 7.29967L11.0054 6.80558L10.5267 6.32693L10.0326 6.82102ZM12.0244 8.81283L12.5031 9.2915L12.9972 8.79739L12.5185 8.31874L12.0244 8.81283Z"
                          fill="white" stroke="white" stroke-width="0.4" />
                      </svg>

                      Buy Tickets
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <div class="artist-timeline-action">
                    <svg fill="none" height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Event Post End -->

    <!-- Shared Post Start -->
    <div v-if="post_type === 'share_link'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image" @click="pushPostDetail(item.id)">
          <div class="useinfo_photoimage">
            <div class="profile_content">
              <span class="news-feed-pic"><img :src="require(`@/assets/img/pp.png`)" /></span>
            </div>

            <div class="news-feed-pic-detials-photo-video-interset">
              <div class="user_and_link m_b-10">
                <h1>Sarah Hogan</h1>
                <p class="link_share">Shared a link</p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg></span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                  <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="artist-timeline-description">
                Since i just played a Live-Set in a storm, on the 12th floor of
                a skyscraper without exterior walls, under extreme
                conditions.... <a href="javascript:;"> more</a>
              </div>
              <div class="news-feed-artist-post">
                <div class="news-feed-artist-post-img newsfeed_artist-post_pic">
                  <img :src="require(`@/assets/img/post-feed.png`)" />
                </div>
                <div class="news-feed-artist-post-detail">
                  <h1>Me or produce besides hastily on running the sun</h1>
                  <p>
                    Considered an invitation do introduced and more that that
                    is...
                  </p>
                  <button>MORE</button>
                </div>
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <div class="artist-timeline-action">
                    <svg fill="none" height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg" d="M0.999999 9L1 3.28545L15 3.28545L10.9989 1"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                      <path class="artist-timeline-action-svg" d="M15 8L15 12.9548L1 12.9548L5.81381 15"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="tag-activity">
                    <svg fill="none" height="16" viewBox="0 0 19 16" width="19" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M1 12.4358C1 12.4358 2.34885 6.17675 10.4363 5.97632C10.5603 4.31967 10.5603 2.65665 10.4363 1L18 7.83691L10.4363 15V9.74725C10.4363 9.74725 6.00177 8.4783 1 12.4358Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Shared Post End -->

    <!-- Article Post Start -->
    <div v-if="post_type === 'article'">
      <ArticlePost :item="item" :index="index" :post="item" @pushPostDetail="pushPostDetail"
        :comment_check="comment_check" :userPicture="userPicture ? userPicture : user_picture"
        :page_check="pageCheck" :activeTab="activeTab" />
    </div>
    <!-- Article Post End -->

    <!-- Shared Post Start -->
    <div v-if="post_type === 'shared'" class="row">
      <div class="col">
        <div class="news-feed-post-detail" @click="pushPostDetail(item.id)">
          <div class="news-feed-post-detail-list">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic"><img src="/img/pp.88db33c4.png" /><span class="online-signal"></span></span>
            </div>
            <div class="news-feed-pic-detials">
              <div class="user_and_link m_b-10">
                <h1>Sarah Hogan</h1>
                <p class="link_share">is feeling shocked</p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg></span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                    <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="feealing_expression">
                <p>Is feeling shocked</p>
                <svg fill="none" height="61" viewBox="0 0 60 61" width="60" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
                    fill="#F8DC24" />
                  <path d="M39 12L44.6964 14.5893L50 17" stroke="#C4B135" stroke-linecap="round" stroke-width="2" />
                  <path d="M11 20.9961L15.6615 18.9262L20.0015 16.999" stroke="#C4B135" stroke-linecap="round"
                    stroke-width="2" />
                  <path
                    d="M17.0629 34.4991C17.0629 34.4991 19.5629 34.4991 21.5629 33.4991C23.5629 32.4991 24.5629 30.9991 24.5629 30.9991"
                    stroke="#F5CC1A" stroke-linecap="round" stroke-width="2" />
                  <path
                    d="M34.4145 32.1383C34.4145 32.1383 35.9961 34.0744 38.0359 34.9906C40.0756 35.9068 41.8699 35.7322 41.8699 35.7322"
                    stroke="#F5CC1A" stroke-linecap="round" stroke-width="2" />
                  <path
                    d="M57.76 19C57.86 19.9452 57.92 20.9003 57.92 21.8653C57.92 38.3509 43.07 51.7124 24.74 51.7124C16.89 51.7124 9.68 49.255 4 45.156C9.2 54.0306 18.86 60 29.93 60C46.5 60 59.93 46.6384 59.93 30.1529C59.93 26.2031 59.15 22.4424 57.76 19Z"
                    fill="#F1C631" />
                  <path
                    d="M43 55.4561C43 53.5473 36.0751 52 30 52C23.9249 52 17 53.5473 17 55.4561L18.0467 58.3574C18.6187 59.9431 20.1236 61 21.8093 61L38.1907 61C39.8764 61 41.3813 59.9431 41.9533 58.3574L43 55.4561Z"
                    fill="#F1C631" />
                  <path
                    d="M39 44.4122C39 39.734 34.9706 35.9416 30 35.9416C25.0294 35.9416 21 39.734 21 44.4122L21 56C21 57.1046 21.8954 58 23 58L37 58C38.1046 58 39 57.1046 39 56L39 44.4122Z"
                    fill="#393C54" />
                  <path
                    d="M38 58C38 52.4772 34.4183 48 30 48C25.5817 48 22 52.4772 22 58C22 58 26.25 58 30 58C33.75 58 38 58 38 58Z"
                    fill="#5F4158" />
                  <path
                    d="M38 58C38 55.2386 34.4183 53 30 53C25.5817 53 22 55.2386 22 58C22 58 26.25 58 30 58C33.75 58 38 58 38 58Z"
                    fill="#F85565" />
                  <path
                    d="M42 33C45.866 33 49 29.866 49 26C49 22.134 45.866 19 42 19C38.134 19 35 22.134 35 26C35 29.866 38.134 33 42 33Z"
                    fill="white" />
                  <path
                    d="M41.8801 29.6294C43.8186 29.6294 45.3901 28.0579 45.3901 26.1194C45.3901 24.1809 43.8186 22.6094 41.8801 22.6094C39.9416 22.6094 38.3701 24.1809 38.3701 26.1194C38.3701 28.0579 39.9416 29.6294 41.8801 29.6294Z"
                    fill="#383B53" />
                  <path
                    d="M18 31C20.7614 31 23 28.7614 23 26C23 23.2386 20.7614 21 18 21C15.2386 21 13 23.2386 13 26C13 28.7614 15.2386 31 18 31Z"
                    fill="white" />
                  <path
                    d="M18 29C19.6569 29 21 27.6569 21 26C21 24.3431 19.6569 23 18 23C16.3431 23 15 24.3431 15 26C15 27.6569 16.3431 29 18 29Z"
                    fill="#383B53" />
                </svg>
              </div>
              <div class="coment-sepr"></div>
              <div class="artist-timeline-activity">
                <div class="activity-main" v-if="!sharedPost">
                  <div class="artist-timeline-action">
                    <svg fill="none" height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="artist-timeline-action">
                    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg" d="M0.999999 9L1 3.28545L15 3.28545L10.9989 1"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                      <path class="artist-timeline-action-svg" d="M15 8L15 12.9548L1 12.9548L5.81381 15"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                    000
                  </div>
                  <div class="tag-activity">
                    <svg fill="none" height="16" viewBox="0 0 19 16" width="19" xmlns="http://www.w3.org/2000/svg">
                      <path class="artist-timeline-action-svg"
                        d="M1 12.4358C1 12.4358 2.34885 6.17675 10.4363 5.97632C10.5603 4.31967 10.5603 2.65665 10.4363 1L18 7.83691L10.4363 15V9.74725C10.4363 9.74725 6.00177 8.4783 1 12.4358Z"
                        stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Shared Post End -->

    <!-- Shared Post Start -->
    <div v-if="category === 'share' || category === 'repost'" class="row">
      <div class="col">
        <div class="
            news-feed-post-detail news_feed_post_with_image
            feed_options_update
          ">
          <div class="useinfo_photoimage">
            <div class="news-feed-post-user-detail-first">
              <span class="news-feed-pic">
                <UserPicture :picture="userPicture ? userPicture : user_picture" :item="item" />
                <span class="online-signal"></span>
              </span>
            </div>
            <div class="news-feed-pic-detials-photo-video-poll">
              <div class="user_and_link">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  {{ name }}
                </h1>
                <p class="link_share">{{ item.post_type_description }}</p>
                <span class="user_dot"><svg fill="none" height="2" viewBox="0 0 2 2" width="2"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg>
                </span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                <component :is="getSvg(item.access)" />
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="row">
                <p class="full-content" v-if="!is_edit_post"
                  @click="pushPostDetail(item.id)" >
                  <span v-html="getFullContent"></span>
                </p>
                <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
                  @click="stop_propagation($event)" />
                <button v-if="is_edit_post" type="button" @click="editSubmit($event)">
                  Edit Post
                </button>
              </div>
              <!--              content-->
              <div v-if="
                post_type === 'Song' ||
                post_type === 'Album' ||
                post_type === 'Podcast' ||
                (post_type === 'Post' && (item.shareable_item) && (item.shareable_item.shareable_item) && (item.shareable_item.shareable_item.type.toLowerCase() === 'song' || item.shareable_item.shareable_item.type.toLowerCase() === 'album'))
              " class="repost-and-share-card">
                <SongOrAlbumTileCard :item="(post_type === 'Post' ) ? item.shareable_item.shareable_item : item.shareable_item" :singles="true" :rating="false" :number="false" :share-check="true" />
              </div>
              <SharedPost v-else :post="item.shareable_item" :same-user="sameUserInShared"
                @openSharedPost="pushPostDetail(item.id)" />
              <!--              content-->
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity mt-2">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
            @comments_count="commentsCount" @addComment="addCommentInList"
            @toggleCommentSection="toggleCommentSection" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Shared Post End -->

    <!-- Share Profile Url Post Start -->
    <div v-if="category === 'share_profile'" class="row">
      <div class="col">
        <div class="news-feed-post-detail news_feed_post_with_image" @click="pushPostDetail(item.id)">
          <div class="useinfo_photoimage">
            <div
              @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )"
              class="profile_content">
              <span class="news-feed-pic"><img :src="item.user.artwork_url" /></span>
            </div>
            <div class="news-feed-pic-detials-photo-video">
              <div class="user_and_link m_b-10">
                <h1 @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    item && item.user && item.user.username
                      ? item.user.username
                      : ''
                  )
                ">
                  {{item.user.full_name}}
                </h1>
                <p class="link_share">Shared a link</p>
                <span class="user_dot">
                  <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" fill="#8B949F" r="1"></circle>
                  </svg></span>
                <p class="post-time">{{ time }}</p>
                <span class="icon_globe">
                  <svg fill="none" height="10" viewBox="0 0 12 10" width="12" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 9.5C5.25 9.5 4.5 9.5 4.5 8.75C4.5 8 5.25 5.75 8.25 5.75C11.25 5.75 12 8 12 8.75C12 9.5 11.25 9.5 11.25 9.5H5.25ZM8.25 5C8.84674 5 9.41903 4.76295 9.84099 4.34099C10.2629 3.91903 10.5 3.34674 10.5 2.75C10.5 2.15326 10.2629 1.58097 9.84099 1.15901C9.41903 0.737053 8.84674 0.5 8.25 0.5C7.65326 0.5 7.08097 0.737053 6.65901 1.15901C6.23705 1.58097 6 2.15326 6 2.75C6 3.34674 6.23705 3.91903 6.65901 4.34099C7.08097 4.76295 7.65326 5 8.25 5Z"
                      fill="#8B949F" />
                    <path clip-rule="evenodd"
                          d="M3.912 9.50032C3.80082 9.26618 3.74537 9.00948 3.75 8.75032C3.75 7.73407 4.26 6.68782 5.202 5.96032C4.73182 5.81545 4.24196 5.7446 3.75 5.75032C0.75 5.75032 0 8.00032 0 8.75032C0 9.50032 0.75 9.50032 0.75 9.50032H3.912Z"
                          fill="#8B949F" fill-rule="evenodd" />
                    <path
                      d="M3.375 5C3.87228 5 4.34919 4.80246 4.70083 4.45083C5.05246 4.09919 5.25 3.62228 5.25 3.125C5.25 2.62772 5.05246 2.15081 4.70083 1.79917C4.34919 1.44754 3.87228 1.25 3.375 1.25C2.87772 1.25 2.40081 1.44754 2.04917 1.79917C1.69754 2.15081 1.5 2.62772 1.5 3.125C1.5 3.62228 1.69754 4.09919 2.04917 4.45083C2.40081 4.80246 2.87772 5 3.375 5Z"
                      fill="#8B949F" />
                  </svg>
                </span>
                <PostDetailDropDown :post="item" :global_type="item.global_type" :activeTab="activeTab" />
              </div>
              <div class="artist-timeline-description">
                <a :href="item.post_type_description">{{item.post_type_description}}</a>
              </div>
              <div class="thoms-post">
                <img :src="item.shareable_item.cover_photo_url" />
              </div>
            </div>
          </div>
          <div class="news-feed-post-detail-list">
            <div class="news-feed-pic-detials">
              <div class="artist-timeline-activity mt-2">
                <div class="activity-main" v-if="!sharedPost">
                  <PostLike :index="index" :item="item" :page_type="pageCheck" :announcement_check="(activeTab === 'announcements')"/>
                  <div @click="toggleCommentSection($event)">
                    <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" />
                  </div>
<!--                  {{ item.id }}-->
                  <PostShare :post="item" :id="item.id"/>
                </div>
              </div>
            </div>
          </div>
          <CommentInput v-if="write_comment && item.allow_comments === 1" :item="item" :comment_check="comment_check"
                        @comments_count="commentsCount" @addComment="addCommentInList"
                        @toggleCommentSection="toggleCommentSection" @commentToggle="toggleCommentSection"/>
        </div>
      </div>
    </div>
    <!-- Share Profile Url Post End -->
  </div>
</template>
<script>
import UserPicture from "../components/UserPicture";
import CommentInput from "./CommentInput";
import SharedPost from "./SharedPost";
import ArticlePost from "../Posts/Article";
import PostLike from "./PostLike";
import PostComment from "./PostComment";
import VoteItem from "./VoteItem";
import AppConfig from "../../../../configs/AppConfig";
import {
  create_post_api,
  post_comment_form_data_api,
} from "../../../../apis/APIs";
import PostDetailDropDown from "./PostDetailDropDown";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import { SUCCESS } from "../../../../store/types";
import SongOrAlbumTileCard from "../../../DesignGuide/Music/Components/SongOrAlbumTileCard";
import PostHashTags from "../components/PostHashTags.vue";
import PublicSvg from "./PublicSvg.vue";
import FriendsSvg from "./FriendsSvg.vue";
export default {
  name: "PostDetail",
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    comment_check: {
      type: Boolean,
      default: false,
    },
    sharedPost: {
      type: Boolean,
      default: false,
    },
    pageCheck: {
      type: String,
      default: "",
    },
    userPicture: {
      type: String,
      default: "",
    },
    activeTab: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      write_comment: false,
      comment_count: 0,
      is_drop_down_open: false,

      edit_post: "",
      is_edit_post: false,
      edit_post_loading: false,

      is_edit_poll_vote_local: false,

      selected_poll_option: undefined,
      change_vote_count: 0,
      text_length: 150,
      more_less: "...more",
      postContent: ""
    };
  },
  components: {
    // CommentList,,
    SharedPost,
    ArticlePost,
    CommentInput,
    PostLike,
    PostComment,
    VoteItem,
    PostDetailDropDown,
    UserPicture,
    SongOrAlbumTileCard,
    PostHashTags,
  },
  mounted() {
    this.init();
    this.$store.dispatch("getActivities");
  },
  watch: {
    // comment_count(value) {
    //   if (this.$parent && this.$parent.increment_comment_count) this.$parent.increment_comment_count(value);
    // },
    item(value) {
      this.init();
    },
  },
  computed: {
    getFullContent() {
      // regular expression which remove a html elements <a> tag from full content
      const text = this.item.full_content ? this.item.full_content.replace(/<a[^>]*>.*?<\/a>/gi, '') : '';

      const content = text ? text.slice(0, this.text_length) : '';
      return content;
    },
    getColorPostFullContent() {
      // regular expression which remove a html elements <a> tag from full content
      const text = this.item.short_content ? this.item.short_content.replace(/<a[^>]*>.*?<\/a>/gi, '') : '';

      const content = text ? text.slice(0, this.text_length) : '';
      return content;
    },
    show_more_content_color_post() {
      const text = this.item.short_content ? this.item.short_content.replace(/<a[^>]*>.*?<\/a>/gi, '') : '';
      return text.length > 150;
    },
    show_more_content() {
      const text = this.item.full_content ? this.item.full_content.replace(/<a[^>]*>.*?<\/a>/gi, '') : '';
      return text.length > 150;
    },
    ...mapGetters({
      user: "user_map_to_props",
    }),
    isPostIsActivity() {
      return !!this.activities.some((e) => e.title === this.post_type);
    },
    activities() {
      return this.$store.getters.getPostsActivities;
    },
    postContainPredefinedClass() {
      if (this.item.post_type === "coloured") {
        return this.item.post_type_description;
      }
      return " ";
    },
    postHasPredefinedClass() {
      if (this.item.post_type === "coloured") {
        return !this.item.post_type_description.includes("#");
      }
      return false;
    },
    post_type() {
      const { post_type } = this.item || {};

      return post_type;
    },
    shareable_post_type() {
      const { shareable_item } = this.item || {};
      return shareable_item.post_type;
    },
    category() {
      const { category } = this.item || {};
      return category;
    },
    photos() {
      const { photo } = this.item;

      return photo && photo.length ? photo : [];
    },
    poll_options() {
      const { options } = this.item;

      return options && options.length ? options : [];
    },
    data_photos() {
      const { artwork_url } = this.item;

      return artwork_url && artwork_url.length
        ? artwork_url.map((ele) =>
          Object.assign(ele, {
            image: `${AppConfig.post_image_url}/${ele.source}`,
          })
        )
        : [];
    },
    data_video() {
      const { artwork_url } = this.item;

      return artwork_url && artwork_url.length
        ? artwork_url.map((ele) => {
          return {
            source: ele.artwork_url,
            properties: ele.custom_properties,
            name: ele.name,
            images: ele.thumb_url,
          };
        })
        : [];
    },
    data_photos_length() {
      const { artwork_url } = this.item;

      return artwork_url && artwork_url.length ? artwork_url.length : 0;
    },
    name() {
      const { user_name } = this.item || {};
      return `${user_name}`;
    },
    user_picture() {
      const { user } = this.item || {};

      return user && user.artwork_url ? user.artwork_url : "";
    },
    time() {
      const { created_at } = this.item || {};
      return moment(created_at).fromNow();
    },
    sameUserInShared() {
      return !!(
        this.item.shareable_item &&
        this.item.user_id === this.item.shareable_item.user_id
      );
    },
  },
  methods: {
    ...mapActions(["get_post_data"]),
    init() {
      const { comment_count } = this.item || {};
      this.comment_count = comment_count;
    },
    pushPostDetail: async function (id) {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("getActivities");
      this.$store.commit("SET_POST_OFFSET", 0);
      const { status } = await this.$store.dispatch("getIndividualPost", id);
      if (status == 'success') {
        if (this.pageCheck) {
          const pathname = `/home/page/${this.pageCheck}/post-detail/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        } else {
          const pathname = `/home/page/post-detail/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        }
      }
    },
    getSvg(access) {
      switch (access) {
        case 'public':
          return PublicSvg;
        case 'friends':
          return FriendsSvg;
        default:
          return FriendsSvg;
      }
    },
    open_post_image_detail: async function (id) {
      await this.$store.dispatch("getActivities");
      this.$store.commit("SET_POST_OFFSET", 0);
      const { status } = await this.$store.dispatch("getIndividualPost", id);
      if (status == SUCCESS) {
        if (this.pageCheck === "timeline") {
          const pathname = `/home/page/${this.pageCheck}/feed-image/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        } else {
          const pathname = `/home/page/feed-image/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        }
      }
    },
    open_post_video_detail: async function (id) {
      await this.$store.dispatch("getActivities");
      this.$store.commit("SET_POST_OFFSET", 0);
      const { status } = await this.$store.dispatch("getIndividualPost", id);
      if (status == SUCCESS) {
        const pathname = `/home/page/feed-video/${id}`;
        if (this.expandable) return;
        await this.$router.push(pathname);
      }
    },
    openPostNewTab: async function (id) {
      if (this.pageCheck === "timeline") {
        const pathname = `/home/page/${this.pageCheck}/post-detail/${id}`;
        window.open(pathname);
      } else if (this.pageCheck === "artist") {
        const pathname = `/home/page/${this.pageCheck}/post-detail/${id}/${this.$route.params.id}`;
        window.open(pathname);
      } else {
        const pathname = `/home/page/post-detail/${id}`;
        window.open(pathname);
      }
    },
    toggleCommentSection(e) {
      if (e) e.stopPropagation();
      this.write_comment = !this.write_comment;
    },
    editPost() {
      this.is_edit_post = true;
      this.edit_post = this.item && this.item.text ? this.item.text : "";
    },
    cancelEditPost() {
      this.is_edit_post = false;
      this.edit_post = "";
    },
    incrementCommentCount() {
      this.comment_count = parseInt(this.comment_count) + 1;
    },
    async submit() {
      const { edit_post, edit_post_loading } = this;

      const { user_data } = this.user || {};
      const { user_id } = user_data || {};

      return;

      if (edit_post_loading || !edit_post) return;

      try {
        const query = `query`;

        this.edit_post_loading = true;
        this.message = "";

        const { success, message } = await post_comment_form_data_api(query);
        this.edit_post_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.init();
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }
      } catch (error) {
        this.edit_post_loading = false;
      }
    },
    async editSubmit(e) {
      if (e) e.stopPropagation();

      const { edit_post, edit_post_loading } = this;
      const post_id = this.item.id;

      const { user_data } = this.user || {};
      const user_id = user_data.id;

      // if(edit_post_loading || (!edit_post)) return;

      try {
        const query = `?action=2&user_id=${user_id}&post_id=${post_id}&text=${edit_post}`;

        this.edit_post_loading = true;
        this.message = "";

        const { success, message } = await create_post_api(query);
        this.edit_post_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.$parent.init();
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }
      } catch (error) {
        console.error("post edit ===> error", error);
        this.edit_post_loading = false;
      }
    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.submit();
      }
    },
    async deleteSubmit(e) {
      if (e) e.stopPropagation();

      const { user_data } = this.user || {};
      const { user_id } = user_data || {};

      const { post_id } = this.item || {};

      try {
        let url = `?action=3&post_id=${post_id}`;

        const { success, message } = await create_post_api(url);

        if (success === 1) {
          this.$parent.init();
        } else {
        }
      } catch (error) { }
    },
    getChangeVoteCountAction(item) {
      const { selected_poll_option } = this;

      const { option_id } = item;

      if (selected_poll_option === undefined) return 0;

      if (typeof selected_poll_option === "string") {
        return option_id === selected_poll_option ? 1 : -1;
      }

      return 0;
    },
    select_poll_option(value) {
      this.selected_poll_option = value;
    },
    stop_propagation(e) {
      if (e) e.stopPropagation();
    },
    getEmoji(feeling) {
      const activityIndex = this.activities.findIndex(
        (e) => e.title === "Feeling"
      );
      if (activityIndex !== null) {
        if (this.activities[activityIndex].emoticons) {
          const feelingIndex = this.activities[
            activityIndex
          ].emoticons.findIndex((ele) => ele.title === feeling);
          if (feelingIndex !== -1) {
            return this.activities[activityIndex].emoticons[feelingIndex]
              ? this.activities[activityIndex].emoticons[feelingIndex]
                .artwork_url
              : false;
          }
        }
      }
      return false;
    },
    reset_data() {
      this.edit_post = "";
      this.is_edit_post = false;
      this.edit_post_loading = false;
    },
    commentsCount(comments_count) {
      if (comments_count > this.comment_count) {
        this.comment_count = comments_count;
      }
    },
    addCommentInList(comment) {
      this.$emit("addCommentInList", comment);
    },
    changeTextLength(event) {
      event.stopPropagation()
      if (this.more_less === "...more") {
        this.more_less = "...less";
        this.text_length = Infinity;
      } else if (this.more_less === "...less") {
        this.more_less = "...more";
        this.text_length = 150;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-text {
  color: #D1433A;
  font-size: 15px;
  font-weight: 700;
}

.post-detail {
  .full-content {
    font-family: HelveticaNeueNormal;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    word-break: break-all;
  }
.text_blue{
  color : #D1433A;
}
  .news-feed-post-detail {
    .useinfo_photoimage {
      .repost-and-share-card {
        border-radius: 2px;
        background: #f7f7f7;
        padding: 30px 20px 20px 20px;
      }
    }
  }
}
</style>
