<template>
  <div>
    <div class="container_xxl mt-2">
      <div class="row" style="margin-bottom: 100px;">
        <div class="col-md-8 margin-top-30 pl-0 ">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{
              message
            }}
          </div>
          <div v-if="success_message" class="alert alert-success" role="alert">
            {{
              success_message
            }}
          </div>
          <div class="editor-selects ">
            <div class="title-fields">
              <label>Title</label>
              <input type="text" v-model="article.title" name="create_post_form_article_title"/>
              <label class="error_msg error_msg_red" v-if="_handleErrorMessage('title')">{{
                  _handleErrorMessage("title")
                }}</label>
            </div>
            <div class="title-fields">
              <label>Content</label>

              <!-- <ckeditor class="forum-editor" style="border-radius: 4px !important;" v-model="article.full_content" :config="editorConfig"></ckeditor> -->
              <quill-editor ref="myTextEditor" v-model="article.full_content" :config="customToolbar">
              </quill-editor>

              <label class="error_msg error_msg_red" v-if="_handleErrorMessage('content')">{{
                  _handleErrorMessage("content")
                }}</label>
            </div>
            <div class="publish-btn " @click="addArticle">
              <button class="w-200">{{
                  loading ? "PLEASE WAIT..." : "PUBLISH"
                }}
              </button>
            </div>
          </div>

        </div>
        <div class="col-md-4 margin-top-30 pr-0 ">
          <div class="cover-widget">
            <h1>Cover</h1>
            <div class="upload-cover-detail">
              <input type="file" accept="png/image, jpeg/image, jpg/image" id="new-article-cover" @change="updateImage">
              <svg v-if="!photo_src" width="30" height="30" viewBox="0 0 30 30" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25 18.3333V22.7777C25 23.3671 24.7659 23.9323 24.3491 24.349C23.9324 24.7658 23.3671 24.9999 22.7778 24.9999H7.22222C6.63285 24.9999 6.06762 24.7658 5.65087 24.349C5.23413 23.9323 5 23.3671 5 22.7777V18.3333"
                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.5554 10.5556L14.9999 5L9.44434 10.5556" stroke="#47505B" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 5V18.3333" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <img v-if="photo_src" :src="photo_src" height="50"/>
            </div>
            <span class="image-error">{{imageError}}</span>
          </div>
          <div class="category-sections-main">
            <div class="category-section-fieldsdd">
              <label>Category</label>
              <div class="in_fields">
                <Select2 v-model="article.article_category" placeholder="Choose categories" :options="categories"
                         :settings="{ multiple: true, tags: true }"/>
              </div>
              <label class="error_msg error_msg_red" v-if="_handleErrorMessage('category')">{{
                  _handleErrorMessage("category")
                }}</label>
            </div>
            <div class="category-section-fieldsdd">
              <label>Tags</label>
              <div class="in_fields">
                <Select2 v-model="article.tag_list" placeholder="Tags" :options="tagsList"
                         :settings="{ multiple: true, tags: true }"/>
              </div>
            </div>
            <div class="category-section-fields">
              <label>Sponsorship Content</label>
              <div class="for-all-user Sponsors-section for-all-user-sp">
                <div class="action-block ">
                  <div class="switch-button">
                    <label class="switch "> <input type="checkbox" v-model="publicAccess" checked="checked"  class="default"> <span
                      class="slider round"></span> </label>
                  </div>
                  For All Users
                </div>
              </div>
            </div>
            <b-button class="select-audience-button" v-b-modal="'my-modal'"  @click="selectedTab = 'selectAudience'">
              {{audience ? audience.toUpperCase().replace(/_/g, ' ') : 'Choose Audience'}}
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </b-button>
          </div>
        </div>
      </div>

    </div>
    <div >

      <b-modal id="my-modal" hide-footer hide-header v-if="selectedTab !== null" >
        <SelectAudience  @goBackToPostMain="goBackToPostMain"  :selected-tab="audience"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import CKEditor from 'ckeditor4-vue';
import SelectAudience from '../NewsFeed/components/selectAudience';
import {CREATE_POST_FORM_ARTICLE_CONTENT, CREATE_POST_FORM_ARTICLE_TAGS, SUCCESS, ERROR} from '../../../store/types';
import Select2 from 'v-select2-component';
// import { mapGetters } from "vuex";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css';
import audience from "../../Events/components/Detail/Audience.vue";

export default {
  name: 'createArticleUserProfile',
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      // editorData: '',
      // editorConfig: {},
      photo: undefined,
      photo_src: undefined,
      tag_value: [],
      errors: [],
      imageError: "",
      access: 'Public',
      audience: 'Public',
      article: {
        title: null,
        full_content: null,
        category:'article',
        post_type: 'article',
        short_content: null,
        post_type_description: 'post and article',
        article_category: [],
        tag_list: [],
        artwork: [],
      },
      deleteMedia: [],
      publicAccess:false,
      loading: false,

      message: "",
      success_message: "",
      selectedTab:'',
      initialDataConfiguration: null
    }
  },
  components: {
    SelectAudience,
    // ckeditor: CKEditor.component,
    Select2,
    quillEditor
  },
  mounted() {
    this.init();
  },

  watch: {
    editorData(value) {
      this.updateCreatePostFormData({
        [CREATE_POST_FORM_ARTICLE_CONTENT]: value
      })
    },
    tag_value(value) {
      this.updateCreatePostFormData({
        [CREATE_POST_FORM_ARTICLE_TAGS]: value
      })
    }
  },

  computed: {
    form_data(){
      return this.$store.getters.create_article_forms_map_to_props
    },
    categories(){
      return  this.$store.getters.getArticleCategories.map(obj=>{
        return obj.catergory
      })
    },
    tagsList(){
      return  this.$store.getters.getPostTagsList.map(obj=>{
        return obj.tag
      })
    },
    title() {
      const {title = ""} = this.form_data || {};

      return title;
    },
    content() {
      const {content = ""} = this.form_data || {};

      return content;
    },
    cover() {
      const {cover = ""} = this.form_data || {};
      return cover;
    },
    category() {
      const {category = ""} = this.form_data || {};

      return category;
    },
    tags() {
      const {tags} = this.form_data || {};

      return tags && tags.length ? tags : [];
    },
    articlePreviousRoute () {
      return this.$store.getters.getArticlePreviousRoute
    },
    post(){
      return this.$store.getters.getArticlePost;
    },
    is_edit(){
      return this.$store.getters.getEditPost;
    },
  },

  methods:{
    async init() {
      try {
        if(this.is_edit === true){
          this.article.title = this.post.title;
          this.article.full_content = this.post.full_content;
          this.article.article_category = this.post.categorylist.map(item => item.title);
          this.photo_src = this.post ? this.post.artwork_url[0].original_url : '';
          this.article.tag_list = this.post.tags.map(item => item.tag);
        }
        this.$store.commit('SET_CATEGORY', this.article.article_category)
      } catch (error) {
      }
    },

    goBack() {
      this.$store.commit('SET_ARTICLE',null);
      this.$store.commit('RESET_EDIT');
      if (this.articlePreviousRoute === 'article') {
        this.$router.replace("/home/page/article");
      } else if (this.$route.params.type === 'page') {
        const id = this.$route.params.id
        const subType = this.$route.params.subtype
        this.$router.replace(`/page-manager/page/${subType}/${ id }`)
      } else {
        this.$router.replace("/home/page/news-feed");
      }
    },
    goBackToPostMain(audience) {
      this.audience = audience.toLowerCase().replace(/ /g, '_');
      this.selectedTab = null;
    },
    onChange(e) {
      const {name, value} = e.target || {};

      this.updateCreatePostFormData({
        [name]: value
      })
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
            this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);
      return message;
    },

    updateImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;
      if (file && file.size < 2000000) {
        this.deleteMedia.push(this.post && this.post.artwork_url[0] ? this.post.artwork_url[0].id : '');
        this.article.artwork[0] = file;
        this.photo_src = URL.createObjectURL(file);
        this.imageError = "";
      }else{
        this.photo_src = undefined;
        this.article.artwork[0] = [];
        this.imageError = "File size must be less than 2 MB";
      }
    },
    async addArticle(){
      this.access = this.audience.toLowerCase().replace(/ /g, '_');
      this.$store.commit("SET_ACCESS", this.access)
      try {
        this.errors= []
        let validate = true
        if (!this.article.title) {
          this.errors.push({
            fieldName: "title",
            message: "Required"
          })
          validate =false
        }
        if (!this.article.full_content) {
          this.errors.push({
            fieldName: "content",
            message: "Required"
          })
          validate =false
        }
        if (!(this.article.article_category.length>0)) {
          this.errors.push({
            fieldName: "category",
            message: "Required"
          })
          validate =false
        }
        if(validate){
          if (this.$route.params.type === 'page') {
            const {data} =  await this.$store.dispatch('addArticle', { Article: this.article, global_type: this.$route.params.type, page_id: this.$route.params.id })
            if(data.success &&  data.success === 1){
              this.success_message = "Article Added Successfully";
              let self = this;
              let id = self.$route.params.id;
              let subType = self.$route.params.subtype;
              this.$store.commit('SET_ARTICLE', null);
              this.$store.commit('RESET_EDIT');
              setTimeout(function () {
                self.$router.go(-1);
                // self.$router.push(`/page-manager/page/${subType}/${ id }`);
              },2000)
            }else{
              this.message = (data.success!==null)? data.message:"Please Try again"
            }
          } else {
            if(this.is_edit === true){
              const {data} =  await this.$store.dispatch('addArticle',{ Article: this.article, post_id: this.post.id, deleteMedia: this.deleteMedia })
              if(data.success &&  data.success== 1){
                this.success_message = "Article Updated Successfully";
                let self =this
                this.$store.commit('SET_ARTICLE', null);
                this.$store.commit('RESET_EDIT');
                setTimeout(function () {
                  // self.$router.push("/home/page/news-feed");
                  self.$router.go(-1);
                },2000)
              }else{
                this.message = (data.success!==null)? data.message:"Please Try again"
              }
            } else{
              const {data} =  await this.$store.dispatch('addArticle',{ Article: this.article })
              if(data.success &&  data.success== 1){
                this.success_message = "Article Created Successfully";
                let self =this
                this.$store.commit('SET_ARTICLE', null);
                this.$store.commit('RESET_EDIT');
                this.$emit('OpenCreateArticle')
                setTimeout(function () {
                  // self.$router.push("/home/page/news-feed");
                 // self.$router.go(-1);
                },2000)
              }else{
                this.message = (data.success!==null)? data.message:"Please Try again"
              }
            }
          }

        }
      }catch (e){
        const {data} =e
        if(data){
          this.message = (data.success!==null)? data.message:"Please Try again"
          return
        }
        this.message = (e.statusText)?e.statusText:"Please Try again"
      }
    },

  }
}

</script>

<style lang="scss" scoped>
.image-error{
  color: red;
  font-size: 11px;
  font-weight: 300;
}
.forum-editor {
  .cke_toolbar:nth-child(1){
    display: none;
  }
  .cke_toolbar:nth-child(2){
    display: none;
  }
  .cke_toolbar:nth-child(3){
    display: none;
  }
  .cke_toolbar:nth-child(4){
    display: none;
  }
  .cke_toolbar:nth-child(5){
    display: none;
  }
  .cke_toolbar:nth-child(6){
    display: none;
  }
  .cke_toolbar:nth-child(9){
    display: none;
  }
  .cke_toolbar:nth-child(10){
    display: none;
  }
  .cke_toolbar:nth-child(11){
    display: none;
  }
  .cke_toolbar:nth-child(8){
    display: block !important;
    .cke_toolgroup :nth-child(3){
      display: none;
    }
    .cke_toolgroup :nth-child(5){
      display: none;
    }
  }
}
.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 308px;
  height: 40px;
  background: #F3F3F3;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
  color: rgba(7, 21, 38, 0.6);
}

.btn-secondary:hover {
  color: rgba(7, 21, 38, 0.6);
  background: #F3F3F3;
  border: none;
}

.btn-secondary:active {
  color: rgba(7, 21, 38, 0.6);
  background: #F3F3F3;
  border: none;
}
.btn-secondary:focus {
  color: rgba(7, 21, 38, 0.6);
  background: #F3F3F3;
  border: none;
}
.uppercase {
  text-transform: uppercase;
}

</style>