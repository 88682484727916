<template>
  <div
    :class="[
      'open show left-nav-bar',
      toggleLeftNav ? 'collapse-left-bar' : '',
    ]"
    id="left_sidebar"
  >
    <div
      class="bar-container"
      :class="{ management: category != 'navigation' }"
    >
      <div class="logo">
        <!-- <img
          :src="
            require(`@/assets/icons/desgin-guide/layout/${NavList.header_icon}`)
          "
        /> -->
        <img :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)" />
      </div>
      <div class="logo-collapsed">
        <div class="collapse_logo">
          <img
            :src="
              require(`@/assets/icons/desgin-guide/layout/${NavList.header_icon_collapsed}`)
            "
          />
        </div>
      </div>
      <!-- <div
        class="bar-menu-header"
        :style="{
          'background-color': NavList.type_color
            ? NavList.type_color
            : '#D1433A',
        }"
      > -->
      <div
        class="bar-menu-header"
        :style="{
          'background-color': '#D1433A',
        }"
      >
        <div class="svg-icon">
          <img
            :src="
              require(`@/assets/icons/desgin-guide/layout/type/${NavList.type_icon}`)
            "
          />
        </div>
        <span>{{ NavList.name }}</span>
      </div>
      <div
        v-if="this.category === 'manager' && NavList.select_artist"
        class="music_select"
      >
        <div class="music_dropdown_select" @click="selectMusic = !selectMusic">
          <img
            :src="current_page ? current_page.artwork_url : ''"
            alt="image"
          />
          <span>{{
            (current_page ? current_page.title : "") | shortTheLengthOfString
          }}</span>
<!--          <div class="drop_icon">-->
<!--            <img :src="require(`@/assets/img/svg/dropdown.svg`)" alt="image" />-->
<!--          </div>-->
        </div>
        <ul
          class="music_select_options"
          v-show="selectMusic && current_page.type !== ('artist' || 'company')"
        >
          <li
            v-for="(page, index) in myPagesList"
            :key="index"
            @click="redirectToPage(page)"
          >
            <img :src="page.artwork_url" alt="image" />
            <span>{{ page.title | shortTheLengthOfString }}</span>
          </li>
        </ul>
      </div>
      <!--      //back link-->

      <div
        v-if="NavList.return"
        tag="div"
        @click="url(NavList.return_url)"
        class="return-arrow-item"
      >
        <a>
          <div class="setting-left-icon">
            <span
              :class="`icon-${NavList.return_icon}-stroke`"
              class="icon-stroke"
            ></span>
            <span
              :class="`icon-${NavList.return_icon}-fill`"
              class="icon-fill"
            ></span>
          </div>
          <span class="main-link-tab-music mt-1">{{
            NavList.return_text
          }}</span>
        </a>
        <div class="menu-left-seprator-line my-0"></div>
      </div>
      <!--      //employee & employer switch-->
      <nav v-if="NavList.employee_switch">
        <div class="nav nav-tabs custom_tab" id="nav-tab" role="tablist">
          <a
            @click="changeActiveTab('Employer')"
            :class="[
              'nav-item nav-link headers-style',
              active_tab === 'Employer' ? 'active' : '',
            ]"
            id="nav-home-tab"
            data-toggle="tab"
            href="#Artist"
            role="tab"
            aria-controls="nav-home"
            :aria-selected="active_tab === 'Artist' ? true : false"
            >Employer</a
          >
          <a
            @click="changeActiveTab('Employee')"
            :class="[
              'nav-item nav-link headers-style',
              active_tab === 'Employee' ? 'active' : '',
            ]"
            style="
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            "
            id="nav-contact-tab"
            data-toggle="tab"
            href="#Company"
            role="tab"
            aria-controls="nav-contact"
            :aria-selected="active_tab === 'Company' ? true : false"
            >Employee
          </a>
        </div>
      </nav>
      <!--      //menu list-->
      <perfect-scrollbar class="mt-3">
        <ul class="left-bar-list">
          <div v-if="category === 'navigation' && type !== 'home'">
            <div v-for="(item, index) in NavList.list" :key="index">
              <div v-if="item.type === 'list-item'">
                <li :class="['nav-item', 5 > index ? '' : 'add-playlist']">
                  <a
                    :class="[
                      'list-item with-drop collapsed dropdown-button',
                      index === 5 ? 'left-nav-margin-1' : '',
                    ]"
                    data-toggle="collapse"
                    :href="'#' + item.type + index + type"
                    role="button"
                    aria-expanded="false"
                    :aria-controls="item.type + index + type"
                  >
                    <div class="setting-left-icon">
                      <span
                        :class="`icon-${item.icon}-stroke`"
                        class="icon-stroke"
                      ></span>
                      <span
                        :class="`icon-${item.icon}-fill`"
                        class="icon-fill"
                      ></span>
                    </div>
                    <span class="main-link-tab-music">{{ item.name }}</span>
                  </a>
                </li>
                <div
                  v-if="item.child_list"
                  class="
                    multi-collapse
                    left-bar-menu-links
                    collapse
                    sub-menu-list
                    without-arrow
                  "
                  :id="item.type + index + type"
                  style=""
                >
                  <ul
                    class="
                      subitem-list-ul
                      left-bar-menu-links
                      sub-menu-list-item
                    "
                  >
                    <router-link
                      tag="li"
                      v-for="(child, index2) in item.child"
                      :key="index2"
                      :to="child.url"
                      class="music-sub-menus"
                    >
                      <a href="javascript:">
                        <span class="main-link-tab-sub">{{ child.name }}</span>
                      </a>
                    </router-link>
                  </ul>
                </div>
              </div>
              <li
                tag="li"
                @click="url(item.url)"
                v-if="item.type === 'list-item-shadow'"
                class="nav-item"
              >
                <a
                  class="
                    list-item
                    with-drop
                    collapsed
                    dropdown-button
                    shadow-item
                  "
                  data-toggle="collapse"
                  :href="'#' + item.type + index + type"
                  role="button"
                  aria-expanded="false"
                  :aria-controls="item.type + index + type"
                >
                  <div class="left-icon-shadow">
                    <span
                      :class="`icon-${item.icon}-stroke`"
                      class="icon-stroke"
                    ></span>
                    <!--                      <span :class="`icon-${item.icon}-fill`" class="icon-fill"></span>-->
                  </div>
                  <span class="main-link-tab-music">{{ item.name }}</span>
                </a>
              </li>
              <li v-if="item.type === 'horizontal-line'">
                <div class="menu-left-seprator-line"></div>
              </li>
            </div>
          </div>
          <div v-else>
            <div
              v-for="(item, index) in NavList.list"
              :key="index"
              @click="
                callFuncDynamically(
                  item.function ? item.function : null,
                  item.parameter ? item.parameter : null
                )
              "
            >
              <div v-if="item.type === 'list-item' && item.child_list">
                <li :class="['nav-item']">
                  <a
                    :class="[
                      'list-item with-drop collapsed dropdown-button',
                      index === 5 ? 'left-nav-margin-1' : '',
                    ]"
                    data-toggle="collapse"
                    :href="'#' + item.type + index + type"
                    role="button"
                    aria-expanded="false"
                    :aria-controls="item.type + index + type"
                  >
                    <div class="setting-left-icon">
                      <span
                        :class="`icon-${item.icon}-stroke`"
                        class="icon-stroke"
                      ></span>
                      <span
                        :class="`icon-${item.icon}-fill`"
                        class="icon-fill"
                      ></span>
                    </div>
                    <span class="main-link-tab">{{ item.name }}</span>
                    <div class="ml-auto rotate-icon">
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.50391 1.49414L6.26391 6.17314L1.5039 11.2641"
                          stroke="#8B949F"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
                <div
                  class="
                    multi-collapse
                    left-bar-menu-links
                    collapse
                    sub-menu-list
                  "
                  :id="item.type + index + type"
                  style=""
                >
                  <ul
                    class="
                      subitem-list-ul
                      left-bar-menu-links
                      sub-menu-list-item
                    "
                  >
                    <li
                      tag="li"
                      v-for="(child, index2) in item.child"
                      :key="index2"
                      @click="url(child.url)"
                      class="music-sub-menus"
                    >
                      <a href="javascript:">
                        <span class="main-link-tab-sub">{{ child.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <li
                tag="li"
                @click="url(item.url, item)"
                v-if="
                  item.type === 'list-item' &&
                  !item.child_list &&
                  checkRequiredType(item)
                "
                :class="['nav-item', is_active == 'profile' ? 'active' : '']"
              >
                <a
                  class="list-item with-drop"
                  :class="{ collapsed: item.child_list != null }"
                  data-toggle="collapse"
                  role="button"
                >
                  <div class="setting-left-icon">
                    <span
                      :class="`icon-${item.icon}-stroke`"
                      class="icon-stroke"
                    ></span>
                    <span
                      :class="`icon-${item.icon}-fill`"
                      class="icon-fill"
                    ></span>
                  </div>
                  <span class="main-link-tab">{{ item.name }}</span>
                </a>
              </li>
              <li
                v-if="item.type === 'list-heading'"
                class="nav-item menu-heading mt-4 mb-3"
              >
                <span class="main-link-tab side-bar-text">{{ item.name }}</span>
              </li>
              <li v-if="item.type === 'horizontal-line'">
                <div class="menu-left-seprator-line"></div>
              </li>
              <li
                tag="li"
                @click="url(item.url)"
                v-if="item.type === 'list-item-shadow'"
                class="nav-item"
              >
                <a
                  class="
                    list-item
                    with-drop
                    collapsed
                    dropdown-button
                    shadow-item
                  "
                  data-toggle="collapse"
                  :href="'#' + item.type + index + type"
                  role="button"
                  aria-expanded="false"
                  :aria-controls="item.type + index + type"
                >
                  <div class="left-icon-shadow">
                    <span
                      :class="`icon-${item.icon}-stroke`"
                      class="icon-stroke"
                    ></span>
                    <!--                      <span :class="`icon-${item.icon}-fill`" class="icon-fill"></span>-->
                  </div>
                  <span class="main-link-tab-music">{{ item.name }}</span>
                </a>
              </li>
            </div>
          </div>
        </ul>
      </perfect-scrollbar>
    </div>
    <!--    //collapse button-->
    <div
      :class="[
        ' leftbar-arrow-rotate-icon',
        toggleLeftNav ? 'displace_toggle_button ' : '',
      ]"
      id="toggle_button"
      v-on:click="toggleLeftNav = !toggleLeftNav"
    >
      <span>
        <svg
          width="11"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 34.075 34.075"
        >
          <path
            class="leftbar-arrow-rotate-icon-svg"
            d="M24.57,34.075c-0.505,0-1.011-0.191-1.396-0.577L8.11,18.432c-0.771-0.771-0.771-2.019,0-2.79 L23.174,0.578c0.771-0.771,2.02-0.771,2.791,0s0.771,2.02,0,2.79l-13.67,13.669l13.67,13.669c0.771,0.771,0.771,2.021,0,2.792 C25.58,33.883,25.075,34.075,24.57,34.075z"
            fill="black"
            stroke="#071526"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  </div>
</template>
<script type="application/javascript">
import { HOME_NAV, MANGERS_BARS, NAVIGATION_BARS } from "../../../store/types";
import icons from "../../../assets/icons-files/layout/navigation-bar/icons.js";

export default {
  data() {
    return {
      navigationIcons: icons,
      is_active: "account",
      toggleLeftNav: false,
      selectMusic: false,
      active_tab: "Employer",
      myPagesList: [],
    };
  },
  computed: {
    NavList() {
      if (this.category === "navigation" && NAVIGATION_BARS[this.type]) {
        if (this.type === "music") {        
          return {
            ...NAVIGATION_BARS[this.type],
            list: [
              ...NAVIGATION_BARS[this.type].list,
              ...this.getPlayLists.map(playlist => ({
                type: "list-item",
                name: playlist.title.toUpperCase(),
                icon: "Playlist",
                link: "",
                url: "",
                show: true,
                child_list: false,
              }))
            ],
          };
        }
        return NAVIGATION_BARS[this.type];
      } else if (this.category === "manager" && MANGERS_BARS[this.type]) {
        return MANGERS_BARS[this.type];
      }
      return HOME_NAV;
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    user_name() {
      return this.$store.getters.user_map_to_props.user_data.username;
    },
    getMyPagesByType() {
      return this.$store.getters.GET_MY_PAGES_TYPE;
    },
    previousRoute() {
      return this.$store.getters.getArticlePreviousRoute;
    },
    getPlayLists(){
      return this.$store.getters.getPlaylists;
    }
  },
  props: {
    category: {
      type: String,
      default: "navigation",
    },
    type: {
      type: String,
      default: "home",
    },
  },
  filters: {
    shortTheLengthOfString(value) {
      return value && value.length > 16 ? value.slice(0, 16) + "..." : value;
    },
  },
  components: {},
  watch: {
    "getMyPagesByType.data"() {
      this.myPages();
    },
    "current_page.type"() {
      this.myPages();
      if (
        this.current_page &&
        (this.current_page.type === "page" ||
          this.current_page.type === "club" ||
          this.current_page.type === "party" ||
          this.current_page.type === "artist" ||
          this.current_page.type === "company")
      ) {
        this.$store.commit("SET_PREVIOUS_ROUTE", this.$route.path);
      }
    },
    type() {
      if (
        this.type === "page" ||
        this.type === "club" ||
        this.type === "party" ||
        this.type === "artist" ||
        this.type === "company"
      ) {
        this.$store.commit("SET_PREVIOUS_ROUTE", this.$route.path);
      }
    },
  },
  methods: {
    redirect: function (link, url) {
      this.is_active = link;
      this.$router.push(url).catch((err) => {
        return err;
      });
    },
    clickOutside() {
      console.log("clickOutside");
    },
    callFuncDynamically(functionName, parameter) {
      this.$store.commit("SET_PAGES_TYPE", "");
      this.$store.commit("SET_PAGES_TYPE", parameter);
      // if(!!(functionName))
      // {
      //   console.log('function',functionName+parameter)
      //   (parameter)?this.$data[`${functionName}`](parameter):this.$data[functionName]()
      //   return true
      // }
    },
    changeActiveTab(tab) {
      this.active_tab = tab;
    },
    checkRequiredType(item) {
      if (item.required_type) {
        return item.required_type === this.active_tab;
      }
      return true;
    },
    url: function (val, obj) {
      // console.log("this.type", this.type);
      // console.log("val", val);
      // console.log("this.category", this.category);
      // console.log("obj", obj);

      if (
        this.category === "manager" &&
        (this.type === "page" ||
          this.type === "club" ||
          this.type === "party" ||
          this.type === "artist" ||
          this.type === "company" ||
          this.type === "market") &&
        val !== "event"
      ) {
        if (
          obj &&
          (obj.url.includes("event") ||
            obj.url.includes("jobs") ||
            obj.url.includes("market"))
        ) {
          this.$router.push(val + "dashboard");
          return;
        }
        this.$router.push(
          val +
            (this.current_page && this.current_page.id
              ? this.current_page.id
              : "")
        );
        return;
      } else if (obj && obj.user_profile) {
        this.$router.push(val + this.user_name);
        return;
      } else if (val === "event") {
        this.$router.push(this.previousRoute);
        return;
      }
      if (this.$route.path != val) {
        this.$router.push(val);
      }
      return;
    },
    myPages() {
      if (this.current_page && this.current_page.type) {
        const { data } = this.getMyPagesByType || [];
        if (data && data.length > 0) {
          const result = data.filter((x) => x.type === this.current_page.type);
          this.myPagesList = result.map((e) => {
            return {
              id: e.id,
              title: e.title,
              artwork_url: e.artwork_url,
              content: e.content,
              active: e.visibility,
              type: e.type,
            };
          });
        }
      }
    },
    async redirectToPage(item) {
      await this.$router.replace("/");
      if (item.type === "artist") {
        await this.$router.push(`/page-manager/page/artist/${item.id}`);
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "music");
      } else if (item.type === "clubroom") {
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts");
        await this.$router.push(`/page-manager/page/club/${item.id}`);
      } else if (item.type === "partyroom") {
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts");
        await this.$router.push(`/page-manager/page/party/${item.id}`);
      }
    },
  },
};
</script>
<style lang="scss">
%stroke-icon {
  .setting-left-icon {
    .icon-stroke {
      display: none;
    }

    .icon-fill {
      display: block;
    }
  }
}

%fill-icon {
  .setting-left-icon {
    .icon-stroke {
      display: block;
    }

    .icon-fill {
      display: none;
    }
  }
}

.left-nav-bar {
  width: 230px;
  padding-top: 0;
  height: 100%;
  transition: all 0.2s ease-in-out;
  padding-left: 0;
  box-shadow: 0px 0px 20px rgba(71, 80, 91, 0.15);
  background-color: #fff;
  letter-spacing: 0.03em;
  position: relative;

  &.open {
    transition: all 0.2s ease-in-out;
    padding-left: 0;
  }

  .bar-container {
    &.management {
      .ps {
        margin-top: 15px !important;
        height: calc(90% - 210px) !important;
      }
    }

    padding-top: 10px;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    .ps {
      margin-top: 30px !important;
      height: calc(100% - 240px);
    }

    .logo {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin: 1rem;
    }

    .logo-collapsed {
      display: none;

      .collapse_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
      }
    }

    .bar-menu-header {
      height: 40px;
      background: #D1433A;
      border-radius: 4px;

      .svg-icon {
        margin-left: 0 !important;
        margin-right: 9px !important;
      }

      display: flex;
      font-size: 13px !important;
      font-weight: normal !important;
      font-family: HelveticaNeueBold, sans-serif;
      line-height: 14px !important;
      margin: 22px 20px 32px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .return-arrow-item {
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 10px;
      opacity: 0.7;
      /* Mid Grey (New) */

      a {
        &:hover {
          background-color: #D1433A1A !important;
          border-left: 4px solid #D1433A;

          .main-link-tab-music {
            color: #D1433A !important;
          }

          @extend %stroke-icon;
        }

        @extend %fill-icon;

        .setting-left-icon {
          background-color: transparent;
          margin-right: 10px;

          span {
            font-size: 18px;
          }
        }

        height: 30px;
        font-size: 13px;
        background-color: #fff;
        padding: 17px 14px 17px;
        margin-bottom: 10px;
        color: #47505b;
        line-height: 16px;
        display: flex;
        align-items: center;
        transition: 0.2s ease all;
        border-left: 4px solid transparent;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .music_select {
      padding-right: 20px;
      padding-left: 20px;

      .music_select_options {
        width: 82%;
        li img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }

    nav {
      .custom_tab {
        border: none !important;
        height: 30px;
        background-color: #ffffff !important;
        padding-left: 20px;
        padding-right: 20px;
        a {
          padding: 6px 0;
        }

        :nth-child(1) {
          border-right-width: 0;
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }

        :nth-child(2) {
          border-left-width: 0;
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0 !important;
        }

        .nav-link {
          border: 1px solid #e6eaf0;
          width: 90%;
          border-radius: 4px;
          height: 30px;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 12px !important;
          line-height: 15px !important;
          /* identical to box height */
          letter-spacing: 0.02em;

          &.active {
            border-color: #D1433A;
            background: #D1433A;
            color: #fff;
          }
        }
      }
    }

    .left-bar-list {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 0;

      .menu-left-seprator-line {
        margin-top: 10px;
        margin-bottom: 0px;
      }

      .sub-menu-list {
        &.without-arrow {
          .sub-menu-list-item {
            a {
              width: 100%;
              .main-link-tab-sub {
                margin-left: 6px;
              }
            }
          }
        }
        .sub-menu-list-item {
          a {
            width: 100%;
            padding: 0 19px;
            .main-link-tab-sub {
              margin-left: 1px;
            }

            @media screen and (min-width: 2560px) {
                font-size: 0.85vw !important;
                padding: 7%;
              }
          }
        }
      }

      li {
        margin-bottom: 10.2px;
        list-style: none;
        display: flex;

        &.add-playlist {
          margin-bottom: 0px;
        }

        &.active {
          .list-item {
            &.shadow-item {
              border-width: 0 !important;
            }
            background-color: #D1433A1A !important;
            border-left: 4px solid #D1433A;
            a span {
              color: #D1433A !important;
            }
            @extend %stroke-icon;
          }
        }
        .list-item {
          width: 100%;

          .setting-left-icon {
            span {
              font-size: 18px !important;

              @media screen and (min-width: 2560px) {
                font-size: 1.5rem !important;
              }

              &.icon-fill {
                display: none;
              }
            }
          }

          &.dropdown-button {
            background-color: #D1433A1A;
            @extend %stroke-icon;
            .main-link-tab {
              color: #D1433A;
            }
            .rotate-icon {
              transform: rotate(90deg);
            }
            .main-link-tab-music {
              color: #D1433A;
            }
          }

          &.collapsed {
            background-color: #fff !important;
            @extend %fill-icon;
            .main-link-tab,
            .main-link-tab-music {
              color: #071526;
            }
            .rotate-icon {
              transform: none;
            }

            &.left-nav-margin {
              margin-top: 19px;
            }

            &.left-nav-margin-1 {
              margin-top: 15px;
            }
          }

          .main-link-tab-music {
            width: 100%;
          }

          &:hover {
            background-color: #D1433A1A !important;
            border-left: 4px solid #D1433A;

            .main-link-tab-music {
              color: #D1433A !important;
            }

            .music-left-icon {
              svg {
                stroke: #D1433A !important;
              }
            }

            @extend %stroke-icon;

            .main-link-tab {
              color: #D1433A !important;
            }
          }
          font-size: 13px;
          background-color: #fff;
          padding: 0 14px;
          color: #47505b;
          line-height: 16px;
          display: flex;
          align-items: center;
          transition: 0.2s ease all;
          border-left: 4px solid transparent;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          .setting-left-icon {
            margin-right: 10px;
            background-color: transparent !important;
            border-radius: 4px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s ease all;
          }

          .left-icon-shadow {
            position: relative;
            width: 40px;
            height: 30px;
            border-radius: 4px;
            font-size: 18px;
            background-color: #e6eaf0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            svg {
              position: absolute;
              left: 5px;
              top: 5px;
            }
          }

          .main-link-tab {
            font-weight: 500;
            color: #071526;
            font-size: 13px;
          }

          @media screen and (min-width: 2560px) {
            padding: 5%;

            .setting-left-icon {
              margin-right: 1rem;
            }
          }
        }
      }
    }

    *,
    ::after,
    ::before {
      box-sizing: border-box;
    }
  }

  &.collapse-left-bar {
    .logo {
      display: none;
    }

    .return-arrow-item {
      a {
        padding: 17px 0 17px !important;
      }
    }

    nav {
      display: none;
    }

    .logo-collapsed {
      display: block;
    }

    .bar-menu-header {
      .svg-icon {
        margin-left: 9px !important;
        margin-right: 9px !important;
      }

      span {
        display: none;
      }
    }

    .music_select {
      .music_dropdown_select {
        padding: 0px;

        .drop_icon {
          display: none;
        }
      }

      .music_select_options {
        width: 50%;

        li {
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      span {
        display: none;
      }
    }

    .main-link-tab,
    .main-link-tab-music {
      transform: scale(0);
      width: 0 !important;
      margin-left: 0;
      opacity: 0;
    }

    .list-item {
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 16px !important;
      border-left: none !important;

      .left-icon-shadow {
        svg {
          position: initial !important;
        }
      }

      .left-icon-shadow,
      .setting-left-icon {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

    .menu-heading {
      display: block !important;
    }
  }

  @media screen and (min-width: 2560px) {
    width: 300px;

    .bar-container {
      .bar-menu-header {
        height: auto;
        padding-block: 5%;
        font-size: 0.65vw;

        .svg-icon {
          margin-right: 0.9rem;

          img {
            width: 0.95vw;
          }
        }
      }
    }
  }
}
</style>
<style lang="css" scoped>
.bar-menu-header:hover {
  transition: all 0.3s;
  opacity: 0.8;
}
</style>
