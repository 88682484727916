<template>
  <div class="events-overview-featured-card">
    <router-link :to="{ name: 'events.Detail', params: { id: event.id } }">
      <div
        class="events-overview-featured-card-image-section"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <img :src="event.artwork_url" alt="Featured Card" />
        <div class="events-overview-featured-card-image-overlay" v-if="hover"></div>
        <div
          class="events-overview-featured-card-image-genre"
          @click.prevent="moodPopup = !moodPopup"
        >
          <span>#{{ event.genres[0].name }}</span>
        </div>
      </div>
    </router-link>

    <div class="events-overview-featured-card-body-section">
      <div>
        <div class="events-overview-featured-card-date-and-count-section">
          <div class="events-overview-featured-card-date">{{ date(event.started_at) }}</div>
          <div class="events-overview-featured-card-count">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>{{ event.going + event.interested }}</span>
          </div>
        </div>
        <span class="events-overview-featured-card-title">
          {{
            event.title.length > 18
              ? event.title.slice(0, 18) + "..."
              : event.title
          }}
        </span>
      </div>
      <div class="events-overview-featured-card-location-and-buttons-section">
        <span>
          {{ event.location }}
        </span>
        <div class="events-overview-featured-card-buttons">
          <div
            class="events-overview-featured-card-interested-btn"
            :class="{ active: event.user_interest === 'interested' }"
            @click="
              InterestedGoing({ event_id: event.id, interest: 'interested' })
            "
          >
            Interested
          </div>
          <div
            :class="{ active: event.user_interest === 'going' }"
            class="events-overview-featured-card-going-btn"
            @click="InterestedGoing({ event_id: event.id, interest: 'going' })"
          >
            Going
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hover: false,
      moodPopup: false,
    };
  },
  methods: {
    ...mapActions(["InterestedGoing"]),
    date(date) {
      return moment(date).format("MMMM D");
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-card {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;

  &:hover {
    box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
  }
  &-img {
    position: relative;
    cursor: pointer;
    img {
      border-radius: 4px 4px 0 0;
      width: 100%;
      height: 200px;
    }
    &-hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #47505b, $alpha: 0.2);
    }
    &-mood {
      position: absolute;
      z-index: 999;
      right: 10px;
      bottom: 10px;
      width: 70px;
      height: 20px;
      cursor: default;
      color: #ffffff;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      font-family: HelveticaNeueNormal;
      background: #8b949f;
      border-radius: 4px;
    }
  }
  &-body {
    position: relative;
    padding: 10px;
    .mood-popup {
      border: 1px solid #e6eaf0;
      box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
      border-radius: 4px;
      background: #fff;
      display: flex !important;
      padding: 11px 10px 9px;
      position: absolute;
      justify-content: space-between;
      top: 0;
      right: 0;
      z-index: 10;
      .moods {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #47505b;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 9px;
        background: rgba(196, 204, 214, 0.5);
      }
    }
    .count {
      width: 50px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
      background: #ae4ea9;
      border-radius: 4px;
      svg {
        margin-right: 3px;
        margin-left: 4px;
      }
      p {
        color: #ffffff;
        font-weight: 500;
        font-size: 12px;
        font-family: HelveticaNeueMedium;
      }
    }
    .date {
      color: #D1433A;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      font-family: HelveticaNeueBold;
      text-transform: uppercase;
    }
    h2 {
      color: #071526;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      font-family: HelveticaNeueBold;
      margin-top: 4px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      p {
        color: #47505b;
        font-family: HelveticaNeueMedium;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 0;
      }
      .inter-going-btn {
        margin: 0;
      }
    }
  }
  .active_btn {
    background: #D1433A;
    color: #ffffff;
    &:hover {
      background: #DA6961 !important;
    }
  }
}
</style>
