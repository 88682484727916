<template>
  <div>
    <div class="right-cover">
      <h4 style="font-family: HelveticaNeueBold, sans-serif;">Cover Image</h4>
      <div class="upload" :class="{ required: imgCoverReq }">
        <div v-if="!cover" class="square">
          <input type="file" ref="cover" @change="handleCover" />
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 6.25V23.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.25 15H23.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div v-else>
          <img :src="cover" alt="cover" />
          <input type="file" ref="cover" @change="handleCover" />
        </div>
      </div>
    </div>
    <Error v-if="imageError" :text="imageError" />

    <div class="error-container">
      <div class="error-img">
        <img :src="require(`@/assets/img/svg/error-icon.svg`)" />
      </div>
      <div class="imgInfo">
        <p>Your image must be:</p>
        <div>
          <div v-for="item in imgformat" :key="item">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Error from "@/components/Error.vue"
export default {
  name: "Artwork",
  components:{
    Error,
  },
  data() {
    return {
      imgCoverReq: false,
      cover: null,
      imgformat: [
        "Dimensions: 770px x 370px",
        "PNG or JPG format",
        "Maximum size: 2mb",
        "RGB format",
      ],
      info: {
        imgCover: null,
      },
      imageError: "",
    };
  },
  methods: {
    handleCover(e) {
      if (e.target.files[0].size < 2000000) {
        this.info.imgCover = e.target.files[0];
        this.cover = URL.createObjectURL(this.info.imgCover);
        this.imgCoverReq = false;
        this.imageError = "";
      } else {
        this.imageError = "Image size must be less than 2mb";
      }
    },
    handleArtWork() {
      if (!this.info.imgCover) {
        this.imgCoverReq = true;
      }

      this.$emit("setNewDataEvent", this.info);
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  border: 1px dashed #FF0039 !important;
}

.right-cover {
  h4 {
    font-size: 18px;
    margin: 32px 0 18px 0;
    color: #071526 !important;
  }
}

.upload {
  position: relative;
  width: 100%;
  width: 710px;
  height: 218px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: 1px dashed #c4ccd6;

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #D1433A;
    background: #D1433A1A;
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}

.error-container {
  margin: 20px 0 0 0;
  padding-left: 17px;
  padding-right: 27px;
  padding-top: 7px;
  padding-bottom: 7px;
  background: rgba(209, 67, 58, 0.10);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 710px;

  .error-img {
    height: 24px;
    width: 24px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imgInfo {
    width: calc(100% - 60px) !important;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span {
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50px;
      background: #D1433A;
      opacity: 0.5;
      margin-right: 14px;
    }

    p {
      margin: 0;
      font-family: 'Lato', sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #D1433A !important;
      padding-top: 7px;
      padding-bottom: 7px;
      white-space: normal; 
      word-wrap: break-word; 
      overflow-wrap: break-word
    }
  }
}
</style>
