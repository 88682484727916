<template>
  <div class="container_xxl">
    <div class="row">
      <div class="col">
        <h1 class="Page_title">{{ header ? "EXPLORE" : "" }}</h1>
      </div>
    </div>
    <div class="content discover">
      <div class="row">
        <div class="col px-0">
          <div
            class="page-filter d-flex d-flex flex-row align-items-center p-r-20"
          >
            <div class="back-link">
              <a
                @click="goBack()"
                class="d-flex flex-row align-items-center cursor-pointer"
              >
                <div class="svg-icon">
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                      stroke="#071526"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                {{ pageFrom }}
              </a>
            </div>
            <div class="filter-title">
              {{ pageTitle }}
            </div>
            <div class="filter-update-text" v-if="titleDescription">
              {{ titleDescription }}
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <!-- remove mt-10 -->
        <div class="row fixTop100">
          <div class="col top100albums-header d-flex flex-row m-b-25 px-0">
            <div class="js-container-hover grid_charts">
              <a href="#">
                <div class="card slider shop-selling">
                  <div class="card-cover">
                    <div
                      class="cover chart-item single border-all"
                      :class="['color-' + getColorOfCard]"
                    >
                      <div class="top-text-list">TOP 100</div>
                      <div class="center-text">Charts</div>
                      <!--                      <div class="bottom-text" v-b-tooltip.hover :title="country">-->
                      <!--                       <span>{{country| setCountryNameShort}}</span>-->
                      <!--                      </div>-->
                      <div class="bottom-text">
                        <span
                          :title="country"
                          data-toggle="tooltip"
                          class="tooltip"
                          style="
                            text-overflow: ellipsis;
                            width: 100%;
                            background-color: #fff0 !important;
                            text-align: center;
                            opacity: 1;
                          "
                          >{{
                            country.length > 14
                              ? country.slice(0, 14) + "..."
                              : country
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="text-block d-flex flex-column justify-content-center h-100"
            >
              <div class="item-title">{{ pageTitle }}</div>
              <div class="item-text">
                By an outlived insisted procured improved am. Paid hill fine ten
                now love even leaf. Supplied feelings mr of dissuade recurred no
                it offering honoured. Am of of in collecting devonshire
                favourable excellence.
              </div>
              <div class="buttons d-flex align-items-center flex-row">
                <div class="play-btn" @click="playAll">
                  <div class="svg-icon">
                    <svg
                      class="svg-play-v3"
                      width="11"
                      height="13"
                      viewBox="0 0 11 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="c-line-1"
                        d="M1.756 12.0961C1.6394 12.0942 1.52466 12.0666 1.42 12.0151C1.30068 11.9449 1.20014 11.8468 1.12699 11.7293C1.05385 11.6117 1.01028 11.4782 1 11.3401V1.76312C1.00597 1.62933 1.04329 1.49881 1.10895 1.38209C1.17461 1.26536 1.26677 1.1657 1.378 1.09112C1.49477 1.02489 1.62813 0.993631 1.76217 1.00108C1.89621 1.00852 2.02528 1.05436 2.134 1.13312L9.485 5.92112C9.58833 5.99054 9.673 6.08432 9.73154 6.19419C9.79008 6.30405 9.8207 6.42663 9.8207 6.55112C9.8207 6.6756 9.79008 6.79818 9.73154 6.90805C9.673 7.01791 9.58833 7.11169 9.485 7.18112L2.134 11.9701C2.02349 12.0491 1.89179 12.093 1.756 12.0961"
                        stroke="white"
                        stroke-width="1.3"
                      />
                    </svg>
                  </div>
                  <span>
                  play all
                </span>
                </div>
                <div class="share-btn">
                  <div class="svg-icon">
                    <svg
                      class="svg-share"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="c-line-1 c-fill-1"
                        d="M1 11.619C1 11.619 2.26951 5.80698 9.88122 5.62087C9.99792 4.08255 9.99792 2.53831 9.88122 1L17 7.34856L9.88122 14V9.12244C9.88122 9.12244 5.70755 7.94414 1 11.619Z"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  share
                </div>
                <div class="more-btn">
                  <div class="svg-icon">
                    <svg
                      width="15"
                      height="3"
                      viewBox="0 0 15 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="c-fill-1"
                        d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
                        fill="#47505B"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M7.29199 3C8.12042 3 8.79199 2.32843 8.79199 1.5C8.79199 0.671573 8.12042 0 7.29199 0C6.46357 0 5.79199 0.671573 5.79199 1.5C5.79199 2.32843 6.46357 3 7.29199 3Z"
                        fill="#47505B"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M13.084 3C13.9124 3 14.584 2.32843 14.584 1.5C14.584 0.671573 13.9124 0 13.084 0C12.2556 0 11.584 0.671573 11.584 1.5C11.584 2.32843 12.2556 3 13.084 3Z"
                        fill="#47505B"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="js-container fixSaleItems">
        <div class="sale-items">
          <div class="row">
            <div class="col-12 px-0">
              <ChartTopSellingList :listData="allData" :stream="isStreaming" />
              <div class="text-center" v-if="loading">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="totalRows > allData.length">
        <div class="col-12 px-0">
          <div class="show-more musicbar_more m-t-10">
            <a
              href="javascript:;"
              @click="GetChannelData(getChannelName, true)"
            >
              <span class="show_more_bg">
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW MORE
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ChartTopSellingList from "@/components/Common/Elements/ChartTopSellingList";
import { getPageData } from "../../../apis/APIs";

export default {
  data() {
    return {
      selectedCountriesIDs: [
        0,
        232,
        233,
        14,
        31,
        39,
        75,
        82,
        85,
        107,
        109,
        132,
        156,
        182,
        204,
        213,
        207,
        219,
      ],
      listing_type: "grid",
      allData: [],
      loading: false,
      dataLoaded: false,
      limit: 6,
      offset: 0,
      totalRows: 0,
      filterApplied: "Latest",
    };
  },
  filters: {
    setCountryNameShort(val) {
      if (val.includes("United Kingdom")) {
        return "UK";
      } else if (val.includes("United States")) {
        return "USA";
      } else {
        return val;
      }
    },
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
    this.GetChannelData();
  },
  components: {
    ChartTopSellingList,
  },
  computed: {
    pageFrom() {
      const { fromPage } = this.$route.query;
      if (fromPage) {
        return fromPage;
      }
      return "Back";
    },
    pageTitle() {
      const { title } = this.$route.query;
      if (title) {
        return "Top 100 " + title;
      }
      return "Top 100";
    },
    country() {
      const { title } = this.$route.query;
      if (title) {
        return title;
      }
      return "Global";
    },
    type() {
      const { type } = this.$route.query;
      if (type) {
        return type;
      }
      return null;
    },
    titleDescription() {
      const { description } = this.$route.query;
      return description ? description : null;
    },
    getChannelName() {
      const { countryId } = this.$route.params;
      return countryId ? countryId : null;
    },
    getCountryId() {
      const { country } = this.$route.query;
      return country ? country : null;
    },
    getColorOfCard() {
      if (this.getCountryId) {
        const index = this.selectedCountriesIDs.findIndex(
          (e) => e === Number(this.getCountryId)
        );
        if (index === -1) {
          return 1;
        }
        return index + 1;
      }
      return 1;
    },
    isStreaming() {
      return this.$store.getters.isMusicModeIsStreaming;
    },
  },
  props: {
    activeHeader: String,
    header: Boolean,
  },
  methods: {
    async GetChannelData(countryId, showMore = false) {
      try {
        this.loading = true;
        if (showMore) {
          this.offset = parseInt(this.offset) + this.limit;
        }
        let { data } = await getPageData(
          this.getChannelName,
          this.limit,
          this.offset,
          this.filterApplied,
          this.getCountryId,
          this.type
        );
        this.allData = showMore
          ? [...this.allData, ...data.channels.center.objects.data]
          : data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    playAll() {
      if (this.allData.length > 0) {
        this.$store.commit("Set_Play_All_Songs_Array", this.allData);
        this.$store.commit("SET_CURRENT_PLAYER", "footerPlayer");
        this.$store.commit("Set_Song_status", true);
      } else {
        console.log("No 100 Top Charts Songs Yet");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.shop-selling {
  width: 170px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  /*width: 120px;*/
  background-color: #D1433A;
  position: fixed;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  /*position: absolute;*/
  z-index: 9999;
}

//.tooltip:hover .tooltiptext {
//  visibility: visible;
//}
</style>
