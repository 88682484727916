<template>
  <div class="landing">
    <LandingHeader />
    <div class="content">
      <div class="container intro-section">
        <div class="row">
          <div class="col">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  :class="['carousel-item', n == 1 ? 'active' : '']"
                  v-for="n in 3"
                  :key="n"
                >
                  <img
                    class="d-block w-100"
                    :src="require(`@/assets/img/demo/landing-intro.png`)"
                    alt="First slide"
                  />
                  <div class="slider-info-item">
                    <div class="item-title item_icon">
                      <img
                        class="for-logo-text audio_logo"
                        :src="require(`@/assets/img/svg/logo-white.png`)"
                      />
                    </div>
                    <div class="item-desc item-slider_subtitle">
                      <span class="m_5_top">The Social Music Markeplace.</span>
                      <br />
                      <span class="m_10_top">
                        Join the wave empowering artists.
                      </span>
                    </div>
                    <div class="btn btn-reg-now Font_semibold" v-on:click="openModal('sign-up-email', 'register')">Signup NOW</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchMusic @check-user="checkIfAuthenticated" />
      <!-- <GridSelling6 :gridTitle="'Selling Now'"/> -->

      <GridSellingNow6 :gridTitle="'Selling Now'" @check-user="checkIfAuthenticated" />
      <div class="container">
        <div class="row platform-items">
          <div class="col-md-3">
            <div class="item">
              <div class="ico-wrap">
                <div class="svg-icon">
                  <img :src="require(`@/assets/icons/music.svg`)" />
                </div>
              </div>
              <div class="item-desc txt_box_platform">
                <div class="item-title Font_semibold">1,000,000 Tracks</div>
                <div class="item-subtitle">
                  Explore trending tracks, podcasts and suggestions
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="item">
              <div class="ico-wrap">
                <div class="svg-icon">
                  <img :src="require(`@/assets/icons/Sponser.svg`)" />
                </div>
              </div>
              <div class="item-desc txt_box_platform">
                <div class="item-title Font_semibold">Sponsor</div>
                <div class="item-subtitle">
                  Become a sponsor of your favourite acts for rewards
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="item">
              <div class="ico-wrap">
                <div class="svg-icon">
                  <img :src="require(`@/assets/icons/Event.svg`)" />
                </div>
              </div>
              <div class="item-desc txt_box_platform">
                <div class="item-title Font_semibold">Events</div>
                <div class="item-subtitle">
                  Buy tickets to events, tours and live streaming
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="item">
              <div class="ico-wrap">
                <div class="svg-icon">
                  <img :src="require(`@/assets/icons/Merch.svg`)" />
                </div>
              </div>
              <div class="item-desc txt_box_platform">
                <div class="item-title Font_semibold">Merch</div>
                <div class="item-subtitle">
                  Buy merch, vinyl, cassettes from your favourite acts
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container music-genre-section m-t-40">
        <div class="row">
          <div class="col">
            <div class="section-title genre_title Font_semibold">
              Music Genres
            </div>
          </div>
        </div>
        <p v-if="loading" class="text-center">Please Wait...</p>
        <div class="row">
          <div
            class="col-sm-2 col-2 genres-select-list"
            v-for="(eachOp, index) in geners"
            :key="index"
          >
            <div class="genres_checkbox_outer">
              <input :id="'genres_' + index" type="checkbox" name="" />
              <label :for="'genres_' + index" class="genres_box">
                <div :class="['genres_icon svg-icon']" style="height: 25px">
                  <img :src="eachOp.icon_url" />
                </div>

                <div class="genres_info">
                  <p class="genres_title" v-html="eachOp.name"></p>
                </div>
                <div class="genres_checkbox">
                  <div class="genres_checkbox_lab">
                    <svg
                      width="7px"
                      height="5px"
                      viewBox="0 0 13 10"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g id="checked(2)" fill="#D1433A" fill-rule="nonzero">
                          <polygon
                            id="Path"
                            class="chekmark-check"
                            points="0.664935065 5.38032418 4.76358812 9.48680081 12.6337662 1.62439985 11.5038734 0.510131007 4.76358812 7.24259274 1.7791807 4.25820853"
                          ></polygon>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container intro-repeat-section selling_info_black_section">
        <div class="row">
          <div class="col">
            <div class="item">
              <div
                class="background"
                :style="
                  'background-image: url(' +
                  require(`@/assets/img/demo/landing-intro2.png`) +
                  ')'
                "
              ></div>
              <div class="slider-info-item">
                <div class="item-title fW_500">
                  <span>Sell your Music, Samples, Beats, Tickets or Merch</span>
                </div>
                <div class="item-desc">
                  You’ll able to sell music, samples, beats, tickets or merch
                  without special permission, just after moderation your items
                  will be available for purchase
                </div>
                <div class="btn btn-reg-now Font_semibold" v-on:click="checkIfAuthenticated">START SELLING</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LastUpdate :hasContainer="true"></LastUpdate>
    </div>
    <section class="landing-footer footer_custom_style">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="footer-nav">
              <div class="item">
                <div class="item-title fW_600">Music</div>
                <ul>
                  <li>
                    <a href="">Genres & Moods</a>
                  </li>
                  <li>
                    <a href="">New Releases</a>
                  </li>
                  <li>
                    <a href="">Charts</a>
                  </li>
                  <li>
                    <a href=""> Podcasts</a>
                  </li>
                </ul>
              </div>
              <div class="item">
                <div class="item-title fW_600">Events</div>
                <ul>
                  <li>
                    <a href="">Gigs</a>
                  </li>
                  <li>
                    <a href="">Clubs</a>
                  </li>
                  <li>
                    <a href="">Festivals</a>
                  </li>
                  <li>
                    <a href=""> Live Streaming</a>
                  </li>
                </ul>
              </div>
              <div class="item">
                <div class="item-title fW_600">Jobs</div>
                <ul>
                  <li>
                    <a href=""> Find Talent</a>
                  </li>
                  <li>
                    <a href=""> Find Companies</a>
                  </li>
                  <li>
                    <a href="">Music Jobs</a>
                  </li>
                </ul>
              </div>
              <div class="item">
                <div class="item-title fW_600">Market</div>
                <ul>
                  <li>
                    <a href="">Samples</a>
                  </li>
                  <li>
                    <a href="">Beats</a>
                  </li>
                  <li>
                    <a href="">Merch</a>
                  </li>
                </ul>
              </div>
              <div class="item">
                <div class="item-title fW_600">Information</div>
                <ul>
                  <li>
                    <a href="">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="">Cookie Policy</a>
                  </li>
                </ul>
              </div>
              <div class="item">
                <div class="item-title fW_600">Social</div>
                <div class="social-wrap">
                  <a href="">
                    <span class="svg-icon">
                      <img :src="require(`@/assets/icons/facebook.svg`)" />
                    </span>
                  </a>
                  <a href="">
                    <img :src="require(`@/assets/icons/insta.svg`)" />
                  </a>
                  <a href="">
                    <span class="svg-icon">
                      <img :src="require(`@/assets/icons/twitter.svg`)" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="custom-hr"></div>
            <div class="copyright_text">
              <span class="copright_left">Playy © 2021</span>
              <span class="copright_right"
                >Ground Music Group Ltd. All rights reserved.</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <AuthHandler ref="auth-handler"/>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import LandingHeader from "@/components/Landing/LandingHeader.vue";
// import MusicSlide from "@/components/Landing/MusicSlide.vue";
//  import GridSelling6 from "@/components/Common/Elements/GridSelling6.vue";
import GridSellingNow6 from "@/components/Common/Elements/GridSellingNow6.vue";
import LastUpdate from "@/components/Landing/LastUpdate.vue";
import SearchMusic from "@/components/Landing/SearchMusic.vue";
import AuthHandler from "../components/Landing/modals/AuthHandler.vue";
import {SIGNUP_ERRORS, SUCCESS, SERVER_PRESENT} from '../store/types';

export default {
  data() {
    return {
      message: "",
      registerData: {
        email: "",
        first_name: "",
        last_name: "",
        gender: "",
        age: "",
        user_name: "",
        password: "",
        repeat_password: "",
        subscribe_to_newspaper: false,
      },
      geners: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      form_data: "system_map_to_props"
    }),
    is_authenticated() {
      return this.form_data && this.form_data.is_authenticated ? this.form_data.is_authenticated : false
    }
  },
  components: {
    LandingHeader,
    LastUpdate,
    SearchMusic,
    GridSellingNow6,
    AuthHandler
  },
  mounted() {
    this.getGeners();
  },
  methods: {
    ...mapActions([
      "get_geners", 
      "check_user_exists"
    ]),
    checkEnter: function () {
      alert("12");
    },
    async getGeners() {
      try {
        this.loading = true;
        const { status, information } = await this.get_geners();
        this.loading = false;
        // this.geners = information && information.length ? information.filter(ele => ele.parent_id === "0").map(({ image, ...rest }) => ({ image: image ? `https://social-music.iapplabz.co.in/api/Script/content/uploads/${image}` : image, ...rest})) : [];
        this.geners =
          information && information.length
            ? information.map(({ image, ...rest }) => ({
                image: image
                  ? `https://social-music.iapplabz.co.in/api/Script/content/uploads/${image}`
                  : image,
                ...rest,
              }))
            : [];
      } catch (error) {
        this.loading = false;
      }
    },
    openModal: function (modalType, modalFor) {
      this.$refs["auth-handler"].openModal(modalType, modalFor)
    },
    async checkIfAuthenticated() {
      if(this.is_authenticated){
        this.$router.push('/home/page/news-feed');
      }else{
        this.$router.push('/register');
      }
    }
  },
};
</script>
<style>
.music_slider_landing .owl-theme .owl-nav .owl-next::after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.69421 10.7761C3.42527 10.494 3.43683 10.0515 3.72154 9.78346L7.73996 6L3.72154 2.21654C3.43683 1.94848 3.42527 1.50599 3.69421 1.22387C3.96794 0.936706 4.42863 0.923819 4.71846 1.19671L9.27846 5.49008C9.42103 5.62431 9.5 5.80891 9.5 6C9.5 6.19109 9.42103 6.37569 9.27846 6.50992L4.71846 10.8033C4.42863 11.0762 3.96794 11.0633 3.69421 10.7761Z' fill='%2347505B'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.music_slider_landing .owl-theme .owl-nav .owl-prev::before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.30579 1.22387C8.57473 1.50599 8.56317 1.94848 8.27846 2.21654L4.26004 6L8.27846 9.78346C8.56317 10.0515 8.57473 10.494 8.30579 10.7761C8.03206 11.0633 7.57137 11.0762 7.28154 10.8033L2.72154 6.50992C2.57897 6.37569 2.5 6.19109 2.5 6C2.5 5.80891 2.57897 5.62431 2.72154 5.49008L7.28154 1.19671C7.57137 0.92382 8.03206 0.936705 8.30579 1.22387Z' fill='%2347505B'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.term_signup_body
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url(../../src/assets/icons/checkbox-tick.svg);
}

.all_singles .owl-item {
  width: 410px !important;
}
</style>
