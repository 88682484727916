<template>
  <div class="sale-items">
    <div class="row">
      <div class="col-sm-12">
        <!-- <h4 class="title-h4 selling_title">{{ gridTitle }} </h4> -->
        <h4 class="title-h4 selling_title">Popular Artists </h4>
      </div>
    </div>
    <div class="music_slider_landing artist-page-carousel selling_now carousel-icon" ref="busstop">
      <carousel :items="6" :autoplay="false" :nav="false" class="selling_now" :responsive="{2560: {items: 4}}" :margin="30">
        <div class="for_hover overviewCardAlign" v-for="(song, index) in listData" :key="index">
          <OverviewArtistCard :item="song" :type="(song.reviewable) ? 'review' : 'song'"/>
          
          <!-- <PageOrArtistCard :item="song" :type="(song.reviewable) ? 'review' : 'song'"/> -->
        </div>
      </carousel>
      <!-- <ListOption :optionWidth="optionWidth" :optionHeight="optionHeight" :showOption="showOption" /> -->
      <!-- </div> -->
      <div class="link-more near_arrow more_btn_landing cursor-pointer">
        <a @click="redirectToChannel(channelType,altName,pageTitle)">See more</a>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import carousel from "vue-owl-carousel";
import ClickOutside from 'vue-click-outside'
import OverviewArtistCard from "@/components/Common/UiComponenets/OverviewArtistCard.vue";
import Vue from "vue";
import {set_favourite_on_songs} from "../../../apis/APIs";

export default {
  data() {
    return {
      default_image: require(`@/assets/img/placeholders.png`),
      showMMore: false,
      optionHeight: 0,
      showOption: false,
      lastWidth: 0,
      optionWidth: 0,
      isHovering: false,
      selectedLoved: []
    }
  },
  components: {
    carousel,
    OverviewArtistCard
  },
  computed: {
    filterData(){
      return this.listData.filter(function(e) {

      });
    }
  },
  afterMount: {},
  mounted: function () {
    this.testData();
    this.getLovedLists();
  },
  props: {
    gridTitle: String,
    redirectLink: String,
    fromPage: String,
    pageTitle: String,
    headerTitle: String,
    altName: String,
    channelType:{
      type:String,
      default:null
    },
    listData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getImgUrl(filename) {
      if (filename) {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', filename, false);
        xhr.send();
        if (xhr.status === "404") {
          return require(`@/assets/img/placeholders.png`);
        } else {
          return filename;
        }

      } else {
        return require(`@/assets/img/placeholders.png`);
      }
    },
    testData: function () {
    },
    addClassToParent: function (event) {
      event.target.parentElement.addClassToParent('mmmm');
    },
    isHoveringFun: function (val, index) {
      this.listData[index].isHovering = val;
    },
    hide: function () {
      this.showOption = false;
    },
    getHeight: function (e) {
      e.stopPropagation();
      this.showOption = !this.showOption;
      if (this.lastWidth !== e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }

      let height = 108;
      this.optionWidth = parseInt(e.screenX) - this.$refs.busstop.getBoundingClientRect().left;
      this.optionHeight = parseInt(height);
    },
    redirect: function () {
      this.$router.push({
        name: 'music.'+this.redirectLink, params: {
          fromPage: this.fromPage,
          pageTitle: this.pageTitle,
          headerTitle: this.headerTitle
        }
      })
    },
    getLovedLists: function() {
      for(let i = 0; i < this.listData.length; i++) {
        Vue.set(this.selectedLoved, this.listData[i].id, this.listData[i].loves);
      }
    },
    setLovedArrayValues: function (id, value) {
      Vue.set(this.selectedLoved, id, value);
    },
    async setLoved(id, favorite) {
      const formdata = new FormData();
      if (id && favorite) {
        formdata.append('id', id);
        formdata.append('object_type', 'artist');
        formdata.append('action', 0);
      } else if (id) {
        formdata.append('id', id);
        formdata.append('object_type', 'artist');
        formdata.append('action', 1);
      }
      console.log("Artist Form Data", formdata);
      try {
        const res = await set_favourite_on_songs(formdata);
        const {success, message} = res.data || {}
      } catch (e) {
        //
      }
    }

  },
  directives: {
    ClickOutside
  }

}
</script>
<style lang="scss">
.for_hover{
  transition: .1s all !important;
  &:hover{
    transition: .1s all;
    box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
    // border-radius: 4px !important;
  }
}
</style>