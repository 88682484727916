var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.checkRegister.is_welcome_page)?_c('div',{staticClass:"news-feed-layer"}):_c('div',{staticClass:"container_xxl newsFeedContainer"},[_vm._m(0),_c('div',{staticClass:"feed_wrapper"},[(!_vm.getTrendingViewCheck)?_c('div',{staticClass:"center_centerbar_wraper_home"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"artist-main-premium-btns"},[_c('CreatePost',{attrs:{"picture":_vm.picture}}),_c('div',{staticClass:"btns-premium"},[_vm._m(1),_c('div',{staticClass:"artist-music-profile-select"},[_c('div',{staticClass:"main-poset-select-box"},[_c('ul',[_c('li',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.optionOptions = true}}},[_vm._v(" "+_vm._s(_vm.filterName)+" ")])]),_c('span',[_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L6.09612 5.67901L1 10.77","stroke":"#D1433A","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])])],1)])]),_c('div',{staticClass:"edit-share-section"},[_c('div',{staticClass:"detail-tab"},[_c('nav',[_c('div',{staticClass:"nav top-tab nav-tabs1",attrs:{"id":"nav-tab","role":"tablist"}},[_c('a',{class:[
                  'nav-item nav-link',
                  _vm.active_tab === 'discover_posts' ? 'active' : '',
                ],attrs:{"id":"nav-home-tab","data-toggle":"tab","href":"#nav-home","role":"tab","aria-controls":"nav-home","aria-selected":_vm.active_tab === 'discover_posts' ? true : false},on:{"click":function($event){return _vm.setActiveTab('discover_posts')}}},[_vm._v("DISCOVER POSTS")]),_c('a',{ref:"liked_posts",class:[
                  'nav-item nav-link',
                  _vm.active_tab === 'liked_posts' ? 'active' : '',
                ],attrs:{"id":"nav-profile-tab","data-toggle":"tab","href":"#nav-profile","role":"tab","aria-controls":"nav-profile","aria-selected":_vm.active_tab === 'liked_posts' ? true : false},on:{"click":function($event){return _vm.setActiveTab('liked_posts')}}},[_vm._v("LIKED POSTS")]),_c('a',{class:[
                  'nav-item nav-link',
                  _vm.active_tab === 'my_posts' ? 'active' : '',
                ],attrs:{"id":"nav-contact-tab","data-toggle":"tab","href":"#nav-contact","role":"tab","aria-controls":"nav-contact","aria-selected":_vm.active_tab === 'my_posts' ? true : false},on:{"click":function($event){return _vm.setActiveTab('my_posts')}}},[_vm._v("MY POSTS ")]),_c('a',{ref:"saved_posts",class:[
                  'nav-item nav-link',
                  _vm.active_tab === 'save_posts' ? 'active' : '',
                ],attrs:{"id":"nav-contact-tab","data-toggle":"tab","href":"#nav-contact","role":"tab","aria-controls":"nav-contact","aria-selected":_vm.active_tab === 'save_posts' ? true : false},on:{"click":function($event){return _vm.setActiveTab('save_posts')}}},[_vm._v("SAVED POSTS ")])])])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"nav-tabContent"}},[(_vm.active_tab === 'discover_posts')?_c('div',{class:[
            'tab-pane fade',
            _vm.active_tab === 'discover_posts' ? 'active show' : '',
          ],attrs:{"id":"nav-home","role":"tabpanel","aria-labelledby":"nav-home-tab"}},[(!_vm.post_list.length)?_c('NoDataComponent',{attrs:{"header":"There are no Posts yet","content":"Post your first article, message or image in your Post Feed!"}}):_vm._l((_vm.post_list),function(eachOp,index){return _c('PostDetail',{key:'post-detail' + eachOp.id,attrs:{"index":index,"item":eachOp,"activeTab":_vm.active_tab}})}),(_vm.is_more_posts)?_c('ShowMore',{on:{"emitposts":function($event){return _vm.showMore('discover_posts')}}}):_vm._e()],2):_vm._e(),(_vm.active_tab === 'liked_posts')?_c('div',{class:[
            'tab-pane fade',
            _vm.active_tab === 'liked_posts' ? 'active show' : '',
          ],attrs:{"id":"nav-profile","role":"tabpanel","aria-labelledby":"nav-profile-tab"}},[(!_vm.post_list.length)?_c('NoDataComponent',{attrs:{"header":"There are no Posts yet","content":"Post your first article, message or image in your Post Feed!"}}):_vm._l((_vm.post_list),function(eachOp,index1){return _c('PostDetail',{key:'post-detail' + eachOp.id,attrs:{"index":index1,"item":eachOp}})}),(_vm.is_more_posts)?_c('ShowMore',{on:{"emitposts":function($event){return _vm.showMore('liked_posts')}}}):_vm._e()],2):_vm._e(),( _vm.active_tab === 'my_posts')?_c('div',{class:[
            'tab-pane fade',
            _vm.active_tab === 'my_posts' ? 'active show' : '',
          ],attrs:{"id":"nav-contact","role":"tabpanel","aria-labelledby":"nav-contact-tab"}},[(!_vm.post_list.length)?_c('NoDataComponent',{attrs:{"header":"There are no Posts yet","content":"Post your first article, message or image in your Post Feed!"}}):_vm._l((_vm.post_list),function(eachOp,index2){return _c('PostDetail',{key:'post-detail' + eachOp.id,attrs:{"index":index2,"item":eachOp,"activeTab":_vm.active_tab}})}),(_vm.is_more_posts)?_c('ShowMore',{on:{"emitposts":function($event){return _vm.showMore('my_posts')}}}):_vm._e()],2):_vm._e(),( _vm.active_tab === 'save_posts')?_c('div',{class:[
            'tab-pane fade',
            _vm.active_tab === 'save_posts' ? 'active show' : '',
          ],attrs:{"id":"nav-contact","role":"tabpanel","aria-labelledby":"nav-contact-tab"}},[(!_vm.post_list.length)?_c('NoDataComponent',{attrs:{"img":"/css/emojis/message-square.png","header":"There are no Posts yet","content":"Post your first article, message or image in your Post Feed!"}}):_vm._l((_vm.post_list),function(eachOp,index2){return _c('PostDetail',{key:'post-detail' + eachOp.id,attrs:{"index":index2,"item":eachOp,"activeTab":_vm.active_tab}})}),(_vm.is_more_posts)?_c('ShowMore',{on:{"emitposts":function($event){return _vm.showMore('save_posts')}}}):_vm._e()],2):_vm._e()])]):_vm._e(),(_vm.getTrendingViewCheck)?_c('TrendingArticles',{attrs:{"last-url":"News Feed"},on:{"showMoreTrending":_vm.showMoreTrending}}):_vm._e(),_c('NewsFeedOtherData')],1),(_vm.optionOptions)?_c('OptionModal',{on:{"filter":_vm.filterPosts,"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h1',{staticClass:"Page_title"},[_vm._v("News Feed")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-feed-radio"},[_c('input',{attrs:{"type":"radio","id":"radio-one-main","name":"switch-one-main","value":"yes","checked":"checked"}}),_c('label',{attrs:{"for":"radio-one-main"}},[_vm._v("Main")]),_c('input',{attrs:{"type":"radio","id":"radio-two-main","name":"switch-one-main","value":"no"}}),_c('label',{attrs:{"for":"radio-two-main"}},[_vm._v("Premium")])])
}]

export { render, staticRenderFns }