<template>
  <div class="events-overview-venue-type-container d-grid gap-3">
    <span class="events-overview-venue-type-heading">Venue Type</span>
    <div class="events-overview-venue-type-list-container">
      <div
        @click="selectVenue($event, item.id)"
        :class="'events-overview-venue-type' + (isSelectedGenre(item.id) ? ' active' : '')"
        v-for="(item, index) in venue"
        :key="index"
      >
        <div class="d-flex gap-3 align-items-center">
          <img :src="item.artwork_url" alt="">
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      venue: [],
      activeVenue: [],
    };
  },
  mounted() {
    this.getVenue();
  },
  methods: {
    ...mapActions(["getVenueTypes"]),
    ...mapMutations(["set_Events_Searech_For"]),
    async getVenue() {
      try {
        const { status, information } = await this.getVenueTypes();
        this.venue = information;
      } catch (error) {
        console.error(error);
      }
    },
    selectVenue(e, value) {
      e.stopPropagation();
      const index = this.activeVenue.findIndex((elem) => elem === value);
      this.set_Events_Searech_For({
        event_type:
          !this.$route.path.includes("/events/page/overview") &&
          this.$route.meta.event_Type_id,
      });
      if (index !== -1) {
        this.activeVenue.splice(index, 1);
        this.set_Events_Searech_For({ venue_type: "" });
      } else {
        this.activeVenue.length = 0;
        this.activeVenue.push(value);
        this.set_Events_Searech_For({ venue_type: value });
      }
      this.$emit("explorEvents", this.GET_EVENTS_SEARCH_FOR);
    },
    isSelectedGenre(id) {
      const index = this.activeVenue.findIndex((elem) => elem === id);
      return index !== -1;
    },
  },
  computed: {
    ...mapGetters(["GET_EVENTS_SEARCH_FOR"]),
  },
};
</script>

<style lang="scss" scoped>
.venue-container {
  display: flex;
  flex-wrap: wrap;

  div {
    cursor: pointer;
  }
}
.venue {
  &-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 21.98px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:rgba(7, 21, 38, 1);
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  &-box {
    height: 50px;
    min-width: 10.1rem;
    max-width: 11.6rem;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    flex: 1 1 auto;
    justify-content: center;
    &:hover {
      background: rgba(209, 67, 58, 0.1);

    }
    &:hover .img {
      background-color: #D1433A;
    }

    &.active {
      background: #D1433A !important;
      box-sizing: unset;

      .img {
        background-color: #ffffff !important;
      }

      span {
        color: #ffffff !important;
      }
    }

    .img {
      width: 30px;
      height: 30px;
      background: #000000;
      mask-position: center;
      mask-repeat: no-repeat;
    }
  }

  span {
    color: #47505b;
    font-family: Helvetica Neue,Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.09px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
</style>
