<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade login-type show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="add-project-popup">
            <div class="work-experience-heading">
              <h1>{{ item ? "Edit" : "Add" }} Project Experience</h1>
              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="svg-color" d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="svg-color" d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">
              <b-form @submit.prevent="creatProject">
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                        label-for="name-your-page"
                        class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        Project Name <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          id="name-your-page"
                          v-model="$v.project.project_name.$model"
                          :state="validateState('project_name')"
                          placeholder="Project Name"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                          id="name-your-page"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                        label-for="title-your-page"
                        class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        Title <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          id="title-your-page"
                          v-model="$v.project.project_title.$model"
                          :state="validateState('project_title')"
                          placeholder="Your Role in the Project"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                          id="title-your-page"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 month_year">
                    <b-form-group
                        label-for="name-your-page"
                        class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        From <span class="text-danger"></span>
                      </template>
                      <div class="row">
                        <div class="col-6">
                          <b-form-select id="from-month" v-model="$v.project.from_month.$model" class="mb-3"
                                         :state="validateState('from_month')" size="sm">
                            <b-form-select-option :value="null">Month</b-form-select-option>
                            <b-form-select-option v-for="(eachOp, index) in months" :value="index" :key="index">
                              {{ eachOp.vaule }}
                            </b-form-select-option>
                          </b-form-select>
                        </div>
                        <div class="col-6">
                          <b-form-select id="from-year" v-model="$v.project.from_year.$model" class="mb-3"
                                         :state="validateState('from_year')" size="sm">
                            <b-form-select-option :value="null">Year</b-form-select-option>
                            <b-form-select-option v-for="(eachOp, index) in years" :value="eachOp.value" :key="index">
                              {{ eachOp.name }}
                            </b-form-select-option>
                          </b-form-select>
                        </div>
                      </div>
                      <b-form-invalid-feedback
                          id="from-month"
                      >This is a required field.
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                          id="from-year"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 month_year">
                    <b-form-group
                        label-for="name-your-page"
                        class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        To <span class="text-danger"></span>
                      </template>
                      <div class="row">
                        <div class="col-6">
                          <b-form-select id="to-month" v-model="$v.project.to_month.$model" class="mb-3 "
                                         :state="validateState('to_month')" size="sm">
                            <b-form-select-option :value="null">Month</b-form-select-option>
                            <b-form-select-option v-for="(eachOp, index) in months" :value="index" :key="index">
                              {{ eachOp.vaule }}
                            </b-form-select-option>
                          </b-form-select>
                        </div>
                        <div class="col-6">
                          <b-form-select id="to-year" v-model="$v.project.to_year.$model" class="mb-3"
                                         :state="validateState('to_year')" size="sm">
                            <b-form-select-option :value="null">Year</b-form-select-option>
                            <b-form-select-option v-for="(eachOp, index) in years" :value="eachOp.name" :key="index">
                              {{ eachOp.name }}
                            </b-form-select-option>
                          </b-form-select>
                        </div>
                      </div>
                      <b-form-invalid-feedback
                          id="to-month"
                      >This is a required field.
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                          id="to-year"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <!--              <div class="row">-->
                <!--                <div class="col-12">-->
                <!--                  <div class="currently-work">-->
                <!--                    <ul class="unstyled centered">-->
                <!--                      <li>-->
                <!--                        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox"-->
                <!--                               v-model="is_currently_work_here">-->
                <!--                        <label for="styled-checkbox-1">I currently work here</label>-->
                <!--                      </li>-->
                <!--                    </ul>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                      label-for="name-your-page"
                      class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        Description (optional)
                      </template>
                      <b-form-textarea
                        id="description"
                        size="lg"
                        :state="validateState('description')"
                        v-model="$v.project.description.$model"
                        placeholder="About your work experience"
                      >
                      </b-form-textarea>
                      <b-form-invalid-feedback
                        id="description"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 ">
                    <div class="upload-photos-title">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="2" fill="#47505B"/>
                        <path d="M12 14L8 10L12 6" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span class="upload-title">
                        Upload Photos
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="upload-photos-section">
                      <ul>
                        <!-- <li>
                        <div class="uploaded-image">
                            <img src="img/Rectangle1.png">
                        </div>
                        <span class="cross-icon">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                        </span>
                        </li> -->
                        <!-- <li>
                        <div class="uploaded-image">
                            <img src="img/Rectangle2.png">
                        </div>
                        <span class="cross-icon">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                        </span>
                        </li> -->

                        <li v-for="(eachOp, index) in preview_list" :key="'image'+index" class="images-list-container">
                          <div class="uploaded-image">
                            <img :src="eachOp">
                          </div>
                          <span @click="deleteImage(index)" class="cross-icon cursor-pointer">
                              <svg width="15" height="15" viewBox="0 0 14 14" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                              </svg>
                          </span>
                        </li>
                        <li class="upload-photo-section-li images-list-container cursor-pointer">
                          <div class="upload-photo-icons">
                            <img :src="require('@/assets/img/add-p.png')">
                            <input type="file" multiple="multiple" accept="image/x-png,image/gif,image/jpeg"
                                   name="fileToUpload"
                                   @change="uploadImages" id="fileToUpload">
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row popup_button">
                  <div class="skip_btn work-cancel col-6 pl-0">
                    <button v-on:click="closeModal()">CANCEL</button>
                  </div>


                  <div class="next_step_btn ad-work-exp col-6 pr-0 d-flex">
                    <button type="submit" class="ml-auto">
                      {{ loading ? "Please Wait..." : `${item ? "EDIT" : "ADD"} PROJECT EXPERIENCE` }}
                    </button>
                  </div>

                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as Utils from '../../../../components/utils/Utils';
import {AddPageProjects, deleteMedia, updatePageProjects} from '../../../../apis/APIs';
import {required} from "vuelidate/lib/validators";

export default {
  name: "AddProject",
  data() {
    return {
      project: {
        project_name: null,
        project_title: null,
        from_month: null,
        from_year: null,
        to_month: null,
        to_year: null,
        description: null,
        artwork: [],
      },
      preview_list: [],

      months: Utils.getMonths(),
      years: Utils.getYears(),

      is_currently_work_here: false,

      loading: false,

      message: "",
      success_message: "",

      is_edit: false
    }
  },
  validations: {
    project: {
      project_name: {
        required
      },
      project_title: {
        required
      },
      from_month: {
        required,
      },
      from_year: {
        required,
      },
      to_month: {
        required,
      },
      to_year: {
        required,
      },
      description: {
        required,
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    pageID: {
      type: Number,
      default: null
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      "user": "user_map_to_props"
    }),
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  mounted() {
    if (this.item) {
      this.project = this.item
      this.project.artwork = []
      this.preview_list = this.item.artwork_url.map(image => {
        return image.artwork_url
      })
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.project[name];
      return $dirty ? !$error : null;
    },
    closeModal: function () {
      this.$parent.closeWindow();
    },
    ...mapActions([
      "edit_user_profile"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints"
    ]),

    uploadImages(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.project.artwork.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
        // this.selectedImages = this.images
      }
    },
    async creatProject() {
      this.$v.project.$touch();
      if (this.$v.project.$anyError) {
        return;
      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formData = new FormData();
          for (const field in this.project) {
            if (Array.isArray(this.project[field])) {
              if (this.project[field].length > 0) {
                for (let i = 0; i < this.project[field].length; i++) {
                  formData.append(field + '[' + i + ']', this.project[field][i]);
                }
              }
            } else {
              if (!!(this.project[field] !== null)) {
                formData.append(field, this.project[field]);
              }
            }
          }
          let res
          if (this.project.id) {
            res = await updatePageProjects(this.project.id, formData);
          } else {
            res = await AddPageProjects(this.current_page.id, formData);
          }
          const {data} = res

          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const page = data.information || {};
            this.$store.commit('SET_PAGE_DATA', page)
            this.$parent.closeWindow();
            await this.notificationToast(true, "Page!", message, 5000, 'success')
          } else {
            await this.notificationToast(true, "Page!", message, 5000, 'error')
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.notificationToast(true, "Page!", e.message, 5000, 'error')
        }

      }
    },
    async deleteImage(index) {
      try {

        this.preview_list.splice(index, 1);
        if(this.project.artwork_url[index])
        {
          await this.$store.dispatch('loading', true)
          const {data}  = await deleteMedia(this.project.artwork_url[index].id);
          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            await this.notificationToast(true, "Page!", message, 5000, 'success')
          } else {
            await this.notificationToast(true, "Page!", message, 5000, 'error')
          }

        }
        else{
          this.project.artwork.splice(index-Number(this.project.artwork_url.length),1)
        }

      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.notificationToast(true, "Page!", e.message, 5000, 'error')
      }
    }
  }
}
</script>
<style scoped lang="scss">

.modal-content {
  width: 570px;
}
.add-project-popup {
  background-color: #FFF;
  border-radius: 2px;
  position: relative;

  .popup-content {
    padding: 18px 20px 30px 20px;
  }
}

.upload-photos-title {
  background: #F7F7F7;
  border-radius: 2px;
  width: 530px;
  height: 40px;
  padding: 10px;
}

.images-list-container {
  width: 170px;
  height: 110px;
}

.upload-title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #47505B;
  margin-left: 10px;
}

.custom-select {
  background: url("../../../../../src/assets/icons/add-project-select-dropdown-icon.svg") no-repeat right 0.75rem center !important;
}

.cross-icon {
  position: absolute;
  top: 11.5px;
  right: 11.5px;
}
</style>
<style scoped>
::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #8B949F;
}
</style>

