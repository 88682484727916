<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="Page Member Invitation">
      <div style="background: #C4CCD6; height: 1px; margin-top: -15px">
      </div>
      <div class="d-block text-center p-4">
        <h6>You have been invited for the {{ this.$route.params.role }} role!</h6>
      </div>
      <div style="background: #C4CCD6; height: 1px;">
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="mt-3 mr-2 prime_button" block @click="acceptAndRejectInvitation('Accept')">ACCEPT</b-button>
        <b-button class="mt-3 danger-button" block @click="acceptAndRejectInvitation('Reject')">REJECT</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {pageMemberApprovalAndRejection} from "../../../../apis/APIs";
import createFormData from "../../../../mixins/common";

export default {
  name: "ApprovalAndRejectionModal",
  mounted() {
    if (this.$route.params.role) {
      this.showModal()
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    acceptAndRejectInvitation: async function(action) {
      let body = {
        page_id: this.$route.params.id,
        action: null
      }
      if (action === 'Accept') {
        body.action = 1
      } else if (action === 'Reject') {
        body.action = 0
      }
      try {
        await this.$store.dispatch('loading', true);
        const formData = createFormData(body);
        const { data } = await pageMemberApprovalAndRejection(formData)
        const { success, message } = data || {}
        await this.$store.dispatch('loading', false);
        if (success === 1) {
          await this.notificationToast(true, 'Page Member Invitation', `Successfully ${message}`, 5000, 'success')
          // await this.makeToast('success', 'Page Member Invitation', `Successfully ${message}`)
        } else {
          await this.notificationToast(true, 'Page Member Invitation', message, 5000, 'error')
        }
        this.hideModal()
      } catch (e) {
        await this.$store.dispatch('loading', false);
        console.error(e.message)
      }
      this.hideModal()
    }
  }
}
</script>

<style scoped>
.danger-button {
  width: 100px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  background: #EE3F3F;
  color: #fff;
  border: 1px solid #EE3F3F;
  border-radius: 4px;
  transition: 0.2s all ease;
  font-family: 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
}
.danger-button:hover {
  background: #f66262;
  color: #fff;
  border-color: #f66262;
}
.prime_button {
  width: 100px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  background: #D1433A;
  color: #fff;
  border: 1px solid #D1433A;
  border-radius: 4px;
  transition: 0.2s all ease;
  font-family: 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
}

.prime_button:hover {
  background: #DA6961;
  color: #fff;
  border-color: #DA6961;
}
</style>
