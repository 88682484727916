<template>
  <div>
    <div class="row " v-if="comment_list_loading ">
      <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <div  :key="comments_count">
      <CommentSection :id="eachOp.id" v-for="(eachOp) in commentList" :key="eachOp.id" :item="eachOp" :post="item"
                      :name="userName"
                      :comment_type="type"
                      @reactOrUnReact="reactOrUnReactOnComment(eachOp)"
                      @removeComment="removeComment" />

    </div>
  </div>
</template>

<script>
import CommentSection from "@/components/Home/NewsFeed/components/CommentSection"
import {mapGetters} from "vuex";
import {addCommentsOfPost, getCommentsOfPost} from "../../../../apis/APIs";
import Vue from "vue";
import moment from "moment-timezone"
export default {
  name: "CommentListing",
  components:{
    CommentSection
  },
  data(){
    return{
      commentItem: {},
      commentKey: 0,
      commentList: [],
      comments_count: null,
      comment: null,
      addCommentLoading: false,
      comment_list_loading: false,
      comment_list: [],
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  watch: {
    type(){
      if(this.id && this.type) {
        this.init()
      }
    },
  },
  mounted() {
    if(this.item && this.id && this.type) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      if(Object.keys(this.item).length !== 0 && this.item.id) {
        return this.item.id;
      }
      return null;
    },
    type() {
      const { type = "", post_type = "" } = this.item || {};
      if (type) {
        return type.charAt(0).toUpperCase() + type.slice(1);
      } else if (post_type || post_type === null) {
        return "Post";
      } else {
        return "";
      }
    },
    userName() {
      if (this.item.user) {
        return this.item.user.name ? '@' + this.item.user.name.charAt(0).toUpperCase() + this.item.user.name.slice(1) + ' ' + this.item.user.last_name.charAt(0).toUpperCase() + this.item.user.last_name.slice(1) : ''
      } else {
        return ''
      }
    },
    comments() {
      return this.commentList.length > 0 ? this.commentList : []
    }
  },
  methods: {
    stopPropagationOnCommentClick(e) {
      if (e) e.stopPropagation();
    },
    async init() {
      try {
        this.comment_list_loading = true;
        const formData = new FormData();
        formData.append('commentable_type', (this.type && this.type === "Music Podcast") ? "Podcast" : this.type);
        formData.append('commentable_id', this.$route.params.id);
        const {data} = await getCommentsOfPost(formData);
        this.commentList = (data.information) ? data.information.data : []
        this.comments_count = (data.information) ? data.information.total : null
        this.comment_list_loading = false;
      } catch (error) {
        console.error(error)
        this.comment_list_loading = false;
      }
    },
    async removeComment(commentId){
      if (this.commentList.length > 0) {
            const replacedIndex = this.commentList.findIndex(e => e.id === commentId)
            if (replacedIndex !== -1) {
              this.commentList.splice(replacedIndex, 1);
            }
        }
    },
    async reactOrUnReactOnComment(comment) {
      console.log('hereee');
      const temp = comment
      let obj = {
        reaction_able_id: comment.id,
        reaction_able_type: 'Comment',
        reaction_type: 'like'
      }
      if (comment.reacted) {
        Vue.set(comment, 'reacted', null)
        const index = comment.reactions.findIndex(e => e.type = obj.reaction_type)
        if (index !== -1) {
          comment.reactions[index].count = --comment.reactions[index].count
        }
      } else {
        Vue.set(comment, 'reacted', obj)
        const index = comment.reactions.findIndex(e => e.type = obj.reaction_type)
        if (index !== -1) {
          comment.reactions[index].count = ++comment.reactions[index].count
        } else {
          comment.reactions.push({
            type: obj.reaction_type,
            count: 1
          })
        }
      }

      let {success} = await this.$store.dispatch('reactOrUnReact', obj)
      if (!(Boolean(success))) {
        Vue.set(comment, 'reacted', (comment.reacted) ? null : obj)
        comment = temp
      }
    },
    addCommentInList(comment){
      this.commentList.unshift(comment)
    }
  }
}
</script>

<style scoped>

</style>
