<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body m-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9.00176 8.00176C9.55404 8.00176 10.0018 8.44947 10.0018 9.00176V12.6018C10.0018 13.154 9.55404 13.6018 9.00176 13.6018C8.44947 13.6018 8.00176 13.154 8.00176 12.6018V9.00176C8.00176 8.44947 8.44947 8.00176 9.00176 8.00176ZM9.00176 4.4C8.44947 4.4 8.00176 4.84771 8.00176 5.4C8.00176 5.95228 8.44947 6.4 9.00176 6.4H9.01076C9.56304 6.4 10.0108 5.95228 10.0108 5.4C10.0108 4.84771 9.56304 4.4 9.01076 4.4H9.00176Z" fill="#47505B"/>
                </svg>
                Basic Info
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="createPage">
                <div class="artist-setting-panel">
                  <b-form-group
                      label-for="name-your-page"
                      class="artist-page-form-field"
                  >
                    <template v-slot:label>
                      Page Name <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="name-your-page"
                      v-model="pageName"
                      :state="validateState('pageName')"
                      placeholder="your page name"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        id="name-your-page"
                    >This is a required field.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                      label="Page URL"
                      label-for="page-address"
                      label-align="left"
                      class="artist-page-form-field"
                      description="Can only contain alphanumeric characters (A–Z, 0–9) and periods ('.')"
                  >
                    <template v-slot:label>
                      Page URL <span class="text-danger">*</span>
                      <b-icon class="mx-2" id="tooltip-target-1" icon="question-circle-fill" font-scale="1.3"></b-icon>
                      <b-tooltip placement="topright" custom-class="custom-tooltip" target="tooltip-target-1" triggers="hover" style="height: 30px!important">
                        Playy members and search engines willuse this unique URL to find your page. Note that a Page URL can’t be changed more than once in a 60-day period. <span class="read-more-text"> Learn more </span>
                      </b-tooltip>
                    </template>
                    <b-input-group
                        prepend="music.playy.co.uk"
                        size="sm"
                        class="mb-2"
                    >
                      <b-form-input
                          id="page-address"
                          v-model="pageAddress"
                          :state="validateState('pageAddress')"
                          placeholder="your address"
                      >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <div class="row">
                    <div class="col-6">
                      <b-form-group
                          label-for="category"
                          class="artist-page-form-field"
                      >
                        <template v-slot:label>
                          Category <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            id="category"
                            v-model="pageCategory"
                            :state="validateState('category')"
                            :options="pageCategories"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            size="sm"
                            :value="null"
                            :disabled="true"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback
                            id="category"
                        >This is a required field.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group
                          v-if="selectedCategorySubCategories.length>0"
                          label-for="sub-category"
                      >
                        <template v-slot:label>
                          SubCategory <span class="text-danger">*</span>
                        </template>
                        {{ subCategory }}
                        <SelectDropdown
                          :options="selectedCategorySubCategories"
                          id="sub-category"
                          v-model="pageSubCategory"
                          :state="validateState('subCategory')"
                          @change="((value) => pageSubCategory = value)"
                          :style="'width: 349px; border: 1px solid #E6EAF0;'"
                        />
                        <label class="error_msg error_msg_red"
                            v-if="!pageSubCategory">This is a required field</label>
                      </b-form-group>
                    </div>
                  </div>
                  <b-form-group
                      label="About"
                      label-for="about"
                      class="artist-page-form-field"
                  >
                    <b-form-textarea
                        id="about"
                        size="sm"
                        :state="validateState('about')"
                        v-model="pageAbout"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback
                        id="about"
                    >This is a required field.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <div class="d-flex justify-content-end">
                    <button class="prime_button" type="submit">Save Changes</button>
                  </div>
                </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePageModal v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import DeletePageModal from '../DeletePageModal'
import {required, requiredIf} from "vuelidate/lib/validators";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue';
import SelectDropdown from "../../../Common/Dropdown/SelectDropdown.vue";

export default {
  data() {
    return {
      deletePage: false,
      createNewPageForm: {
        pageName: '',
        pageAddress: '',
        category: null,
        subCategory: null,
        specification: '',
        about: '',
        type: 'Artist',
        avatarImage: {
          image: null,
          name: null,
          crop_image: null
        },
        coverImage: {
          image: null,
          crop_image: null
        }
      },
      categories: [],
      subCategories: [],
      specifications: [],
      profileAndCoverModel: false,
      profileAndCoverModelType: '',

    }
  },
  components: {
    EyeIcon,
    // MusicSubHeader,
    DeletePageModal,
    SelectDropdown,
  },
  computed: {
    pageCategories() {
      return this.$store.getters.getPageCategories.map(category => {
        return {
          value: category.id,
          text: category.name,
          subCategories: category.page_sub_category
        }
      })
    },
    selectedCategorySubCategories() {
      if (this.pageCategory !== null) {
        const index = this.pageCategories.findIndex(category => category.value === this.pageCategory)
        if (index !== -1) {
          return this.pageCategories[index].subCategories.map(category => {
            return {
              value: category.id,
              label: category.name,
              specification: category.page_specification
            }
          })
        }
      }
      return []
    },
    selectedSubCategorySpecification() {
      if (this.pageSubCategory !== null) {
        const index = this.selectedCategorySubCategories.findIndex(category => category.value === this.pageSubCategory)
        if (index !== -1) {
          return this.selectedCategorySubCategories[index].specification.map(category => {
            return {
              value: category.id,
              text: category.name,
            }
          })
        }
      }
      return []
    },
    pageType(){
      return this.createNewPageForm.type
    },
    createPageIcon(){
      if(this.pageType==='Clubs'){
        return require(`@/assets/img/svg/create_club_page.svg`)
      }
      else if(this.pageType==='Company'){
        return require(`@/assets/img/svg/create_company_page.svg`)
      }else{
        return require(`@/assets/img/user-1.png`)
      }
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    pageName:{
      set(newValue){
        this.$v.createNewPageForm.pageName.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.title:null
      }
    },
    pageAddress:{
      set(newValue){
        this.$v.createNewPageForm.pageAddress.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.page_address:null
      }
    },
    pageCategory:{
      set(newValue){
        this.$v.createNewPageForm.category.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.page_category_id:null
      }
    },
    pageSubCategory: {
      set(newValue){
        this.current_page.page_subcategory_id = newValue;
        this.$v.createNewPageForm.subCategory.$model = newValue;
      },
      get(){
        console.log("subcategpry", this.createNewPageForm.subCategory);
        return (this.current_page)? this.current_page.page_subcategory_id: null
      }
    },
    pageAbout:{
      set(newValue){
        this.$v.createNewPageForm.about.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.content:null
      }
    },
  },
  validations: {
    createNewPageForm: {
      pageName: {
        required
      },
      category: {
        required
      },
      subCategory: {
        required: requiredIf(function (nestedModel) {
          return this.selectedCategorySubCategories.length > 0
        })
      },
      pageAddress: {
        required,
      },
      about: {
        required,
      }
    }
  },
  watch: {
    'createNewPageForm.subCategory': function (newVal, oldVal) {
      if (oldVal !== null) {
        this.createNewPageForm.specification = null
      }
    },
    pageName(val){
      if(val)
      this.createNewPageForm.pageName = val
    },
    pageAddress(val){
      if(val)
      this.createNewPageForm.pageAddress = val
    },
    pageAbout(val){
      if(val)
      this.createNewPageForm.about = val
    },
    pageSubCategory(val){
      if(val)
      this.createNewPageForm.subCategory = val
    }
  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.createNewPageForm[name];
      return $dirty ? !$error : null;
    },
    changeProfileAndCoverValue(value, image) {
      this.profileAndCoverModel = value;
    },
    openImageAndCoverModel(type) {
      this.profileAndCoverModel = true
      this.profileAndCoverModelType = type
    },
    async createPage() {
      this.$v.createNewPageForm.$touch();
      if (this.$v.createNewPageForm.$anyError) {
        return;
      } else {
        try {
          this.$store.commit('SET_NEW_PAGE_FORM_DATA', this.createNewPageForm)
          let {data} = await this.$store.dispatch('UpdatePageBasicInfo',this.current_page.id)
          if (data.success === 1) {
            this.$store.commit('SET_PAGE_DATA',data.information)
            await this.notificationToast(true, 'Page', 'Page Basic Info Updated!', 5000, 'success')
          } else {
            await this.notificationToast(true, 'Page', data.message, 5000, 'error')
          }
        } catch (e) {
          await this.notificationToast(true, 'Page', e.message, 5000, 'error')
        }

      }
    },
    setPageType(){
      this.createNewPageForm.type=(this.$route.query.type &&(this.$route.query.type==='Clubs'||this.$route.query.type==='Company'))?this.$route.query.type:'Artist';
    },
    setPageDataFromStore(){
      this.createNewPageForm.pageName = this.pageName
      this.createNewPageForm.pageAddress = this.pageAddress
      this.createNewPageForm.about = this.pageAbout
      this.createNewPageForm.category = this.pageCategory
      this.createNewPageForm.subCategory = this.pageSubCategory
    }

  },
  mounted() {
    this.$store.dispatch('getPageCategories', {stopLoadingOnComplete: true})
    this.setPageType()
    this.setPageDataFromStore()
  }
}
</script>
<style>
.custom-tooltip > .tooltip-inner{
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #FFFFFF!important;
  border: 1px solid #E6EAF0;
  box-shadow: 0px 10px 20px rgba(71, 80, 91, 0.15);
  border-radius: 6px;
  max-width: none;
  width: 300px;
  color: #000000;
}

.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}
.read-more-text{
  color: #D1433A;
}
</style>
<style scoped lang="scss">
.form-control:focus {
  border-color: #e6eaf0 !important;
  box-shadow: none !important;
}
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
}
</style>
