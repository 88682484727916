<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="thanks-conf-email"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        style="padding-right: 15px; display: block;"
    >
      <div class="modal-dialog">
        <div class="modal-content email_confirmed_box">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body">
            <div class="body-title Font_semibold m_b_22">
              Thanks! Your e-mail is confirmed
            </div>
            <div class="modal-title-sm grey-text m_b_20">
              Set up your account or in 4 easy steps. Now you’re able to get
              started on Playy.
            </div>
            <div
                class="btn btn-login m-t-20 Font_semibold"
                data-dismiss="modal"
                aria-label="Close"
                v-on:click="closeModal"
            >
              GET STARTED
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {mapMutations} from 'vuex';
import * as SMStorage from "../../../apis/SMStorage";
import {SYSTEM_DATA_IS_AUTHENTICATED, SYSTEM_DATA_IS_WELCOME_PAGE} from '../../../store/types';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations([
      "updateSystemDataUIConstraints"
    ]),
    closeModal: function () {
      this.updateSystemDataUIConstraints({
        [SYSTEM_DATA_IS_AUTHENTICATED]: true,
        [SYSTEM_DATA_IS_WELCOME_PAGE]: true
      });
      this.$parent.closeModal();
      this.$router.push("/home");
    }
  }
};
</script>
