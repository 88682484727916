<template>
  <div class="events-overview-genres-container d-grid gap-3">
    <span class="events-overview-genres-heading">Genres</span>
    <div class="events-overview-genres-list-container">
      <div class="events-overview-genres-list">
        <div
          :class="'events-overview-genre' + (isSelectedGenre(eachOp.id) ? ' active' : '')"
          @click="selectGenre($event, eachOp.id)"
          v-for="(eachOp, index) in genres"
          :key="index"

        >
          <span> {{ eachOp.name }}</span>
        </div>
        <div
          @click="clearGenreFilter"
          v-if="selectedGenres.length > 0"
          class="events-overview-genre-clear-history-btn"
        >
          Clear all history
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      genres: [],
      selectedGenres: [],
    };
  },
  async mounted() {
    await this.getGenres();
  },
  methods: {
    ...mapMutations(["set_Events_Searech_For"]),
    ...mapActions(["getGenersType"]),
    async getGenres() {
      try {
        const { information } = await this.getGenersType();
        this.genres =
          information && information.length
            ? information.map((genre) => {
                return {
                  id: genre.id,
                  name: genre.name.split("&amp;").join("&"),
                };
              })
            : [];
      } catch (error) {
        console.error(error);
      }
    },
    clearGenreFilter() {
      this.selectedGenres = [];
      this.set_Events_Searech_For({
        Genre: "",
        event_type:
          !this.$route.path.includes("/events/page/overview") &&
          this.$route.meta.event_Type_id,
      });
      this.$emit("explorEvents", this.GET_EVENTS_SEARCH_FOR);
    },
    selectGenre(e, value) {
      e.stopPropagation();
      const index = this.selectedGenres.findIndex((e) => e === value);
      if (index !== -1) {
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(value);
      }
      this.set_Events_Searech_For({
        Genre: this.selectedGenres,
        event_type:
          !this.$route.path.includes("/events/page/overview") &&
          this.$route.meta.event_Type_id,
      });
      this.$emit("explorEvents", this.GET_EVENTS_SEARCH_FOR);
    },
    isSelectedGenre(id) {
      const index = this.selectedGenres.findIndex((e) => e === id);
      return index !== -1;
    },
  },
  computed: {
    ...mapGetters(["GET_EVENTS_SEARCH_FOR"]),
  },
};
</script>

<style lang="scss" scoped>
.gen_mooods {
  li {
    cursor: pointer;
  }
  .clear-history-btn {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.87px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(139, 148, 159, 1);
  }
}
</style>
