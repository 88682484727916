<template>
  <div class="ag-container">
    <PMSubHeader :current_page_user="(current_page && current_page.current_user) ? current_page.current_user : null"/>
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.35 8C15.35 12.0593 12.0593 15.35 8 15.35C3.94071 15.35 0.65 12.0593 0.65 8C0.65 3.94071 3.94071 0.65 8 0.65C12.0593 0.65 15.35 3.94071 15.35 8Z" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 11.2V8" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 4.7998H8.01" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{(current_page && current_page.type === 'partyroom') ? 'PartyRoom' : 'ClubRoom'}} Information
              </div>
<!--              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">-->
<!--                        <span class="pr-2">-->
<!--                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"-->
<!--                                xmlns="http://www.w3.org/2000/svg">-->
<!--                              <g clip-path="url(#clip0)">-->
<!--                                 <path-->
<!--                                   d="M0.666504 8.00008C0.666504 8.00008 3.33317 2.66675 7.99984 2.66675C12.6665 2.66675 15.3332 8.00008 15.3332 8.00008C15.3332 8.00008 12.6665 13.3334 7.99984 13.3334C3.33317 13.3334 0.666504 8.00008 0.666504 8.00008Z"-->
<!--                                   stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"-->
<!--                                   stroke-linejoin="round"></path>-->
<!--                                 <path-->
<!--                                   d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"-->
<!--                                   stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"-->
<!--                                   stroke-linejoin="round"></path>-->
<!--                              </g>-->
<!--                              <defs>-->
<!--                                 <clipPath id="clip0">-->
<!--                                    <rect width="16" height="16" fill="white"></rect>-->
<!--                                 </clipPath>-->
<!--                              </defs>-->
<!--                           </svg>-->
<!--                        </span>-->
<!--                View Profile-->
<!--              </button>-->
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="createPage">
                  <div class="artist-setting-panel">
                    <b-form-group
                      label-for="name-your-page"
                      class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        Page Name <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="name-your-page"
                        v-model="page_name"
                        :state="validateState('page_name')"
                        placeholder="your page name"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        id="name-your-page"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <div class="row">
                      <div class="col-6">
                        <b-form-group
                          label-for="category"
                          class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="category"
                            v-model="pageCategory"
                            :state="validateState('category')"
                            :options="pageCategories"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            size="sm"
                            :value="null"
                            :disabled="true"
                          >
                          </b-form-select>
                          <b-form-invalid-feedback
                            id="category"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <b-form-group
                          v-if="selectedCategorySubCategories.length>0"
                          label-for="sub-category"
                          class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            SubCategory <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="sub-category"
                            v-model="pageSubCategory"
                            :state="validateState('subCategory')"
                            :options="selectedCategorySubCategories"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            size="sm"
                            :value="null"

                          >
                          </b-form-select>
                          <b-form-invalid-feedback
                            id="sub-category"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <b-form-group
                          label-for="location"
                          class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Location
                          </template>
                          <b-form-input
                            v-if="locationCheck"
                            id="location"
                            v-model="location"
                            placeholder="Location"
                            @click = "locationCheck =! locationCheck"
                          >
                          </b-form-input>
                          <div v-else class="location-input">
                            <GoogleMapAndLocation @getLocation="showLocation" />
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-4">
                        <b-form-group
                          label-for="phone"
                          class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Phone
                          </template>
                          <b-form-input
                            id="phone"
                            v-model="phone"
                            :state="validateState('phone')"
                          >
                          </b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-4">
                        <b-form-group
                          label-for="page-url"
                          class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Website
                          </template>
                          <b-form-input
                            id="page-url"
                            v-model="website"
                            :state="validateState('website')"
                          >
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                    <b-form-group
                      label-for="music-genres"
                      class="artist-page-form-field"
                    >
                      <template v-slot:label>
                        Music Genres
                      </template>
                      <Select2 v-model="$v.createNewPageForm.genre.$model"
                               id="genre"
                               placeholder="Choose "
                               :key="'specification_id' + errorsList['genre']"
                               :class="{'is-invalid':errorsList['genre']}"
                               :state="validateState('genre')"
                               :options="genres"
                               :settings="{ multiple: true, tags: true }"/>
                      <div class="invalid-feedback d-block" v-if="errorsList['genre']">
                        This is a required field.
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="About"
                      label-for="about"
                      class="artist-page-form-field"
                    >
                      <b-form-textarea
                        id="about"
                        size="sm"
                        v-model="about"
                        :state="validateState('about')"
                      >
                      </b-form-textarea>
                      <b-form-invalid-feedback
                        id="about"
                      >This is a required field.
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <div class="d-flex justify-content-end">
                      <button class="prime_button" type="submit">Save Changes</button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePageModal v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
import { minLength, required, requiredIf, numeric } from "vuelidate/lib/validators";
import DeletePageModal from "./DeletePageModal";
import PMSubHeader from "../PMSubHeader";
import {
  get_geners_list,
  getCitiesOfCountry,
  updateClubPageInfo,
  updateContactsOFPage,
  updateLocationOFPage
} from "../../../apis/APIs";
import Select2 from "v-select2-component";
import GoogleMapAndLocation from "../../Common/Google Location/GoogleMapAndLocation";
import createFormData from "../../../mixins/common";

export default {
  name: "ClubInformation",
  data() {
    return {
      deletePage: false,
      locationCheck: false,
      createNewPageForm: {
        page_name: '',
        website: '',
        country: null,
        city: null,
        location: null,
        latitude: null,
        longitude: null,
        phone: '',
        category: null,
        subCategory: null,
        genre: [],
        specification: '',
        about: '',
        type: '',
        workLocation: ''
      },
      genres: [],
      categories: [],
      subCategories: [],
      specifications: [],
      errorsList:[],
      citiesOfSelectedCountry: []
    }
  },
  components: {
    PMSubHeader,
    DeletePageModal,
    Select2,
    GoogleMapAndLocation
  },
  computed: {
    pageCategories() {
      return this.$store.getters.getPageCategories.map(category => {
        return {
          value: category.id,
          text: category.name,
          subCategories: category.page_sub_category
        }
      })
    },
    selectedCategorySubCategories() {
      if (this.pageCategory !== null) {
        const index = this.pageCategories.findIndex(category => category.value === this.pageCategory)
        if (index !== -1) {
          return this.pageCategories[index].subCategories.map(category => {
            return {
              value: category.id,
              text: category.name,
              specification: category.page_specification
            }
          })
        }
      }
      return []
    },
    selectedSubCategorySpecification() {
      if (this.pageSubCategory !== null) {
        const index = this.selectedCategorySubCategories.findIndex(category => category.value === this.pageSubCategory)
        if (index !== -1) {
          return this.selectedCategorySubCategories[index].specification.map(category => {
            return {
              value: category.id,
              text: category.name,
            }
          })
        }
      }
      return []
    },
    pageType(){
      return this.createNewPageForm.type
    },
    createPageIcon(){
      if(this.pageType==='Clubs'){
        return require(`@/assets/img/svg/create_club_page.svg`)
      }
      else if(this.pageType==='Company'){
        return require(`@/assets/img/svg/create_company_page.svg`)
      }else{
        return require(`@/assets/img/user-1.png`)
      }
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    page_name:{
      set(newValue){
        this.$v.createNewPageForm.page_name.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.title:null
      }
    },
    website:{
      set(newValue){
        this.$v.createNewPageForm.website.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.page_address:null
      }
    },
    pageCategory:{
      set(newValue){
        this.$v.createNewPageForm.category.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.page_category_id:null
      }
    },
    pageSubCategory:{
      set(newValue){
        this.$v.createNewPageForm.subCategory.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.page_subcategory_id:null
      }
    },
    about:{
      set(newValue){
        this.$v.createNewPageForm.about.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.content:null
      }
    },
    location:{
      set(newValue){
        this.$v.createNewPageForm.location.$model = newValue
      },
      get(){
        return (this.current_page)? this.current_page.address:null
      }
    },
    phone:{
      set(newValue){
        this.$v.createNewPageForm.phone.$model = newValue
      },
      get(){
        return (this.current_page) ? this.current_page.company_phoneno : null
      }
    },
    genre: {
      set(newValue){
        this.$v.createNewPageForm.genre.$model = newValue
      },
      get(){
        return (this.current_page) ? this.current_page.genres.map(genre=>{
          return genre.id
        }): []
      }
    }
  },
  validations: {
    createNewPageForm: {
      page_name: {
        required
      },
      category: {
        required
      },
      subCategory: {
        required: requiredIf(function (nestedModel) {
          return this.selectedCategorySubCategories.length > 0
        })
      },
      phone: {
        numeric
      },
      website: {
        required
      },
      genre: {
        required,
        maxLength: minLength(1)
      },
      about: {
        required
      }
    }
  },
  watch: {
    'createNewPageForm.subCategory': function (newVal, oldVal) {
      if (oldVal !== null) {
        this.createNewPageForm.specification = null
      }
    },
    page_name(val){
      if(val)
        this.createNewPageForm.page_name = val
    },
    website(val){
      if(val)
        this.createNewPageForm.website = val
    },
    about(val){
      if(val)
        this.createNewPageForm.about = val
    },
    pageSubCategory(val){
      if(val)
        this.createNewPageForm.subCategory = val
    }
  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.createNewPageForm[name];
      return $dirty ? !$error : null;
    },
    async createPage() {
      this.$v.createNewPageForm.$touch();
      if (this.$v.createNewPageForm.$anyError) {
        return;
      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formData = createFormData(this.createNewPageForm)
          let {data} = await updateClubPageInfo(this.current_page.id, formData)
          await this.$store.dispatch('loading', false)
          if (data.success === 1) {
            this.$store.commit('SET_PAGE_DATA',data.information)
            await this.notificationToast(true, "Page!", 'Page Basic Info Updated!', 5000, 'success')
          } else {
          await this.notificationToast(true, "Page!", data.message, 5000, 'error')
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.notificationToast(true, "Page!",(e.message) ? e.message : 'Unable to save Page Data', 5000, 'error')
        }

      }
    },
    setPageType(){
      this.createNewPageForm.type=(this.$route.query.type &&(this.$route.query.type==='Clubs'||this.$route.query.type==='Company'))?this.$route.query.type:'Artist';
    },
    setPageDataFromStore(){
      this.createNewPageForm.page_name = this.page_name
      this.createNewPageForm.website = this.website
      this.createNewPageForm.about = this.about
      this.createNewPageForm.category = this.pageCategory
      this.createNewPageForm.subCategory = this.pageSubCategory
      this.createNewPageForm.genre = this.genre
      this.createNewPageForm.location = this.location
      this.createNewPageForm.phone = this.phone
      this.locationCheck = (this.location) ? true : false
     },
    async getGenreList() {
      try {

        const res = await get_geners_list();
        const {success, information} = res.data || {};
        if (success === 1) {
          if (information && information.length) {
            this.genres = information.map(ele => ({id: ele.id, text: ele.name, subgenre: ele.subgenre}));
          }
        }

      } catch (error) {

      }
    },
    async showLocation(location) {
      this.createNewPageForm.country = location.country
      this.createNewPageForm.city = location.city
      this.createNewPageForm.location = location.location
      this.createNewPageForm.latitude = location.latitude
      this.createNewPageForm.longitude = location.longitude
    },
  },
  async mounted() {
    await this.$store.dispatch('getPageCategories')
    await this.getGenreList()
    this.setPageType()
    this.setPageDataFromStore()
  }
}
</script>
<style>
.custom-tooltip > .tooltip-inner{
  /* Removes the default max-width */
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;


  max-width: none;
  width: 300px;

  /* Apply whatever other styles you want */
  padding: 10px;
}
.category-select::after {
  background-image: url(../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../assets/img/setting-checkbox.png);
}
.read-more-text{
  color: #D1433A;
}

</style>
<style lang="scss">
.location-input {
  border: 1px solid #E6EAF0;
  border-radius: 4px;

  div {
    input {
      padding: 0!important;
      margin-left: 0!important;
      height: 28px!important;
    }
  }
}
</style>

