<template>
  <div class="row" style="margin-left: 0.1rem !important;">
    <div v-for="(albums,index) in filteredAlbums" :key="index" class="col-md-artist-photo">
      <div class="photo-block" v-if="!singleAlbum">
        <img class="cursor-pointer"  :src="albums.artwork.last_url" @click="singleAlbumOpen(albums.slug, albums.title)" @error="onPostImageError">
        <div class="photo-block-name">{{ albums.title ? albums.title : 'Title' }}</div>
      </div>
    </div>
    <div>
      <SingleAlbum v-if="singleAlbum" :singleAlbumData="singleAlbumData" :title="title" :slug="slug" @closeSingleAlbum="closeSingleAlbum" @showMore="showMore"/>
    </div>
  </div>
</template>
<script type="application/javascript">
import SingleAlbum from "../../../../../Home/Profile/media/albums/SingleAlbum";
import Vue from "vue";
import VueImg from "v-img";
import { getPageSelectedPhotos } from "../../../../../../apis/APIs";
const vueImgConfig = {
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
}
Vue.use(VueImg, vueImgConfig);
export default {
  name: "mediaPhotosAlbumsListing",
  data(){
    return {
      singleAlbum: false,
      singleAlbumData: [],
      title: '',
      loading: false,
      slug: '',
      offset: 0,
      limit: 8,
      total: 0
    }
  },
  components:{
    SingleAlbum
  },
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    listData () {
      this.getMediaPhotosAlbumsList();
    }
  },
  computed: {
  filteredAlbums() {
    return this.listData.filter(album => !this.singleAlbum && album.artwork.total > 0);
  }
},
  methods: {
    getMediaPhotosAlbumsList () {
      this.$emit('getMediaPhotosAlbums')
    },
    async singleAlbumOpen(slug,title){
      try{
        this.$emit('setShowMore');
        this.$emit('toggleAllPhotos')
        this.singleAlbum = true;
         this.title = title;
         this.slug = slug;
        this.$store.dispatch('loading', true)
        const data = await getPageSelectedPhotos(slug, this.offset, this.limit, this.$route.params.id)
        this.total = data.data.information.total
        this.singleAlbumData =  data.data.information.data;
        this.$store.dispatch('loading', false)
      }
      catch (error){
        this.loading = false
        this.singleAlbum = false
      }
    },
    closeSingleAlbum(){
      this.$emit('toggleAllPhotos')
      this.singleAlbum = false;
    },
    async showMore() {
      if(this.total > 8){
        this.offset = this.offset + this.limit;
        const data = await getPageSelectedPhotos(this.slug, this.offset, this.limit, this.$route.params.id)
        this.singleAlbumData =  [...this.singleAlbumData, ...data.data.information.data];
      }
    }
  }
}
</script>

<style scoped>

</style>
