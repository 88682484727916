<template>
    <div class="page-artist-card card slider round artist card-hover-effect" @click="redirectToPage()">
        <div class="card-cover js-card-hover image-hover-effect">
            <div class="cover square round"><img :src="profile" alt="" /></div>
        </div>
        <div class="card-description position-relative pb-10 round card-hover-effect">
            <div class="circle-blue">
                <div class="svg-icon">
                    <svg class="svg-star" width="7" height="6" viewBox="0 0 7 6" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 2.81104L2.75 4.52698L5.66101 1" stroke="white" stroke-width="1.3"></path>
                    </svg>
                </div>
            </div>

            <div class="title text-left text-truncate">
                {{
                    type === "song" && item.title
                        ? item.title
                        : type === "review" && item.reviewable
                            ? item.reviewable.title
                            : ""
                }}
            </div>

            <div class="name text-left text-truncate" v-if="item.type === 'artist' || item.type === 'company'">
                #{{ category }}, {{ subCategory }}
            </div>

            <div v-else class="name text-center text-truncate">
                {{ (item && item.members) ? item.members + ' members' : '0 member' }}
            </div>

            <!-- rating -->

            <!-- <div class="cover-rating" v-if="item.type === 'artist' || item.type === 'company'">
                
          <div class="svg-icon">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.55653 0.494009C5.69064 0.0597346 6.30505 0.0591306 6.44 0.493147L7.613 4.2655H11.3693C11.8115 4.2655 12.0015 4.82658 11.6504 5.09534L8.59534 7.43377L9.76586 11.2242C9.89851 11.6538 9.40241 12.001 9.04437 11.7291L6.00045 9.4181L2.97053 11.7373C2.61284 12.0111 2.11467 11.6639 2.2476 11.2335L3.41801 7.4434L0.348437 5.09543C-0.0028397 4.82673 0.187148 4.2655 0.629383 4.2655H4.39186L5.55653 0.494009Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="rating">
            {{
              type === "review"
                ? item.rating
                : (type === "song" || type === "album") && item.avg > 0
                ? item.avg
                : "0"
            }}
          </div>
        </div> -->
            <!-- <div class="followers" v-if="follow === 'approved'">
                <div class="text">
                    <div class="svg-icon">
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="c-line-1 c-fill-1"
                                d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                                fill="#D1433A"></path>
                            <path class="c-line-1 c-fill-1"
                                d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                                fill="#D1433A"></path>
                            <path class="c-line-1 c-fill-1"
                                d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                                fill="#D1433A"></path>
                        </svg>
                    </div>
                    Follow 873.7k
                </div>
            </div> -->


            <div v-if="spinner" class="text-center spinner-color mt-3">
                <b-spinner small label="Small Spinner"></b-spinner>
            </div>

            <div class="followers following-btn"
                v-if="follow === 'approved' && (item.type === 'artist' || item.type === 'company') && !spinner" @click="
                    followUnfollow(type === 'review' ? item.reviewable : item, $event)
                    ">
                <div class="text">


                    <!-- <div class="svg-icon">
              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3346 1L5.0013 8.33333L1.66797 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                          <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.33398 7.22913C1.33398 6.94591 1.56358 6.71631 1.8468 6.71631C3.81893 6.71631 5.71028 7.49974 7.10478 8.89424C8.49928 10.2887 9.2827 12.1801 9.2827 14.1522C9.2827 14.4354 9.05311 14.665 8.76988 14.665C8.48666 14.665 8.25706 14.4354 8.25706 14.1522C8.25706 12.4521 7.5817 10.8216 6.37954 9.61947C5.17739 8.41732 3.54691 7.74195 1.8468 7.74195C1.56358 7.74195 1.33398 7.51236 1.33398 7.22913Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.33398 1.84485C1.33398 1.56163 1.56358 1.33203 1.8468 1.33203C5.24702 1.33203 8.50796 2.68276 10.9123 5.08707C13.3166 7.49139 14.6673 10.7523 14.6673 14.1525C14.6673 14.4358 14.4377 14.6654 14.1545 14.6654C13.8713 14.6654 13.6417 14.4358 13.6417 14.1525C13.6417 11.0244 12.399 8.02428 10.187 5.81231C7.97507 3.60034 4.975 2.35767 1.8468 2.35767C1.56358 2.35767 1.33398 2.12807 1.33398 1.84485Z"
                 fill="white"
               />
               <path
                 fill-rule="evenodd"
                 clip-rule="evenodd"
                 d="M1.33398 13.383C1.33398 12.6749 1.90798 12.1009 2.61604 12.1009C3.32409 12.1009 3.89809 12.6749 3.89809 13.383C3.89809 14.091 3.32409 14.665 2.61604 14.665C1.90798 14.665 1.33398 14.091 1.33398 13.383Z"
                 fill="white"
               />
               <path
                 fill-rule="evenodd"
                 clip-rule="evenodd"
                 d="M14.6673 1.89063L12.6954 3.9987L11.334 2.82081L12.0174 2.1948L12.6195 2.71572L13.9138 1.33203L14.6673 1.89063Z"
                 fill="white"
               />
             </svg>
            </div> -->


                    <span style="margin-top: -2px;"> {{ follow === "pending" ? "Pending" : "Following" }}
                    </span>
                </div>
            </div>

            <div class="followers"
                v-else-if="!spinner && (item.type === 'artist' || item.type === 'company') && item.current_user !== 'admin'"
                @click="
                    followUnfollow(type === 'review' ? item.reviewable : item, $event)
                    ">
                <div class="text">
                    <div class="svg-icon">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                                d="M1.83398 7.22915C1.83398 6.94593 2.06358 6.71633 2.3468 6.71633C4.31893 6.71633 6.21028 7.49975 7.60478 8.89425C8.99928 10.2888 9.7827 12.1801 9.7827 14.1522C9.7827 14.4355 9.55311 14.665 9.26988 14.665C8.98666 14.665 8.75706 14.4355 8.75706 14.1522C8.75706 12.4521 8.0817 10.8216 6.87954 9.61949C5.67739 8.41734 4.04691 7.74197 2.3468 7.74197C2.06358 7.74197 1.83398 7.51237 1.83398 7.22915Z"
                                fill="#D1433A" />
                            <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                                d="M1.83398 1.84485C1.83398 1.56163 2.06358 1.33203 2.3468 1.33203C5.74702 1.33203 9.00797 2.68276 11.4123 5.08707C13.8166 7.49139 15.1673 10.7523 15.1673 14.1525C15.1673 14.4358 14.9377 14.6654 14.6545 14.6654C14.3713 14.6654 14.1417 14.4358 14.1417 14.1525C14.1417 11.0244 12.899 8.02428 10.687 5.81231C8.47507 3.60034 5.475 2.35767 2.3468 2.35767C2.06358 2.35767 1.83398 2.12807 1.83398 1.84485Z"
                                fill="#D1433A" />
                            <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                                d="M1.83398 13.383C1.83398 12.6749 2.40798 12.1009 3.11604 12.1009C3.82409 12.1009 4.39809 12.6749 4.39809 13.383C4.39809 14.0911 3.82409 14.665 3.11604 14.665C2.40798 14.665 1.83398 14.0911 1.83398 13.383Z"
                                fill="#D1433A" />
                        </svg>
                    </div>
                    <span style="margin-top: -1px;"> Follow {{ total_followers ? total_followers : '' }}</span>
                    <!-- {{
              item.followers_count ? item.followers_count + (follow ? 1 : 0) : 0
            }} -->
                </div>
            </div>
            <div class="followers join" :class="{ 'joined': (item && item.join_status === 'approved') }"
                v-if="(item.type === 'clubroom' || item.type === 'partyroom') && !spinner"
                @click="joinPage(item.join_status)">
                <div class="text">
                    <div class="svg-icon" v-if="section !== 'my_pages'">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4349_501)">
                                <path
                                    d="M10.6667 14V12.6667C10.6667 11.9594 10.3858 11.2811 9.8857 10.781C9.3856 10.281 8.70733 10 8.00008 10H3.33341C2.62617 10 1.94789 10.281 1.4478 10.781C0.9477 11.2811 0.666748 11.9594 0.666748 12.6667V14"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M13.3333 5.33331V9.33331" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.3333 7.33331H11.3333" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_4349_501">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    {{ (section === 'my_pages') ? 'More' : (item && item.join_status === 'approved') ? 'Joined' : 'Join'
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { requestToJoinClubPage } from "../../../apis/APIs";
import { mapGetters } from "vuex";
import createFormData from "../../../mixins/common";

export default {
    name: "OverviewArtistCard",
    props: {
        item: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: "song",
        },
        section: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            follow: "",
            spinner: false,
            total_followers: null,
        };
    },
    computed: {
        ...mapGetters({
            user: "user_map_to_props",
        }),
        category() {
            if (this.type === "review") {
                const { page_category } = this.item.reviewable;
                return page_category && page_category.description
                    ? page_category.description
                    : "";
            } else {
                const { page_category } = this.item;
                return page_category && page_category.description
                    ? page_category.description
                    : "";
            }
        },
        subCategory() {
            if (this.type === "review") {
                const { page_sub_category } = this.item.reviewable;
                return page_sub_category && page_sub_category.description
                    ? page_sub_category.description
                    : "";
            } else {
                const { page_sub_category } = this.item;
                return page_sub_category && page_sub_category.description
                    ? page_sub_category.description
                    : "";
            }
        },
        profile() {
            if (this.type === "review") {
                const { avatar_image } = this.item.reviewable;
                return avatar_image && avatar_image[0] && avatar_image[0].original_url
                    ? avatar_image[0].original_url
                    : require(`@/assets/img/photo-1.png`);
            } else {
                const { avatar_image } = this.item;
                return avatar_image && avatar_image[0] && avatar_image[0].original_url
                    ? avatar_image[0].original_url
                    : require(`@/assets/img/photo-1.png`);
            }
        },
        followerCount() {
            if (this.type === "review") {
                const { followers_count } = this.item.reviewable;
                return followers_count ? followers_count + (this.follow ? 1 : 0) : 0;
            } else {
                const { followers_count } = this.item;
                return followers_count ? followers_count + (this.follow ? 1 : 0) : 0;
            }
        },
    },
    mounted() {
        if (this.item) {
            if (this.type === "review") {
                this.follow = this.item.reviewable.follow_action;
            } else {
                this.follow = this.item.follow_action;
                this.total_followers = this.item.followers_count
            }
        }
    },
    methods: {
        async followUnfollow(object, event) {
            if (event) event.stopPropagation();
            this.spinner = true;
            const res = await this.followOrUnfollowPage(object, this.follow);
            if (res.success === 1) {
                this.follow = this.follow === "approved" ? null : "approved";
                this.follow ? this.total_followers += 1 : this.total_followers -= 1;
            }
            this.spinner = false;
        },
        async joinPage(status) {
            if (this.section !== 'my_pages') {
                try {
                    await this.$store.dispatch('loading', true)
                    const payload = {
                        type: 'Page',
                        type_id: this.item.id,
                        action: (status === 'approved') ? 0 : 1
                    }
                    const formData = createFormData(payload)
                    const { data } = await requestToJoinClubPage(formData)
                    const { message, success } = data || {}
                    if (success === 1) {
                        this.notificationToast(true, 'Club Page', message, 5000, 'success');
                    } else {
                        this.notificationToast(true, "Club Page", message, 5000, 'error');
                    }
                    await this.$store.dispatch('loading', false)
                } catch (e) {
                    await this.$store.dispatch('loading', false)
                    this.notificationToast(true, "Club Page", message, 5000, 'error');
                    console.error(e)
                }
            } else {
                this.redirectToPage()
            }
        },

        redirectToPage() {
            let redirect_id = "";
            if (this.type === "review") {
                redirect_id = this.item.reviewable.id;
            } else {
                redirect_id = this.item.id;
            }
            if ((this.item.age_restrictions === 'over_18_only' && this.checkIfUserIsAbove18()) || this.item.age_restrictions === 'everyone') {
                if (this.item.type === "artist" || this.item.type === 'company') {
                    this.$router.push(`/page-manager/page/${this.item.type}/${redirect_id}`);
                    this.$store.commit("SET_PAGE_ACTIVE_TAB", "music");
                } else if (this.item.type === "clubroom") {
                    this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts");
                    this.$router.push(`/page-manager/page/club/${redirect_id}`);
                } else if (this.item.type === "partyroom") {
                    this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts")
                    this.$router.push(`/page-manager/page/party/${this.item.id}`)
                }
            } else {
                this.notificationToast(true, 'Age Restriction', 'You are under 18 can not visit this page!', 5000, 'warning')
            }
        },
    },
};
</script>

<style scoped lang="scss">
.page-artist-card {
    width: 170px;
    height: 290px;
    border-radius: 2px;
    border: 1px Solid rgba(255, 255, 255, 1);

}

.spinner-color {
    color: #D1433A;
}

.join {
    &:hover {
        svg {
            path {
                stroke: #ffffff;
            }
        }
    }
}

.joined {
    background: #D1433A;

    .text {
        color: #ffffff !important;

        svg {
            path {
                stroke: #ffffff;
            }
        }
    }
}

.card-hover-effect:hover {
    background-color: #f7f8fa !important;
}

.card-description.card-hover-effect {
    background-color: unset;
}

.image-hover-effect:hover {
    transition: all 0.3s;
    opacity: 0.8;
}
</style>