<template>
  <div class="container_xxl">
    <div class="feed_wrapper">
      <div class="center_centerbar_wraper_home">
        <div class="row" style="margin-top: 24px;">
          <div class="col">
            <div class="page-filter d-flex d-flex flex-row align-items-center p-r-20">
              <div class="back-link">
                <a class="d-flex flex-row align-items-center" @click="goBack">
                  <div class="svg-icon">
                    <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152" stroke="#071526" stroke-width="1.3"
                        stroke-linecap="round" stroke-linejoin="round" class="c-line-1"></path>
                    </svg>
                  </div>
                  <div v-if="this.$route.params.name" style="margin-top: 2px; cursor: pointer;">
                    {{
                        (this.$route.params.name === 'timeline') ? this.$route.params.name.toUpperCase() :
                          this.$route.params.name.toUpperCase()
                    }}
                  </div>
                  <div v-else style="margin-top: 2px; cursor: pointer;">
                    Create
                  </div>
                </a>
              </div>
              <div class="filter-title">{{ mainTitle }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="left-bar-head">
              <div>
                <span class="left-bar-heading">Release Details</span>
                <div style="height: 1px; background: #E6EAF0; margin-top: 16px; margin-bottom: 25px;"></div>
              </div>
              <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a :class="['nav-item nav-link', ((active_tab === 'format') ? 'active d-flex align-items-center' : '')]" id="v-pills-format-tab"
                   role="tab" aria-controls="v-pills-home" :aria-selected="(active_tab === 'format')">
                  <span class="number-color">1.</span> Format
                  <svg class="ml-auto mr-3" v-if="active_tab === 'format'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
                <a :class="['nav-item nav-link', ((active_tab === 'info') ? 'active d-flex align-items-center' : '')]" id="v-pills-info-tab"
                   role="tab" aria-controls="v-pills-profile" :aria-selected="(active_tab === 'info')">
                  <span class="number-color">2.</span> Basic Info
                  <svg class="ml-auto mr-3" v-if="active_tab === 'info'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
                <a :class="['nav-item nav-link', ((active_tab === 'metadata') ? 'active d-flex align-items-center' : '')]" id="v-pills-metadata-tab"
                   role="tab" aria-controls="v-pills-messages" :aria-selected="(active_tab === 'metadata')">
                  <span class="number-color">3.</span> MetaData
                  <svg class="ml-auto mr-3" v-if="active_tab === 'metadata'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
                <a :class="['nav-item nav-link', ((active_tab === 'collaborators') ? 'active d-flex align-items-center' : '')]"
                   id="v-pills-collaborators-tab" role="tab" aria-controls="v-pills-messages"
                   :aria-selected="(active_tab === 'collaborators')">
                  <span class="number-color">4.</span> Collaborators
                  <svg class="ml-auto mr-3" v-if="active_tab === 'collaborators'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
                <a :class="['nav-item nav-link', ((active_tab === 'scheduling') ? 'active d-flex align-items-center' : '')]"
                   id="v-pills-scheduling-tab" role="tab" aria-controls="v-pills-settings"
                   :aria-selected="(active_tab === 'scheduling')">
                  <span class="number-color">5.</span> Scheduling & Pricing
                  <svg class="ml-auto mr-3" v-if="active_tab === 'scheduling'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
                <a :class="['nav-item nav-link', ((active_tab === 'territory') ? 'active d-flex align-items-center' : '')]"
                   id="v-pills-territory-tab" role="tab" aria-controls="v-pills-settings"
                   :aria-selected="(active_tab === 'territory')">
                  <span class="number-color">6.</span> Territory Restrictions
                  <svg class="ml-auto mr-3" v-if="active_tab === 'territory'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
                <a :class="['nav-item nav-link', ((active_tab === 'permissions') ? 'active d-flex align-items-center' : '')]"
                   id="v-pills-permissions-tab" role="tab" aria-controls="v-pills-settings"
                   :aria-selected="(active_tab === 'permissions')">
                  <span class="number-color">7.</span> Permissions
                  <svg class="ml-auto mr-3" v-if="active_tab === 'permissions'" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16812 9C1.21449 9 1.26087 8.97472 1.28406 8.94942L4.94783 5.12964C5.01739 5.05375 5.01739 4.95257 4.94783 4.87668L1.28406 1.05692C1.21449 0.981028 1.12174 0.981028 1.05217 1.05692C0.982609 1.13281 0.982609 1.23399 1.05217 1.30988L4.57681 5.00316L1.05217 8.69645C0.982609 8.77234 0.982609 8.87353 1.05217 8.94942C1.07536 9.00001 1.12174 9 1.16812 9Z" fill="white" stroke="#D1433A" stroke-width="0.5"/>
                  </svg>
                </a>
              </div>
            </div>
            <div class="storage-box">
              <span class="left-bar-heading">Storage</span>
              <div style="height: 1px; background: #E6EAF0; margin-top: 16px; margin-bottom: 25px;"></div>
              <div class="space-box">
                <div class="d-flex align-items-center" style="margin-bottom: 23px;">
                  <p> Tracks upload</p>
                  <p class="ml-auto">22</p>
                </div>
                <div class="d-flex align-items-center" style="margin-bottom: 24px;">
                  <span>Total Storage</span>
                  <span class="ml-auto">400MB</span>
                </div>
                <div class="d-flex align-items-center" style="margin-bottom: 10px;">
                  <span>Used Space</span>
                  <span class="ml-auto">Free Space</span>
                </div>
                <div class="progress-wrapper">
                  <div class="progress-outer">
                    <div class="progress-inner" :style="`width: ${25}%`"></div>
                  </div>
                </div>
                <div class="mbs d-flex align-items-center">
                  <span>200MB</span>
                  <span class="ml-auto">400MB</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-8" style="max-width: 72%; flex: 0 0 100%; margin-left: 10px;">
            <div class="tab-content" id="v-pills-tabContent">
              <div :class="['tab-pane fade', (active_tab === 'format') ? 'active show' : '']" id="format"
                role="tabpanel" aria-labelledby="v-pills-format-tab">
                <div class="row">
                  <div class="col-12 pr-2">
                    <UploadSongAndAlbum @nextStep="nextStep" @previousStep="previousStep" @changeType="changeType"
                      :type="type" :actionType="actionType" ref="uploadSongAndAlbum"/>
                  </div>
                </div>
              </div>
              <div :class="['tab-pane fade', (active_tab === 'info') ? 'active show' : '']" id="info" role="tabpanel"
                aria-labelledby="v-pills-info-tab">
                <div class="row">
                  <div class="col-12">
                    <AddBasicInformationSongAndAlbum ref="uploadSong" @nextStep="nextStep" @previousStep="previousStep" :type="type"
                      :actionType="actionType" />
                  </div>
                </div>
              </div>
              <div :class="['tab-pane fade', (active_tab === 'metadata') ? 'active show' : '']" id="metadata"
                role="tabpanel" aria-labelledby="v-pills-metadata-tab">
                <div class="row">
                  <div class="col-12">
                    <MetaDataSongAndAlbum @nextStep="nextStep" @previousStep="previousStep" :type="type"
                      :actionType="actionType" />
                  </div>
                </div>
              </div>
              <div :class="['tab-pane fade', (active_tab === 'collaborators') ? 'active show' : '']" id="collaborators"
                role="tabpanel" aria-labelledby="v-pills-collaborators">
                <div class="row">
                  <div class="col-12">
                    <Collaborators @nextStep="nextStep" @previousStep="previousStep" :type="type"
                      :actionType="actionType" />
                  </div>
                </div>
              </div>
              <div :class="['tab-pane fade', (active_tab === 'scheduling') ? 'active show' : '']" id="scheduling"
                role="tabpanel" aria-labelledby="v-pills-scheduling-tab">
                <SchedulingSongAndAlbum @nextStep="nextStep" @previousStep="previousStep" :type="type"
                  :actionType="actionType" />
              </div>
              <div :class="['tab-pane fade', (active_tab === 'territory') ? 'active show' : '']" id="territory"
                role="tabpanel" aria-labelledby="v-pills-territory-tab">
                <TerritoryRestrictionsSongAndAlbum @nextStep="nextStep" @previousStep="previousStep" :type="type"
                  :actionType="actionType" />
              </div>
              <div :class="['tab-pane fade', (active_tab === 'permissions') ? 'active show' : '']" id="permissions"
                role="tabpanel" aria-labelledby="v-pills-permissions-tab">
                <PermissionsSongAndAlbum :type="type" :actionType="actionType" />
              </div>
            </div>

          </div>
        </div>
        <!-- <content /> -->
      </div>
    </div>
  </div>
</template>

<script>
import UploadSongAndAlbum from "./Components/UploadSongAndAlbum"
import AddBasicInformationSongAndAlbum from "./Components/AddBasicInformationSongAndAlbum"
import Collaborators from "./Components/Collaborators"
import MetaDataSongAndAlbum from "./Components/MetaDataSongAndAlbum";
import SchedulingSongAndAlbum from "./Components/SchedulingAndPricingSongAndAlbum";
import TerritoryRestrictionsSongAndAlbum from "./Components/TerritoryRestrictionsSongAndAlbum";
import PermissionsSongAndAlbum from "./Components/PermissionsSongAndAlbum";

export default {
  name: "AddProductSongAlbum",
  data() {
    return {
      active_tab: 'format',
      tabs: ['format', 'info', 'metadata', 'collaborators', 'scheduling', 'territory', 'permissions'],
      active_tab_id: 0,
      type: 'song',
    }
  },
  components: {
    UploadSongAndAlbum,
    AddBasicInformationSongAndAlbum,
    MetaDataSongAndAlbum,
    SchedulingSongAndAlbum,
    TerritoryRestrictionsSongAndAlbum,
    PermissionsSongAndAlbum,
    Collaborators
  },
  computed: {
    songUploadedID() {
      return this.$store.getters.getTempUploadSongID
    },
    pageId() {
      if (this.$route.params.id) {
        return this.$route.params.id
      }
      return (this.$route.query && this.$route.query.page_id) ? this.$route.query.page_id : null
    },
    actionType() {
      return (this.$route.meta && this.$route.meta.type) ? this.$route.meta.type : null
    },
    // eslint-disable-next-line vue/return-in-computed-property
    mainTitle() {
      if (this.actionType !== '' || this.actionType !== null) {
        if (this.actionType === 'createSongAlbum') {
          return 'ADD NEW SONG & ALBUM';
        } else if (this.actionType === 'createPodcast') {
          return 'CREATE NEW PODCAST';
        }
      }
    },
  },
  watch: {
    active_tab(val) {
      this.active_tab_id = this.tabs.findIndex(e => e === val)
    },
    songUploadedID(val) {
      if (!(val)) {
        this.active_tab_id = 0
      }
    },
    'mainTitle': {
      immediate: true,
      handler(value) {
        this.changeType(this.actionType === 'createSongAlbum' ? 'song' : 'podcast');
      }
    },
  },
  methods: {
    nextStep(obj = {}) {
      if ((Number(this.active_tab_id) + 1) === this.tabs.length) {
        return
      }
      if (obj.check === 'upload') {
        this.$refs.uploadSong.uploadSongOrAlbum(obj.data)
      }
      this.active_tab_id++
      this.active_tab = this.tabs[this.active_tab_id]
    },
    previousStep() {
      if ((this.active_tab_id) === 0) {
        return
      }
      this.active_tab_id--
      this.active_tab = this.tabs[this.active_tab_id]
      // this.$refs.uploadSongAndAlbum.resetTempUploadObject();
    },
    uploadFile(e) {
      this.file = e.target.files;
    },
    dragFile(e) {
      this.file = e.dataTransfer.files[0].name;
    },
    setActiveTab(tab_name) {
      this.active_tab = tab_name
    },
    changeType(value) {
      this.type = value
    },
    goBack() {
      this.$router.go(-1)
    },
  }
}

</script>

<style scoped>
.left-bar-head {
  padding: 20px 16px 20px 16px;
  height: 430px;
  background: #FFFFFF;
  border: 1px solid #E6EAF0;
  box-shadow: 0px 15px 40px rgba(71, 80, 91, 0.05);
  border-radius: 4px;
}

.left-bar-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #071526;
  opacity: 0.9;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.nav-link {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #47505B !important;
  padding: 8px 0px 8px 10px !important;
  margin-bottom: 16px !important;

}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link .number-color {
  color: #D1433A !important;
  background-color: #D1433A1A !important;
}

.number-color {
  color: #C4CCD6;
  margin-right: 15px;
}

.nav-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #47505B;
}

/**************************/
</style>

<style lang="scss" scoped>
.nav-item {
  &.active {
    .number-color {
      color: #D1433A;
    }
  }
}
.storage-box {
  margin-top: 1rem;
  background: #FFFFFF;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  width: 270px;
  height: 240px;
  padding: 16px 20px 27px 20px;

  .space-box {
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #071526;
      margin-bottom: 0;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #071526;
    }

    .progress-wrapper {
      flex-direction: row;
      align-items: center;
      font-style: normal;
      text-align: right;
      font-weight: 600;
      line-height: 20px;
      color: #071526;
      font-size: 13px;
      display: flex;

      .progress-outer {
        position: relative;
        top: -1px;
        background: #D9E2FF;
        width: 100%;
        overflow: hidden;
        height: 7px;

        .progress-inner {
          height: 100%;
          background-color: #D1433A;
        }
      }
    }

    .mbs {
      margin-top: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: #071526;
    }
  }
}
</style>
