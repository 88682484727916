<template>
  <div :class="{'show': show}" class="toast">
    <div class="icon">
      <svg v-if="toast && toast.toast_type === 'success'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" fill="white"/>
        <path d="M18.2857 5H4V25H24V18.3333" stroke="#31EAA0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.71387 14.5233L14.4758 19.2853L25.9043 6.9043" stroke="#31EAA0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <img v-else-if="toast && toast.toast_type === 'error'" :src="require(`@/assets/icons/cross.svg`)" alt="" style="width: 30px; height: 30px;"/>
      <img v-else-if="toast && toast.toast_type === 'warning'" :src="require(`@/assets/icons/warning.svg`)" alt="" style="width: 30px; height: 30px;"/>
    </div>
    <div class="information">
      <p class="title">{{ (toast && toast.toast_title) ? toast.toast_title : 'TITLE' }}</p>
      <span class="text">{{ (toast && toast.toast_text) ? toast.toast_text : 'Text' }}</span>
    </div>
    <div class="close-icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="closeNotification()">
        <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "VueNotificationToast",
  data() {
    return {
      show: false
    }
  },
  watch: {
    toast: {
      handler: function() {
        if (this.toast && this.toast.toast_show) {
          this.show = this.toast.toast_show
          this.hideNotification(this.toast.toast_delay)
        }
      },
      deep: true,
    }
  },
  computed: {
    toast() {
      return this.$store.getters.getToastOptions
    }
  },
  methods: {
    closeNotification() {
      this.$store.commit('RESET_TOAST_OPTIONS', {})
      this.show = false
    },
    hideNotification(delay) {
      setTimeout(() => {
        this.show = false;
        setTimeout(() => {
          this.$store.commit('RESET_TOAST_OPTIONS');
        }, delay);
      }, delay);
    }
  }
};
</script>

<style scoped lang="scss">
.toast {
  display: flex!important;
  align-items: center;
  position: fixed;
  padding: 16px 10px 12px 20px;
  width: 320px;
  height: 70px;
  right: 0px;
  top: 80px;
  background: #FFFFFF;
  box-shadow: 0 8px 20px rgba(71, 80, 91, 0.15);
  border-radius: 4px;
  transition: all 0.50s ease-out;

  &.show {
    right: 20px;
  }

  .information {
    margin-left: 20px;
    width: 200px;
    margin-right: 10px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #D1433A;
      margin-bottom: 2px;
      text-transform: uppercase;
    }
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #47505B;
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    display: flex;
    cursor: pointer;

    &:hover {
      background: rgba(230, 234, 240, 0.5);
    }

    svg {
      margin: auto;
    }
  }
}
</style>
