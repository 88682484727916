<script>
export default {
  name: "AddEventButtonComponent",
  props: {
    createEventsModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      createEventModal: this.createEventsModal,
    };
  },
  methods: {
    openCreateEventModal(newValue) {
      this.$emit("openCreateEventModal", newValue);
    },
  },
};
</script>

<template>
  <div class="events-overview-add-event-btn-section">
    <div class="events-overview-add-event-btn"
      @click="openCreateEventModal(!createEventsModal)">
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z" fill="#D1433A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025Z" fill="#D1433A"/>
      </svg>
      <span>ADD NEW EVENT</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../assets/style/events/addeventbutton.scss";
</style>