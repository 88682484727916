<template>
  <div class="post-list">
    <PostDetail 
      v-for="(eachOp, index) in event_posts" 
      :key="'post-detail' + eachOp.id"
      :index="index"
      :item="eachOp"
      :pageCheck="'event'"
      :userPicture="eachOp.user && eachOp.user.artwork_url"
      :activeTab="'posts'"
    />
    
    <div class="col" v-if="shouldShowLoadMore">
      <div class="show_more_btn" style="margin-bottom: 80px">
        <div class="show-more musicbar_more">
          <a @click="loadMore" href="javascript:;">
            <span class="show_more_bg">
              <svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.24219 0.689941V7.01994"
                  stroke="#071526"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M1 3.85498H7.48702"
                  stroke="#071526"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              SHOW MORE
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostDetail from '@/components/Home/NewsFeed/PostItem/PostDetail.vue';
import { mapActions } from 'vuex';

export default {
  name: 'EventPosts',
  components: {
    PostDetail
  },
  props: {
    GET_EVENT_POSTS: {
      type: [Object, String],
      default: () => ({
        data: [],
        information: { total: 0 }
      })
    }
  },
  data() {
    return {
      offset: 0,
      limit: 8,
      loading: false,
      total: 0
    };
  },
  computed: {
    event_posts() {
      if (!this.GET_EVENT_POSTS || typeof this.GET_EVENT_POSTS === 'string') {
        return [];
      }
      return this.GET_EVENT_POSTS.data || [];
    },
    shouldShowLoadMore() {
      if (!this.GET_EVENT_POSTS || typeof this.GET_EVENT_POSTS === 'string') {
        return false;
      }
      const information = this.GET_EVENT_POSTS.information || {};
      const totalPosts = information.total || 0;
      return totalPosts > (this.event_posts.length || 0);
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler: 'fetchInitialPosts'
    }
  },
  methods: {
    ...mapActions(['getEventPosts']),
    async fetchInitialPosts() {
      this.offset = 0;
      await this.getEventPosts({
        id: this.$route.params.id,
        offset: this.offset,
        limit: this.limit
      });
    },
    async loadMore() {
      if (this.loading) return;
      
      try {
        this.loading = true;
        this.offset += this.limit;
        
        await this.getEventPosts({
          id: this.$route.params.id,
          offset: this.offset,
          limit: this.limit,
          append: true
        });
      } catch (error) {
        console.error('Error loading more posts:', error);
      } finally {
        this.loading = false;
      }
    },
    async refreshPosts() {
      this.offset = 0;
      await this.fetchInitialPosts();
    }
  }
};
</script>

<style lang="scss" scoped>
.post-list {
  margin-top: 20px;
}
</style>