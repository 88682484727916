<template>
  <div>
    <div class="modal create-group fade show" id="create-group" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content create-group-popup">
        <div class="close-button-pop" ref="closeModal" data-dismiss="modal" aria-label="Close" @click="clearData">
          <div class="svg-icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="title-headings d-flex justify-content-center align-items-center">
          <div class="svg-icon">
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.81898 15.0925V13.5153C0.819501 12.8164 1.05213 12.1374 1.48034 11.585C1.90854 11.0326 2.50808 10.6381 3.18481 10.4634"
                stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M6.33936 1C5.66082 1.17373 5.05941 1.56835 4.62993 2.12165C4.20045 2.67495 3.96734 3.35545 3.96734 4.05587C3.96734 4.75629 4.20045 5.43679 4.62993 5.99009C5.05941 6.54339 5.66082 6.93801 6.33936 7.11174"
                stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M11.9887 7.36102C10.2321 7.36102 8.80817 5.93706 8.80817 4.18051C8.80817 2.42396 10.2321 1 11.9887 1C13.7452 1 15.1692 2.42396 15.1692 4.18051C15.1692 5.93706 13.7452 7.36102 11.9887 7.36102Z"
                fill="#8B949F" stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.1695 9.89136C16.1854 9.89136 17.1597 10.2949 17.8781 11.0133C18.5964 11.7316 19 12.706 19 13.7219V15.3121C19 15.6711 18.709 15.9621 18.35 15.9621V15.9641H5.65168V15.9617C5.64381 15.962 5.6359 15.9621 5.62796 15.9621C5.26898 15.9621 4.97796 15.6711 4.97796 15.3121V13.7219C4.97796 12.706 5.38153 11.7316 6.09989 11.0133C6.81825 10.2949 7.79256 9.89136 8.80847 9.89136H15.1695Z"
                    fill="#8B949F"/>
            </svg>
          </div>
          <div class="text short-border">{{(getAddMemberCheck) ? 'Add Members' : 'Invite Friends'}}</div>
        </div>
<!--        <div class="group-name group-name-chat d-flex flex-row align-items-center">-->
<!--          <div class="add-file">-->
<!--            <div class="svg-icon">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M12 5V19" stroke="#D1433A" stroke-width="2" stroke-linecap="round"-->
<!--                      stroke-linejoin="round"/>-->
<!--                <path d="M5 12H19" stroke="#D1433A" stroke-width="2" stroke-linecap="round"-->
<!--                      stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--          </div>-->
<!--          <input class="for-group-name" type="text" v-model="title" placeholder="Name Your Group"/>-->
<!--        </div>-->
        <div v-if="getAddMemberCheck" class="user-launch">
          <UsersLaunch ref="user_launch" @closeModal="cancelAndCloseModal"/>
        </div>
        <div v-else  class="content-block chat-content-block d-flex flex-row">
          <div class="left-block">
            <input class="search-people" type="text" placeholder="Search for people to add" v-model="search" @input="searchFriend"/>
            <div class="item-groups">
              <div class="items js-create-group" data-simplebar="init" data-simplebar-auto-hide="true">
                <div class="simplebar-wrapper" style="margin: 0px;">
                  <div class="simplebar-height-auto-observer-wrapper">
                    <div class="simplebar-height-auto-observer"></div>
                  </div>
                  <div class="simplebar-mask">
                    <div class="simplebar-offset">
                      <div class="simplebar-content" style="padding: 0px; height: auto; ">
                        <div v-if="friendListArray">
                          <div v-for="(friend, index) in friendListArray" :key="index">
                            <CreateModalGroupFriendBar :friend="friend" :iconCheck="checkIfFriendExistInSelectedFriend(friend)" @selectFriend="selectFriendToCreateGroup"/>
                          </div>
                        </div>
                        <div v-else class="text-center">
                          <b-spinner small variant="primary" label="Text Centered"></b-spinner>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="simplebar-placeholder" style="width: 0px; height: 0px;"></div>
                </div>
                <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                  <div class="simplebar-scrollbar"
                       style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
                </div>
                <div class="simplebar-track simplebar-vertical" style="visibility: hidden;">
                  <div class="simplebar-scrollbar"
                       style="transform: translate3d(0px, 0px, 0px); visibility: hidden; height: 227px;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-block">
            <div class="selected mt-3 mb-3">SELECTED<span>{{ (selectedFriends && selectedFriends.length > 0) ? selectedFriends.length : '0' }}</span>
            </div>
            <div v-if="selectedFriends">
              <div v-for="(friend, index) in selectedFriends" :key="index">
                <div class="item d-flex flex-row align-items-center selected-friend">
                  <div class="img-wrapper">
                    <div class="img-cover">
                      <img :src="friend.artwork_url" alt=""/>
                    </div>
                  </div>
                  <div class="item-title ml-2">{{friend.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-block d-flex flex-row justify-content-end">
          <div class="cancel-chat cursor-pointer" @click="cancelAndCloseModal">Cancel</div>
          <div class="btn create cursor-pointer" @click="inviteMembers($event)">
            <b-spinner v-if="inviteLoader" small></b-spinner>
            <span v-else>{{(getAddMemberCheck) ? 'ADD MEMBER' : 'Invite'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import CreateModalGroupFriendBar from "./CreateModalGroupFriendBar";
import createFormData from "../../mixins/common";
import { sendMemberInvitationToFriends } from "../../apis/APIs";
import UsersLaunch from "../FullViewChat/Components/launchRoom/UsersLaunch";

export default {
  name: "CreateGroupModal",
  data() {
    return {
      friendListArray: [],
      selectedFriends: [],
      iconCheck: false,
      title:"",
      search:"",
      loading: false,
      inviteLoader: false
    }
  },
  watch: {
    'getFriendsListWithoutHavingMembersInIt': {
      immediate: true,
      handler(newVal) {
        this.setData()
      }
    },
    'currentPage.id': {
      handler(newVal) {
        this.getData
        this.setData()
      }
    }
  },
  components: {
    CreateModalGroupFriendBar,
    UsersLaunch
  },
  computed: {
    getMyFriendsList() {
      return this.$store.getters.getMyFriends
    },
    getAddMemberCheck() {
      return this.$store.getters.getAddMemberCheck
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    getCurrentPageMembers() {
      return this.$store.getters.getArtistFollowerList
    },
    getFriendsListWithoutHavingMembersInIt() {
      return this.$store.getters.getFriendsListWithoutMembersInIt
    },
    route() {
      return this.$route
    }
  },
  methods: {
    async getData () {
      try {
        this.loading = true
        await this.$store.dispatch('getFriendListWithoutHavingMembers', (this.currentPage && this.currentPage.id) ? this.currentPage.id : this.$route.params.id)
        this.loading = false
      } catch (e) {
        this.loading = false
        console.error(e);
      }
      // this.selectedFriends = []
      // if ( this.currentPage && Object.keys(this.currentPage).length !== 0) {
      //   const membersIds = this.getCurrentPageMembers.map((e) => {
      //     return e.id
      //   })
      //   this.friendListArray = this.getMyFriendsList.filter((e) => {
      //     return !(membersIds.some( x => x === e.id))
      //   })
      // } else {
      //   this.friendListArray = this.getMyFriendsList
      // }
    },
    selectFriendToCreateGroup (friend) {
      if (!this.checkIfFriendExistInSelectedFriend(friend)) {
        this.selectedFriends.push(friend)
      } else {
        const index = this.selectedFriends.findIndex(e => e.id === friend.id)
        this.selectedFriends.splice(index, 1)
      }
    },
    checkIfFriendExistInSelectedFriend (friend) {
      return  this.selectedFriends.some( e => e.id == friend.id)
    },
    cancelAndCloseModal () {
      this.selectedFriends = []
      this.friendListArray = []
      this.title = ""
      this.$refs.closeModal.click()
      this.setAddMemberCheck()
    },
    async createGroup(event) {
      event.stopPropagation()
      if (this.title.length > 0 && this.selectedFriends.length > 0) {
        await this.createChatGroup(this.title, this.selectedFriends);
        this.cancelAndCloseModal()
        await this.setLastMessagesGroups()
      }
    },
    async inviteMembers(event) {
      event.stopPropagation()
      if(this.getAddMemberCheck) {
        await this.$refs.user_launch.addMembers()
      } else {
        if (this.selectedFriends.length > 0) {
          try {
            this.inviteLoader = true
            const payload = {
              userIds: this.selectedFriends.map((e) => {
                return e.id
              }),
              type:'Page',
              type_id:this.$route.params.id
            }
            const formData = createFormData(payload)
            const {data} = await sendMemberInvitationToFriends(formData)
            const {success, message} = data || {}
            if(success === 1) {
              await this.notificationToast(true, `Invite`, message, 5000, 'success')

            } else {
              await this.notificationToast(true, `Invite`, message, 5000, 'error')
            }
            this.inviteLoader = false
            this.cancelAndCloseModal()
          } catch (e) {
            this.inviteLoader = false
            await this.notificationToast(true, `Invite`, "Unable to send invite. Please try again later" , 5000, 'error')
            console.error(e)
          }
        }
      }
    },
    searchFriend(){
        let searchWord=this.search
        const friends=this.getFriendListWithoutHavingMembers
        let result = friends.filter(o => o.name.toLowerCase().includes(searchWord.toLowerCase()))
        this.friendListArray=result
    },
    clearData() {
      this.search = ''
      this.title = ''
      this.friendListArray = this.getFriendListWithoutHavingMembers
      this.setAddMemberCheck()
    },
    setAddMemberCheck() {
      this.$store.commit('SET_ADD_MEMBER_CHECK', false)
    },
    setData() {
      this.friendListArray = this.getFriendsListWithoutHavingMembersInIt
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  .modal-dialog {
    top: 123px;
    right: 5px;
    width: 572px;

    .modal-content {
      box-shadow: unset;

      .title-headings {

        .text {
          letter-spacing: 1px;
        }
      }
      .user-launch {
        padding: 0 20px 0 20px;
      }
      .content-block {
        .left-block {
          .item-groups {
            .items {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-offset {
                    height: unset!important;
                    .simplebar-content {
                      .item {
                        .img-wrapper {
                          .img-cover {
                            img {
                              width: inherit;
                            }
                          }
                        }

                        .check-icon {
                          display: unset!important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .right-block {
          .selected-friend {
            height: 45px;
          }
          .img-wrapper {
            .img-cover {
              img {
                width: 30px;
                height: 30px;
                border-radius: 50px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
    margin: 1.75rem auto;
  }
}</style>

<style scoped>
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4ccd6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4ccd6;
}
</style>

