<template>
  <div :class="['main-div',(receiver) ?'receiver-document' :'',(fullChat) ?'full-chat-document' :'']">
    <div class="row">
      <div class="d-flex align-items-center justify-content-evenly">
        <div class="svg-icon" >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="2" fill="#D1433A"/>
            <path d="M15.667 8.33325H11.0003C10.6467 8.33325 10.3076 8.47373 10.0575 8.72378C9.80747 8.97382 9.66699 9.31296 9.66699 9.66659V20.3333C9.66699 20.6869 9.80747 21.026 10.0575 21.2761C10.3076 21.5261 10.6467 21.6666 11.0003 21.6666H19.0003C19.3539 21.6666 19.6931 21.5261 19.9431 21.2761C20.1932 21.026 20.3337 20.6869 20.3337 20.3333V12.9999L15.667 8.33325Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.667 8.33325V12.9999H20.3337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="seek-wrapper">
          <p class="mb-0">{{shortTheText(file_name)}}</p>
          <span>{{formatSizeUnits(size)}}</span>
        </div>

          <div class="svg-icon ml-auto cursor-pointer" style="align-self: end">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="downloadItem(url,file_name,type)">
              <rect x="0.5" y="0.5" width="29" height="29" rx="1.5" stroke="#D1433A"/>
              <path d="M21 17V19.6667C21 20.0203 20.8595 20.3594 20.6095 20.6095C20.3594 20.8595 20.0203 21 19.6667 21H10.3333C9.97971 21 9.64057 20.8595 9.39052 20.6095C9.14048 20.3594 9 20.0203 9 19.6667V17" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.667 13.6667L15.0003 17.0001L18.3337 13.6667" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15 17V9" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "ChatDocument",
  props:{
    file_name:{
      type:String,
      default:'none'
    },
    url:{
      type:String,
      default:'none'
    },
    size:{
      type:Number,
      default:null
    },
    receiver:{
      type:Boolean,
      default: false
    },
    fullChat:{
      type:Boolean,
      default: false
    },
    type:{
      type:String,
      default: ""
    },
    textCheck: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    shortTheText: function(value) {
      return (value.length > 10 && this.textCheck) ? value.slice(0, 10) + '...' : value
    }
  }
};
</script>

<style scoped lang="scss">
.main-div {
  padding: 0.8rem;
  background: #D1433A1A;
  border-radius: 10px;
  margin-bottom: 0.7rem;
  width: 250px;

  .seek-wrapper{
    margin-left: 0.5rem;
   p{

     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     line-height: 20px;
     color: #47505B;
   }
    span{
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      color: #C4CCD6;

    }
  }

}
.svg-icon{
  svg{
    border-radius: 2px;
  }
}
.receiver-document{
  width: 210px !important;
  margin-left: 0.5rem;
}
.full-chat-document{
  width: 540px !important;
  height: 70px !important;
  border-radius: 10px !important;
}

</style>
