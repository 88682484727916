<template>
  <!-- Upload Photos -->
  <div>
    <div class="post-filed-options-c" :style="is_edit_post ? 'border-bottom:' + 'none' : ''" v-if="!selectedTab">
      <div class="upload-photos-section mt-3">
        <div class="row">
          <div class="col">
            <div class="upload-photos-title">
              <div class="back-photo-icon" @click="goBackk()">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 9L1 5L5 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <div class="bac-text-tite">Upload Photos</div>
            </div>
            <div class="custom-notification d-flex align-items-center Create-album-d">
              <input type="checkbox" v-model="create_album" class="checkbox" id="customRadio1"
                name="example1">
              <label for="customRadio1">Create Album </label>
            </div>
          </div>
        </div>
        <div class="row" v-if="create_album">
          <div class="col">
            <div class="name-of-album">
              <input type="text" v-model="albumTitle" placeholder="Album Name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="upload-photos-section">
              <ul>
                <!-- <li>
              <div class="uploaded-image">
                  <img src="img/Rectangle1.png">
              </div>
              <span class="cross-icon">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
              </span>
              </li> -->
                <!-- <li>
              <div class="uploaded-image">
                  <img src="img/Rectangle2.png">
              </div>
              <span class="cross-icon">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
              </span>
              </li> -->

                <li v-for="(eachOp, index) in preview_list" :key="'image' + index">
                  <div class="uploaded-image">
                    <img :src="eachOp">
                  </div>
                   <span class="cross-icon btn" style="background: #454545" @click="removeImage(index)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#000000"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="white" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.5 4.5L13.5 13.5" stroke="white" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                </li>
                <li class="upload-photo-section-li">
                  <div class="upload-photo-icons">
                    <img :src="require('@/assets/img/add-p.png')" class="sampleImg">
                    <input type="file" multiple="multiple" accept="image/x-png,image/gif,image/jpeg,image/jpg"
                      name="fileToUpload" @change="uploadImages" id="fileToUpload">
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <span class="image-error">{{ imageError }}</span>
        <div class="row" v-if="!is_edit_post">
          <div class="col">
            <div class="Sponsors-section upload-photo-sponsers">
              <div class="action-block ">
                <div class="switch-button">
                  <label class="switch "> <input type="checkbox" checked="checked" class="default"> <span
                      class="slider round"></span> </label>
                </div>
                For All Users
              </div>
              <div class="select-audience-button" v-if="!selectedTab" @click="selectedTab = 'selectAudience'">
                <span v-if="!audience"> Choose Audience</span>
                <span v-if="audience"> {{audience.toUpperCase().replace(/_/g, ' ')}}</span>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
      <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
    </div>
  </div>
  <!-- Exit -->

</template>
<script>
import SelectAudience from './components/selectAudience';
import { mapGetters, mapMutations } from 'vuex';
import {
  CREATE_POST_FORM_IMAGES,
  CREATE_POST_FORM_IS_ALBUM,
  CREATE_POST_FORM_ALBUM_NAME,
  CREATE_POST_FORM_SELECTED_IMAGES
} from '../../../store/types'
import { readURL } from '../../utils/Utils';
import audience from "../../Events/components/Detail/Audience.vue";

export default {
  data() {
    return ({
      images: [],
      create_album: false,
      album_name: "",
      preview_list: [],
      imageError: "",
      selectedTab:"",
      audience: null,
    })
  },
  props:{
    is_edit_post: {
      type: Boolean
    },
    post: {
      type: Object
    },
    share_model:{
      type:Boolean,
      default: false
    }
  },
  watch: {
    create_album(value) {
      if (value) {
        this.$store.commit('SET_TYPE', 'album')
        this.$store.commit('SET_TYPE_DESCRIPTION', 'share a album')

      } else {
        this.$store.commit('SET_TYPE', 'timeline_photo')
        this.$store.commit('SET_TYPE_DESCRIPTION', 'share a photo')
        this.$store.commit('SET_ALBUM', null)
      }
    },
    selectedTab(value) {
      if (value) this.hideTopBarAndShowPostBtn();
    }
  },
  components: {
    SelectAudience,
  },
  computed: {
    ...mapGetters({
      form_data: "create_album_forms_map_to_props",
    }),
    shared_with() {
      const { shared_with } = this.form_data;

      return shared_with ? shared_with : "";
    },
    selectedImages: {
      set(newValue) {
        this.$store.commit('SET_PICTURES', newValue)
      },
      get() {
        return this.$store.getters.getPictures
      }
    },
    albumTitle: {
      get() {
        return this.$store.getters.getAlbumTitle
      },
      set(newValue) {
        this.$store.commit('SET_ALBUM', newValue)
      }
    }
  },
  mounted() {
    this.$store.commit('SET_TYPE', 'timeline_photo')
    this.$store.commit('SET_TYPE_DESCRIPTION', 'share a photo')
    if(this.is_edit_post){
      this.preview_list = this.post && this.post.artwork_url.map((item) => {return item.artwork_url ? item.artwork_url : item.original_url });
      if(this.post && this.post.album !== null){
        this.create_album = true;
        this.albumTitle = this.post && this.post.album.title ? this.post.album.title : "";
      }
    }
  },
  methods: {
    ...mapMutations([
      "updateCreatePostFormData"
    ]),
    init() {
      const { is_album, album_name, images } = this.form_data;

      this.create_album = is_album;
      this.album_name = album_name;
      this.images = images;
    },
    goBackk() {
      if(this.share_model){
        this.$emit('closeThisModel');
        return
      }
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if(createPostOptionsContainer !== null)
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
      this.$parent.goBack();
    },
    onChange(e) {
      const { name, value } = e.target || {};
      this.updateCreatePostFormData({
        [name]: value
      });
    },
    removeImage(index){
      this.imageError = "";
      this.preview_list.splice(index, 1);
      this.images.splice(index, 1);
      const deletedId = this.post.artwork_url[index].id;
      this.post.artwork_url.splice(index, 1);
      this.$emit('deleteMediaList', deletedId)
    },
    // uploadImages(event) {
    //   var input = event.target;
    //   var count = input.files.length;
    //   var index = 0;
    //   if (input.files && input.files[index].size < 1000000) {
    //     while (count--) {
    //       var reader = new FileReader();
    //       reader.onload = (e) => {
    //         this.preview_list.push(e.target.result);
    //       }
    //       this.images.push(input.files[index]);
    //       reader.readAsDataURL(input.files[index]);
    //       index++;
    //     }
    //     this.selectedImages = this.images
    //     this.imageError = "";
    //   } else if (input.files[0].size > 100000) {
    //     this.imageError = "Image size should be less than 1 MB";
    //   }
    // },
    uploadImages(event) {
      const input = event.target;
      const newFiles = Array.from(input.files);
      const maxImages = 3;

      if (this.images.length + newFiles.length > maxImages) {
        this.imageError = `You can only add up to ${maxImages} photos.`;
        return;
      }

      newFiles.forEach((file) => {
        if (file.size < 2000000) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.images.push(file);
          reader.readAsDataURL(file);
          this.imageError = "";
        } else {
          this.imageError = "Image size should be less than 2 MB";
        }
      });

      this.selectedImages = this.images;
    },
    hideTopBarAndShowPostBtn() {
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      createPostOptionsContainer.style.display = "none";
    },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      if(audience) this.audience = audience.toLowerCase().replace(/ /g, '_');
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
    }
  }
}
</script>
<style lang="scss" scoped>
.image-error {
  color: red;
  size: 13px;
  font-weight: 400;
}
.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 40px;
  background: #F3F3F3;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
.sampleImg{
  width: unset;
  height: unset;
  object-fit: none;
}

.custom-notification{
  padding-left: 0px !important;
}

.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  margin-right: 13px;
  vertical-align: middle;
  margin-top: -10px;
}

.checkbox:checked {
  background-color: #D1433A;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:not(:checked) {
  background-color: #FFF8F8;
  background-image: none;
}
</style>
