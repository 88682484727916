var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bs-container events-overview-content d-flex justify-content-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-grid gap-4"},[_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.displayPage},scopedSlots:_vm._u([{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('SkeletonLoader',{key:item})})},proxy:true}])},[_c('div',{staticClass:"d-grid gap-4"},[_c('Search',{on:{"changeFilterKey":_vm.changeFilterKey,"explorEvents":_vm.explorEvents}}),_c('VenueContainer',{on:{"explorEvents":_vm.explorEvents}}),_c('Genres',{on:{"explorEvents":_vm.explorEvents}}),(
                _vm.GET_ALL_EVENTS.featured &&
                _vm.GET_ALL_EVENTS.featured.data.length > 0
              )?_c('Featured',{key:_vm.keyFeatured,attrs:{"events":_vm.GET_ALL_EVENTS.featured,"eventsType":`${
                _vm.title_event_type === 'Explore Events'
                  ? 'Featured'
                  : 'Featured' + ' ' + _vm.title_event_type
              }`,"eventsPath":"featured-events"}}):_vm._e(),_c('div',{staticClass:"events-overview-events-and-filter-section"},[_c('div',{staticClass:"events-overview-events-section d-grid gap-4"},[(
                  _vm.GET_ALL_EVENTS.popular &&
                  _vm.GET_ALL_EVENTS.popular.data.length > 0
                )?_c('EventsContainer',{key:_vm.keyPopular,staticClass:"mt-0",attrs:{"events":_vm.GET_ALL_EVENTS.popular,"eventsType":`${
                  _vm.title_event_type === 'Explore Events'
                    ? 'Popular Events'
                    : 'Popular' + ' ' + _vm.title_event_type
                }`,"eventsPath":"popular-events"}}):_vm._e(),(
                  _vm.GET_ALL_EVENTS.thisweek &&
                  _vm.GET_ALL_EVENTS.thisweek.data.length > 0
                )?_c('EventsContainer',{key:_vm.keyThisweek,attrs:{"events":_vm.GET_ALL_EVENTS.thisweek,"eventsType":`${
                  _vm.title_event_type === 'Explore Events'
                    ? 'This Week'
                    : 'This Week' + ' ' + _vm.title_event_type
                }`,"eventsPath":"this-week"}}):_vm._e(),(
                  _vm.GET_ALL_EVENTS.online &&
                  _vm.GET_ALL_EVENTS.online.data.length > 0 &&
                  _vm.GET_EVENTS_SEARCH_FOR.filterStatus !== 'offline'
                )?_c('EventsContainer',{key:_vm.keyOnline,attrs:{"events":_vm.GET_ALL_EVENTS.online,"eventsType":`${
                  _vm.title_event_type === 'Explore Events'
                    ? 'Online Events'
                    : 'Online' + ' ' + _vm.title_event_type
                }`,"eventsPath":"online-events"}}):_vm._e(),(
                  _vm.GET_ALL_EVENTS.festivals &&
                  _vm.GET_ALL_EVENTS.festivals.data.length > 0
                )?_c('EventsContainer',{key:_vm.keyFestivals,attrs:{"events":_vm.GET_ALL_EVENTS.festivals,"eventsType":`${
                  _vm.title_event_type === 'Explore Events'
                    ? 'Festival Events'
                    : 'Festival' + ' ' + _vm.title_event_type
                }`,"eventsPath":"festival-events"}}):_vm._e(),(
                  _vm.GET_ALL_EVENTS.latest && _vm.GET_ALL_EVENTS.latest.data.length > 0
                )?_c('EventsContainer',{key:_vm.keyLatest,attrs:{"events":_vm.GET_ALL_EVENTS.latest,"eventsType":`${
                  _vm.title_event_type === 'Explore Events'
                    ? 'Latest Events'
                    : 'Latest' + ' ' + _vm.title_event_type
                }`,"eventsPath":"Latest-events"}}):_vm._e()],1),_c('div',{staticClass:"events-overview-filter-section d-grid gap-2"},[_c('AddEventButtonComponent',{attrs:{"create-events-modal":_vm.createEventsModal},on:{"openCreateEventModal":function($event){_vm.createEventsModal = !_vm.createEventsModal}}}),_c('FilterEvents',{key:_vm.filtrKey,on:{"changeFilterKey":_vm.changeFilterKey,"explorEvents":_vm.explorEvents}}),_c('news-feed')],1)])],1)])],1),(_vm.createEventsModal)?_c('div',{staticClass:"create-events-wrapper"},[_c('event-post-container',{on:{"closeEventModal":function($event){_vm.createEventsModal = false}}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }