<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path
                  d="M14.8919 7.58645V8.19978C14.8911 9.63739 14.4256 11.0362 13.5648 12.1877C12.704 13.3391 11.4941 14.1814 10.1155 14.589C8.73688 14.9967 7.26343 14.9477 5.91491 14.4495C4.56638 13.9513 3.41503 13.0305 2.63257 11.8245C1.8501 10.6185 1.47845 9.19182 1.57304 7.75732C1.66764 6.32282 2.2234 4.95733 3.15745 3.86449C4.0915 2.77166 5.35378 2.01003 6.75605 1.69321C8.15832 1.37639 9.62544 1.52134 10.9386 2.10645"
                  stroke="#47505B" stroke-width="1.5" />
                <path d="M14.8918 2.86633L8.2251 9.53967L6.2251 7.53967" stroke="#47505B" stroke-width="1.5" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="16" height="16" fill="white" transform="translate(0.225098 0.199707)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p class="ml-3 mb-0">Verification</p>
          <button type="button" class="btn-view ml-auto" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="settings-content active">
          <div class="set-block-content d-flex flex-column verification-block">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <div class="item-title">Verification Documents</div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <div class="
                    input-title
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                  ">
                  <div class="svg-icon">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.0274 3.26027H14.7464C14.6903 3.26019 14.6351 3.2469 14.5852 3.22149C14.5353 3.19607 14.492 3.15924 14.459 3.11399L13.2742 1.4847C13.1647 1.33478 13.0215 1.21277 12.8561 1.12855C12.6907 1.04434 12.5078 1.0003 12.3221 1H8.17101C7.98537 1.00031 7.80242 1.04436 7.63699 1.12859C7.47156 1.21282 7.32831 1.33486 7.21886 1.4848L6.03415 3.11399C6.00111 3.15923 5.95788 3.19606 5.90795 3.22147C5.85803 3.24689 5.80282 3.26018 5.7468 3.26027H3.46575C2.81202 3.26102 2.18528 3.52105 1.72303 3.9833C1.26077 4.44556 1.00075 5.0723 1 5.72603V13.5342C1.00075 14.188 1.26077 14.8147 1.72303 15.277C2.18528 15.7392 2.81202 15.9993 3.46575 16H17.0274C17.6811 15.9993 18.3079 15.7392 18.7701 15.277C19.2324 14.8147 19.4924 14.188 19.4932 13.5342V5.72603C19.4924 5.0723 19.2324 4.44556 18.7701 3.9833C18.3079 3.52105 17.6811 3.26102 17.0274 3.26027ZM18.6712 13.5342C18.6707 13.9701 18.4974 14.3879 18.1892 14.6961C17.881 15.0042 17.4632 15.1776 17.0274 15.1781H3.46575C3.02993 15.1776 2.61211 15.0042 2.30394 14.6961C1.99577 14.3879 1.82242 13.9701 1.82192 13.5342V5.72603C1.82242 5.29021 1.99577 4.87238 2.30394 4.56421C2.61211 4.25604 3.02993 4.08269 3.46575 4.08219H5.7468C5.93241 4.08189 6.11534 4.03785 6.28076 3.95364C6.44617 3.86942 6.5894 3.74741 6.69884 3.59749L7.88356 1.9683C7.9166 1.92303 7.95984 1.88618 8.00979 1.86074C8.05973 1.83531 8.11497 1.82201 8.17101 1.82192H12.3221C12.3782 1.822 12.4334 1.83529 12.4833 1.86071C12.5332 1.88612 12.5765 1.92295 12.6095 1.9682L13.7943 3.59749C13.9037 3.74741 14.047 3.86942 14.2124 3.95364C14.3778 4.03785 14.5607 4.08189 14.7464 4.08219H17.0274C17.4632 4.08269 17.881 4.25604 18.1892 4.56421C18.4974 4.87238 18.6707 5.29021 18.6712 5.72603V13.5342Z"
                        fill="#8B949F" stroke="#8B949F" stroke-width="0.4"></path>
                      <path
                        d="M10.2462 5.72607C9.47399 5.72607 8.71917 5.95505 8.07714 6.38403C7.43512 6.81302 6.93472 7.42276 6.63923 8.13615C6.34373 8.84953 6.26642 9.63451 6.41706 10.3918C6.5677 11.1492 6.93953 11.8448 7.48553 12.3908C8.03153 12.9368 8.72718 13.3086 9.4845 13.4593C10.2418 13.6099 11.0268 13.5326 11.7402 13.2371C12.4536 12.9416 13.0633 12.4412 13.4923 11.7992C13.9213 11.1572 14.1503 10.4023 14.1503 9.63018C14.1491 8.59511 13.7374 7.60277 13.0055 6.87086C12.2736 6.13895 11.2812 5.72725 10.2462 5.72607ZM10.2462 12.7124C9.63655 12.7124 9.04064 12.5316 8.53378 12.1929C8.02691 11.8543 7.63186 11.3729 7.39858 10.8097C7.16529 10.2465 7.10426 9.62676 7.22318 9.02888C7.34211 8.43099 7.63566 7.8818 8.06671 7.45074C8.49777 7.01969 9.04696 6.72614 9.64485 6.60722C10.2427 6.48829 10.8625 6.54933 11.4257 6.78261C11.9889 7.01589 12.4702 7.41095 12.8089 7.91781C13.1476 8.42467 13.3283 9.02058 13.3283 9.63018C13.3274 10.4473 13.0024 11.2308 12.4246 11.8086C11.8467 12.3864 11.0633 12.7115 10.2462 12.7124Z"
                        fill="#8B949F" stroke="#8B949F" stroke-width="0.4"></path>
                      <path
                        d="M16.0003 5.31494C15.7971 5.31494 15.5985 5.3752 15.4295 5.48809C15.2606 5.60098 15.1289 5.76144 15.0511 5.94917C14.9733 6.1369 14.953 6.34348 14.9926 6.54277C15.0323 6.74207 15.1301 6.92513 15.2738 7.06882C15.4175 7.2125 15.6006 7.31035 15.7999 7.34999C15.9992 7.38964 16.2057 7.36929 16.3935 7.29153C16.5812 7.21377 16.7417 7.08208 16.8545 6.91313C16.9674 6.74418 17.0277 6.54554 17.0277 6.34234C17.0274 6.06995 16.919 5.80882 16.7264 5.61621C16.5338 5.42361 16.2727 5.31526 16.0003 5.31494ZM16.0003 6.85604C15.8987 6.85604 15.7994 6.82591 15.7149 6.76946C15.6304 6.71302 15.5646 6.63279 15.5257 6.53892C15.4868 6.44506 15.4766 6.34177 15.4965 6.24212C15.5163 6.14247 15.5652 6.05094 15.6371 5.9791C15.7089 5.90726 15.8004 5.85833 15.9001 5.83851C15.9997 5.81869 16.103 5.82886 16.1969 5.86774C16.2907 5.90662 16.371 5.97247 16.4274 6.05694C16.4839 6.14142 16.514 6.24074 16.514 6.34234C16.5138 6.47853 16.4597 6.6091 16.3634 6.7054C16.2671 6.8017 16.1365 6.85588 16.0003 6.85604Z"
                        fill="#8B949F" stroke="#8B949F" stroke-width="0.4"></path>
                    </svg>
                  </div>
                  Your Photo
                </div>
                <div class="
                    input-content
                    d-flex
                    align-items-center
                    justify-content-center
                  ">
                  <img class="
                    input-content
                    d-flex
                    align-items-center
                    justify-content-center
                    cover-fit
                  " v-if="photo_src" :src="photo_src" />
                  <input type="file" id="photo" @change="updateImage" />
                  <div class="svg-icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.8107 15.3107C17.092 15.0294 17.25 14.6478 17.25 14.25V6C17.25 5.60218 17.092 5.22064 16.8107 4.93934C16.5294 4.65804 16.1478 4.5 15.75 4.5H12.75L11.25 2.25H6.75L5.25 4.5H2.25C1.85218 4.5 1.47064 4.65804 1.18934 4.93934C0.908035 5.22064 0.75 5.60218 0.75 6V14.25C0.75 14.6478 0.908035 15.0294 1.18934 15.3107C1.47064 15.592 1.85218 15.75 2.25 15.75H15.75C16.1478 15.75 16.5294 15.592 16.8107 15.3107ZM12 9.75C12 11.4069 10.6569 12.75 9 12.75C7.34315 12.75 6 11.4069 6 9.75C6 8.09315 7.34315 6.75 9 6.75C10.6569 6.75 12 8.09315 12 9.75Z"
                        fill="#8B949F" />
                    </svg>
                  </div>
                </div>
                <div class="small-text">
                  Please attach your photo and your Passport or National ID
                </div>
                <label class="error_msg error_msg_red" v-if="_handleErrorMessage('photo')">{{
                  _handleErrorMessage("photo") }}</label>
              </div>
              <div class="d-flex flex-column">
                <div class="
                    input-title
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                  ">
                  <div class="svg-icon">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.2 1.5H16.8C17.2474 1.50964 17.6735 1.67077 17.99 1.95001C18.3065 2.22925 18.4891 2.60521 18.5 3V13.4C18.4891 13.7948 18.3065 14.1707 17.99 14.45C17.6735 14.7292 17.2474 14.8904 16.8 14.9H3.2C2.75257 14.8904 2.32649 14.7292 2.01001 14.45C1.69354 14.1707 1.51092 13.7948 1.5 13.4V3C1.51092 2.60521 1.69354 2.22925 2.01001 1.95001C2.32649 1.67077 2.75257 1.50964 3.2 1.5V1.5Z"
                        stroke="#8B949F" stroke-width="1.3"></path>
                      <path d="M11 11.2002L16 11.2002" stroke="#8B949F" stroke-width="1.5" stroke-linejoin="round">
                      </path>
                      <path d="M11 5.3999H16" stroke="#8B949F" stroke-width="1.5" stroke-linejoin="round"></path>
                      <path d="M11 8.2002H16" stroke="#8B949F" stroke-width="1.5" stroke-linejoin="round"></path>
                      <path
                        d="M6.51931 9.41558C7.40722 9.41558 8.12701 8.69579 8.12701 7.80789C8.12701 6.91998 7.40722 6.2002 6.51931 6.2002C5.63141 6.2002 4.91162 6.91998 4.91162 7.80789C4.91162 8.69579 5.63141 9.41558 6.51931 9.41558Z"
                        fill="#8B949F"></path>
                      <path
                        d="M3.93856 11.7003C3.93856 11.7003 3.89622 10.5579 5.75776 10.5156H7.19623C9.10007 10.5156 9.10007 11.7003 9.10007 11.7003"
                        stroke="#8B949F"></path>
                    </svg>
                  </div>
                  Passport or ID
                </div>
                <div class="
                    input-content
                    d-flex
                    align-items-center
                    justify-content-center
                  ">
                  <img class="
                    input-content
                    d-flex
                    align-items-center
                    justify-content-center
                    cover-fit
                  " v-if="passport_src" :src="passport_src" />
                  <input type="file" id="pass" @change="updatePassportImage" />
                  <div class="svg-icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.8107 15.3107C17.092 15.0294 17.25 14.6478 17.25 14.25V6C17.25 5.60218 17.092 5.22064 16.8107 4.93934C16.5294 4.65804 16.1478 4.5 15.75 4.5H12.75L11.25 2.25H6.75L5.25 4.5H2.25C1.85218 4.5 1.47064 4.65804 1.18934 4.93934C0.908035 5.22064 0.75 5.60218 0.75 6V14.25C0.75 14.6478 0.908035 15.0294 1.18934 15.3107C1.47064 15.592 1.85218 15.75 2.25 15.75H15.75C16.1478 15.75 16.5294 15.592 16.8107 15.3107ZM12 9.75C12 11.4069 10.6569 12.75 9 12.75C7.34315 12.75 6 11.4069 6 9.75C6 8.09315 7.34315 6.75 9 6.75C10.6569 6.75 12 8.09315 12 9.75Z"
                        fill="#8B949F" />
                    </svg>
                    <!-- <img v-if="passport_src" :src="passport_src" height="50" /> -->
                  </div>
                </div>
                <label class="error_msg error_msg_red" v-if="_handleErrorMessage('passport')">{{
                  _handleErrorMessage("passport") }}</label>
              </div>
            </div>
            <div class="item-title mt-4 mb-3">Additional Information</div>
            <div>
              <textarea class="d-flex" v-model="additional_info"></textarea>
            </div>
            <div class="small-text">
              Please attach your photo and your Passport or National ID
            </div>
            <label class="error_msg error_msg_red" v-if="_handleErrorMessage('additional_info')">{{
              _handleErrorMessage("additional_info") }}</label>
              
            <div class="send-request" @click="submit">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.43351 11.249L3.78223 8.04899L5.259 7.61464L7.33757 9.43631L10.4204 6.09659L12.8489 5.38232L7.43351 11.249Z"
                  fill="white" />
              </svg>
              <span>
                {{ loading ? "Please Wait..." : "Send Request" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
  <!-- <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="verification" class="settings-content active">
      <div class="set-block d-flex flex-column setting-header">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M14.8919 7.58645V8.19978C14.8911 9.63739 14.4256 11.0362 13.5648 12.1877C12.704 13.3391 11.4941 14.1814 10.1155 14.589C8.73688 14.9967 7.26343 14.9477 5.91491 14.4495C4.56638 13.9513 3.41503 13.0305 2.63257 11.8245C1.8501 10.6185 1.47845 9.19182 1.57304 7.75732C1.66764 6.32282 2.2234 4.95733 3.15745 3.86449C4.0915 2.77166 5.35378 2.01003 6.75605 1.69321C8.15832 1.37639 9.62544 1.52134 10.9386 2.10645"
                  stroke="#47505B"
                  stroke-width="1.5"
                />
                <path
                  d="M14.8918 2.86633L8.2251 9.53967L6.2251 7.53967"
                  stroke="#47505B"
                  stroke-width="1.5"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0.225098 0.199707)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          Verification
        </div>
        <div class="set-block-content d-flex flex-column verification-block">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <div v-if="success_message" class="alert alert-success" role="alert">
            {{ success_message }}
          </div>
          <div class="item-title">Verification Documents</div>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column">
              <div
                class="
                  input-title
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                "
              >
                <div class="svg-icon">
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0274 3.26027H14.7464C14.6903 3.26019 14.6351 3.2469 14.5852 3.22149C14.5353 3.19607 14.492 3.15924 14.459 3.11399L13.2742 1.4847C13.1647 1.33478 13.0215 1.21277 12.8561 1.12855C12.6907 1.04434 12.5078 1.0003 12.3221 1H8.17101C7.98537 1.00031 7.80242 1.04436 7.63699 1.12859C7.47156 1.21282 7.32831 1.33486 7.21886 1.4848L6.03415 3.11399C6.00111 3.15923 5.95788 3.19606 5.90795 3.22147C5.85803 3.24689 5.80282 3.26018 5.7468 3.26027H3.46575C2.81202 3.26102 2.18528 3.52105 1.72303 3.9833C1.26077 4.44556 1.00075 5.0723 1 5.72603V13.5342C1.00075 14.188 1.26077 14.8147 1.72303 15.277C2.18528 15.7392 2.81202 15.9993 3.46575 16H17.0274C17.6811 15.9993 18.3079 15.7392 18.7701 15.277C19.2324 14.8147 19.4924 14.188 19.4932 13.5342V5.72603C19.4924 5.0723 19.2324 4.44556 18.7701 3.9833C18.3079 3.52105 17.6811 3.26102 17.0274 3.26027ZM18.6712 13.5342C18.6707 13.9701 18.4974 14.3879 18.1892 14.6961C17.881 15.0042 17.4632 15.1776 17.0274 15.1781H3.46575C3.02993 15.1776 2.61211 15.0042 2.30394 14.6961C1.99577 14.3879 1.82242 13.9701 1.82192 13.5342V5.72603C1.82242 5.29021 1.99577 4.87238 2.30394 4.56421C2.61211 4.25604 3.02993 4.08269 3.46575 4.08219H5.7468C5.93241 4.08189 6.11534 4.03785 6.28076 3.95364C6.44617 3.86942 6.5894 3.74741 6.69884 3.59749L7.88356 1.9683C7.9166 1.92303 7.95984 1.88618 8.00979 1.86074C8.05973 1.83531 8.11497 1.82201 8.17101 1.82192H12.3221C12.3782 1.822 12.4334 1.83529 12.4833 1.86071C12.5332 1.88612 12.5765 1.92295 12.6095 1.9682L13.7943 3.59749C13.9037 3.74741 14.047 3.86942 14.2124 3.95364C14.3778 4.03785 14.5607 4.08189 14.7464 4.08219H17.0274C17.4632 4.08269 17.881 4.25604 18.1892 4.56421C18.4974 4.87238 18.6707 5.29021 18.6712 5.72603V13.5342Z"
                      fill="#8B949F"
                      stroke="#8B949F"
                      stroke-width="0.4"
                    ></path>
                    <path
                      d="M10.2462 5.72607C9.47399 5.72607 8.71917 5.95505 8.07714 6.38403C7.43512 6.81302 6.93472 7.42276 6.63923 8.13615C6.34373 8.84953 6.26642 9.63451 6.41706 10.3918C6.5677 11.1492 6.93953 11.8448 7.48553 12.3908C8.03153 12.9368 8.72718 13.3086 9.4845 13.4593C10.2418 13.6099 11.0268 13.5326 11.7402 13.2371C12.4536 12.9416 13.0633 12.4412 13.4923 11.7992C13.9213 11.1572 14.1503 10.4023 14.1503 9.63018C14.1491 8.59511 13.7374 7.60277 13.0055 6.87086C12.2736 6.13895 11.2812 5.72725 10.2462 5.72607ZM10.2462 12.7124C9.63655 12.7124 9.04064 12.5316 8.53378 12.1929C8.02691 11.8543 7.63186 11.3729 7.39858 10.8097C7.16529 10.2465 7.10426 9.62676 7.22318 9.02888C7.34211 8.43099 7.63566 7.8818 8.06671 7.45074C8.49777 7.01969 9.04696 6.72614 9.64485 6.60722C10.2427 6.48829 10.8625 6.54933 11.4257 6.78261C11.9889 7.01589 12.4702 7.41095 12.8089 7.91781C13.1476 8.42467 13.3283 9.02058 13.3283 9.63018C13.3274 10.4473 13.0024 11.2308 12.4246 11.8086C11.8467 12.3864 11.0633 12.7115 10.2462 12.7124Z"
                      fill="#8B949F"
                      stroke="#8B949F"
                      stroke-width="0.4"
                    ></path>
                    <path
                      d="M16.0003 5.31494C15.7971 5.31494 15.5985 5.3752 15.4295 5.48809C15.2606 5.60098 15.1289 5.76144 15.0511 5.94917C14.9733 6.1369 14.953 6.34348 14.9926 6.54277C15.0323 6.74207 15.1301 6.92513 15.2738 7.06882C15.4175 7.2125 15.6006 7.31035 15.7999 7.34999C15.9992 7.38964 16.2057 7.36929 16.3935 7.29153C16.5812 7.21377 16.7417 7.08208 16.8545 6.91313C16.9674 6.74418 17.0277 6.54554 17.0277 6.34234C17.0274 6.06995 16.919 5.80882 16.7264 5.61621C16.5338 5.42361 16.2727 5.31526 16.0003 5.31494ZM16.0003 6.85604C15.8987 6.85604 15.7994 6.82591 15.7149 6.76946C15.6304 6.71302 15.5646 6.63279 15.5257 6.53892C15.4868 6.44506 15.4766 6.34177 15.4965 6.24212C15.5163 6.14247 15.5652 6.05094 15.6371 5.9791C15.7089 5.90726 15.8004 5.85833 15.9001 5.83851C15.9997 5.81869 16.103 5.82886 16.1969 5.86774C16.2907 5.90662 16.371 5.97247 16.4274 6.05694C16.4839 6.14142 16.514 6.24074 16.514 6.34234C16.5138 6.47853 16.4597 6.6091 16.3634 6.7054C16.2671 6.8017 16.1365 6.85588 16.0003 6.85604Z"
                      fill="#8B949F"
                      stroke="#8B949F"
                      stroke-width="0.4"
                    ></path>
                  </svg>
                </div>
                Your Photo
              </div>
              <div
                class="
                  input-content
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <input type="file" id="photo" @change="updateImage" />
                <div class="svg-icon">
                  <svg
                    v-if="!photo_src"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.8107 15.3107C17.092 15.0294 17.25 14.6478 17.25 14.25V6C17.25 5.60218 17.092 5.22064 16.8107 4.93934C16.5294 4.65804 16.1478 4.5 15.75 4.5H12.75L11.25 2.25H6.75L5.25 4.5H2.25C1.85218 4.5 1.47064 4.65804 1.18934 4.93934C0.908035 5.22064 0.75 5.60218 0.75 6V14.25C0.75 14.6478 0.908035 15.0294 1.18934 15.3107C1.47064 15.592 1.85218 15.75 2.25 15.75H15.75C16.1478 15.75 16.5294 15.592 16.8107 15.3107ZM12 9.75C12 11.4069 10.6569 12.75 9 12.75C7.34315 12.75 6 11.4069 6 9.75C6 8.09315 7.34315 6.75 9 6.75C10.6569 6.75 12 8.09315 12 9.75Z"
                      fill="#8B949F"
                    ></path>
                  </svg>
                  <img v-if="photo_src" :src="photo_src" height="50" />
                </div>
              </div>
              <div class="small-text">
                Please attach your photo and your Passport or National ID
              </div>
              <label
                class="error_msg error_msg_red"
                v-if="_handleErrorMessage('photo')"
                >{{ _handleErrorMessage("photo") }}</label
              >
            </div>
            <div class="d-flex flex-column">
              <div
                class="
                  input-title
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                "
              >
                <div class="svg-icon">
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.2 1.5H16.8C17.2474 1.50964 17.6735 1.67077 17.99 1.95001C18.3065 2.22925 18.4891 2.60521 18.5 3V13.4C18.4891 13.7948 18.3065 14.1707 17.99 14.45C17.6735 14.7292 17.2474 14.8904 16.8 14.9H3.2C2.75257 14.8904 2.32649 14.7292 2.01001 14.45C1.69354 14.1707 1.51092 13.7948 1.5 13.4V3C1.51092 2.60521 1.69354 2.22925 2.01001 1.95001C2.32649 1.67077 2.75257 1.50964 3.2 1.5V1.5Z"
                      stroke="#8B949F"
                      stroke-width="1.3"
                    ></path>
                    <path
                      d="M11 11.2002L16 11.2002"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 5.3999H16"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 8.2002H16"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M6.51931 9.41558C7.40722 9.41558 8.12701 8.69579 8.12701 7.80789C8.12701 6.91998 7.40722 6.2002 6.51931 6.2002C5.63141 6.2002 4.91162 6.91998 4.91162 7.80789C4.91162 8.69579 5.63141 9.41558 6.51931 9.41558Z"
                      fill="#8B949F"
                    ></path>
                    <path
                      d="M3.93856 11.7003C3.93856 11.7003 3.89622 10.5579 5.75776 10.5156H7.19623C9.10007 10.5156 9.10007 11.7003 9.10007 11.7003"
                      stroke="#8B949F"
                    ></path>
                  </svg>
                </div>
                Passport or ID
              </div>
              <div
                class="
                  input-content
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <input type="file" id="pass" @change="updatePassportImage" />
                <div class="svg-icon">
                  <svg
                    v-if="!passport_src"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.8107 15.3107C17.092 15.0294 17.25 14.6478 17.25 14.25V6C17.25 5.60218 17.092 5.22064 16.8107 4.93934C16.5294 4.65804 16.1478 4.5 15.75 4.5H12.75L11.25 2.25H6.75L5.25 4.5H2.25C1.85218 4.5 1.47064 4.65804 1.18934 4.93934C0.908035 5.22064 0.75 5.60218 0.75 6V14.25C0.75 14.6478 0.908035 15.0294 1.18934 15.3107C1.47064 15.592 1.85218 15.75 2.25 15.75H15.75C16.1478 15.75 16.5294 15.592 16.8107 15.3107ZM12 9.75C12 11.4069 10.6569 12.75 9 12.75C7.34315 12.75 6 11.4069 6 9.75C6 8.09315 7.34315 6.75 9 6.75C10.6569 6.75 12 8.09315 12 9.75Z"
                      fill="#8B949F"
                    ></path>
                  </svg>
                  <img v-if="passport_src" :src="passport_src" height="50" />
                </div>
              </div>
              <label
                class="error_msg error_msg_red"
                v-if="_handleErrorMessage('passport')"
                >{{ _handleErrorMessage("passport") }}</label
              >
            </div>
          </div>
          <div class="item-title mt-4 mb-3">Additional Information</div>
          <div>
            <textarea class="d-flex" v-model="additional_info"></textarea>
          </div>
          <div class="small-text">
            Please attach your photo and your Passport or National ID
          </div>
          <label
            class="error_msg error_msg_red"
            v-if="_handleErrorMessage('additional_info')"
            >{{ _handleErrorMessage("additional_info") }}</label
          >
          <div class="send-request" @click="submit">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.43351 11.249L3.78223 8.04899L5.259 7.61464L7.33757 9.43631L10.4204 6.09659L12.8489 5.38232L7.43351 11.249Z"
                fill="white"
              />
            </svg>

            {{ loading ? "Please Wait..." : "Send Request" }}
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import { user_verification_api } from "../../../apis/APIs";
import {
  EDIT_PROFILE_ERRORS,
  USER_ROOT,
  USER_DATA,
  USER_KEY,
  SUCCESS,
  ERROR,
} from "../../../store/types";
import AppConfig from "../../../configs/AppConfig";
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";

export default {
  components: {
    EyeIcon
  },
  data() {
    return {
      photo: undefined,
      photo_src: undefined,
      passport: undefined,
      passport_src: undefined,
      additional_info: "",

      message: "",
      success_message: "",

      loading: false,
      errors: [],
    };
  },
  mounted() {
    this.getVerificationData();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
              this.errors[
                this.errors.findIndex((ele) => ele.fieldName === key)
              ].message,
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    updateImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;

      if (file) {
        this.photo = file;
        this.photo_src = URL.createObjectURL(file);
      }
    },
    updatePassportImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;

      if (file) {
        this.passport = file;
        this.passport_src = URL.createObjectURL(file);
      }
    },
    async submit(e) {
      if (e) e.preventDefault();

      const {
        loading,
        photo,
        photo_src,
        passport_src,
        passport,
        additional_info,
      } = this;

      const { user_data } = this.user || {};
      const { user_id } = user_data || {};

      if (loading) return;

      this.errors = [];

      if (!photo_src) {
        this.errors.push({
          fieldName: "artwork",
          message: "Required",
        });
      }

      if (!passport_src) {
        this.errors.push({
          fieldName: "passport",
          message: "Required",
        });
      }

      if (!additional_info) {
        this.errors.push({
          fieldName: "additional_info",
          message: "Rquired",
        });
      }

      if (this.errors && this.errors.length) return;

      try {
        var formdata = new FormData();
        formdata.append("id", user_id);
        if (photo) formdata.append("artwork", photo);
        if (passport) formdata.append("passport", passport);
        formdata.append("additional_info", additional_info);

        this.message = "";
        this.loading = true;
        const res = await user_verification_api(formdata);
        const { success, message, data } = res.data || {};

        if (success === 1) {
          this.success_message = message;
          this.fetchUserData();
          this.$store.commit("updateUserUIConstraints", {
            [USER_DATA]: data,
          });
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getVerificationData() {
      const { user_data } = this.user || {};
      const { artwork_url, passport_url, additional_info } = user_data || {};

      if (artwork_url) this.photo_src = `${artwork_url}`;
      if (passport_url) this.passport_src = `${passport_url}`;
      if (additional_info) this.additional_info = additional_info;
    },
  },
};
</script>
<style scoped>
.cover-fit {
  object-fit: contain;
}
</style>
