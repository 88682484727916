<template>
  <div class="events-favorites-content">
    <b-skeleton-wrapper :loading="!displayPage">
      <template #loading>
        <SkeletonLoader v-for="item in 5" :key="item" />
      </template>
      <div class="events-favorites-left-content">
        <TabComponent
          :active-tab="activeTab"
          :view="view"
          :tabs="tabs"
          @changeTabsAccordingType="changeTabsAccordingType"
          @changeViewAccordingType="changeViewAccordingType"
        />
        <div class="event-favorites-events-section">
          <div v-if="view === 'grid'"
               class="event-favorites-events-grid-view"
          >
            <EventsCard
              v-for="(item, index) in activeTab === 'going'
              ? GET_MY_FAVORITES_GOING_EVENT_LIST.data
              : GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data"
              :key="item.id"
              :event="item"
              :no_margin_right="!((index + 1) % 4)"
              @getFavoritesData="getAllFavoritesData_going_and_interested"
              :mRight="'30px'"
            />
            <EmptyDataCard
              v-if="
              (activeTab === 'going' &&
                GET_MY_FAVORITES_GOING_EVENT_LIST.data.length === 0) ||
              (activeTab === 'interested' &&
                GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data.length === 0)
            "
              :item="emptyCartData"
              @buttonClick="goToOverviewPage"
            />
          </div>
          <div v-if="view === 'list'"
               class="event-favorites-events-list-view"
          >
            <EventListCart
              v-for="item in activeTab === 'going'
              ? GET_MY_FAVORITES_GOING_EVENT_LIST.data
              : GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data"
              :key="item.id"
              :event="item"
              @getFavoritesData="getAllFavoritesData_going_and_interested"
            />
            <EmptyDataCard
              v-if="
              (activeTab === 'going' &&
                GET_MY_FAVORITES_GOING_EVENT_LIST.data.length === 0) ||
              (activeTab === 'interested' &&
                GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data.length === 0)
            "
              :item="emptyCartData"
              @buttonClick="goToOverviewPage"
            />
          </div>
          <div v-if="(activeTab === 'going' && GET_MY_FAVORITES_GOING_EVENT_LIST.total > currentGoing) || (activeTab === 'interested' && GET_MY_FAVORITES_INTERESTED_EVENT_LIST.total > currentintersted)"
               class="show_more_btn"
               @click="showMoreEvent"
          >
            <div class="show-more musicbar_more">
              <a>
              <span class="show_more_bg"
              ><svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW 10 MORE EVENTS
              </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <FilterEvents
        :key="filterKey"
        @changeFilterKey="changeFilterKey"
        @getAllFavoritesData_going_and_interested="getAllFavoritesData_going_and_interested"
      />
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import EventsCard from "@/components/Events/components/EventsCard.vue";
import FilterEvents from "../components/Filter.vue";
import EventListCart from "../components/EventListCart.vue";
import EmptyDataCard from "@/components/Common/UiComponenets/EmptyDataCard.vue";
import { mapActions, mapGetters } from "vuex";
import TabComponent from "../../Common/Elements/TabComponent.vue";
import NewsFeed from "../components/NewsFeed.vue";
import Genres from "../components/Genres.vue";
import EventsContainer from "../components/EventsContainer.vue";
import VenueContainer from "../components/Venue/VenueContainer.vue";
import Search from "../components/Search.vue";
import Featured from "../components/Featured/Featured.vue";
import SkeletonLoader from "../../Common/Loaders/SkeletonLoader.vue";
export default {
  components: {
    SkeletonLoader,
    TabComponent,
    EventsCard,
    FilterEvents,
    EventListCart,
    EmptyDataCard,
  },
  data() {
    return {
      tabs: [
        { value: "going", count: 0, name: "GOING" },
        { value: "interested", count: 0, name: "INTERESTED" },
      ],
      displayPage: false,
      filterKey: 0,
      view: "grid",
      activeTab: "going",
      currentGoing: 8,
      currentInterested: 8,
      emptyCartData: {
        title: "Not Going or Interested  to Events yet",
        description:
          "You aren’t going to any events yet. Let’s find some events! ",
        icon: require(`@/assets/img/calendar.png`),
        buttonText: "FIND EVENTS",
      },
    };
  },
  methods: {
    ...mapActions(["myFavoritesEvents"]),
    changeTabsAccordingType(activeTab) {
      this.activeTab = activeTab;
    },
    changeViewAccordingType(view) {
      this.view = view;
    },
    async getDataFavorites(payload) {
      await this.myFavoritesEvents(payload);
    },
    async showMoreEvent() {
      await this.$store.dispatch("loading", true);
      await this.getDataFavorites({
        value: this.activeTab,
        limit:
          this.activeTab === "going"
            ? (this.currentGoing += 8)
            : (this.currentInterested += 8),
        ...this.GET_EVENTS_SEARCH_FOR,
      });
      await this.$store.dispatch("loading", false);
    },
    goToOverviewPage() {
      this.$router.push("/events/page/overview");
    },
    changeFilterKey() {
      this.filterKey += 1;
    },
    async getAllFavoritesData_going_and_interested(payload) {
      await this.$store.dispatch("loading", true);
      await this.getDataFavorites({
        value: "going",
        limit: this.currentGoing,
        ...payload,
      });
      await this.getDataFavorites({
        value: "interested",
        limit: this.currentInterested,
        ...payload,
      });

      await this.$store.dispatch("loading", false);
    },
  },
  computed: {
    ...mapGetters([
      "GET_MY_FAVORITES_INTERESTED_EVENT_LIST",
      "GET_MY_FAVORITES_GOING_EVENT_LIST",
      "GET_EVENTS_SEARCH_FOR",
    ]),

    getAllFavoriteEvents() {
      return [
        ...this.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data,
        ...this.GET_MY_FAVORITES_GOING_EVENT_LIST.data,
      ];
    },
  },
  async created() {
    await this.getAllFavoritesData_going_and_interested();
  },

  watch: {
    GET_MY_FAVORITES_INTERESTED_EVENT_LIST: {
      handler: function() {
        this.tabs[1].count = this.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.total;
      },
      deep: true,
    },
    GET_MY_FAVORITES_GOING_EVENT_LIST: {
      handler: function() {
        this.tabs[0].count = this.GET_MY_FAVORITES_GOING_EVENT_LIST.total;
      },
      deep: true,
    },

    getAllFavoriteEvents: {
      handler: function() {
        setTimeout(() => {
          this.displayPage = true;
        }, 3000);
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/events/favorites.scss";

.wr_eventsfavorites {
  margin-bottom: 90px;
  .title {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin: 35px 0 13px 0;
  }
  .wr_contener {
    display: flex;
    .contener {
      width: 770px;
    }
    .ticket-info-tabs {
      margin-bottom: 27px;
      padding: 0 20px;
      background: #fff;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        height: 100%;
        padding-top: 5px;
        div {
          cursor: pointer;
          margin-right: 42px;
          font-family: HelveticaNeueBold;
          height: 100%;
          display: flex;
          color: #c4ccd6;
          align-items: center;
          border-bottom: 3px solid #fff;
          span {
            margin-left: 6px;
            min-width: 26px;
            background: #D1433A;
            border-radius: 4px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .active_tabs {
          color: #47505b;
          border-color: #D1433A;
        }
      }
      .wr_event_form {
        padding: 0;
        width: 82px;
        height: 30px;
        display: flex;
        border: 1px solid #D1433A;
        border-radius: 3px;
        cursor: pointer;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          border-right: 1px;
          height: 100%;
          border-color: #D1433A;
        }
        .form_list {
          border-left: 1px;
        }
        .activ_form {
          background: #D1433A;
          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
  .wr_favorites_contener {
    display: flex;
    flex-wrap: wrap;
  }
  .zeroEvent {
    background: #c4ccd6 !important;
  }
}
</style>