<template>
<div class="no-data">
  <div class="no-content-here">
    <div class="no-detail-content">
      <div class="category-content-icon">
        <img :src="require(`@/assets/img/svg/message-square.png`)" />
      </div>
      <div class="no-detail-content-heading">
        {{ header }}
      </div>
      <div class="no-detail-content-subheading">
        {{ content }}
      </div>
    </div>
  </div>
</div>
</template>
<script>


export default {
  name: "NoDataComponent",
  components: {  },
  props: {
    header: {
      type: String,
      required: true
    },
    content: {
      type: String,
    }
  },
};
</script>

<style scoped lang="scss">
.no-data {
  width: 769px;
  border-radius: 5px;
  background: #E6EAF0;
}
.no-detail-content-subheading{
  color: #47505B;
  text-align: center;
  font-family: HelveticaNeueNormal, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
.no-detail-content-heading {
  color: #071526;
  text-align: center;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>