<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="confirm-email"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog confirm_email">
        <div class="modal-content">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeLoginModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body">
            <div class="body-title Font_semibold">
              Confirm your E-mail
            </div>
            <div class="modal-title-sm grey-text">
              We sent a 4-digit code to your e-mail <br/>
              <span v-if="emailFromParams">{{ emailFromParams }}</span>
              <span v-else>{{ email }}</span>
            </div>
            <p class="error_msg text-center error_msg_red" v-if="message">{{ message }}</p>
            <div class="input-row">
              <vue-fake-input
                  :length="4"
                  :fontSize="32"
                  v-model="code"
                  inputColor="#D1433A"
                  :allowPaste="false"
                  class="pl-2 ml-5"
              />
            </div>
            <button
                id="verify_email_address"
                class="btn btn-login m-t-20 non-active Font_semibold"
                v-on:click="startVerification"
                :disabled="is_valid_btn || loading"
                :style="[is_valid_btn ? { cursor: 'not-allowed' } : { cursor: 'pointer' }]"
            >
              {{ loading ? "Please Wait..." : "CONFIRM E-mail" }}
            </button>
            <button
                class="btn btn-login m-t-20 non-active Font_semibold"
                v-on:click="resendCode"
                :disabled="is_timer_active || resendLoading"
                :style="[is_timer_active ? { cursor: 'not-allowed' } : { cursor: 'pointer' }]"
            >
              {{ resendLoading ? "Please Wait..." : "RESEND CODE IN : " + timerCount }}
            </button>
            <div class="custom-hr"></div>
            <div class="modal-title-sm grey-text">
              You can confirm your e-mail with the code sent to your e-mail or click on the link from your e-mail for
              automatic confirmation
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapGetters, mapActions, mapMutations } from "vuex";
import {resendCode, verifyEmail} from '../../../apis/APIs';
import VueFakeInput from 'vue-fake-input';
import * as SMStorage from "../../../apis/SMStorage";
import {USER_DATA} from "../../../store/types";

export default {
  Props: {
    length: {
      type: Number,
      default: 4
    },
    placeholder: {
      type: String,
      default: '-'
    },
    theme: {
      type: String,
      default: 'block'
    }
  },
  data() {
    return {
      email: "",
      emailFromParams:"",
      one: "",
      two: "",
      three: "",
      four: "",
      code: "",
      loading: false,
      resendLoading: false,
      message: "",
      timerCount: 60,
    };
  },
  watch: {

    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },
  components: {VueFakeInput},
  computed: {
    ...mapGetters({
      form_data: "login_form_map_to_props",
      check_email_data: "signup_email_form_map_to_props",
      "user": "user_map_to_props"
    }),
    is_valid_btn() {
      return !this.code || (!this.email && !this.emailFromParams);
    },
    is_timer_active() {
      if (this.timerCount == 0) {
        return false;
      } else {
        return true;
      }
    },
  },

  mounted() {
    const { code, email } = this.$route.query;
    if (code){
      this.code = code;
    }
    if (email){
      this.emailFromParams=email
    }
    this.init();
  },
  methods: {
    ...mapActions([
      "set_user_data"
    ]),
    ...mapMutations([
      "updateSignupFormData",
    ]),
    init() {
      const {user_data} = this.user || {};
      const {email} = user_data || {}

      if (email) return this.email = email;

      this.email = this.check_email_data && this.check_email_data.email ? this.check_email_data.email : "";
    },
    closeLoginModal: function () {
      this.updateSignupFormData({});
      this.$parent.closeModal();
    },
    goBack: function () {
      this.$parent.newModal("register-step-2");
    },
    startVerification: async function () {
      try {
        const {code, loading, email} = this;

        if (loading) return;

        // const body = {
        //   code: `${one}${two}${three}${four}`,
        //   email
        // }

        this.loading = true;

        var formdata = new FormData();
        formdata.append("code", code);
        formdata.append("email", email)
        const {data} = await verifyEmail(formdata);
        console.log(data);
        this.loading = false;
        const {success, message, information, token_type, access_token} = data || {};

        this.message = "";
        if (success === 1) {
          this.$store.commit("SET_ACCESS_TOKEN", `${access_token}`);
          localStorage.setItem('token', `Bearer ${access_token}`)
          await this.set_user_data(information);
          this.$parent.newModal("verification-status");
        } else {
          this.message = message;
        }

      } catch (error) {
        this.loading = false;
        this.message = error && error.message ? error.message : "Please try again.";
      }
    },
    resendCode: async function () {
      try {
        const {code, resendLoading, email} = this;

        if (resendLoading) return;

        // const body = {
        //   code: `${one}${two}${three}${four}`,
        //   email
        // }

        this.resendLoading = true;

        var formdata = new FormData();
        formdata.append("email", email)
        const {data} = await resendCode(formdata);
        console.log(data);
        this.resendLoading = false;
        const {success, message} = data || {};

        this.message = "";
        if (success === 1) {
          this.message = message;
        } else {
          this.message = message;
        }

      } catch (error) {
        this.resendLoading = false;
        this.message = error && error.message ? error.message : "Please try again.";
      }
    }
  }
};
</script>
