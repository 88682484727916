<template>
  <div
    class="comment-post-dot-dropdown"
    @click="toggle_drop_down($event)"
    v-click-outside="clickOutside"
    style="display: flex;justify-content: flex-end; margin-right: 30px; left: 0;"
  >
    <span>
      <img :src="require(`@/assets/img/svg/more-horizontal.svg`)" />
    </span>
    <div
      class="my-post-contanier"
      v-if="is_drop_down_open"
    >
      <div class="my-post-contaniermain">
        <div class="regular-links">
          <ul>
            <li @click="redirectWidgetsToProfilePage($event, item && item.user && item.user.username ? item.user.username : '')">
              <a href="javascript:;">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                User Profile
              </a>
            </li>
            <li @click="deleteSubmit" v-if="(item && item.user_id === userId) || (post && post.user_id === userId)">
              <a href="javascript:;">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 4.5L4.5 13.5"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.5 4.5L13.5 13.5"
                      stroke="#D1433A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Remove Message
              </a>
            </li>
            <!-- <li @click="deleteSubmit" v-if="(item && item.user_id !== userId) && item.id">
              <a href="javascript:;">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 4.5L4.5 13.5"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.5 4.5L13.5 13.5"
                      stroke="#D1433A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Remove Message
              </a>
            </li> -->
            <span class="seprator-line" v-if="item && item.user_id !== userId"></span>
            <li v-if="item && item.user.id !== userId">
              <a href="javascript:;">
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.66406 14.6667V10"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.66406 10.0007C2.66406 10.0007 3.33073 9.33398 5.33073 9.33398C7.33073 9.33398 8.66406 10.6673 10.6641 10.6673C12.6641 10.6673 13.3307 10.0007 13.3307 10.0007V2.00065C13.3307 2.00065 12.6641 2.66732 10.6641 2.66732C8.66406 2.66732 7.33073 1.33398 5.33073 1.33398C3.33073 1.33398 2.66406 2.00065 2.66406 2.00065V10.0007Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Report user
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"DeleteCommentDropDown",
  data() {
    return {
      is_drop_down_open: false,
      pin_post: null,
      allow_comments: null
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    post:{
      type: Object,
    }
  },
  computed: {
    postType() {
      let { post_type } = this.post;
      return post_type;
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    },
  },
  mounted() {
    if(this.post){
    }
    if (this.post && this.post.pin_check) {
      this.pin_post = this.post.pin_check;
    }
    if (this.post && this.post.allow_comments) {
      this.allow_comments = this.post.allow_comments;
    }
  },
  methods: {
    toggle_drop_down(e) {
      if (e) e.stopPropagation();
      this.is_drop_down_open = !this.is_drop_down_open;
    },
    clickOutside() {
      this.is_drop_down_open = false;
    },
    async deleteSubmit() {
        const res = await this.$store.dispatch('deleteComment',this.item.id);
        const {success, message} = res.data;
        if(success === 1) {
          await this.notificationToast(true, "Comment Deleted", message, 5000, 'success')
        }
       this.$emit('removeCommentFromList');
    },
  },
};
</script>
<style scoped>
.user_and_link h1 {
  font-weight: 500 !important;
}
</style>
