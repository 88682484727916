<template>
  <div class="rate-album-sections">
    <div class="user-related-album">
      <div class="user-images"><img :src="userPicture"></div>
      <div class="star-bars">
        <p>Rate Beat {{ (selected_rating > 0) ? selected_rating + '.0' : ''}}</p>
        <star-rating @rating-selected="setRating"
                     v-model="selected_rating"
                     v-bind:increment="1"
                     v-bind:max-rating="5"
                     :clearable="true"
                     :inline="true"
                     inactive-color="#47505B"
                     active-color="#D1433A"
                     v-bind:star-size="15" />
      </div>
    </div>
    <div class="comment-submit">
      <div class="post-something-field" :style="emoji ? 'z-index: 1' : ''">
        <input type="text" placeholder="Write a Comment" v-model="comment" v-on:keyup="checkEnter" ref="Input"
               @click="emoji = false">
        <div class="comment-emojis" v-if="emoji" @shown="onShown" @hidden="onHidden" ref="dialog">
          <VEmojiPicker @select="selectEmoji" :emojiWithBorder="false" :emojiSize="26" :continuousList="true"
                        :emojisByRow="6"/>
        </div>
        <ul class="list_of_icons">
          <li @click="toggleEmoji" v-if="emoji">
            <span>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                  stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                  d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                  stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                  d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                  fill="#D1433A"/>
                <path
                  d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                  fill="#D1433A"/>
              </svg>
            </span>
          </li>
          <li @click="toggleEmoji" v-if="!emoji">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                fill="#8B949F"/>
              <path
                d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                fill="#8B949F"/>
            </svg>

          </li>
        </ul>
      </div>
      <button class="submit-btns" v-if="!addReviewLoading" @click="submit" :disabled="!comment">
        SUBMIT review
      </button>
      <b-button class="send-btn" v-else variant="primary">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </b-button>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import {VEmojiPicker} from 'v-emoji-picker';
import { addReviewOnSongAlbumPodcast, getReviewsOfSongsAlbumsPodcast } from "../../../../apis/APIs";
import '/public/css/vue-star-rating.scss';
export default {
  name: "ReviewInput",
  data(){
    return{
      selected_rating: 0,
      comment: null,
      addReviewLoading: false,
      emoji: false,
      reviews_list_loading: false,
    }
  },
  props:{
    item: {
      type: Object
    }
  },
  components: {
    StarRating,
    VEmojiPicker
  },
  computed: {
    id() {
      const {id = ""} = this.item || {};
      return id;
    },
    type() {
      const {type = ""} = this.item || {};
      return type.charAt(0).toUpperCase()+type.slice(1)
    },
    userPicture() {
      return (this.$store.getters.user_map_to_props.user_data && this.$store.getters.user_map_to_props.user_data.artwork_url) ? this.$store.getters.user_map_to_props.user_data.artwork_url : 'require(`@/assets/img/svg/mask-group.svg`)'
    }
  },
  methods: {
    setRating: function(rating){
      this.selected_rating = rating;
    },
    toggleEmoji() {
      this.emoji = !this.emoji
    },
    onShown() {
      this.$refs.dialog.focus()
    },
    onHidden() {
      this.$refs.Input.focus()
    },
    selectEmoji(emoji) {
      const {data, key} = emoji || {};
      const {comment} = this;
      this.comment = comment ? `${comment} ${data}` : data;
    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.emoji = false
        this.submit();
      }
    },
    reset_data() {
      this.comment = null;
      this.selected_rating = 0;
    },

    async submit() {
      this.emoji = false
      try {
        this.addReviewLoading = true;
        // Form data appending
        const formData = new FormData();
        formData.append('reviewable_type', (this.type && this.type === 'Music Podcast') ? 'Podcast' : this.type);
        formData.append('reviewable_id', this.id);
        formData.append('comment', this.comment);
        formData.append('rating', this.selected_rating)

        const {data} = await addReviewOnSongAlbumPodcast(formData);
        this.$emit('addReview', data.information)
        this.reset_data()
        this.addReviewLoading = false;
      } catch (error) {
        console.error(error);
        await this.notificationToast(true, `Add Review`, error.data.errors[0].message, 5000, 'error')

        this.reset_data()
        this.addReviewLoading = false;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.rate-album-sections {
  .user-related-album{
    .user-images{
      width: 38px;
      img {
        width: inherit;
        border-radius: 50%;
      }
    }
    .star-bars{
      border-radius: 10px!important;
    }
  }
  .comment-submit {
    padding: 10px 10px 10px 70px!important;
  }
}
</style>
