var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"events-favorites-content"},[_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.displayPage},scopedSlots:_vm._u([{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('SkeletonLoader',{key:item})})},proxy:true}])},[_c('div',{staticClass:"events-favorites-left-content"},[_c('TabComponent',{attrs:{"active-tab":_vm.activeTab,"view":_vm.view,"tabs":_vm.tabs},on:{"changeTabsAccordingType":_vm.changeTabsAccordingType,"changeViewAccordingType":_vm.changeViewAccordingType}}),_c('div',{staticClass:"event-favorites-events-section"},[(_vm.view === 'grid')?_c('div',{staticClass:"event-favorites-events-grid-view"},[_vm._l((_vm.activeTab === 'going'
            ? _vm.GET_MY_FAVORITES_GOING_EVENT_LIST.data
            : _vm.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data),function(item,index){return _c('EventsCard',{key:item.id,attrs:{"event":item,"no_margin_right":!((index + 1) % 4),"mRight":'30px'},on:{"getFavoritesData":_vm.getAllFavoritesData_going_and_interested}})}),(
            (_vm.activeTab === 'going' &&
              _vm.GET_MY_FAVORITES_GOING_EVENT_LIST.data.length === 0) ||
            (_vm.activeTab === 'interested' &&
              _vm.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data.length === 0)
          )?_c('EmptyDataCard',{attrs:{"item":_vm.emptyCartData},on:{"buttonClick":_vm.goToOverviewPage}}):_vm._e()],2):_vm._e(),(_vm.view === 'list')?_c('div',{staticClass:"event-favorites-events-list-view"},[_vm._l((_vm.activeTab === 'going'
            ? _vm.GET_MY_FAVORITES_GOING_EVENT_LIST.data
            : _vm.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data),function(item){return _c('EventListCart',{key:item.id,attrs:{"event":item},on:{"getFavoritesData":_vm.getAllFavoritesData_going_and_interested}})}),(
            (_vm.activeTab === 'going' &&
              _vm.GET_MY_FAVORITES_GOING_EVENT_LIST.data.length === 0) ||
            (_vm.activeTab === 'interested' &&
              _vm.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.data.length === 0)
          )?_c('EmptyDataCard',{attrs:{"item":_vm.emptyCartData},on:{"buttonClick":_vm.goToOverviewPage}}):_vm._e()],2):_vm._e(),((_vm.activeTab === 'going' && _vm.GET_MY_FAVORITES_GOING_EVENT_LIST.total > _vm.currentGoing) || (_vm.activeTab === 'interested' && _vm.GET_MY_FAVORITES_INTERESTED_EVENT_LIST.total > _vm.currentintersted))?_c('div',{staticClass:"show_more_btn",on:{"click":_vm.showMoreEvent}},[_c('div',{staticClass:"show-more musicbar_more"},[_c('a',[_c('span',{staticClass:"show_more_bg"},[_c('svg',{attrs:{"width":"9","height":"8","viewBox":"0 0 9 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.24219 0.689941V7.01994","stroke":"#071526","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M1 3.85498H7.48702","stroke":"#071526","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" SHOW 10 MORE EVENTS ")])])])]):_vm._e()])],1),_c('FilterEvents',{key:_vm.filterKey,on:{"changeFilterKey":_vm.changeFilterKey,"getAllFavoritesData_going_and_interested":_vm.getAllFavoritesData_going_and_interested}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }