<template>
  <div class="events-overview-events-card-section">
    <div class="events-overview-events-card-title-section">
      <span class="events-overview-events-card-title">{{ eventsType }}</span>
      <span class="events-overview-events-card-section-see-more-btn" @click="goTo">SEE MORE</span>
    </div>
    <div class="overview-events-card-carousel-container music_slider_landing carousel-icon">
      <carousel
        v-if="events"
        :items="4"
        :autoplay="false"
        :nav="false"
        :margin="25"
      >
        <EventsCard
          v-for="(event, index) in events.data"
          :key="index"
          :event="event"
        />
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import { mapActions, mapGetters } from "vuex";
import { login } from "../../../apis/APIs";
import EventsCard from "./EventsCard.vue";
export default {
  components: {
    carousel,
    EventsCard,
  },
  props: {
    eventsType: {
      type: String,
      default: () => "",
    },
    events: {
      type: Object,
      default: () => {},
    },
    eventsPath: String,
  },
  methods: {
    ...mapActions(["getEventsByType"]),
    async goTo() {
      let type =
        this.eventsPath === "this-week"
          ? "weekly"
          : this.eventsPath.split("-")[0].toLowerCase();
      await this.$store.dispatch("loading", true);
      await this.getEventsByType({
        type: type,
        offset: 0,
        limit: 12,
        sort_by: "trending",
        event_type: this.$route.meta.event_Type_id,
      });
      await this.$store.dispatch("loading", false);
      this.$router.push({
        name: "events.findEvents",
        params: { eventsName: this.eventsPath, eventsType: this.eventsType },
        query: {
          events: this.events,
          event_type_id: this.$route.meta.event_Type_id,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["GET_ALL_EVENTS_BY_TYPE"]),
  },
};
</script>