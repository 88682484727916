<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.00016 13.6668C10.6821 13.6668 13.6668 10.6821 13.6668 7.00016C13.6668 3.31826 10.6821 0.333496 7.00016 0.333496C3.31826 0.333496 0.333496 3.31826 0.333496 7.00016C0.333496 10.6821 3.31826 13.6668 7.00016 13.6668ZM7.00016 6.00016C7.55245 6.00016 8.00016 6.44788 8.00016 7.00016V9.66683C8.00016 10.2191 7.55245 10.6668 7.00016 10.6668C6.44788 10.6668 6.00016 10.2191 6.00016 9.66683V7.00016C6.00016 6.44788 6.44788 6.00016 7.00016 6.00016ZM7.00016 3.3335C6.44788 3.3335 6.00016 3.78121 6.00016 4.3335C6.00016 4.88578 6.44788 5.3335 7.00016 5.3335H7.00683C7.55911 5.3335 8.00683 4.88578 8.00683 4.3335C8.00683 3.78121 7.55911 3.3335 7.00683 3.3335H7.00016Z"
                    fill="#47505B"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">About & Skills</p>
          <button class="btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <form class="setting-form" @submit="submit">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="label-title">Title</label>
                  <input type="text" class="form-control" v-model="title"
                         placeholder="Your Title, “DJ Mastering” for example" />
                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('title')">{{
                      _handleErrorMessage("title") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="expert-label">
                  <div class="form-group expertiseLevelGroup">
                    <label class="label-title">Expertise Level</label>
                    <ul>
                      <li>
                        <div class="custom-radio expert-radio">
                          <input type="radio" id="Entry" name="radio-group" value="entry" v-model="expertise_level" />
                          <label for="Entry">Entry</label>
                        </div>
                      </li>
                      <li>
                        <div class="custom-radio expert-radio">
                          <input type="radio" id="Intermediate" name="radio-group" value="intermediate"
                                 v-model="expertise_level" />
                          <label for="Intermediate">Intermediate</label>
                        </div>
                      </li>
                      <li>
                        <div class="custom-radio expert-radio">
                          <input type="radio" id="Expert" name="radio-group" value="expert" v-model="expertise_level" />
                          <label for="Expert">Expert</label>
                        </div>
                      </li>
                    </ul>
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('experti se_level')">{{
                        _handleErrorMessage("expertise_level") }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="label-title">About (for work profile)</label>
                  <textarea class="textarea-field" v-model="about"></textarea>
                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('about')">{{
                      _handleErrorMessage("about") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="label-title">Skills</label>
                  <div class="tag-input">
                    <div v-if="skills.length > 0">
                      <div v-for="(skill, index) in skills.filter(skill => skill.length > 0)" :key="`${skill}${index}`" class="tag-input__tag">
                        <span v-if="skill.length > 0">{{ skill }}</span>
                        <span @click="removeSkill(index)">
                          <div class="svg-icon remove-tag">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.5 2.5L2.5 7.5" stroke="#47505B" stroke-linecap="round"
                                    stroke-linejoin="round" />
                              <path d="M2.5 2.5L7.5 7.5" stroke="#47505B" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                          </div>
                        </span>
                      </div>

                    </div>

                    <input type="text" maxLength="22" :placeholder="(skills && skills.length > 1) ? '' : 'Enter Your Skills'"
                           class="tag-input__text" @keydown.enter="addSkill" @keydown.188="addSkill" />
                  </div>
                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('skills')">{{
                      _handleErrorMessage("skills") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="label-title">Tools</label>
                  <div class="tag-input">
                    <div v-if="tools.length > 0">
                      <div v-for="(tool, index) in tools.filter(tool => tool.length > 0)" :key="`${tool}${index}`" class="tag-input__tag">
                        <span v-if="tool.length > 1">{{ tool }}</span>
                        <div class="svg-icon remove-tag" @click="removeTool(index)">
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 2.5L2.5 7.5" stroke="#47505B" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path d="M2.5 2.5L7.5 7.5" stroke="#47505B" stroke-linecap="round"
                                  stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <input type="text" maxLength="22" :placeholder="(tools && tools.length > 1) ? '' : 'Enter Your Tools'"
                           class="tag-input__text" @keydown.enter="addTool" @keydown.188="addTool" />
                  </div>
                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('tools')">{{
                      _handleErrorMessage("tools") }}</label>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="label-title">Languages</label>
                  <Select2 v-model="languages" placeholder="Languages" :options="languages_list"
                           :settings="{ multiple: true }" />
                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('languages')">{{
                      _handleErrorMessage("languages") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="save-btn">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class=""></div>
    </div>
    <full-page-loader :show="loading" />
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import {
  get_languages_api,
  degree_tool_skill_api,
  get_about_and_skills_api
} from "../../../apis/APIs";
import { EDIT_PROFILE_ERRORS, SUCCESS, ERROR } from "../../../store/types";
import Select2 from "v-select2-component";
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";

export default {
  data() {
    return {
      title: "",
      expertise_level: "",
      about: "",
      skills: [],
      tools: [],
      languages: [],

      message: "",
      success_message: "",

      languages_list_loading: false,
      languages_list: [],

      skills_list: [],
      tools_list: [],
      list_loading: false,
      loading: false,
      fetch_data: false
    };
  },
  components: {
    Select2,
    EyeIcon
  },
  created() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
    this.resetEditProfileState();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props"
    }),
    // loading() {
    //   const {loading = false} = this.form_data || {};
    //
    //   return loading;
    // },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions(["add_udpate_about_and_skills"]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
      "resetEditProfileState"
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
            this.errors[
              this.errors.findIndex((ele) => ele.fieldName === key)
              ].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    init() {
      this.fetchLanguagesData();
      this.fetchSkillsToolsData();
      this.initData();
    },
    async fetchLanguagesData() {
      try {
        this.languages_list_loading = true;
        this.loading = true;
        const res = await get_languages_api();
        const { success, message, data } = res.data || {};
        this.languages_list_loading = false;

        if (success === 1) {
          if (data && data.length)
            this.languages_list = data.map((ele) => ({
              id: ele.id,
              text: ele.native_name
            }));
        }
        this.loading = false;
      } catch (error) {
        this.languages_list_loading = false;
      }
    },
    async fetchSkillsToolsData() {
      try {
        this.list_loading = true;
        this.loading = true;
        const res = await degree_tool_skill_api();
        const { success, skills, tools } = res.data || {};
        this.list_loading = false;

        if (success === 1) {
          if (skills && skills.length)
            this.skills = skills.map((ele) => ({
              id: ele.id,
              text: ele.name
            }));
          if (tools && tools.length)
            this.tools = tools.map((ele) => ({
              id: ele.id,
              text: ele.name
            }));
        }
        this.loading = false;
      } catch (error) {
        this.list_loading = false;
      }
    },
    async initData() {
      try {
        this.fetch_data = true;

        const { user_data } = this.user || {};
        const { title, expertise_level, about, skills, tools, language } =
        user_data || {};

        this.title = title;
        this.expertise_level = expertise_level;
        this.about = about;

        if (skills && skills.length) this.skills = skills;
        if (tools && tools.length) this.tools = tools;
        if (language && language.length)
          this.languages = language.map((ele) => ele.id);

        // const query = `?id=${id}`;
        // const res = await get_about_and_skills_api(query);
        // const { success, information } = res.data || {};
        // this.fetch_data = false;

        // if(success === 1){
        //
        // }
      } catch (error) {
        this.fetch_data = false;
      }
    },
    onChange(e) {
      const { name, value } = e.target || {};

      console.log("this ===> name", name, value);

      this.updateEditProfileFormData({
        [name]: value
      });
    },
    submit(e) {
      if (e) e.preventDefault();

      const { user_data } = this.user || {};
      const { user_id } = user_data || {};

      const {
        loading,
        title,
        expertise_level,
        message,
        skills,
        tools,
        about,
        languages
      } = this;

      if (loading) return;

      let body = {
        title,
        expertise_level,
        about,
        skills,
        tools,
        languages
      };
      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: []
            });
            try {
              this.message = "";
              body.id = user_id;
              this.loading = true;
              const { status, message } =
                await this.add_udpate_about_and_skills(body);
              this.loading = false;
              switch (status) {
                case SUCCESS:
                  this.success_message = message;
                  await this.$store.dispatch("fetchUserData");
                  setTimeout(() => (this.success_message = ""), 5000);
                  break;
                case ERROR:
                  this.message = message;
                  break;
              }
            } catch (e) {
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]:
                response && response.length ? response : []
            });
        })
        .catch((err) => console.log(err));
    },
    addSkill(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        this.skills.push(val);
        event.target.value = "";
      }
    },
    removeSkill(index) {
      this.skills.splice(parseInt(index), 1);
    },
    addTool(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        this.tools.push(val);
        event.target.value = "";
      }
    },
    removeTool(index) {
      this.tools.splice(parseInt(index), 1);
    }
  }
};
</script>

<style scoped>
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  min-height: 30px;
  box-sizing: border-box;
  padding: 0 10px;
}

.tag-input__tag {
  height: 22px;
  float: left;
  margin-right: 5px;
  background-color: #D1433A1A;
  line-height: 22px;
  padding: 0 5px;
  border-radius: 2px;
  margin-top: 3px;
  font-size: 13px;
  font-family: HelveticaNeueNormal, sans-serif;
  font-weight: normal;
  letter-spacing: 0.5px;
}

.tag-input__tag::placeholder {
  font-size: 13px;
  font-family: HelveticaNeueNormal, sans-serif;
  font-weight: normal;
}

.svg-icon.remove-tag {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 13px;
  line-height: 27px;
  background: none;
  font-weight: normal;
  font-family: HelveticaNeueNormal, sans-serif;
  letter-spacing: 0.5px;
}

.select2-container--default .select2-search--inline .select2-search__field::placeholder {
  font-family: "HelveticaNeueNormal" !important;
  letter-spacing: 0.5px;
  font-weight: normal;
  font-size: 13px;
  color: #c4ccd6;
}
</style>
