<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.3335H3.33333C2.59695 7.3335 2 7.93045 2 8.66683V13.3335C2 14.0699 2.59695 14.6668 3.33333 14.6668H12.6667C13.403 14.6668 14 14.0699 14 13.3335V8.66683C14 7.93045 13.403 7.3335 12.6667 7.3335Z"
                fill="#47505B" stroke="#47505B" stroke-width="1.5"></path>
              <path
                d="M4.6665 7.3335V4.66683C4.6665 3.78277 5.01769 2.93493 5.64281 2.30981C6.26794 1.68469 7.11578 1.3335 7.99984 1.3335C8.88389 1.3335 9.73174 1.68469 10.3569 2.30981C10.982 2.93493 11.3332 3.78277 11.3332 4.66683V7.3335"
                stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Password</p>
          <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <form class="setting-form" @submit="submit">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <div class="row">

              <div class="col">
                <div class="form-group">
                  <label for="password">Confirm Current Password</label>
                  <div class="fixPasswordIcon">
                    <input :type="type.old_password" class="form-control width730" v-model="old_password"
                      placeholder="" />
                    <div class="icon-wrapper" @click="hideAndShowPassword('old_password')">
                      <img v-if="showPassword.old_password" :src="require(`@/assets/icons-files/hidep.png`)" />
                      <img v-else class="show_pass_icon" :src="require(`@/assets/icons-files/showp.png`)" />
                    </div>
                  </div>

                </div>

                <label class="error_msg error_msg_red" v-if="_handleErrorMessage('old_password')">{{
                  _handleErrorMessage("old_password") }}</label>

              </div>
            </div>

            <div class="row">

              <div class="col" style="margin-right: 39px;">
                <div class="form-group">
                  <label for="password">Your New Password</label>
                  <div class="fixPasswordIcon">
                    <input :type="type.password" class="form-control width330" v-model="password" placeholder="" />
                    <div class="icon-wrapper" @click="hideAndShowPassword('password')">
                      <img v-if="showPassword.password" class="hide-password"
                        :src="require(`@/assets/icons-files/hidep.png`)" />
                      <img v-else :src="require(`@/assets/icons-files/showp.png`)" />
                    </div>
                  </div>

                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('password')">{{
                    _handleErrorMessage("password") }}</label>
                </div>
              </div>

              <div class="col">

                <div class="form-group">
                  <label for="password">Confirm New Password</label>

                  <div class="fixPasswordIcon">
                  <input :type="type.confirm_password" class="form-control width330" v-model="confirm_password"
                    placeholder="" />
                  <div class="icon-wrapper" @click="hideAndShowPassword('confirm_password')">
                    <img v-if="showPassword.confirm_password" class="hide-password"
                      :src="require(`@/assets/icons-files/hidep.png`)" />
                    <img v-else :src="require(`@/assets/icons-files/showp.png`)" />
                  </div>
                </div>

                  <label class="error_msg error_msg_red" v-if="_handleErrorMessage('confirm_password')">{{
                    _handleErrorMessage("confirm_password") }}</label>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="save-btn">{{ loading ? "Please Wait..." : "Save Changes" }}</button>
              </div>
            </div>
          </form>
        </div>

      </div>
      <div class=""></div>
    </div>
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import { EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR } from '../../../store/types';
import EyeIcon from '../../../assets/svgs/EyeIcon.vue';

export default {
  components: {
    EyeIcon
  },
  data() {
    return {
      password: "",
      old_password: "",
      confirm_password: "",
      message: "",
      success_message: "",
      type: {
        old_password: 'password',
        password: 'password',
        confirm_password: 'password'
      },
      showPassword: {
        old_password: false,
        password: false,
        confirm_password: false
      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.resetEditProfileState();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props"
    }),
    loading() {
      const { loading = false } = this.form_data || {};

      return loading;
    },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    }
  },
  methods: {
    hideAndShowPassword(field) {
      if (this.showPassword[field]) {
        this.type[field] = 'password';
        this.showPassword[field] = false;
      } else {
        this.type[field] = 'text';
        this.showPassword[field] = true;
      }
    },
    ...mapActions([
      "change_password"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
      "resetEditProfileState"
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    submit(e) {
      if (e) e.preventDefault();

      const { loading, password, old_password, confirm_password } = this;

      if (loading) return;

      let body = {
        password,
        confirm_password,
        old_password
      };

      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: []
            });
            try {
              this.message = "";
              const user = this.$store && this.$store.state[USER_ROOT] ? this.$store.state[USER_ROOT] : undefined;
              const user_key = user && user[USER_KEY] ? user[USER_KEY] : undefined;
              const user_data = user_key && user_key[USER_DATA] ? user_key[USER_DATA] : undefined;

              const email = user_data && user_data.email ? user_data.email : "";

              body.email = email;

              if (!email) {
                this.message = "Email address is not found.";
                return;
              }

              const { status, message } = await this.change_password(body);

              switch (status) {
                case SUCCESS:
                  this.success_message = message;
                  this.password = "";
                  this.old_password = "";
                  this.confirm_password = "";

                  setTimeout(() => {
                    this.success_message = ""
                  }, 5000);
                  break;
                case ERROR:
                  this.message = message;
                  break;
              }
            } catch (e) {
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else this.updateEditProfileUIConstraints({
            [EDIT_PROFILE_ERRORS]: response && response.length ? response : []
          });
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
