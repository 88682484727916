<template>
  <div class="wrapVisibility">
    <h3 class="visibeFont">Visibility</h3>
    <p style="font-size: 13px;" >Who can see your event?</p>
    <div class="wrPubPri">
      <div class="evenPubPri" :class="{ for_border: !activeEvent }"
        @click="activeEvent ? (activeEvent = !activeEvent) : activeEvent">
        <div class="wrap-icon" :class="{ for_bg: !activeEvent }">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.0013 18.3333C14.6037 18.3333 18.3346 14.6023 18.3346 9.99996C18.3346 5.39759 14.6037 1.66663 10.0013 1.66663C5.39893 1.66663 1.66797 5.39759 1.66797 9.99996C1.66797 14.6023 5.39893 18.3333 10.0013 18.3333Z"
              :stroke="activeEvent ? '#47505B' : '#fff'" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M1.66797 10H18.3346" :stroke="activeEvent ? '#47505B' : '#fff'" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M10.0013 1.66663C12.0857 3.94859 13.2703 6.90999 13.3346 9.99996C13.2703 13.0899 12.0857 16.0513 10.0013 18.3333C7.9169 16.0513 6.73234 13.0899 6.66797 9.99996C6.73234 6.90999 7.9169 3.94859 10.0013 1.66663V1.66663Z"
              :stroke="activeEvent ? '#47505B' : '#fff'" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div>
          <div class="activEevent">
            <h6>Public Event</h6>
            <svg v-if="!activeEvent" width="16" height="12" viewBox="0 0 16 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6654 1L5.4987 10.1667L1.33203 6" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <p>
            Anyoner can see the event, its users and posts.
          </p>
        </div>
      </div>
      <div class="evenPubPri" :class="{ for_border: activeEvent }"
        @click="!activeEvent ? (activeEvent = !activeEvent) : activeEvent">
        <div class="wrap-icon" :class="{ for_bg: activeEvent }">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.8333 9.16663H4.16667C3.24619 9.16663 2.5 9.91282 2.5 10.8333V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V10.8333C17.5 9.91282 16.7538 9.16663 15.8333 9.16663Z"
              :stroke="!activeEvent ? '#47505B' : '#fff'" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M5.83203 9.16663V5.83329C5.83203 4.72822 6.27102 3.66842 7.05242 2.88701C7.83382 2.10561 8.89363 1.66663 9.9987 1.66663C11.1038 1.66663 12.1636 2.10561 12.945 2.88701C13.7264 3.66842 14.1654 4.72822 14.1654 5.83329V9.16663"
              :stroke="!activeEvent ? '#47505B' : '#fff'" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div>
          <div class="activEevent">
            <h6>Private Event</h6>
            <svg v-if="activeEvent" width="16" height="12" viewBox="0 0 16 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6654 1L5.4987 10.1667L1.33203 6" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <p>
            Only invited and confirmed users can see posts.
          </p>
        </div>
      </div>
    </div>
    <h5 class="visibeFont">Invites</h5>
    <div>
      <p style="font-size: 13px;font-family: HelveticaNeueMedium, sans-serif">
        Are there specific people that you would like to invite to this event?
      </p>
      <span>Use this field to add specific users only to this event</span>
    </div>
    <div class="wrapInvest">
      <h6>Invites</h6>
      <Select2 v-model="info.selectedInvites" placeholder="User" :options="select_item_for_invites"
        :settings="{ multiple: true }" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";

export default {
  name: "Visibility",
  components: {
    // VueTagsInput,
    Select2,
  },
  data() {
    return {
      InvitesPopup: false,
      activeEvent: false,
      tag: "",
      info: {
        selectedInvites: [],
        privacy: !this.activeEvent ? "public_event" : "private_event",
      },
      select_item_for_invites: [],
    };
  },
  methods: {
    ...mapActions(["getAllUserListForSendInvites"]),
    handleVisibility() {
      this.$emit("setNewDataEvent", this.info);
    },
    // removeInvites(id) {
    //   this.info.selectedInvites = this.info.selectedInvites.filter(
    //     (elem) => elem.id !== id
    //   );
    // },
    // hideInvitesPopup() {
    //   this.InvitesPopup = false;
    // },
    // handleInvites(invites) {
    //   if (this.info.selectedInvites.some((elem) => elem.id === invites.id)) {
    //     this.InvitesPopup = false;
    //     return;
    //   } else {
    //     this.info.selectedInvites.push(invites);
    //   }
    //   this.InvitesPopup = false;
    // },
  },
  computed: {
    ...mapGetters(["GET_USER_LIST_FOR_SEND_INVITES"]),
  },
  async created() {
    await this.getAllUserListForSendInvites(
      this.$store.getters.user_map_to_props.user_data.id
    );
    this.GET_USER_LIST_FOR_SEND_INVITES.forEach((el) => {
      this.select_item_for_invites.push({ id: el.id, text: el.name });
    });
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-size: 10px !important;
  color: red !important;
  font-family: HelveticaNeueBold;
}

.wrapVisibility {
  margin: 22px 0 0 0;

  h3,
  h5,
  h6 {
    font-family: HelveticaNeueBold;
  }

  h6 {
    font-size: 13px;
    margin-bottom: 6px;
  }

  h3,
  h5 {
    color: #071526;
  }

  p {
    font-family: HelveticaNeueNormal;
    color: #47505b;
    font-size: 11px;
    margin: 0;
  }

  span {
    font-family: HelveticaNeueNormal;
    font-size: 13px;
    color: #8b949f;
  }

  .wrPubPri {
    margin: 13px 0 22px 0;
    display: flex;
    justify-content: space-between;

    .evenPubPri {
      cursor: pointer;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      display: flex;
      padding: 10px 10px 10px 10px;
      width: 340px;
align-items: center;

      .wrap-icon {
        margin: 0 22px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #f3f3f3;
      }
    }
  }

  .for_bg {
    background: #D1433A !important;
  }

  .for_border {
    border: 1px solid #D1433A !important;

    h6 {
      color: #071526;
    }
  }

  .activEevent {
    display: flex;
    justify-content: space-between;
  }

  .wrapInvest {
    margin: 20px 0 0 0;
  }
}

.vue-tags-input {
  max-width: 100% !important;
}

.invites-select-wraper {
  display: flex;
  flex-wrap: wrap;
}

.left {
  background-color: #fff;
  width: 770px;
  max-width: 100%;

  &-title {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;

    p {
      color: #47505b;
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueBold;
      margin-bottom: 7px;
    }

    input {
      max-width: 100%;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      height: 40px;
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueBold;
      padding-left: 10px;
    }

    input::placeholder {
      color: rgba(7, 21, 38, 0.6);
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
    }
  }

  &-date {
    justify-content: space-between;
    margin-top: 17px;
  }

  &-select {
    justify-content: space-between;
    margin-top: 17px;

    &-co {
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      max-width: 100%;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 13px 0 10px;
      cursor: pointer;
      position: relative;

      span,
      p {
        color: rgba(7, 21, 38, 0.6);
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueMedium;
      }

      span {
        background: #e6eaf0;
        border-radius: 2px;
        min-width: 80px;
        height: 20px;
        padding: 0 7px 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 3px;
      }

      .invites-popup {
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
        border-radius: 4px;
        width: 100%;
        position: absolute;
        z-index: 999;
        padding: 10px 0;
        left: 0;
        top: 0;

        .ps {
          max-height: 310px;
        }

        .item {
          justify-content: space-between;
          color: #071526;
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueNormal;
          padding: 7px 20px;
          transition: all 0.3s;

          &:hover {
            background: #D1433A1A;
            color: #D1433A;
          }
        }
      }
    }
  }

  &-editor {
    margin-top: 27px;
  }

  &-actions {
    display: flex;
    margin-top: 30px;

    button {
      height: 40px;
      font-size: 12px;
      line-height: 16px;
      font-family: HelveticaNeueBold;
      border-radius: 4px;
      text-transform: uppercase;
    }

    .create {
      background: #D1433A;
      width: 130px;
      max-width: 100%;
      color: #fff;
      border: none;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      svg {
        margin-left: 8px;
      }
    }

    .SaveDraft {
      border: 1px solid #D1433A;
      color: #D1433A;
      width: 130px;
      background: #ffffff;
    }

    .cancel {
      border: none;
      outline: none;
      width: 130px;
      max-width: 100%;
      background: #e6eaf0;
      color: #47505b;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style>
.wrapVisibility .ti-icon-close:before {
  content: "\2716";
  font-size: 15px;
  margin: 0 0 0 5px;
}
</style>
