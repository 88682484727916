<template>
  <div class="video_image_right">
    <div class="single_post_comment_details">
      <div class="post_user_details">
        <div class="user_detail_img">
          <UserPicture :picture="(item.user && item.user.artwork_url) ? item.user.artwork_url : ''"/>
        </div>
        <div class="user_image_content">
          <div class="user_name_link">
            <h1> {{ name }} </h1>
            <p v-if="data_photos_length > 1" class="link_share"> added {{
                data_photos_length
              }} photos </p>
            <p v-if="data_photos_length <= 1" class="link_share"> added an image</p>
            <PostDetailDropDown class="detail-dropdown"  :post="item" style="margin-right: 32px"/>
          </div>
          <div class="hr_globe">
            <p>{{ time }}</p>
            <span>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                    stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
                <path d="M1 7H13" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                    stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div v-if="!is_edit_post" class="artist-timeline-description">
          <span v-html="(item.full_content === 'null') ? '' : item.full_content"></span>
      </div>
      <input v-if="is_edit_post" v-model="edit_post" placeholder="Edit post" type="text"
             @click="stop_propagation($event)"/>
      <button v-if="is_edit_post" type="button" @click="editSubmit($event)">Edit Post</button>
    </div>
    <div>
      <div class="activity-main" v-if="!sharedPost">
        <PostLike :index="index" :item="item" :page_type="'single_post'"/>
        <div @click="toggleCommentSection($event)">
          <PostComment v-if="item.allow_comments === 1" :post="item" :comments_count="comment_count" :active_check="write_comment"/>
        </div>
        <PostShare :post="item" :margin="'margin-top: 0px'" />
      </div>
      <div class="comments_Tabs">
        <nav>
          <div id="nav-tab1" role="tablist" class="nav top-tab nav-tabs1">
            <a id="nav-upcoming-tab" data-toggle="tab" href="#nav-upcoming" role="tab"
               aria-controls="nav-home" aria-selected="false"
               class="nav-item nav-link active">COMMENTS
              <span class="event_counting">{{ postCommentsCount }}</span>
            </a>
            <a id="nav-past-tab" data-toggle="tab" href="#nav-past" role="tab"
               aria-controls="nav-profile" aria-selected="false"
               class="nav-item nav-link">RELATED
              <span class="event_counting">{{ postsRelatedCount }}</span>
            </a>
          </div>
        </nav>
        <div id="myTabContent" class="tab-content">
          <div id="nav-upcoming" role="tabpanel" aria-labelledby="nav-upcoming-tab"
               class="tab-pane comit-lists fade active show scroller">
            <!-- Comment List Start -->
            <single-post-comments :item="item" ref="commentList" :input_check="true"/>
            <!-- Comment List End -->
          </div>

          <div id="nav-past" role="tabpanel" aria-labelledby="nav-past-tab" class="tab-pane fade comit-lists">
            <!--              xyz-->
          </div>
        </div>
      </div>
    </div>
     <div class="comment-dialogue" v-if="write_comment">
            <CommentInput :item="item" :comment_check="comment_check" @comments_count="commentsCount" @addComment="addCommentInList" :design_check="true"/>
      </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppConfig from "../../../../configs/AppConfig";
import moment from "moment-timezone";
import {SUCCESS} from "../../../../store/types";
import {create_post_api, post_comment_form_data_api} from "../../../../apis/APIs";
import CommentInput from "../PostItem/CommentInput";
import PostLike from "../PostItem/PostLike";
import PostComment from "../PostItem/PostComment";
import PostShare from "../PostItem/PostShare";
import VoteItem from "../PostItem/VoteItem";
import PostDetailDropDown from "../PostItem/PostDetailDropDown";
import UserPicture from "../components/UserPicture";
import SinglePostComments from "./SinglePostComments";

export default {
  name: "SinglePostForImage",
  mounted() {
    this.init();
  },
  data() {
    return {
      write_comment: false,
      comment_count: 0,
      related_count: 0,
      is_drop_down_open: false,

      edit_post: "",
      is_edit_post: false,
      edit_post_loading: false,

      is_edit_poll_vote_local: false,

      selected_poll_option: undefined,
      change_vote_count: 0
    };
  },
  watch: {
    // comment_count(value) {
    //   if (this.$parent && this.$parent.increment_comment_count) this.$parent.increment_comment_count(value);
    // },
    item(value) {
      this.init();
    }
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    postCommentsCount(){
      if ( this.comment_count > this.item.comment_count ) {
        return this.comment_count
      } else {
        return this.item.comment_count
      }
    },
    postsRelatedCount(){
      return this.related_count;
    },
    isPostIsActivity() {
      return !!(this.activities.some(e => e.title === this.post_type))
    },
    activities() {
      return this.$store.getters.getPostsActivities
    },
    postContainPredefinedClass() {
      if (this.item.post_type === 'coloured') {
        return this.item.post_type_description
      }
      return ' '
    },
    postHasPredefinedClass() {
      if (this.item.post_type === 'coloured') {
        return !(this.item.post_type_description.includes('#'))
      }
      return false
    },
    post_type() {
      const {post_type} = this.item || {};

      return post_type;
    },
    photos() {
      const {photo} = this.item;

      return photo && photo.length ? photo : [];
    },
    poll_options() {
      const {options} = this.item;

      return options && options.length ? options : [];
    },
    data_photos() {
      const {artwork_url} = this.item;

      return artwork_url && artwork_url.length ? artwork_url.map(ele => Object.assign(ele, {image: `${AppConfig.post_image_url}/${ele.source}`})) : [];
    },
    data_video() {
      const {video} = this.item;

      return video && video.post_id ? Object.assign(video, {video: `${AppConfig.post_image_url}/${video.source}`}) : {};
    },
    data_photos_length() {
      const {artwork_url} = this.item;

      return artwork_url && artwork_url.length ? artwork_url.length : 0;
    },
    name() {
      const {user_name} = this.item || {};
      return `${user_name}`
    },
    user_picture() {
      const {user} = this.item || {};

      return (user && user.artwork_url) ? user.artwork_url : ''
    },
    time() {
      const {created_at} = this.item || {};
      return moment(created_at).fromNow();
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    expandable: {
      type: Boolean,
      default: false
    },
    comment_check: {
      type: Boolean,
      default: false
    },
    sharedPost: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CommentInput,
    PostLike,
    PostComment,
    PostShare,
    PostDetailDropDown,
    UserPicture,
    SinglePostComments
  },
  methods: {
    ...mapActions([
      "get_post_data"
    ]),
    init() {
      const {comment_count} = this.item || {};
      this.comment_count = comment_count;
    },
    pushPostDetail: async function (id) {
      await this.$store.dispatch('getActivities');
      this.$store.commit('SET_POST_OFFSET', 0)
      const {status} = await this.$store.dispatch('getIndividualPost', id);
      if (status == SUCCESS) {
        const pathname = `/home/page/post-detail/${id}`;
        if (this.expandable) return;
        await this.$router.push(pathname);
      }
    },
    open_post_image_detail: async function (id) {
      await this.$store.dispatch('getActivities');
      this.$store.commit('SET_POST_OFFSET', 0)
      const {status} = await this.$store.dispatch('getIndividualPost', id);
      if (status == SUCCESS) {
        const pathname = `/home/page/feed-image/${id}`;
        if (this.expandable) return;
        await this.$router.push(pathname);
      }
    },
    openPostNewTab() {
      const {post_id} = this.item;

      const pathname = `/home/page/post-detail/${post_id}`;
      window.open(pathname);
    },
    toggleCommentSection(e) {
      if (e) e.stopPropagation();

      this.write_comment = !this.write_comment
    },
    editPost() {
      this.is_edit_post = true;
      this.edit_post = this.item && this.item.text ? this.item.text : "";
    },
    cancelEditPost() {
      this.is_edit_post = false;
      this.edit_post = "";
    },
    incrementCommentCount() {
      this.comment_count = parseInt(this.comment_count) + 1;
    },
    async submit() {

      const {edit_post, edit_post_loading} = this;

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      return;

      if (edit_post_loading || (!edit_post)) return;

      try {

        const query = `query`;

        this.edit_post_loading = true;
        this.message = "";

        const {success, message} = await post_comment_form_data_api(query);
        this.edit_post_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.init();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

      } catch (error) {
        this.edit_post_loading = false;
      }
    },
    async editSubmit(e) {
      if (e) e.stopPropagation();
      const {edit_post, edit_post_loading} = this;
      const {post_id} = this.item || {};

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      // if(edit_post_loading || (!edit_post)) return;

      try {

        const query = `?action=2&user_id=${user_id}&post_id=${post_id}&text=${edit_post}`;

        this.edit_post_loading = true;
        this.message = "";

        const {success, message} = await create_post_api(query);
        this.edit_post_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.$parent.init();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

      } catch (error) {
        console.log("post edit ===> error", error);

        this.edit_post_loading = false;
      }
    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.submit();
      }
    },
    async deleteSubmit(e) {
      if (e) e.stopPropagation();

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      const {post_id} = this.item || {};

      try {

        let url = `?action=3&post_id=${post_id}`;

        const {success, message} = await create_post_api(url);

        if (success === 1) {
          this.$parent.init();
        } else {
        }

      } catch (error) {
      }
    },
    getChangeVoteCountAction(item) {
      const {selected_poll_option} = this;

      const {option_id} = item;

      if (selected_poll_option === undefined) return 0;

      if (typeof selected_poll_option === "string") {
        return option_id === selected_poll_option ? 1 : -1;
      }

      return 0;
    },
    select_poll_option(value) {
      this.selected_poll_option = value;
    },
    open_post_video_detail() {
      const {id} = this.item || {};

      if (!this.expandable) return;

      this.$router.push(`/home/page/feed-video/${id}`)
    },
    stop_propagation(e) {
      if (e) e.stopPropagation();
    },
    getEmoji(feeling) {
      const activityIndex = this.activities.findIndex(e => e.title === 'Feeling')
      if (activityIndex !== null) {
        if (this.activities[activityIndex].emoticons) {
          const feelingIndex = this.activities[activityIndex].emoticons.findIndex(ele => ele.title === feeling)
          if (feelingIndex !== -1) {
            return (this.activities[activityIndex].emoticons[feelingIndex]) ? this.activities[activityIndex].emoticons[feelingIndex].artwork_url : false
          }

        }
      }
      return false
    },
    reset_data() {
      this.edit_post = "";
      this.is_edit_post = false;
      this.edit_post_loading = false;
    },
    commentsCount(comments_count) {
      if (comments_count > this.comment_count ) {
        this.comment_count = comments_count
        this.$emit('comment_count', this.comment_count)
      }
    },
    addCommentInList(comment) {
      this.$refs.commentList.addCommentInList(comment)
    }
  }
}
</script>

<style scoped>

.detail-dropdown {
  z-index: 10;
  cursor: pointer;
}

.artist-timeline-description {
  width: 341px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}

.comment-dialogue {
  position: absolute;
  bottom: 1rem;
  width: 350px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #E6EAF0;
  border-radius: 5px;
  padding: 1.5%;
}

.scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 526px;
  margin-top: 2rem;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C4CCD6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
</style>
