<template>
  <div>
    <div
      v-if="do_in_a_few_second"
      class="overview-event-card"
    >
      <router-link :to="{ name: 'events.Detail', params: { id: event.id } }">
        <div
          class="overview-event-card-image-section"
          @mouseover="cover = true"
          @mouseleave="cover = false"
        >
          <img :src="event.artwork_url" alt="Card Img" />
          <div class="overview-events-card-image-overlay" v-if="cover"></div>
          <div
            v-if="
              event.genres && event.genres.length > 0 && event.genres[0].name
            "
            class="overview-events-card-image-genre"
            @click.prevent="moodPopup = !moodPopup"
          >
            <span>#{{ event.genres[0].name }}</span>
          </div>
        </div>
      </router-link>
      <div class="overview-event-card-body-section">
        <div>
          <div class="overview-event-card-date-and-count-section">
            <div class="overview-event-card-date">{{ date(event.started_at) }}</div>
            <div class="overview-event-card-count">
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>{{ event.going + event.interested }}</span>
            </div>
          </div>

        </div>
        <div class="overview-event-card-title-section mt-2">
            <span class="overview-event-card-title">
              {{
                event.title.length > 18
                  ? event.title.slice(0, 18) + "..."
                  : event.title
              }}
            </span>
        </div>
        <div v-if="!$route.path.includes('/page/my_Tickets')"
          class="overview-event-card-buttons mt-2">
          <div
            class="overview-event-card-interested-btn"
            :class="{ active: event.user_interest === 'interested' }"
            @click="
              InterestedGoing({ event_id: event.id, interest: 'interested' })
            "
          >
            Interested
          </div>
          <div
            :class="{ active: event.user_interest === 'going' }"
            class="overview-event-card-going-btn"
            @click="InterestedGoing({ event_id: event.id, interest: 'going' })"
          >
            Going
          </div>
        </div>
        <div v-if="$route.path.includes('/page/my_Tickets')" class="wr_btns">
          <button
            @click="showTicket({ ticket_id: ticket_id, order_id: order_id })"
            :class="{ remaining: event.eventtype.title === 'Livestreaming' }"
            class="show_ticket mt-2"
          >
            {{ goSingleBtnContent }}
          </button>
          <button
            @click="moreOrPrint({ ticket_id: ticket_id, order_id: order_id })"
            :class="{ more: event.eventtype.title === 'Livestreaming' }"
            class="print"
          >
            {{ event.eventtype.title === "Livestreaming" ? "MORE" : "PRINT" }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!do_in_a_few_second"
      class="events-card wr_do_in_a_few_second"
      :class="{ no_margin_right: no_margin_right }"
    >
      <div class="icon_div">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.7332 39.7311C47.8692 31.5952 47.8692 18.4043 39.7332 10.2684C31.5973 2.13244 18.4064 2.13244 10.2705 10.2684C2.13453 18.4043 2.13453 31.5952 10.2705 39.7311C18.4064 47.8671 31.5973 47.8671 39.7332 39.7311Z"
            stroke="#D1433A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.8945 30.8923L30.8945 19.1072L19.1094 19.1072"
            stroke="#D1433A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.1094 30.8914L30.8945 19.1062"
            stroke="#D1433A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h5>Event was moved to Interested</h5>
      <span>You can cancel it in {{ do_10_second }}s..</span>
      <button @click.stop="cansel_change_favorites_type">Cancel</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  props: ["event", "ticket_id", "order_id", "no_margin_right",'mRight'],
  data() {
    return {
      moodPopup: false,
      cover: false,
      do_in_a_few_second: true,
      do_10_second: 10,
      timer_id_for_interval: "",
      timer_id_for_timeout: "",
      goSingleBtnContent: "",
    };
  },
  methods: {
    ...mapActions(["InterestedGoing", "getSingleTicket"]),
    date(date) {
      return moment(date).format("MMM D");
    },
    async interestedGoing(payload) {
      if (this.$route.path.includes("/page/favorites")) {
        this.do_in_a_few_second = !this.do_in_a_few_second;
        this.timer_id_for_interval = setInterval(() => {
          this.do_10_second -= 1;
        }, 1000);
        this.timer_id_for_timeout = setTimeout(() => {
          clearInterval(this.timer_id_for_interval);
          this.changeMyFavoritesData(payload);
        }, 10000);
      } else {
        await this.$store.dispatch("loading", true);
        await this.InterestedGoing(payload);
        await this.$store.dispatch("loading", false);
      }
    },
    async changeMyFavoritesData(payload) {
      await this.$store.dispatch("loading", true);
      await this.InterestedGoing(payload);
      await this.$store.dispatch("loading", false);
      this.$emit("getFavoritesData");
    },
    cansel_change_favorites_type() {
      clearInterval(this.timer_id_for_interval);
      clearTimeout(this.timer_id_for_timeout);
      this.do_10_second = 10;
      this.do_in_a_few_second = !this.do_in_a_few_second;
    },
    async showTicket(params) {
      if (this.event.eventtype.title != "Livestreaming") {
        await this.$store.dispatch("loading", true);
        await this.getSingleTicket({
          order_id: params.order_id,
          ticket_id: params.ticket_id,
        });
        await this.$store.dispatch("loading", false);
        this.$router.push({
          name: "mySingle.Ticket",
          params: {
            order_id: params.order_id,
            ticket_id: params.ticket_id,
          },
        });
      }
    },
    async moreOrPrint(params) {
      if (this.event.eventtype.title === "Livestreaming") {
        await this.$store.dispatch("loading", true);
        await this.getSingleTicket({
          order_id: params.order_id,
          ticket_id: params.ticket_id,
        });
        await this.$store.dispatch("loading", false);
        this.$router.push({
          name: "mySingle.Ticket",
          params: {
            order_id: params.order_id,
            ticket_id: params.ticket_id,
          },
        });
      }
    },
    go_Single_Btn_Content(payload) {
      if (payload.eventtype.title === "Livestreaming") {
        if (
          new Date(payload.started_at).getTime() <
            new Date(payload.end_at).getTime() &&
          new Date(payload.end_at).getTime() > new Date().getTime()
        ) {
          this.goSingleBtnContent = "STARTED";
        } else if (new Date(payload.end_at).getTime() < new Date().getTime()) {
          this.goSingleBtnContent = "PAST";
        } else {
          this.goSingleBtnContent =
            this.dateFormatFromNow(payload.started_at).slice(2) + " remaining";
        }
      } else {
        this.goSingleBtnContent = "TICKET INFO";
      }
    },
  },
  created() {
    this.go_Single_Btn_Content(this.event);
  },
  destroyed() {
    clearInterval(this.timer_id_for_interval);
    clearTimeout(this.timer_id_for_timeout);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/events/eventscard.scss";


.events-card {
  width: 170px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 17px;
  &:hover {
    box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
  }
  & .mood-popup {
    width: 100%;
    border: 1px solid #e6eaf0;
    box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
    border-radius: 4px;
    background: #fff;
    display: flex !important;
    padding: 11px 10px 9px;
    position: absolute;
    justify-content: space-between;
    top: 0;
    right: 0;
    .moods {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #47505b;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 9px;
      background: rgba(196, 204, 214, 0.5);
    }
  }
  &-img {
    width: 100%;
    height: 170px;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    position: relative;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #47505b, $alpha: 0.2);
    }
    .mood {
      font-family: HelveticaNeueNormal;
      padding: 5px 8px 5px 12px;
      position: absolute;
      z-index: 0;
      bottom: 10px;
      right: 10px;
      background: #8b949f;
      border-radius: 4px;
      color: #ffffff;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
    }
  }
  &-body {
    padding: 10px;
    position: relative;
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      &-date {
        color: #D1433A;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        font-family: HelveticaNeueBold;
        text-transform: uppercase;
      }
      &-description {
        background: #ae4ea9;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 5px 5px 5px 8px;
        p {
          color: #ffffff;
          font-size: 12px;
          line-height: 15px;
          font-family: HelveticaNeueNormal;
          margin-left: 5px;
        }
      }
    }
    .description {
      margin-bottom: 10px;
      h3 {
        color: #071526;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        font-family: HelveticaNeueBold;
        margin-bottom: 0;
      }
    }
  }
  .active_btn {
    background: #D1433A;
    color: #ffffff;
    &:hover {
      background: #DA6961 !important;
    }
  }
}
.no_margin_right {
  margin-right: 0 !important;
}
.wr_do_in_a_few_second {
  height: 284px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 10px 9px;
  .icon_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(213, 232, 252, 0.6);
  }
  h5 {
    margin: 30px 0 0 0;
    font-family: HelveticaNeueMedium;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #071526;
  }
  span {
    width: 85px;
    font-family: HelveticaNeueNormal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #47505b;
    letter-spacing: 0.7px;
    margin: 10px 0 35px;
  }
  button {
    width: 100%;
    background: #e6eaf0;
    border-radius: 4px;
    color: #47505b;
    font-weight: 500;
    font-size: 11px;
    font-family: HelveticaNeueMedium;
    height: 30px;
    border: none;
    outline: none;
    &:hover {
      background: #D1433A1A;
    }
  }
}
.wr_btns {
  // width: 100%;
  display: flex;
  height: 30px;
  justify-content: space-between;
  button {
    background-color: #ffffff;
    border: 1.5px solid #D1433A;
    border-radius: 2px;
    color: #D1433A;
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    font-family: HelveticaNeueBold;
  }
  .print {
    margin: 0 0px 0 10px;
    padding: 0 10px;
    &:hover {
      background-color: #D1433A1A;
    }
  }
  .show_ticket {
    width: 89px;
    height: 100%;
    background-color: #D1433A;
    color: #fff;
    &:hover {
      background-color: #DA6961;
    }
  }
  .remaining {
    width: 89px;
    background-color: #e6eaf0;
    color: #999999;
    border: 1.5px solid #e6eaf0;
    cursor: unset;
    &:hover {
      background-color: #e6eaf0;
    }
  }
  .more {
    background-color: #D1433A;
    color: #fff;
    &:hover {
      background-color: #DA6961;
    }
  }
}
</style>
