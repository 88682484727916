<template>
  <div class="about-work about-section-page aboutMarginBottomFix">
    <div class="row">
      <div class="col">
        <div class="about-work-text ">
          <h1 class="about-title fixAboutTitle">Social links</h1>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="about_listing">
          <div class="about-more-details">
               <span>
                 <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0 7.54188C0 11.2706 2.70813 14.3713 6.25 15V9.58313H4.375V7.5H6.25V5.83313C6.25 3.95813 7.45813 2.91687 9.16687 2.91687C9.70813 2.91687 10.2919 3 10.8331 3.08313V5H9.875C8.95813 5 8.75 5.45813 8.75 6.04187V7.5H10.75L10.4169 9.58313H8.75V15C12.2919 14.3713 15 11.2712 15 7.54188C15 3.39375 11.625 0 7.5 0C3.375 0 0 3.39375 0 7.54188Z"
                        fill="#8B949F"/>
                  </svg>
               </span>
            <p>{{contact.facebook}}</p>
          </div>
          <div class="about-more-details">
               <span>
                  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M15.6663 2.3387C15.5758 2.01552 15.3993 1.72293 15.1557 1.49204C14.9052 1.25404 14.5982 1.08379 14.2637 0.997368C13.0117 0.667368 7.99568 0.667368 7.99568 0.667368C5.90457 0.643577 3.81397 0.748218 1.73568 0.980702C1.40114 1.07351 1.09472 1.24756 0.843679 1.48737C0.597012 1.7247 0.418346 2.01737 0.325012 2.33804C0.100808 3.54586 -0.00812629 4.77226 -0.000320966 6.0007C-0.00832097 7.22804 0.100346 8.45404 0.325012 9.66337C0.416346 9.9827 0.594346 10.274 0.841679 10.5094C1.08901 10.7447 1.39701 10.9147 1.73568 11.0047C3.00435 11.334 7.99568 11.334 7.99568 11.334C10.0895 11.3579 12.1827 11.2532 14.2637 11.0207C14.5982 10.9343 14.9052 10.764 15.1557 10.526C15.4023 10.2907 15.5777 9.99804 15.6657 9.67937C15.8957 8.472 16.0076 7.2451 15.9997 6.01604C16.017 4.78177 15.9053 3.54908 15.6663 2.33804V2.3387ZM6.40101 8.28337V3.7187L10.5743 6.00137L6.40101 8.28337Z"
    fill="#8B949F"/>
</svg>
               </span>
            <p>{{contact.youtube}}</p>
          </div>
          <div class="about-more-details">
               <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M15.36 0H0.64C0.286 0 0 0.286 0 0.64V15.36C0 15.714 0.286 16 0.64 16H15.36C15.714 16 16 15.714 16 15.36V0.64C16 0.286 15.714 0 15.36 0ZM4.746 13.634H2.372V5.998H4.746V13.634ZM3.56 4.954C3.28785 4.954 3.02182 4.8733 2.79554 4.7221C2.56925 4.57091 2.39289 4.356 2.28874 4.10457C2.1846 3.85314 2.15735 3.57647 2.21044 3.30956C2.26353 3.04264 2.39458 2.79746 2.58702 2.60502C2.77946 2.41258 3.02464 2.28153 3.29156 2.22844C3.55847 2.17535 3.83514 2.2026 4.08657 2.30674C4.338 2.41089 4.55291 2.58725 4.7041 2.81354C4.8553 3.03982 4.936 3.30585 4.936 3.578C4.934 4.338 4.318 4.954 3.56 4.954ZM13.634 13.634H11.262V9.92C11.262 9.034 11.246 7.896 10.028 7.896C8.794 7.896 8.604 8.86 8.604 9.856V13.634H6.234V5.998H8.51V7.042H8.542C8.858 6.442 9.632 5.808 10.788 5.808C13.192 5.808 13.634 7.39 13.634 9.446V13.634Z"
    fill="#8B949F"/>
</svg>

               </span>
            <p>{{contact.linked_in}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about_listing">
          <div class="about-more-details">
               <span>
                  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M14.7767 2.0872C14.2548 2.31845 13.6942 2.4747 13.1048 2.54532C13.7129 2.18144 14.1679 1.60874 14.3848 0.934074C13.8135 1.27344 13.1882 1.51232 12.5361 1.64032C12.0976 1.17211 11.5167 0.861778 10.8838 0.757495C10.2508 0.653213 9.60114 0.760819 9.0356 1.06361C8.47006 1.3664 8.02031 1.84743 7.75617 2.43201C7.49203 3.0166 7.42828 3.67204 7.57482 4.29657C6.41713 4.23845 5.28459 3.93754 4.25071 3.41339C3.21684 2.88924 2.30472 2.15355 1.57357 1.25407C1.32357 1.68532 1.17982 2.18532 1.17982 2.71782C1.17955 3.1972 1.29759 3.66923 1.5235 4.09203C1.7494 4.51484 2.07618 4.87535 2.47482 5.14157C2.0125 5.12686 1.56037 5.00194 1.15607 4.7772V4.8147C1.15603 5.48704 1.38859 6.13869 1.81431 6.65907C2.24003 7.17946 2.83268 7.53653 3.4917 7.6697C3.06281 7.78577 2.61316 7.80287 2.1767 7.7197C2.36264 8.29821 2.72482 8.80409 3.21255 9.16653C3.70029 9.52896 4.28915 9.72981 4.8967 9.74095C3.86535 10.5506 2.59163 10.9898 1.28045 10.9878C1.04819 10.9879 0.816122 10.9743 0.585449 10.9472C1.91637 11.8029 3.46566 12.2571 5.04795 12.2553C10.4042 12.2553 13.3323 7.81907 13.3323 3.97157C13.3323 3.84657 13.3292 3.72032 13.3236 3.59532C13.8931 3.18343 14.3848 2.67338 14.7754 2.08907L14.7767 2.0872Z"
    fill="#8B949F"/>
</svg>

               </span>
            <p>{{contact.twitter}}</p>
          </div>
          <div class="about-more-details">
               <span>
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M7.5007 5.02555C6.13878 5.02555 5.02736 6.13698 5.02736 7.49889C5.02736 8.86081 6.13878 9.97223 7.5007 9.97223C8.86261 9.97223 9.97404 8.86081 9.97404 7.49889C9.97404 6.13698 8.86261 5.02555 7.5007 5.02555ZM14.9189 7.49889C14.9189 6.47467 14.9281 5.45973 14.8706 4.43737C14.8131 3.24987 14.5422 2.19596 13.6738 1.3276C12.8036 0.457388 11.7516 0.188345 10.5641 0.130825C9.53986 0.0733058 8.52492 0.0825831 7.50255 0.0825831C6.47833 0.0825831 5.46339 0.0733058 4.44103 0.130825C3.25353 0.188345 2.19962 0.459243 1.33126 1.3276C0.46105 2.19782 0.192007 3.24987 0.134487 4.43737C0.0769679 5.46159 0.0862453 6.47653 0.0862453 7.49889C0.0862453 8.52125 0.0769679 9.53805 0.134487 10.5604C0.192007 11.7479 0.462905 12.8018 1.33126 13.6702C2.20148 14.5404 3.25353 14.8094 4.44103 14.867C5.46525 14.9245 6.48019 14.9152 7.50255 14.9152C8.52677 14.9152 9.54171 14.9245 10.5641 14.867C11.7516 14.8094 12.8055 14.5385 13.6738 13.6702C14.5441 12.8 14.8131 11.7479 14.8706 10.5604C14.93 9.53805 14.9189 8.52311 14.9189 7.49889ZM7.5007 11.3045C5.39474 11.3045 3.69513 9.60485 3.69513 7.49889C3.69513 5.39293 5.39474 3.69333 7.5007 3.69333C9.60666 3.69333 11.3063 5.39293 11.3063 7.49889C11.3063 9.60485 9.60666 11.3045 7.5007 11.3045ZM11.4621 4.42624C10.9704 4.42624 10.5734 4.02917 10.5734 3.53747C10.5734 3.04577 10.9704 2.6487 11.4621 2.6487C11.9538 2.6487 12.3509 3.04577 12.3509 3.53747C12.351 3.65422 12.3282 3.76986 12.2835 3.87776C12.2389 3.98565 12.1735 4.08369 12.0909 4.16625C12.0083 4.24881 11.9103 4.31427 11.8024 4.35888C11.6945 4.40349 11.5789 4.42638 11.4621 4.42624Z"
    fill="#8B949F"/>
</svg>

               </span>
            <p>{{contact.instagram}}</p>
          </div>
          <div class="about-more-details">
               <span>
                  <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M1.15902 9.4645C1.20802 9.4645 1.24902 9.4255 1.25702 9.3715L1.52702 7.3495L1.25702 5.2805C1.25002 5.2265 1.20702 5.1875 1.15802 5.1875C1.10802 5.1875 1.06402 5.2275 1.05802 5.2805L0.822021 7.3495L1.05802 9.3705C1.06402 9.4255 1.10802 9.4645 1.15802 9.4645H1.15902Z"
    fill="#8B949F"/>
<path
    d="M0.272 8.69647C0.32 8.69647 0.36 8.66047 0.367 8.60647L0.576 7.35047L0.367 6.07047C0.36 6.01747 0.32 5.98047 0.272 5.98047C0.221 5.98047 0.182 6.01747 0.174 6.07047L0 7.35047L0.174 8.60647C0.182 8.65947 0.221 8.69647 0.272 8.69647Z"
    fill="#8B949F"/>
<path
    d="M2.21984 4.89525C2.21783 4.86464 2.20436 4.83591 2.18213 4.81479C2.15989 4.79366 2.13051 4.78169 2.09984 4.78125C2.0692 4.78147 2.0398 4.7934 2.01767 4.81459C1.99555 4.83579 1.98237 4.86465 1.98084 4.89525L1.75684 7.34925L1.98084 9.71325C1.98287 9.74367 1.9964 9.77218 2.01869 9.79298C2.04098 9.81378 2.07035 9.82532 2.10084 9.82525C2.13142 9.82506 2.16079 9.81331 2.18305 9.79234C2.20532 9.77138 2.21881 9.74276 2.22084 9.71225L2.47484 7.34925L2.22084 4.89525H2.21984Z"
    fill="#8B949F"/>
<path
    d="M3.05197 9.92031C3.08755 9.91967 3.12161 9.90579 3.1475 9.88138C3.17338 9.85697 3.18924 9.82379 3.19197 9.78831L3.43297 7.34931L3.19297 4.82731C3.19023 4.79167 3.17423 4.75834 3.14813 4.73391C3.12203 4.70948 3.08772 4.69571 3.05197 4.69531C3.01603 4.69527 2.98144 4.70905 2.95537 4.73381C2.92931 4.75857 2.91377 4.79241 2.91197 4.82831L2.69897 7.34931L2.91097 9.78831C2.91278 9.82439 2.92846 9.85837 2.95474 9.88316C2.98101 9.90794 3.01585 9.92161 3.05197 9.92131V9.92031Z"
    fill="#8B949F"/>
<path
    d="M4.01063 9.96138C4.05192 9.96146 4.09168 9.94577 4.12179 9.91752C4.1519 9.88927 4.17009 9.85059 4.17263 9.80938L4.39863 7.35038L4.17263 5.01038C4.16984 4.96934 4.15155 4.93091 4.12147 4.90287C4.09138 4.87483 4.05176 4.85928 4.01063 4.85938C3.96955 4.85932 3.93002 4.87508 3.90023 4.90338C3.87045 4.93167 3.85269 4.97034 3.85063 5.01138L3.65063 7.35138L3.85063 9.80938C3.85269 9.85041 3.87045 9.88908 3.90023 9.91738C3.93002 9.94568 3.96955 9.96143 4.01063 9.96138Z"
    fill="#8B949F"/>
<path
    d="M5.3702 7.353L5.1582 3.548C5.15542 3.50148 5.13508 3.45774 5.1013 3.42563C5.06752 3.39352 5.02281 3.37542 4.9762 3.375C4.92952 3.37519 4.88468 3.3932 4.85085 3.42536C4.81702 3.45752 4.79675 3.50139 4.7942 3.548L4.6062 7.353L4.7942 9.811C4.79892 9.85598 4.82013 9.89762 4.85374 9.92788C4.88735 9.95815 4.93097 9.9749 4.9762 9.9749C5.02143 9.9749 5.06505 9.95815 5.09866 9.92788C5.13227 9.89762 5.15348 9.85598 5.1582 9.811L5.3702 7.353Z"
    fill="#8B949F"/>
<path
    d="M5.95075 9.98704C6.00254 9.9871 6.05235 9.96716 6.08979 9.93139C6.12724 9.89562 6.14944 9.84678 6.15175 9.79504V9.79704L6.35075 7.35304L6.15175 2.67704C6.14815 2.62579 6.12525 2.57782 6.08767 2.54279C6.05009 2.50776 6.00063 2.48828 5.94925 2.48828C5.89788 2.48828 5.84842 2.50776 5.81084 2.54279C5.77326 2.57782 5.75036 2.62579 5.74675 2.67704L5.57275 7.35304L5.74775 9.79604C5.75059 9.84777 5.77328 9.89641 5.8111 9.93181C5.84892 9.96721 5.89895 9.98664 5.95075 9.98604V9.98704Z"
    fill="#8B949F"/>
<path
    d="M6.93041 2.07424C6.87305 2.07392 6.8178 2.09582 6.77623 2.13534C6.73465 2.17486 6.70999 2.22894 6.70741 2.28624L6.54541 7.35124L6.70741 9.76924C6.71 9.82645 6.73469 9.88042 6.77629 9.91978C6.81789 9.95914 6.87314 9.98082 6.93041 9.98024C6.98775 9.98107 7.04314 9.95948 7.0848 9.92007C7.12645 9.88065 7.15107 9.82654 7.15341 9.76924L7.33841 7.35124L7.15341 2.28624C7.15132 2.22877 7.12682 2.1744 7.08513 2.13477C7.04345 2.09515 6.98791 2.07342 6.93041 2.07424Z"
    fill="#8B949F"/>
<path
    d="M7.92015 9.98777C7.98313 9.98886 8.04401 9.96514 8.08965 9.92174C8.1353 9.87833 8.16206 9.81873 8.16415 9.75577V9.75777L8.33415 7.35377L8.16415 2.11877C8.16206 2.056 8.13544 1.99654 8.09001 1.95316C8.04458 1.90979 7.98395 1.88595 7.92115 1.88677C7.85819 1.88543 7.79727 1.90909 7.75172 1.95258C7.70618 1.99606 7.67972 2.05582 7.67815 2.11877L7.52515 7.35377L7.67815 9.75777C7.68015 9.88677 7.78815 9.98777 7.92115 9.98777H7.92015Z"
    fill="#8B949F"/>
<path
    d="M8.91714 9.98403C8.98525 9.98485 9.05096 9.95891 9.10014 9.91178C9.14932 9.86466 9.17805 9.80012 9.18014 9.73203V9.73403L9.33714 7.35303L9.18014 2.25003C9.17753 2.1823 9.14859 2.11827 9.09946 2.07157C9.05033 2.02487 8.98491 1.9992 8.91714 2.00003C8.84919 1.99894 8.78351 2.02449 8.73417 2.07121C8.68483 2.11794 8.65574 2.18213 8.65314 2.25003L8.51514 7.35303L8.65414 9.73303C8.65714 9.87303 8.77314 9.98303 8.91714 9.98303V9.98403Z"
    fill="#8B949F"/>
<path
    d="M10.3485 7.35502L10.2065 2.43802C10.2039 2.36481 10.1727 2.29553 10.1196 2.24506C10.0665 2.19458 9.99575 2.16692 9.9225 2.16802C9.84891 2.16666 9.77775 2.19434 9.72441 2.24506C9.67107 2.29577 9.63985 2.36545 9.6375 2.43902L9.5105 7.35502L9.6375 9.72102C9.64011 9.7944 9.67144 9.86383 9.72475 9.91433C9.77806 9.96484 9.84908 9.99237 9.9225 9.99102C9.99625 9.99209 10.0674 9.96402 10.1206 9.91291C10.1738 9.86179 10.2047 9.79175 10.2065 9.71802V9.72102L10.3485 7.35502Z"
    fill="#8B949F"/>
<path
    d="M10.9359 9.99486C11.1009 9.99486 11.2369 9.86486 11.2399 9.70486L11.3689 7.35586L11.2399 1.50586C11.2373 1.42725 11.204 1.35279 11.1472 1.29841C11.0904 1.24402 11.0145 1.21403 10.9359 1.21486C10.8572 1.21428 10.7814 1.24432 10.7245 1.29863C10.6675 1.35293 10.634 1.42725 10.6309 1.50586L10.5159 7.35386L10.6309 9.70586C10.6339 9.86386 10.7709 9.99486 10.9359 9.99486Z"
    fill="#8B949F"/>
<path
    d="M11.9439 0.664105C11.8597 0.662739 11.7783 0.694429 11.7171 0.752381C11.656 0.810332 11.62 0.88993 11.6169 0.974105L11.4839 7.3561L11.6179 9.6711C11.621 9.75475 11.6568 9.83384 11.7175 9.89141C11.7783 9.94899 11.8592 9.98047 11.9429 9.9791C12.0269 9.98019 12.108 9.94818 12.1686 9.88999C12.2292 9.8318 12.2645 9.7521 12.2669 9.6681V9.6711L12.4129 7.3561L12.2669 0.975105C12.2645 0.891283 12.2294 0.811735 12.169 0.753574C12.1086 0.695412 12.0277 0.663286 11.9439 0.664105Z"
    fill="#8B949F"/>
<path
    d="M12.8654 9.996L21.0464 10C22.6774 10 23.9994 8.732 23.9994 7.167C23.9994 5.603 22.6774 4.335 21.0464 4.335C20.6424 4.335 20.2564 4.414 19.9044 4.555C19.6714 2.003 17.4394 0 14.7174 0C14.0524 0 13.4034 0.126 12.8304 0.339C12.6074 0.422 12.5474 0.507 12.5454 0.672V9.661C12.5485 9.74631 12.5828 9.82752 12.6418 9.88925C12.7008 9.95098 12.7803 9.98896 12.8654 9.996Z"
    fill="#8B949F"/>
</svg>

               </span>
            <p>{{contact.soundcloud}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "socialLinks",
  data() {
    return {
      deletePage: false,
      contact: {
        facebook: null,
        instagram: null,
        soundcloud: null,
        twitter: null,
        youtube: null,
        linked_in: null,
      }
    }
  },
  computed: {
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    setPageData() {
      if (this.current_page) {
        let {facebook, instagram, youtube, linked_in, twitter, soundcloud} = this.current_page
        this.contact.facebook = facebook
        this.contact.instagram = instagram
        this.contact.youtube = youtube
        this.contact.linked_in = linked_in
        this.contact.twitter = twitter
        this.contact.soundcloud = soundcloud
      }
    }
  },
  mounted() {
    this.setPageData()
  },
  watch: {
    current_page(val) {
      if (val)
        this.setPageData()

    }
  }
}
</script>

<style scoped>

</style>
