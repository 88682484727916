<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.9155 4.66992H3.24886C2.51248 4.66992 1.91553 5.26688 1.91553 6.00326V12.6699C1.91553 13.4063 2.51248 14.0033 3.24886 14.0033H13.9155C14.6519 14.0033 15.2489 13.4063 15.2489 12.6699V6.00326C15.2489 5.26688 14.6519 4.66992 13.9155 4.66992Z"
                fill="#47505B"></path>
              <path
                d="M11.2489 13.0034V3.33675C11.2489 2.98313 11.1084 2.64399 10.8583 2.39394C10.6083 2.14389 10.2691 2.00342 9.91553 2.00342H7.24886C6.89524 2.00342 6.5561 2.14389 6.30605 2.39394C6.056 2.64399 5.91553 2.98313 5.91553 3.33675V13.0034"
                stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Work & Education</p>
          <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <div class="work-experience-section">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <div class="work-heading-block">
              <div class="work-experience">Work Experience</div>
              <div class="work-add-more">
                <button v-if="work_list && work_list.length" v-on:click="addWork()">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="add-btn-active"
                      d="M7.00008 12.8332C10.2217 12.8332 12.8334 10.2215 12.8334 6.99984C12.8334 3.77818 10.2217 1.1665 7.00008 1.1665C3.77842 1.1665 1.16675 3.77818 1.16675 6.99984C1.16675 10.2215 3.77842 12.8332 7.00008 12.8332Z"
                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="add-btn-active" d="M7 4.6665V9.33317" stroke="#D1433A" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="add-btn-active" d="M4.66675 7H9.33342" stroke="#D1433A" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  ADD MORE
                </button>
              </div>
            </div>
            <ul class="add-work-experience">
              <li class="empty-exp" v-if="work_list && !work_list.length">
                <div class="empty-experience">
                  <h1>You haven’t added any Work Experience yet</h1>
                  <p>Use button «Add Work Experience» to fill out this information</p>
                  <div class=" ad-work-exp-btn">
                    <button v-on:click="addWork()">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </svg>
                      ADD WORK EXPERIENCE
                    </button>
                  </div>
                </div>
              </li>

              <li v-for="(eachOp, index) in work_list" :key="index">
                <div class="experiance-detail-section">
                  <div class="experiance-detail-section-heading">
                    <h1>{{ eachOp.company_name }}</h1>
                    <p>{{ new Date(0, eachOp.from_month + 1, 0).toLocaleString("en-us", { month: "long" }) }}
                      {{ eachOp.from_year }} -
                      {{ eachOp.to_month ? new Date(0, eachOp.to_month + 1, 0).toLocaleString("en-us", {
                        month: "long"
                      }) : "continue"
                      }}
                      {{ eachOp.to_year }}</p>
                  </div>
                  <div class="edit-delete">
                    <button @click="editWork(eachOp)">
                      <!-- <img src="img/edit-icon.png"> -->
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <WorkEducationDeleteBtn :deleteItem="deleteWorkEducationData" :id="eachOp.id" />
                  </div>
                </div>
                <p class="exp-des">
                  {{ eachOp.description }}
                </p>
              </li>
              <ul>
              </ul>
            </ul>
            <div v-if="remainingExperiences > 0" @click="showMoreExperience" class="show-more-button">
              <a href="javascript:;">SHOW {{ remainingExperiences }} MORE</a>
            </div>
          </div>
          <div class="work-experience-section">
            <div class="work-heading-block">
              <div class="work-experience">Education</div>
              <div class="work-add-more">
                <button v-if="education_list && education_list.length" v-on:click="addEducation()">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="add-btn-active"
                      d="M7.00008 12.8332C10.2217 12.8332 12.8334 10.2215 12.8334 6.99984C12.8334 3.77818 10.2217 1.1665 7.00008 1.1665C3.77842 1.1665 1.16675 3.77818 1.16675 6.99984C1.16675 10.2215 3.77842 12.8332 7.00008 12.8332Z"
                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="add-btn-active" d="M7 4.6665V9.33317" stroke="#D1433A" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="add-btn-active" d="M4.66675 7H9.33342" stroke="#D1433A" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  ADD MORE
                </button>
              </div>
            </div>
            <ul class="add-work-experience">
              <li class="empty-exp" v-if="education_list && !education_list.length">
                <div class="empty-experience">
                  <h1>You haven’t added any Education Information yet</h1>
                  <p>Use button «Add Education» to fill out this information</p>
                  <div class=" ad-work-exp-btn">
                    <button v-on:click="addEducation()">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                      </svg>
                      ADD EDUCATION
                    </button>
                  </div>
                </div>
              </li>

              <li v-for="(eachOp, index) in education_list" :key="index">
                <div class="experiance-detail-section">
                  <div class="experiance-detail-section-heading">
                    <h1>{{ eachOp.school_name }}</h1>
                    <p>{{ eachOp.degree_name.name }} / {{ eachOp.from_year }}-{{ eachOp.to_year ? eachOp.to_year :
                      'continue' }}</p>

                  </div>
                  <div class="edit-delete">
                    <button @click="editEducation(eachOp)">
                      <!-- <img src="img/edit-icon.png"> -->
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <WorkEducationDeleteBtn :deleteItem="deleteEducationData" :id="eachOp.id" />
                  </div>
                </div>
                <p class="exp-des">
                  {{ eachOp.description }}
                </p>
              </li>
              <ul>
              </ul>
            </ul>
            <div v-if="remainingEducations > 0" @click="showMoreEducation" class="show-more-button">
              <a href="javascript:;">SHOW {{ remainingEducations }} MORE</a>
            </div>
            <!-- <ul class="add-work-experience">
               <li>
                  <div class="experiance-detail-section">
                     <div class="experiance-detail-section-heading">
                        <h1>Moscow Music Academy</h1>
                        <p>Bachelor / 2017 - 2021</p>
                     </div>
                     <div class="edit-delete">
                        <button>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                        <button>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5 4.5L4.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M4.5 4.5L13.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                     </div>
                  </div>
                  <p class="exp-des">Another journey chamber way yet females man. Way extensive and dejection get delivered deficient sincerity gentleman age. Too end instrument possession contrasted motionless.</p>
               </li>
               <ul>
               </ul>
            </ul> -->
          </div>

          <!-- <div class="row">
            <div class="col text-right">
              <button class="btn save-btn">{{ loading ? "Please Wait..." : "Save Changes" }}</button>
            </div>
          </div> -->

<!--          <div class="row">-->
<!--            <div class="col text-right">-->
<!--              <button type="submit" class="save-btn">{{ loading ? "Please Wait..." : "Save Changes" }}</button>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <!-- STart -->
        <!-- <div class="setting-content-inner">
<div class="work-experience-section">
<div class="work-heading-block">
<div class="work-experience">Work Experience</div>

</div>
<ul class="add-work-experience">
<li class="empty-exp">
  <div class="empty-experience">
    <h1>You didn’t add Work Experience yet</h1>
    <p>Use button «Add Work Experience» to fill out this information</p>
    <div class=" ad-work-exp-btn">
          <button>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        ADD WORK EXPERIENCE
      </button>
  </div>
  </div>
</li>
<ul>
</ul></ul></div>


<div class="work-experience-section">
<div class="work-heading-block">
<div class="work-experience">Education</div>

</div>
<ul class="add-work-experience">
<li class="empty-exp">
  <div class="empty-experience">
    <h1>You didn’t add Education Information yet</h1>
    <p>Use button «Add Education» to fill out this information</p>
    <div class=" ad-work-exp-btn">
          <button>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        ADD EDUCATION
      </button>
  </div>
  </div>
</li>
<ul>
</ul></ul></div>
<div class="row">
  <div class="col text-right">
    <button type="submit" class="btn save-btn">Save Changes</button>
  </div>
</div>

</div> -->
        <!-- End -->
      </div>
      <div class=""></div>
    </div>
    <AddWork v-if="workModal" @click="closeWindow" :item="selected_work_education_list_item" />
    <AddEducation v-if="educationModal" @click="closeWindow" :item="selected_education_list_item" />
    <full-page-loader :show="loading" />
  </div>
</template>
<script type="application/javascript">
import AddWork from "../settings/AddWork";
import AddEducation from "../settings/AddEducation";
import WorkEducationDeleteBtn from "./Components/WorkEducationDeleteBtn";
import { mapGetters } from "vuex";
import {
  delete_user_education_api,
  delete_user_work_api,
  get_user_education_api, get_user_work_api
} from "../../../apis/APIs";
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";

export default {
  data() {
    return {
      educationModal: false,
      workModal: false,
      educationlimit: 3,
      educationoffset: 0,
      worklimit: 3,
      workoffset: 0,
      totalWorkHistory: 0,
      totalEducation: 0,
      message: "",
      success_message: "",

      education_list: [],
      education_list_loading: false,

      work_list: [],
      work_list_loading: false,

      selected_education_list_item: undefined,
      selected_work_education_list_item: undefined,
      loading: false
    };
  },
  components: {
    AddWork,
    AddEducation,
    WorkEducationDeleteBtn,
    EyeIcon
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    currentTab() {
      return this.$store.state.active_tab;
    },
    remainingEducations() {
      return this.totalEducation - this.educationlimit;
    },
    remainingExperiences() {
      return this.totalWorkHistory - this.worklimit;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    addEducation: function () {
      this.educationModal = !this.educationModal;
      this.workModal = false;

    },
    showMoreEducation() {
      if (this.educationlimit < this.totalEducation) {
        this.educationlimit += 3;
        this.fetchEducationData();
      }
    },
    showMoreExperience() {
      if (this.worklimit < this.totalWorkHistory) {
        this.worklimit += 3;
        this.fetchWorkEducationData();
      }
    },
    addWork: function () {
      this.workModal = !this.workModal;
      this.educationModal = false;
    },
    closeWindow: function () {
      this.educationModal = false;
      this.workModal = false;

      this.selected_education_list_item = undefined;
      this.selected_work_education_list_item = undefined;
    },
    init() {
      if (this.education_list && this.education_list.length < 1) {
        this.fetchEducationData();
      }
      if (this.work_list && this.work_list.length < 1) {
        this.fetchWorkEducationData();
      }
    },
    async fetchEducationData() {
      try {
        this.education_list_loading = true;
        this.loading = true;

        const res = await get_user_education_api(this.educationoffset , this.educationlimit, this.user.user_data.id);
        const { success, data } = res.data || {};
        this.totalEducation = data.total;
        this.education_list_loading = false;
        if (success === 1) {
          if (data && data.data.length) {
            this.education_list = data.data.map(ele => (
              {
                id: ele.id ? ele.id : "",
                school_name: ele.title ? ele.title : "",
                degree_name: ele.degree ? ele.degree : "",
                from_year: ele.from ? ele.from : "",
                to_year: ele.to ? ele.to : "",
                description: ele.description ? ele.description : ""
              }
            ));
          } else {
            this.education_list = [];
          }
        }
        this.loading = false;
      } catch (error) {
        this.education_list_loading = false;
      }
    },
    async fetchWorkEducationData() {
      try {
        this.work_list_loading = true;
        this.loading = true;
        const res = await get_user_work_api(this.workoffset, this.worklimit, this.user.user_data.id);
        const { success, data } = res.data || {};
        this.work_list_loading = false;
        this.totalWorkHistory = data.total;
        if (success === 1) {
          if (data && data.data.length) {
            this.work_list = data.data.map(ele => (
              {
                from_year: ele.from_year ? ele.from_year : "",
                to_year: ele.to_year ? ele.to_year : "",
                from_month: ele.from_month ? ele.from_month : "",
                to_month: ele.to_month ? ele.to_month : "",
                company_name: ele.company_name ? ele.company_name : "",
                description: ele.description ? ele.description : "",
                title: ele.title ? ele.title : "",
                location: ele.location ? ele.location : "",
                id: ele.id ? ele.id : "",
                currently_working: ele.currently_working ? ele.currently_working : ""
              }
            ));
          } else {
            this.work_list = [];
          }
        }
        this.loading = false;

      } catch (error) {
        this.work_list_loading = false;
      }
    },
    editEducation(item) {
      this.selected_education_list_item = item;
      this.addEducation();
    },
    editWork(item) {
      this.selected_work_education_list_item = item;
      this.addWork();
    },
    async deleteEducationData(id, cb = () => {
    }) {
      try {
        this.loading = true;
        const res = await delete_user_education_api(id);
        const { success, message } = res.data || {};

        if (success === 1) {
          this.success_message = message;
          setTimeout(() => this.success_message = "", 5000);
          await this.fetchEducationData();
        } else {
          this.message = message;
        }
        this.loading = false;
        cb();

      } catch (e) {
        this.message = e && e.message ? e.message : "Please try again.";
        cb();
      }
    },
    async deleteWorkEducationData(id, cb = () => {
    }) {
      try {
        this.loading = true;
        const res = await delete_user_work_api(id);
        const { success, message } = res.data || {};

        if (success === 1) {
          this.success_message = message;
          setTimeout(() => this.success_message = "", 5000);
          await this.fetchWorkEducationData();
        } else {
          this.message = message;
        }
        this.loading = false;
        cb();

      } catch (e) {
        this.message = e && e.message ? e.message : "Please try again.";
        cb();
      }
    }
  }
};
</script>

<style scoped>
.show-more-button {
  text-align: center;
  display: block;
  margin-bottom: 26px;
}

.show-more-button a {
  font-size: 13px;
  font-weight: normal;
  color: #D1433A;
  font-family: HelveticaNeueBold, sans-serif;
}
</style>