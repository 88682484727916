<template>
  <div class="sale-items grid-selling-with-slider" ref="busstop">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="title-h4 channel-title release-title">{{ gridTitle }}</h4>
      </div>
    </div>
    <div
      class="music_slider_landing selling_now2 img-carousel pg-owl-carousel slider-cards carousel-icon"
      v-if="listData && listData.length > 0"
    >
      <carousel
        :items="6"
        :autoplay="false"
        :nav="false"
        :key="listData.length"
        :margin="30"
        :responsive="{2560: {items: 4}}"
      >
        <div v-for="(song, index) in listData" :key="index">
          <Grid :item="listData[index % listData.length]" :stream="isStreaming" />
        </div>
      </carousel>
      <ListOption
        :optionWidth="optionWidth"
        :optionHeight="optionHeight"
        :showOption="showOption"
        :music_type="music_type"
      />
      <div class="link-more near_arrow more_btn_landing cursor-pointer">
        <a @click="redirectToChannel(channelType, altName, pageTitle)"
          >See more</a
        >
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { addLove } from "../../../apis/APIs";
import ListOption from "@/components/Common/Elements/ListOptions.vue";
import Grid from "@/components/DesignGuide/Music/Components/Grid.vue";
import carousel from "vue-owl-carousel";
import ClickOutside from "vue-click-outside";
import Vue from "vue";

export default {
  data() {
    return {
      showMMore: false,
      optionHeight: 0,
      showOption: false,
      lastWidth: 0,
      optionWidth: 0,
      localListData: [],
      key: 0,
      options: {
        responsive: {  2560: { items: 2  } }
      }
    };
  },
  components: {
    ListOption,
    Grid,
    carousel,
  },
  mounted: function() {
    this.localListData = this.listData;
  },
  computed: {
    isStreaming() {
      return this.$store.getters.isMusicModeIsStreaming;
    },
  },
  props: {
    gridTitle: String,
    pageTitle: String,
    redirectLink: String,
    altName: String,
    listData: {
      type: Array,
      default: () => [],
    },
    channelType: {
      type: String,
      default: null,
    },
    componentType: {
      type: String,
      default: "buy",
    },
    music_type: {
      type: String,
      default: "song",
    },
  },
  methods: {
    async addToLoveToggle(song) {
      try {
        console.log(song);
        this.loading = true;
        let data = await addLove(1, song.id, "song", 0);
        this.loading = false;
        console.log(data, "GEt data ");
      } catch (error) {
        this.loading = false;
      }
    },
    getImgUrl(filename) {
      if (filename) {
        var xhr = new XMLHttpRequest();
        xhr.open("HEAD", filename, false);
        xhr.send();
        if (xhr.status == "404") {
          return require(`@/assets/img/placeholders.png`);
        } else {
          return filename;
        }
      } else {
        return require(`@/assets/img/placeholders.png`);
      }
    },
    getHeight: function(e, ei) {
      e.stopPropagation();
      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }
      console.log(e.screenX, "screen x");
      const height = 112;
      this.optionWidth =
        parseInt(e.pageX) - this.$refs.busstop.getBoundingClientRect().left;
      this.optionHeight = parseInt(height);
    },
  },
  watch: {
    listData: {
      handler: function(value) {
        this.localListData = value;
      },
      deep: true,
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style scoped lang="scss">
.pg-new-play-button {
  cursor: pointer;
  transition: all 0.3s;
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
  display: block;
}
.card-padding {
  padding-right: 20px;
}

.channel-card-cover:hover .top-week-album-img-hover {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(7, 21, 38, 0.3588) 0%,
    rgba(7, 21, 38, 0.69) 100%
  );
}
@import "/src/assets/style/music/components/gridSellingWithSlider.scss";
</style>
