import {
  getGeners,
  getCountriesList,
  getVenueTypes,
  getEventTypes,
  explorEvents,
  createNewEvent,
  InterestedGoing,
  getAllUserListForSendInvites,
  getSearchHistoryCityContry,
  addSearchHistoryCityContry,
  getMyPagesByType,
  getEventsByType,
} from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";

import {
  ERROR,
  SUCCESS,
  SYSTEM_DATA_IS_AUTHENTICATED,
  SYSTEM_DATA_IS_WELCOME_PAGE,
  SYSTEM_DATA_KEY,
  SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS,
  LOADING,
  REFRESH_TOKEN,
  EVENT_MODAL,
} from "../types";
import mixin from "../../mixins/sweetAlert";

const INITIAL_STATE = {
  [SYSTEM_DATA_KEY]: {
    [SYSTEM_DATA_IS_AUTHENTICATED]: false,
    [SYSTEM_DATA_IS_WELCOME_PAGE]: false,
    [SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS]: "",
  },
  [LOADING]: false,
  allEvents: {},
  userListForSendInvites: "",
  genres: [],
  venues: [],
  myPagesByType: "",
  eventsSearchFor: {
    filterStatus: "",
    event_type: "",
    start_date: "",
    end_date: "",
    sort_by: "",
    venue_type: "",
    Genre: "",
    search: "",
    country: "",
    city: "",
    date: "",
    start_price: "",
    end_price: "",
  },
  end_search_city_contry: "",
  [EVENT_MODAL]: false,
  allEventsByType: "",
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async getGenersType({ state, commit }, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await getGeners();
        resolve({
          status: SUCCESS,
          message: "",
          information: data.data.information,
        });
        commit("set_genres", [
          { name: "All Genres" },
          ...data.data.information,
        ]);
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async getCountriesList({ state, commit }, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getCountriesList();
        const { success, message, information } = res.data || {};
        if (success === 1) {
          resolve({
            status: SUCCESS,
            message: message,
            information,
          });
        } else {
          reject({
            status: ERROR,
            message: message ? message : "Please try again.",
          });
        }
      } catch (error) {
        Utils.log("EditProfile ===> error", error);
        reject({
          status: ERROR,
          message: "Please try again.",
        });
      }
    });
  },
  async getVenueTypes({ state, commit }, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getVenueTypes();
        const { success, message, information } = res.data || {};
        if (success === 1) {
          resolve({
            status: SUCCESS,
            message: message,
            information,
          });
          commit("set_venues", [{ title: "All Venues" }, ...information]);
        } else {
          reject({
            status: ERROR,
            message: message ? message : "Please try again.",
          });
        }
      } catch (error) {
        Utils.log("Events Venue ===> error", error);
        reject({
          status: ERROR,
          message: "Please try again.",
        });
      }
    });
  },
  async getEventTypes({ state, commit }, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getEventTypes();
        const { success, message, information } = res.data || {};
        if (success === 1) {
          resolve({
            status: SUCCESS,
            message: message,
            information,
          });
        } else {
          reject({
            status: ERROR,
            message: message ? message : "Please try again.",
          });
        }
      } catch (error) {
        Utils.log("Event types ===> error", error);
        reject({
          status: ERROR,
          message: "Please try again.",
        });
      }
    });
  },
  async getAllEvents({ state, commit, dispatch }, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await explorEvents(body);
        const { success, message, information } = res.data || {};
        dispatch("loading", true);
        if (success === 1) {
          resolve({
            status: SUCCESS,
            message: message,
            information,
          });
          commit("set_All_Events", information);
        } else {
          reject({
            status: ERROR,
            message: message ? message : "Please try again.",
          });
        }
      } catch (error) {
        Utils.log("All Events ===> error", error);
        reject({
          status: ERROR,
          message: "Please try again.",
        });
      } finally {
        dispatch("loading", false);
      }
    });
  },
  async createEvent({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await createNewEvent(payload);
        dispatch("loading", false);
        if (data.success === 1) {
          await mixin.methods.makeToast("success", "New Event", data.message);
        } else {
          await mixin.methods.errorToaster("error", "Error", data.message);
        }
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async InterestedGoing({ state, dispatch }, payload) {
    await InterestedGoing(payload);
    await dispatch("getAllEvents", state.eventsSearchFor);
  },
  async getAllUserListForSendInvites({ commit }, myId) {
    let { data } = await getAllUserListForSendInvites();
    commit(
      "set_user_List_For_Send_Invites",
      data.users.filter((el) => el.id != myId)
    );
  },
  async addSearchHistoryCityContry({}, body) {
    await addSearchHistoryCityContry(body);
  },
  async getSearchHistoryCityContry({ commit }) {
    let { data } = await getSearchHistoryCityContry();
    commit("set_end_search_city_contry", data.information);
  },
  async getMyPagesByType({ commit }) {
    let { data } = await getMyPagesByType("all", 0, 50);
    commit("set_my_pages_type", data.information);
  },
  async getEventsByType({ commit }, payload) {
    let { data } = await getEventsByType(payload);
    commit("set_All_Events_By_Type", data.information);
  },
  closeEventModal ({commit, state}) {
    state[EVENT_MODAL] = false
  }
};

export const mutations = {
  set_All_Events_By_Type(state, payload) {
    state.allEventsByType = payload;
  },
  set_genres(state, payload) {
    state.genres = payload;
  },
  set_venues(state, payload) {
    state.venues = payload;
  },
  set_my_pages_type(state, payload) {
    state.myPagesByType = payload;
  },
  set_All_Events(state, payload) {
    state.allEvents = payload;
  },
  set_Events_Searech_For(state, played) {
    state.eventsSearchFor = { ...state.eventsSearchFor, ...played };
  },
  set_user_List_For_Send_Invites(state, played) {
    state.userListForSendInvites = played;
  },
  set_end_search_city_contry(state, played) {
    state.end_search_city_contry = played;
  },
  SET_EVENT_MODAL(state, payload) {
    state[EVENT_MODAL] = payload;
  },
};

const getters = {
  GET_ALL_EVENTS_BY_TYPE(state) {
    return state.allEventsByType;
  },
  GET_GENRES(state) {
    return state.genres;
  },
  GET_VENUES(state) {
    return state.venues;
  },
  GET_MY_PAGES_TYPE(state) {
    return state.myPagesByType;
  },
  GET_ALL_EVENTS(state) {
    return state.allEvents;
  },
  GET_EVENTS_SEARCH_FOR(state) {
    return state.eventsSearchFor;
  },
  GET_USER_LIST_FOR_SEND_INVITES(state) {
    return state.userListForSendInvites;
  },
  GET_END_SEARCH_CITY_CONTRY(state) {
    return state.end_search_city_contry;
  },
  getEventModal(state) {
    return state[EVENT_MODAL];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
