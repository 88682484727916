<template>
  <div>
    <div class="activity-main-list">
      <div
        v-for="(notification, index) in activityNotifications.slice(0, 2)"
        :key="index"
      >
        <div class="activity-block">
          <div class="activity-user-detail">
            <div class="a-user-img">
              <img
                :src="
                  notification.activities[0] &&
                  notification.activities[0].user &&
                  notification.activities[0].user.artwork_url
                    ? notification.activities[0].user.artwork_url
                    : ''
                "
              />
              <span></span>
            </div>
            <div class="activity-user-detail">
              <p>
                <span>{{
                  notification.activities[0] &&
                  notification.activities[0].user &&
                  notification.activities[0].user.full_name
                    ? notification.activities[0].user.full_name
                    : notification.activities[0] &&
                      notification.activities[0].user &&
                      notification.activities[0].user.name
                    ? notification.activities[0].user.name
                    : ""
                }}</span>
                is listening to
              </p>
            </div>
          </div>
          <div class="detail-activities">
            <div class="vol-icon">
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.16675L4.99984 7.50008H1.6665V12.5001H4.99984L9.1665 15.8334V4.16675Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.8919 4.1084C17.4541 5.67113 18.3317 7.79036 18.3317 10.0001C18.3317 12.2098 17.4541 14.329 15.8919 15.8917M12.9502 7.05007C13.7313 7.83143 14.1701 8.89105 14.1701 9.9959C14.1701 11.1007 13.7313 12.1604 12.9502 12.9417"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="music-activity-image">
              <img
                :src="
                  notification.activities[0] &&
                  notification.activities[0].model &&
                  notification.activities[0].model.artwork_url
                    ? notification.activities[0].model.artwork_url
                    : ''
                "
                alt=""
              />
            </div>
            <div class="music-activity-texts">
              <h1>
                {{ notification | filterTitle }}
              </h1>
              <p>
                {{
                  notification.activities[0] &&
                  notification.activities[0].model &&
                  notification.activities[0].model.page
                    ? notification.activities[0].model.page
                    : ""
                }}
              </p>
              <button
                :class="[
                  notification.activities[0] &&
                  notification.activities[0].model &&
                  notification.activities[0].model.page
                    ? ''
                    : 'mt-3',
                ]"
                v-if="
                  currentPlayId === notification.activities[0].model.id &&
                  songStatus &&
                  activityId === 101
                "
                class="play-song-1"
                style="background: #D1433A"
                @click="
                  playPause(
                    $event,
                    [notification.activities[0].model],
                    '',
                    '',
                    101
                  )
                "
              >
                <svg
                  class="mr-0"
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1V15"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M10 1V15"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
              <button
                :class="[
                  notification.activities[0] &&
                  notification.activities[0].model &&
                  notification.activities[0].model.page
                    ? ''
                    : 'mt-3',
                ]"
                v-else
                class="play-song-1"
                @click="
                  playPause(
                    $event,
                    [notification.activities[0].model],
                    '',
                    '',
                    101
                  )
                "
              >
                <svg
                  width="13"
                  height="16"
                  viewBox="0 0 13 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="play-song-svg"
                    d="M1.945 14.8685C1.79896 14.8649 1.65553 14.8291 1.525 14.7635C1.37619 14.676 1.25074 14.5538 1.15933 14.4074C1.06792 14.261 1.01326 14.0946 1 13.9225L1 1.95149C1.00785 1.78456 1.05473 1.62179 1.13688 1.47626C1.21902 1.33072 1.33414 1.20648 1.473 1.11349C1.61905 1.03089 1.78575 0.991979 1.95327 1.00137C2.1208 1.01077 2.2821 1.06808 2.418 1.16649L11.606 7.15149C11.7353 7.23816 11.8413 7.35534 11.9146 7.49269C11.9879 7.63003 12.0262 7.78331 12.0262 7.93899C12.0262 8.09466 11.9879 8.24795 11.9146 8.38529C11.8413 8.52264 11.7353 8.63982 11.606 8.72649L2.418 14.7135C2.27976 14.8124 2.11495 14.8675 1.945 14.8715"
                    fill="#D1433A"
                  />
                  <path
                    class="play-song-svg"
                    d="M1.945 14.8685C1.79896 14.8649 1.65553 14.8291 1.525 14.7635C1.37619 14.676 1.25074 14.5538 1.15933 14.4074C1.06792 14.261 1.01326 14.0946 1 13.9225L1 1.95149C1.00785 1.78456 1.05473 1.62179 1.13688 1.47626C1.21902 1.33072 1.33414 1.20648 1.473 1.11349C1.61905 1.03089 1.78575 0.991979 1.95327 1.00137C2.1208 1.01077 2.2821 1.06808 2.418 1.16649L11.606 7.15149C11.7353 7.23816 11.8413 7.35534 11.9146 7.49269C11.9879 7.63003 12.0262 7.78331 12.0262 7.93899C12.0262 8.09466 11.9879 8.24795 11.9146 8.38529C11.8413 8.52264 11.7353 8.63982 11.606 8.72649L2.418 14.7135C2.27976 14.8124 2.11495 14.8675 1.945 14.8715"
                    stroke="#D1433A"
                    stroke-width="1.5"
                  />
                </svg>
                Play Song
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(listenedSong, index) in listenedSongs"
        :key="'listened_song' + index"
      >
        <div class="activity-block">
          <div class="activity-user-detail">
            <div class="a-user-img">
              <img :src="listenedSong.user.artwork_url" />
              <span></span>
            </div>
            <div class="activity-user-detail user-forum">
              <div class="activity-user-detail-text">
                <span>{{ listenedSong | filterUserName }} </span> listened to
                <span>{{
                  listenedSong.details && listenedSong.details.objects
                    ? listenedSong.details.objects.length
                    : ""
                }}</span>
                {{
                  listenedSong.details &&
                  listenedSong.details.objects &&
                  listenedSong.details.objects.length > 1
                    ? "songs"
                    : "song"
                }}
              </div>
              <span class="time-ago pl-0">{{
                convertDateIntoHumanReadableForm(listenedSong.updated_at)
              }}</span>
            </div>
          </div>
          <div class="music-play-section">
            <div class="main-music-icon">
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12.6667V8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8V12.6667"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 13.4443C15 13.8569 14.8361 14.2525 14.5444 14.5443C14.2527 14.836 13.857 14.9999 13.4444 14.9999H12.6667C12.2541 14.9999 11.8584 14.836 11.5667 14.5443C11.275 14.2525 11.1111 13.8569 11.1111 13.4443V11.111C11.1111 10.6984 11.275 10.3028 11.5667 10.011C11.8584 9.71931 12.2541 9.55542 12.6667 9.55542H15V13.4443ZM1 13.4443C1 13.8569 1.16389 14.2525 1.45561 14.5443C1.74733 14.836 2.143 14.9999 2.55556 14.9999H3.33333C3.74589 14.9999 4.14155 14.836 4.43328 14.5443C4.725 14.2525 4.88889 13.8569 4.88889 13.4443V11.111C4.88889 10.6984 4.725 10.3028 4.43328 10.011C4.14155 9.71931 3.74589 9.55542 3.33333 9.55542H1V13.4443Z"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <ul
              v-for="(song, index) in listenedSong.details.objects.slice(0, 5)"
              :key="index"
            >
              <li class="mr-1">
                <img :src="song.artwork_url" :alt="song.title" />
              </li>
            </ul>
          </div>
          <div class="activity-btns-sec">
            <button
              v-if="
                currentPlayId === listenedSong.details.objects[0].id &&
                songStatus &&
                activityId === index
              "
              class="play-song-1"
              style="background: #D1433A"
              @click="
                playPause(
                  $event,
                  [listenedSong.details.objects[0]],
                  '',
                  '',
                  index
                )
              "
            >
              <svg
                class="mr-0"
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1V15"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M10 1V15"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <button
              v-else
              class="play-song-1 play-song-bg"
              @click="
                playPause(
                  $event,
                  [listenedSong.details.objects[0]],
                  '',
                  '',
                  index
                )
              "
            >
              <svg
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.945 14.8685C1.79896 14.8649 1.65553 14.8291 1.525 14.7635C1.37619 14.676 1.25074 14.5538 1.15933 14.4074C1.06792 14.261 1.01326 14.0946 1 13.9225L1 1.95149C1.00785 1.78456 1.05473 1.62179 1.13688 1.47626C1.21902 1.33072 1.33414 1.20648 1.473 1.11349C1.61905 1.03089 1.78575 0.991979 1.95327 1.00137C2.1208 1.01077 2.2821 1.06808 2.418 1.16649L11.606 7.15149C11.7353 7.23816 11.8413 7.35534 11.9146 7.49269C11.9879 7.63003 12.0262 7.78331 12.0262 7.93899C12.0262 8.09466 11.9879 8.24795 11.9146 8.38529C11.8413 8.52264 11.7353 8.63982 11.606 8.72649L2.418 14.7135C2.27976 14.8124 2.11495 14.8675 1.945 14.8715"
                  fill="#D1433A"
                  class="play-song-svg"
                ></path>
                <path
                  d="M1.945 14.8685C1.79896 14.8649 1.65553 14.8291 1.525 14.7635C1.37619 14.676 1.25074 14.5538 1.15933 14.4074C1.06792 14.261 1.01326 14.0946 1 13.9225L1 1.95149C1.00785 1.78456 1.05473 1.62179 1.13688 1.47626C1.21902 1.33072 1.33414 1.20648 1.473 1.11349C1.61905 1.03089 1.78575 0.991979 1.95327 1.00137C2.1208 1.01077 2.2821 1.06808 2.418 1.16649L11.606 7.15149C11.7353 7.23816 11.8413 7.35534 11.9146 7.49269C11.9879 7.63003 12.0262 7.78331 12.0262 7.93899C12.0262 8.09466 11.9879 8.24795 11.9146 8.38529C11.8413 8.52264 11.7353 8.63982 11.606 8.72649L2.418 14.7135C2.27976 14.8124 2.11495 14.8675 1.945 14.8715"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  class="play-song-svg"
                ></path>
              </svg>
              Play Song
            </button>
            <button
              class="ad-to"
              @click="
                openListOption(
                  $event,
                  listenedSong.details.objects[0],
                  'default_option_list',
                  listenedSong.details.objects[0],
                  true,
                  '',
                  '',
                  true
                )
              "
            >
              <span>
                <svg
                  class="mr-0"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.00006 1C8.42239 1 8.76476 1.34237 8.76476 1.76471V14.2353C8.76476 14.6576 8.42239 15 8.00006 15C7.57772 15 7.23535 14.6576 7.23535 14.2353V1.76471C7.23535 1.34237 7.57772 1 8.00006 1Z"
                    fill="#071526"
                    stroke="#071526"
                    stroke-width="0.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 8.00006C1 7.57772 1.34237 7.23535 1.76471 7.23535H14.2353C14.6576 7.23535 15 7.57772 15 8.00006C15 8.42239 14.6576 8.76476 14.2353 8.76476H1.76471C1.34237 8.76476 1 8.42239 1 8.00006Z"
                    fill="#071526"
                    stroke="#071526"
                    stroke-width="0.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p>Add to</p>
              <span>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5.76 5.679L1 10.77"
                    stroke="#47505B"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="seeall-btn">
        <button>SEE ALL</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    activityNotifications: {
      type: Array,
      required: true,
    },
  },
  filters: {
    filterTitle(notification) {
      const title =
        notification.activities[0] &&
        notification.activities[0].model &&
        notification.activities[0].model.title
          ? notification.activities[0].model.title
          : "";
      return title.length > 10 ? title.slice(0, 13) + "..." : title;
    },
    filterUserName(listenedSong) {
      let name =
        listenedSong.user && listenedSong.user.full_name
          ? listenedSong.user.full_name
          : listenedSong.user && listenedSong.user.name
          ? listenedSong.user.name
          : "";
      name = name.trim().split(" ");
      return name && name.length > 0 && name[0].length > 8
        ? name[0]
        : name[0] + " " + name[1].slice(0, 1);
    },
  },
  computed: {
    listenedSongs() {
      return this.$store.getters.getListenedSongs;
    },
    currentPlayer() {
      return this.$store.getters.getCurrentPlayer;
    },
    currentPlayId() {
      return this.$store.getters.getCurrentPlayId;
    },
    songStatus() {
      return this.$store.getters.getSongsStatus;
    },
    currentPlayTypeID() {
      return this.$store.getters.getCurrentPlayTypeId;
    },
    activityId() {
      return this.$store.getters.getCurrentPlayActivityId;
    },
  },
  mounted() {
    this.$store.dispatch("getListenedSongs");
  },
};
</script>
<style lang="scss" scoped>
.activity-main-list {
  .activity-block {
    width: 240px;
    height: 130px;

    .activity-user-detail {
      .activity-user-detail {
        .activity-user-detail-text {
          font-family: HelveticaNeueNormal, sans-serif;
          color: #071526;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          margin-bottom: 5px;
          margin-top: 1px;
          span {
            font-family: HelveticaNeueNormal, sans-serif;
            color: #071526;
            font-size: 12px;
            font-style: normal;
            line-height: normal;
          }
        }
        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #071526;
          font-family: HelveticaNeueMedium, sans-serif;
        }
      }
      .activity-user-detail {
        &.user-forum {
          margin-left: 10px;
          .time-ago {
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            text-transform: lowercase;
            color: #071526;
            font-family: HelveticaNeueNormal, sans-serif;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .music-play-section {
      margin-top: 8px;
      margin-bottom: 10px;
      ul {
        li {
          img {
            border-radius: 4px;
          }
        }
      }
    }
    .detail-activities {
      .vol-icon {
        svg {
          border-radius: 4px;
        }
      }
      .music-activity-image {
        img {
          border-radius: 4px;
        }
      }
      .music-activity-texts {
        h1 {
          color: #47505B;
          font-family: HelveticaNeueMedium, sans-serif;
          font-size: 11px;
          font-style: normal;
          line-height: normal;
        }
      }
    }
  }
}
</style>
