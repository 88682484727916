import AppConfig from '../configs/AppConfig'
import moment from 'moment-timezone'
import md5 from 'md5'

/**
 * Check for, is object empty?
 * @param {*object} obj
 */
export const isObjectEmpty = (obj) => {
    let names = Object.getOwnPropertyNames(obj);
    return Promise.resolve({status: (names.length === 0) ? true : false, names});
}

/**
 * Check for, is data array format?
 * @param {*object} obj
 * @param {*function} cb
 */
export const isDataArray = (obj, cb) => {
    cb(obj.length !== undefined ? true : false);
}

/**
 * For sorting
 * @param {*any} a
 * @param {*any} b
 */
export const compare = (a, b) => {
    if (a.name < b.name)
        return -1;
    if (a.name > b.name)
        return 1;
    return 0;
}

/**
 * Validate email address
 * @param {*string} email
 */
export const validateEmail = (email) => {
    var re =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
}

export const validateUsername = (username) => {
    var re =  /^[a-zA-Z0-9]+$/;
    return re.test(username);
}

//
// /**
//  * Validate first_name address
//  * @param {*string} first_name
//  */
// export const  validateFirstName = (first_name) => {
//     var re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
//     return re.test(first_name);
// }
//
// /**
//  * Validate first_name address
//  * @param {*string} last_name
//  */
// export const  validateLastName = (last_name) => {
//     var re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
//     return re.test(last_name);
// }

/**
 * Validate name address
 * @param {*string} name
 */
export const validateName = (name) => {
    var re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
    return re.test(name);
}

export const validateExpiry = (expirt) => {
    // var re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
    // return re.test(name);
    return true;
}

export const validteCvv = (expirt) => {
    // var re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
    // return re.test(name);
    return true;
}

export const validateCardNumber = (ccNum) => {

    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
        isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
        isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
        isValid = true;
    } else if (discovRegEx.test(ccNum)) {
        isValid = true;
    }

    return isValid;
}

/**
 * Validate vehical_name address
 * @param {*string} vehical_no
 */
export const validateVehical_no = (vehical_no) => {
    var re = /^[A-Za-z0-9]+$/
    return re.test(vehical_no);
}

/**
 * Validate vehical_name address
 * @param {*string} vehical_name
 */
export const validateVehical_name = (vehical_name) => {
    var re = /^[A-Za-z]+$/
    return re.test(vehical_name);
}

/**
 * Validate license_no address
 * @param {*string} license_no
 */
export const validateLicense_no = (license_no) => {
    var re = /^[A-Za-z0-9]+$/
    return re.test(license_no);
}

/**
 * Validate mobile
 * @param {*string} number
 */
export const validateMobile = (data) => {
    const number = data.toString();
    // var re = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
    // return re.test(number);

    return (number.length >= 10 && number.length <= 12);
}

export const validateSalesTax = (number) => {
    var re = /^[0-9]+$/
    return re.test(number);
}

export const validateAgeLength = (number) => {
    return (number >= 18 && number <= 100);
}

export const validateAge = (number) => {
    var re = /[1-9]{1,3}/
    return re.test(number)
}

export const validateZipcode = (number) => {
    var re = /^[0-9]{0,7}$/
    return re.test(number)
}

/** Validate password */
export const validatePassword = (value) => {
    return (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$").test(value)) ? true : false;
}

/** Validate website_url */
export const validateWebsite = (value) => {
    return (new RegExp("https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}").test(value)) ? true : false;
}

/** Validate card expiry date */
export const validateCardExpiryDate = (value) => {
    var splited_string = value.split("/");

    if (splited_string.length === 2) {
        var card_month = parseInt(splited_string[0]);
        var card_year = parseInt(`20${splited_string[1]}`);

        var full_year = new Date().getFullYear();
        if (card_year >= full_year && card_month >= 1 && card_month <= 12) return true;
    }

    return false;
}

/**
 * Mask Card
 * @param {*string} cardNumber
 */
export const MakeCardMask = (cardNumber) => {

    cardNumber = cardNumber.replace(/\D/gi, '');
    if (cardNumber.length < 5) return cardNumber;

    cardNumber = cardNumber.replace(/(\d{4})/, '$1-');
    cardNumber = cardNumber.replace(/(\d{4})(\d{1})/, '$1-$2');
    cardNumber = cardNumber.replace(/(\d{4})(\d{1})/, '$1-$2');
    cardNumber = cardNumber.replace(/(\d{4})(\d{1})/, '$1-$2');
    cardNumber = cardNumber.replace(/(\d{4})(\d{1})/, '$1-$2');


    return cardNumber
}

/**
 * Mask Date
 * @param {*string} date
 */
export const MakeDateMask = (dateNumber) => {

    dateNumber = dateNumber.replace(/\D/gi, '');

    if (dateNumber.length < 3) return dateNumber;

    dateNumber = dateNumber.replace(/(\d{2})/, '$1/');

    return dateNumber
}

/**
 * Merge objects
 * @param {*object} obj
 * @param {*function} oldObj
 */
export const mergeObject = (obj, oldObj) => {
    return Object.assign(obj, oldObj)
}

export const getAuthrizeToken = () => {
    return md5(`${AppConfig.app_key}${md5(moment(new Date()).tz("Asia/Kolkata").format("DD-MM-YYYY"))}`);
}

/**
 * Validate the request
 * @param {*object} obj
 */
export const validate = (parameters, obj) => {
    return isObjectEmpty(obj)
        .then(({status, names}) => {
            if (!status) {
                let existedFields = {
                    keys: names,
                    emptyKeys: []
                }
                // parameters.forEach((element) => {
                //     const value = obj[element] && typeof obj[element] === "string" ? obj[element].trim() : obj[element];
                //     !value && existedFields.emptyKeys.push({fieldName: element, message: "Required"});
                // });
                parameters.forEach((element) => {
                    const value = obj[element] !== undefined && obj[element] !== null && typeof obj[element] === "string" ? obj[element].trim() : obj[element];
                    (value === undefined || value === null || value === '') && existedFields.emptyKeys.push({fieldName: element, message: "Required"});
                });
                //Specific fields validations
                // existedFields.emptyKeys.length <= 0 &&
                existedFields.keys.forEach((element) => {
                    switch (element) {
                        case "email":
                            !validateEmail(obj["email"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Email address is not valid."
                            });
                            break;
                        case "i_agree":
                            !obj["i_agree"] && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Required",
                            });
                            break;
                        case "first_name":
                            if (obj["first_name"].replace(/\s+/g, "").length < 4) {
                                existedFields.emptyKeys.push({
                                    fieldName: element,
                                    message: "First Name must have at least 4 letters."
                                });
                            }
                            break;
                        case "last_name":
                            if (obj["last_name"].replace(/\s+/g, "").length < 4) {
                                existedFields.emptyKeys.push({
                                    fieldName: element,
                                    message: "Last Name must have at least 4 letters."
                                });
                            }
                            break;
                        case "username":
                            !validateUsername(obj["username"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Special characters are not allowed."
                            });
                            break;
                        case "name":
                            !validateName(obj["name"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Name is not valid."
                            });
                            break;
                        case "f_name":
                            !validateName(obj["f_name"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "First name is not valid."
                            });
                            break;
                        case "l_name":
                            !validateName(obj["l_name"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Last name is not valid."
                            });
                            break;
                        case "card_number":
                            !validateCardNumber(obj["card_number"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Last name is not valid."
                            });
                            break;
                        case "expiry":
                            !validateExpiry(obj["expiry"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Last name is not valid."
                            });
                            break;
                        case "cvv":
                            !validteCvv(obj["cvv"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Last name is not valid."
                            });
                            break;
                        case "office_phone":
                            !validateMobile(obj["office_phone"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Office phone is not valid."
                            });
                            break;
                        case "home_phone":
                            !validateMobile(obj["home_phone"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Home number is not valid."
                            });
                            break;
                        case "mobile":
                            !validateMobile(obj["mobile"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Mobile number is not valid."
                            });
                            break;
                        case "phone":
                            !validateMobile(obj["phone"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Phone number is not valid."
                            });
                            break;
                        case "mobile_number":
                            !validateMobile(obj["mobile_number"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Mobile number is not valid."
                            });
                            break;
                        case "zipcode":
                            !validateZipcode(obj["zipcode"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Zipcode is not valid."
                            });
                            break;
                        case "card_expiry_date":
                            !validateCardExpiryDate(obj["card_expiry_date"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Expiry date is not valid."
                            });
                            break;
                        case "age":
                            !validateAge(obj["age"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Age is not valid."
                            });
                            !validateAgeLength(obj["age"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Age length is not valid."
                            });
                            break;
                        case "password":
                            !validatePassword(obj["password"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid password, password must have atleast 8 characters, one uppercase character, one lower case character and one special character."
                            });
                            break;
                        case "facebook_url":
                            !validateWebsite(obj["facebook_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "google_url":
                            !validateWebsite(obj["google_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "instagram_url":
                            !validateWebsite(obj["instagram_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "twitter_url":
                            !validateWebsite(obj["twitter_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "linkedin_url":
                            !validateWebsite(obj["linkedin_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "youtube_url":
                            !validateWebsite(obj["youtube_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "vkontake_profile_url":
                            !validateWebsite(obj["vkontake_profile_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "website_url":
                            !validateWebsite(obj["website_url"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "website":
                            !validateWebsite(obj["website"]) && existedFields.emptyKeys.push({
                                fieldName: element,
                                message: "Invalid website link."
                            });
                            break;
                        case "confirm_password":
                            if (obj["password"] !== obj["confirm_password"]) {
                                existedFields.emptyKeys.push({
                                    fieldName: "password",
                                    message: "Password is not matched."
                                });
                                existedFields.emptyKeys.push({
                                    fieldName: "confirm_password",
                                    message: "Password is not matched."
                                });
                            }
                            break;
                    }
                });
                return Promise.resolve({
                    status: existedFields.emptyKeys.length > 0 ? false : true,
                    response: existedFields.emptyKeys
                });
            } else return Promise.resolve({status: false, response: parameters});
        });
}

export const isValidForm = (keys, body) => {
    return validate(keys, body)
        .then(({status, response}) => {
            if (status) {
                return Promise.resolve({message: "Success"});
            } else return Promise.resolve({status, response});
        })
}

/** Reset and push rout in stack */
export const resetAndPushRoot = (history, path) => {
    history.entries = [];
    history.index = -1;
    history.push(path);
}
