<template>
  <div class="Signup_pop_overlay">
    <div class="wr_launchRoom">
      <div class="title_launch">
        <div>Launch Room</div>
        <div class="close_icon" @click="setActiveLaunchRoom()">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="wr_info">
        <div class="tabs_launch">
          <div class="tabs" :class="{ active_tab_launch: tabsLaunch >= 1 }">
            INFO
          </div>
          <div class="tabs" :class="{ active_tab_launch: tabsLaunch >= 2 }">
            USERS
          </div>
          <div class="tabs" :class="{ active_tab_launch: tabsLaunch >= 3 }">
            SCHEDULE
          </div>
        </div>
        <div class="conten_launch">
          <InfoLaunch v-if="tabsLaunch === 1" ref="roomInfo" />
          <UsersLaunch v-if="tabsLaunch === 2" ref="userInfo" />
          <ScheduleLaunch v-if="tabsLaunch === 3" ref="scheduleInfo" />
        </div>
        <div class="btns">
          <button class="cancel" @click="SET_ACTIVE_LAUNCH_ROOM">Cancel</button>
          <button v-if="tabsLaunch < 3" @click="changeTabs" class="next">
            Next Step
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.25 9L5.25 5L1.25 1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button v-if="tabsLaunch === 3" class="next" @click="launchRoom">LAUNCH</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import InfoLaunch from "./InfoLaunch.vue";
import ScheduleLaunch from "./ScheduleLaunch.vue";
import UsersLaunch from "./UsersLaunch.vue";
export default {
  components: {
    InfoLaunch,
    ScheduleLaunch,
    UsersLaunch
  },
  data() {
    return {
      tabsLaunch: 1,
      coverImage: null
    };
  },

  methods: {
    ...mapMutations(["SET_ACTIVE_LAUNCH_ROOM"]),
    changeTabs() {
      let roomInfo=null
      if(this.tabsLaunch===1)
      {
        const { success, file } =this.$refs.roomInfo.setRoomInfo()
        roomInfo = success
        this.coverImage = file
      }
      else if(this.tabsLaunch===2)
      {
        roomInfo=this.$refs.userInfo.setUserInfo()
      }
      if(roomInfo)
      {
        this.tabsLaunch += 1;
      }

    },
    async launchRoom()
    {
      if(this.tabsLaunch===3)
      {
       const roomInfo=this.$refs.scheduleInfo.setScheduleInfo()
        if(roomInfo)
        {
          await this.$store.commit('SET_ACTIVE_LAUNCH_ROOM',false)
          await this.launchRoomStream(this.coverImage)
        }
      }
    },
    setActiveLaunchRoom()
    {
      this.$store.commit('SET_ADD_MEMBER_CHECK', false)
      this.$store.commit('SET_ACTIVE_LAUNCH_ROOM',false)
    }
  },
};
</script>

<style lang="scss" scoped>
.next:hover {
  transition: all 0.3s;
  opacity: 0.8;
}

.cancel:hover {
  transition: all 0.3s;
  background: #d1d9e3 !important;
}

.wr_launchRoom {
  width: 570px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
  border-radius: 5px;
  .close_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    &:hover {
      background: #e4e6eb;
    }
  }
  .title_launch {
    padding: 20px 20px 13px;
    border-bottom: 1px solid #e4e6eb;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #071526;
    font-weight: 700;
    font-size: 20px;
    font-family: HelveticaNeueBold;
  }
  .wr_info {
    padding: 0 20px 20px 20px;
    .tabs_launch {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tabs {
        text-align: center;
        width: 170px;
        color: #c4ccd6;
        font-family: HelveticaNeueBold;
        font-weight: 700;
        font-size: 16px;
        border-bottom: 3px solid #c4ccd6;
        padding: 15px 0;
      }
      .active_tab_launch {
        color: #47505b;
        border-color: #D1433A;
      }
    }
    .btns {
      margin-top: 20px;
      display: flex;
      padding-top: 20px;
      justify-content: space-between;
      border-top: 1px solid #e6eaf0;
      button {
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 40px;
        background: #e6eaf0;
        color: #47505b;
        font-weight: 700;
        font-family: HelveticaNeueBold;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 4px;
        svg {
          margin-left: 8px;
        }
      }
      .next {
        color: #ffffff;
        background: #D1433A;
      }
    }
  }
}
</style>
