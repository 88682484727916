<template>
  <div class="w-100" ref="busstop">
    <div class="new_release">
      <div class="wrapper">
        <div class="row">
          <div class="col">
            <div class="overview-mains">
              <h1 class="Page_title">New Releases</h1>
                 <div class="switch-field">
                  <payStreamSwitch/>
              </div>
            </div>
          </div>
        </div>
        <div class=" discover content">
          <div class=" gallery-card ">
            <div class="row">
              <div class="col-sm-12">
                <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index">
                  <component v-bind:is="myComponentList[singleChannel.alt_name].component"
                             :cname="singleChannel.description"
                             :myInd="index"
                             :gridTitle="singleChannel.title"
                             :header="true"
                             :altName="singleChannel.alt_name"
                             :channelType="singleChannel.type"
                             :pageTitle="singleChannel.title"
                             :redirectLink="'continue-listening-to'"
                             :listData="singleChannel.objects.data"
                             v-if="myComponentList[singleChannel.alt_name] && singleChannel.channel_element_view != 'four_grid_slider' && singleChannel.objects.data.length > 0 && singleChannel.channel_element_view == 'six_grid'"/>
                </div>

              </div>
            </div>
          </div>
          <div class="tab-pane moods-pane">
            <div class="row">
              <div class="col-12">
                <div class="new-releae-geners">
                  <h1 class="new-release-geners-title">Genres</h1>
                  <div class="gen_mooods">
                    <ul>
                      <li @click="selectGenre($event,eachOp.id)" class="gen-mood-wrapper"
                          v-for="(eachOp, index) in genres" :key="index">
                        <a :class="{'active':isSelectedGenre(eachOp.id)}" href="javascript:;">
                          <span v-html="eachOp.name"></span>
                        </a>
                      </li>
                    </ul>
                    <div @click="clearGenreFilter" class="clear-history clr-history-btn"  v-if="selectedGenres.length>0">
                      <span class="clear-history-text">Clear all history</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index"
                 :class="{'col-lg-12 col-xl-6':singleChannel.alt_name==='top-new-singles' || singleChannel.alt_name==='top-new-albums'}">
              <component v-bind:is="myComponentList[singleChannel.alt_name].component"
                         :cname="singleChannel.description"
                         :myInd="index"
                         :gridTitle="singleChannel.title"
                         :altName="singleChannel.alt_name"
                         :channelType="singleChannel.type"
                         :pageTitle="singleChannel.title"
                         :header="true"
                         :redirectLink="myComponentList[singleChannel.alt_name].link"
                         :listData="singleChannel.objects.data"
                         v-if="myComponentList[singleChannel.alt_name] && singleChannel.channel_element_view != 'four_grid_slider'&& singleChannel.objects.data.length > 0 && singleChannel.channel_element_view != 'six_grid'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style"/>
    <full-page-loader :show="loading"/>
  </div>
</template>
<script type="application/javascript">
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue"
import GridSellingWithSlider from "@/components/Common/Elements/GridSellingWithSlider.vue";
import GridSelling6NoSlider from "@/components/Common/Elements/GridSelling6NoSlider.vue";
import TrendingRelease from "@/components/Common/Elements/TrendingRelease.vue";
import TopNewSinglesAlbums from "@/components/Common/Elements/TopNewSinglesAlbums.vue";
import {mapActions} from 'vuex';
import {getChannelData} from '../../../apis/APIs';

export default {
  data() {
    return {
      showMd: false,
      showAd: false,
      selectedGenres: [],
      loading: false,
      dataLoaded: false,
      allChannels: [],
      myComponentList: {
        // New Releases
        'new-releases': {'component': 'GridSelling6NoSlider', 'link': 'new-releases', 'subComponent': ''},
        'top-new-singles': {'component': 'TopNewSinglesAlbums', 'link': 'top-new-singles', 'subComponent': ''},
        'top-new-albums': {'component': 'TopNewSinglesAlbums', 'link': 'top-new-albums', 'subComponent': ''},
        'pre-order-future-releases': {
          'component': 'GridSellingWithSlider',
          'link': 'pre-order-future-releases',
          'subComponent': ''
        },
        'recent-releases': {'component': 'GridSellingWithSlider', 'link': 'recent-releases', 'subComponent': ''},
        'popular-artist-this-week': {
          'component': 'TrendingRelease',
          'link': 'popular-artist-this-week',
          'subComponent': ''
        },//3
      }
    }
  },
  components: {
    payStreamSwitch,
    GridSellingWithSlider,
    TrendingRelease,
    GridSelling6NoSlider,
    TopNewSinglesAlbums
  },
  watch:{
    selectedGenres(){
     this.pageChannels()
    },
  },
  computed: {
    genres() {
      return (this.$store.getters.getGenres && this.$store.getters.getGenres.length > 0) ? this.$store.getters.getGenres : [];
    }
  },
  async mounted() {
    await this.pageChannels();
    await this.getGenres();
  },
  methods: {
    ...mapActions([
      "get_geners"
    ]),
    async pageChannels() {
      try {
        this.allChannels = [];
        await this.$store.dispatch('loading', true)
        let data = await getChannelData('new_release',this.selectedGenres);
        this.allChannels = data.data.channels;
        await this.$store.dispatch('loading', false)
      } catch (error) {
        await this.$store.dispatch('loading', false)
      }
    },
    async getGenres() {
      try {
        if (!this.$store.getters.getGenres && !this.$store.getters.getGenres.length > 0) {
          await this.$store.dispatch('get_geners')
        }
      } catch (error) {
        console.error(error)
      }
    },
    clearGenreFilter(){
      this.selectedGenres =[]
    },
    selectGenre(e,value) {
      e.stopPropagation()
      const index = this.selectedGenres.findIndex(e => e === value);
      if(index !== -1) {
        this.selectedGenres.splice(index, 1)}
      else{
        this.selectedGenres.push(value);
      }
    },
    isSelectedGenre(id){
      const index = this.selectedGenres.findIndex(e=>e === id)
      return(index !== -1)
    },
    redirect: function (url) {
      this.$router.push({name: url, params: {title: 'Hot new singles', pageFrom: 'overview'}})
    },
  }
}
</script>
<style>
.notification-style {
  margin-top: 5rem;
}
.switch-field input:checked + label::before {
  content: '';
  position: absolute;
  background-image: url(../../../../src/assets/img/check-white.png);
  width: 12px;
  height: 9px;
  top: 9px;
  left: 8%;
}

.all_singles .owl-item {
  width: 393px !important;
}

.new_release .gallery-card {
  padding-top: 0;
}

.new_release .gallery-card > .row {
  position: unset;
}


</style>
