import {
    PAGE_ACTIVE_TAB,
    CURRENT_PAGE,
    DISCOVER_PAGES,
    ERROR,
    LIKED_PAGES,
    LOADING,
    PAGE,
    PAGE_ABOUT,
    PAGE_ADDRESSES,
    PAGE_CATEGORIES,
    PAGE_CATEGORY,
    PAGE_COVER,
    PAGE_LIMIT,
    PAGE_NAME,
    PAGE_OFFSET,
    PAGE_POSTS,
    PAGE_PROFILE,
    PAGE_SPECIFICATION,
    PAGE_SUB_CATEGORY,
    PAGE_TYPE,
    PAGES,
    POST_LIMIT,
    POST_LIST,
    POST_OFFSET,
    POST_TOTAL,
    SUCCESS,
    TOTAL_PAGES,
    USER_PAGES,
    Artist_Followers_List,
    Artist_Followers_Offset,
    Artist_Followers_Limit,
    Artist_Followers_Total,
    Artist_Followings_List,
    Artist_Followings_Offset,
    Artist_Followings_Limit,
    Artist_Followings_Total,
    Artist_Mutual_Followings_List,
    Artist_Mutual_Followings_Offset,
    Artist_Mutual_Followings_Limits,
    Artist_Mutual_Followings_Total,
    Artist_Followings_User_Type,
    Artist_Followers_User_Type,
    Artist_Mutual_Followings_User_Type,
    Similar_Artists_List,
    Similar_Artists_Offset,
    Similar_Artists_Limit,
    Similar_Artists_Total,
    ROLES,
    PAGE_ROLES,
    SEARCH_ARTISTS,
    GENRE,
    SUB_GENRE,
    SELECTED_PAGE_CATEGORIES,
    SELECTED_FILTERS,
    SELECTED_CATEGORY,
    SELECTED_LOCATION,
    SELECTED_GENRE,
    SELECTED_SORT,
    PAGES_TYPE,
    SELECTED_SUBCATEGORY,
    FAVORITE_PAGE_LISTING,
    PAGE_EVENTS,
    PAGE_PARTNER_ROLES,
    FRIENDS_SEARCH,
    SEARCH_PARTNERS
} from "../types";
import {
    addPage,
    getArtistPageFollowers,
    getArtistPageFollowings,
    getFavouritePagesByType,
    getMyFollowers,
    getMyPagesByType,
    getPage,
    getPageCategories,
    getPagesByType,
    searchProfileFriends,
    updatePageBasicInfo,
    getArtistPageMutualFollowings,
    searchArtistFollowers,
    pageChangeUserFollowStatus,
    changeFollowStatus,
    pageBasicSettings,
    pageDelete,
    pageWorkSetting,
    getPageSimilarArtists,
    getUserRoles,
    getPageUserRoles,
    searchArtists, getFavoritePageListing, clubPageGeneralSettings, getPagePartnerRoles, searchPartners
} from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";
import router from '../../router/index';
import mixin from "../../mixins/sweetAlert";

const INITIAL_STATE = {
    [PAGE_ROLES]: [],
    [PAGE_PARTNER_ROLES]: [],
    [SEARCH_PARTNERS]: [],
    [PAGES_TYPE]: '',
    [FAVORITE_PAGE_LISTING] : [],
    [SEARCH_ARTISTS]: [],
    [SELECTED_FILTERS]: {
        [SELECTED_CATEGORY]: '',
        [SELECTED_SUBCATEGORY]: '',
        [SELECTED_LOCATION]: {},
        [SELECTED_GENRE]: '',
        [SELECTED_SORT]: 'latest'
    },
    [PAGE_ACTIVE_TAB]: '',
    [PAGE]: {
        [PAGE_NAME]: null,
        [PAGE_ADDRESSES]: null,
        [PAGE_CATEGORY]: null,
        [PAGE_SUB_CATEGORY]: null,
        [GENRE]: null,
        [SUB_GENRE]: null,
        [PAGE_ABOUT]: null,
        [PAGE_PROFILE]: null,
        [PAGE_COVER]: null,
        [PAGE_TYPE]: null,
        [PAGE_SPECIFICATION]: null,
    },
    [DISCOVER_PAGES]: {
        [PAGES]: [],
        [TOTAL_PAGES]: 0,
        [PAGE_OFFSET]: 0,
        [PAGE_LIMIT]: 8,
        [LOADING]: false,
    },
    [LIKED_PAGES]: {
        [PAGES]: [],
        [TOTAL_PAGES]: 0,
        [PAGE_OFFSET]: 0,
        [PAGE_LIMIT]: 8,
        [LOADING]: false,
    },
    [USER_PAGES]: {
        [PAGES]: [],
        [TOTAL_PAGES]: 0,
        [PAGE_OFFSET]: 0,
        [PAGE_LIMIT]: 8,
        [LOADING]: false,
    },

    [PAGE_CATEGORIES]: [],

    [Artist_Followers_List]: [],
    [Artist_Followers_Offset]: 0,
    [Artist_Followers_Limit]: 10,
    [Artist_Followers_Total]: null,

    [Artist_Followings_List]: [],
    [Artist_Followings_Offset]: 0,
    [Artist_Followings_Limit]: 10,
    [Artist_Followings_Total]: null,

    [Artist_Mutual_Followings_List]: [],
    [Artist_Mutual_Followings_Offset]: 0,
    [Artist_Mutual_Followings_Limits]: 10,
    [Artist_Mutual_Followings_Total]: null,

    [Artist_Followings_User_Type]: null,
    [Artist_Followers_User_Type]: null,
    [Artist_Mutual_Followings_User_Type]: null,

    [Similar_Artists_List]: [],
    [Similar_Artists_Offset]: 0,
    [Similar_Artists_Limit]: 10,
    [Similar_Artists_Total]: null,
    [PAGE_EVENTS]:[]

}

export const state = {...INITIAL_STATE};

export const actions = {
    async createPage({state, commit, dispatch}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                const pageFormData = state[PAGE];
                console.log('page data',pageFormData);
                const formData = new FormData();
                for (const field in pageFormData) {
                    if (Array.isArray(pageFormData[field])) {
                        if (pageFormData[field].length > 0) {
                            for (let i = 0; i < pageFormData[field].length; i++) {
                                formData.append(field + "[" + i + "]", pageFormData[field][i]);
                            }
                        }
                    } else {
                        if (!!pageFormData[field]) {
                            formData.append(field, pageFormData[field]);
                        }
                    }
                }
                const {data} = await addPage(formData);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                const {data} = error
                const {message} = data.errors[0]
                reject({
                    status: ERROR,
                    message: message
                });
            }
        });
    },
    async UpdatePageBasicInfo({state, commit, dispatch}, pageId) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                const pageFormData = state[PAGE];
                const formData = new FormData();
                for (const field in pageFormData) {
                    if (Array.isArray(pageFormData[field])) {
                        if (pageFormData[field].length > 0) {
                            for (let i = 0; i < pageFormData[field].length; i++) {
                                formData.append(field + "[" + i + "]", pageFormData[field][i]);
                            }
                        }
                    } else {
                        if (!!pageFormData[field]) {
                            formData.append(field, pageFormData[field]);
                        }
                    }
                }
                const {data} = await updatePageBasicInfo(pageId, formData);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getMyPages({state, commit, dispatch}, showMore = false) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: USER_PAGES,
                    loadingState: true
                });
                if (showMore) {
                    commit("NEXT_PAGINATE_PAGES_OFFSET_SET", USER_PAGES);
                }
                const {data} = await getMyPagesByType(
                    state[PAGES_TYPE],
                    state[USER_PAGES][POST_OFFSET],
                    state[USER_PAGES][POST_LIMIT]
                );
                const {information} = data;
                if (showMore) {
                    commit("JOIN_USER_PAGES_WITH_OTHER_PAGES", information.data);
                } else {
                    commit("SET_USER_PAGES", information.data);
                }
                commit("SET_USER_PAGES_TOTAL", information.total);
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: USER_PAGES,
                    loadingState: false
                });
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: USER_PAGES,
                    loadingState: false
                });
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getLikedPages({state, commit, dispatch}, showMore = false) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: LIKED_PAGES,
                    loadingState: true
                });
                if (showMore) {
                    commit("NEXT_PAGINATE_PAGES_OFFSET_SET", LIKED_PAGES);
                }
                const {data} = await getFavouritePagesByType(
                    state[PAGES_TYPE],
                    state[LIKED_PAGES][POST_OFFSET],
                    state[LIKED_PAGES][POST_LIMIT],
                    state[SELECTED_FILTERS][SELECTED_CATEGORY] ? state[SELECTED_FILTERS][SELECTED_CATEGORY] : '',
                    state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] ? state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] : '',
                    state[SELECTED_FILTERS][SELECTED_LOCATION] ? state[SELECTED_FILTERS][SELECTED_LOCATION] : {},
                    state[SELECTED_FILTERS][SELECTED_GENRE] ? state[SELECTED_FILTERS][SELECTED_GENRE] : '',
                    state[SELECTED_FILTERS][SELECTED_SORT] ? state[SELECTED_FILTERS][SELECTED_SORT] : '',
                );
                const {information} = data;
                if (showMore) {
                    commit("JOIN_LIKED_PAGES_WITH_OTHER_PAGES", information.data);
                } else {
                    commit("SET_LIKED_PAGES", information.data);
                }
                commit("SET_LIKED_PAGES_TOTAL", information.total);
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: LIKED_PAGES,
                    loadingState: false
                });
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: LIKED_PAGES,
                    loadingState: false
                });
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getAllPages({state, commit, dispatch}, showMore = false) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: DISCOVER_PAGES,
                    loadingState: true
                });
                if (showMore) {
                    commit("NEXT_PAGINATE_PAGES_OFFSET_SET", DISCOVER_PAGES);
                }
                const {data} = await getPagesByType(
                    state[PAGES_TYPE],
                    state[DISCOVER_PAGES][POST_OFFSET],
                    state[DISCOVER_PAGES][POST_LIMIT],
                    state[SELECTED_FILTERS][SELECTED_CATEGORY] ? state[SELECTED_FILTERS][SELECTED_CATEGORY] : '',
                    state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] ? state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] : '',
                    state[SELECTED_FILTERS][SELECTED_LOCATION] ? state[SELECTED_FILTERS][SELECTED_LOCATION] : {},
                    state[SELECTED_FILTERS][SELECTED_GENRE] ? state[SELECTED_FILTERS][SELECTED_GENRE] : '',
                    state[SELECTED_FILTERS][SELECTED_SORT] ? state[SELECTED_FILTERS][SELECTED_SORT] : '',
                );
                const {information} = data;
                if (showMore) {
                    commit("JOIN_DISCOVER_PAGES_WITH_OTHER_PAGES", information.data);
                } else {
                    commit("SET_DISCOVER_PAGES", information.data);
                }
                commit("SET_DISCOVER_PAGES_TOTAL", information.total);
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: DISCOVER_PAGES,
                    loadingState: false
                });
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: DISCOVER_PAGES,
                    loadingState: false
                });
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            } finally {
                dispatch('loading', false)
                commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                    type: DISCOVER_PAGES,
                    loadingState: false
                });
            }
        });
    },
    async getPageCategories({state, commit, dispatch},stopLoadingOnComplete= false) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                const {data} = await getPageCategories();
                commit("SET_PAGE_CATEGORIES", data.information);
                if(stopLoadingOnComplete){
                    dispatch("loading", false);
                }
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getArtistPageFollowers({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                if (payload.showMore) {
                    state[Artist_Followers_Offset] = state[Artist_Followers_List].length;
                    // state[Artist_Followers_Limit] = state[Artist_Followers_List].length;
                    state[Artist_Followers_Limit] = 10;
                } else {
                    state[Artist_Followers_Offset] = 0;
                    state[Artist_Followers_Limit] = 10;
                }
                if (payload.name === null || payload.name === "") {
                    var {data} = await getArtistPageFollowers(
                        payload.page_id,
                        state[Artist_Followers_Offset],
                        state[Artist_Followers_Limit]
                    );
                } else {
                    var {data} = await searchArtistFollowers(
                        payload.page_id,
                        state[Artist_Followers_Offset],
                        state[Artist_Followers_Limit],
                        payload.search_type,
                        payload.name
                    );
                }
                if (payload.showMore) {
                    commit("join_with_setArtistFollowers", data.information.data);
                } else {
                    commit("setArtistFollowers", data.information.data);
                }
                commit("setArtistFollowersTotal", data.information.total);
                commit("setArtistFollowerUserType", data.user);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getArtistPageFollowings({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                if (payload.showMore) {
                    state[Artist_Followings_Offset] = state[Artist_Followings_List].length;
                    //state[Artist_Followings_Limit] = state[Artist_Followings_List].length;
                    state[Artist_Followings_Limit] = 10;
                } else {
                    state[Artist_Followings_Offset] = 0;
                    state[Artist_Followings_Limit] = 10;
                }
                if (payload.name === null || payload.name === "") {
                    var {data} = await getArtistPageFollowings(
                        payload.page_id,
                        state[Artist_Followings_Offset],
                        state[Artist_Followings_Limit]
                    );
                } else {
                    var {data} = await searchArtistFollowers(
                        payload.page_id,
                        state[Artist_Followings_Offset],
                        state[Artist_Followings_Limit],
                        payload.search_type,
                        payload.name
                    );
                }
                if (payload.showMore) {
                    commit("join_with_setArtistFollowings", data.information.data);
                } else {
                    commit("setArtistFollowings", data.information.data);
                }
                commit("setArtistFollowingsTotal", data.information.total);
                commit("setArtistFollowingUserType", data.user);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getArtistPageMutualFollowings({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                if (payload.showMore) {
                    state[Artist_Mutual_Followings_Offset] = state[Artist_Mutual_Followings_List].length;
                    state[Artist_Mutual_Followings_Limits] = 10;
                } else {
                    state[Artist_Mutual_Followings_Offset] = 0;
                    state[Artist_Mutual_Followings_Limits] = 10;
                }
                if (payload.name === null || payload.name === "") {
                    var {data} = await getArtistPageMutualFollowings(
                        payload.page_id,
                        state[Artist_Mutual_Followings_Offset],
                        state[Artist_Mutual_Followings_Limits]
                    );
                } else {
                    var {data} = await searchArtistFollowers(
                        payload.page_id,
                        state[Artist_Mutual_Followings_Offset],
                        state[Artist_Mutual_Followings_Limits],
                        payload.search_type,
                        payload.name
                    );
                }
                if (payload.showMore) {
                    commit("join_with_setArtistMutualFollowings", data.information.data);
                } else {
                    commit("setArtistMutualFollowings", data.information.data);
                }
                commit("setArtistMutualFollowingsTotal", data.information.total);
                commit("setArtistMutualFollowingUserType", data.user);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async pageChangeUserFollowStatus({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                if (payload.object_type === "user") {
                    let obj = {
                        id: payload.user_id,
                        object_type: "user",
                        action: payload.action
                    };
                    const {data} = await changeFollowStatus(obj);
                    if (data.success === 1) {
                        if (payload.user) {
                            payload.user.follow_status = payload.action;
                        }
                    }
                } else if (payload.object_type === "admin") {
                    let obj = {
                        user_id: payload.user_id,
                        page_id: payload.page_id,
                        type: "User",
                        action: payload.action
                    };
                    const {data} = await pageChangeUserFollowStatus(obj);
                    if (data.success === 1) {
                        if (payload.user) {
                            payload.user.page_follow_status = payload.action;
                        }
                    }
                }
                dispatch("loading", false);
            } catch (error) {
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async pageBasicSettings({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                let obj = {
                    messaging: payload.messaging,
                    visitor_posts: payload.visitor_posts,
                    age_restrictions: payload.age_restrictions
                };
                const {data} = await pageBasicSettings(obj, payload.page_id);
                dispatch("loading", false);
            } catch (error) {
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async clubPageGeneralSettings({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                let obj = {
                    access: payload.access,
                    visitor_posts: payload.visitor_posts,
                    country_restriction: payload.country_restriction,
                    age_restrictions: payload.age_restrictions
                };
                const {data} = await clubPageGeneralSettings(obj, payload.page_id);
                dispatch("loading", false);
                resolve( {
                    data
                })
            } catch (error) {
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async pageWorkSetting({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                const {data} = await pageWorkSetting(payload.body, payload.page_id);
                dispatch("loading", false);
            } catch (error) {
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async pageDelete({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                let obj = {
                    password: payload.password
                };
                const {data} = await pageDelete(obj, payload.page_id);
                if (data.success === 1) {
                    // router.push("/home/page/pages/Artist");
                    router.push("/home/page/pages");
                    let index = state[DISCOVER_PAGES][PAGES].indexOf(payload);
                    state[DISCOVER_PAGES][PAGES].splice(index, 1);
                    await mixin.methods.vueNotification('Delete Page', 'Page Deleted Successfully!', 'success')
                } else {
                    await mixin.methods.vueNotification('Delete Page', `${data.message}`, 'error')
                }
                dispatch("loading", false);
            } catch (error) {
                await mixin.methods.vueNotification('Delete Page', 'Please try again', 'error')
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getPageSimilarArtists({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);
                if (payload.showMore) {
                    state[Similar_Artists_Offset] = state[Similar_Artists_List].length;
                    //state[Similar_Artists_Limit] = state[Similar_Artists_List].length;
                    state[Similar_Artists_Limit] = 10;
                } else {
                    state[Similar_Artists_Offset] = 0;
                    state[Similar_Artists_Limit] = 10;
                }
                const {data} = await getPageSimilarArtists(
                    payload.page_id,
                    state[Similar_Artists_Offset],
                    state[Similar_Artists_Limit]
                );
                if (payload.showMore) {
                    commit("join_with_setSimilarArtists", data.information.data);
                } else {
                    commit("setSimilarArtists", data.information.data);
                }
                commit("setSimilarArtistsTotal", data.information.total);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getPageRoles({state, commit, dispatch}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);

                const {data} = await getPageUserRoles();
                commit("SET_PAGE_ROLES", data.information);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getPartnerRoles({state, commit, dispatch}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);

                const {data} = await getPagePartnerRoles();
                commit("SET_PAGE_PARTNER_ROLES", data.information);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async searchPartner({state, commit, dispatch},payload) {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await searchPartners(payload.page_id, payload.search );
                const {information} = data
                commit('SET_SEARCH_PARTNERS_DATA', information)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async searchArtists({state, commit, dispatch}, {type, name}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch("loading", true);

                const {data} = await searchArtists(type, name);
                commit("SET_SEARCHED_ARTISTS", data.information);
                dispatch("loading", false);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch("loading", false);
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async getFavoritePageListing({state, commit, dispatch}) {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await getFavoritePageListing();
                const {information} = data;
                commit("SET_FAVORITE_PAGE_LISTING", information);
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
};

export const mutations = {
    SET_NEW_PAGE_FORM_DATA(state, page) {
        try {
            console.log('set page data');
            console.log(page);
            state[PAGE][PAGE_NAME] = page.pageName;
            state[PAGE][PAGE_ADDRESSES] = page.pageAddress;
            state[PAGE][PAGE_CATEGORY] = page.category;
            state[PAGE][GENRE] = page[GENRE];
            state[PAGE][SUB_GENRE] = page[SUB_GENRE];
            state[PAGE][PAGE_SUB_CATEGORY] = page.subCategory;
            state[PAGE][PAGE_SPECIFICATION] = page.specification;
            state[PAGE][PAGE_ABOUT] = page.about;
            state[PAGE][PAGE_TYPE] = page.type;
            state[PAGE][PAGE_PROFILE] = page.avatarImage.crop_image;
            state[PAGE][PAGE_COVER] = page.coverImage.crop_image;
            console.log(state[PAGE]);
        } catch (e) {
            console.error(e.message, "unable to set page post form data");
        }
    },
    CLEAR_NEW_PAGE_FORM_DATA(state, page) {
        try {
            state[PAGE][PAGE_NAME] = null;
            state[PAGE][PAGE_ADDRESSES] = null;
            state[PAGE][PAGE_CATEGORY] = null;
            state[PAGE][PAGE_SUB_CATEGORY] = null;
            state[PAGE][PAGE_PROFILE] = null;
            state[PAGE][PAGE_COVER] = null;
            state[PAGE][PAGE_TYPE] = null;
            state[PAGE][PAGE_SPECIFICATION] = null;
        } catch (e) {
            console.error(e.message, "unable to clear page post form data");
        }
    },
    CLEAR_PAGES_DATA(state) {
        try {
            let pages = [LIKED_PAGES, USER_PAGES, DISCOVER_PAGES];
            for (const page of pages) {
                state[page][PAGES] = [];
                state[page][TOTAL_PAGES] = 0;
                state[page][PAGE_OFFSET] = 0;
                state[page][PAGE_LIMIT] = 8;
            }
        } catch (e) {
            console.error(e.message, "unable to clear pages data");
        }
    },
    SET_OFFSET_OF_SPECIFIC_PAGES(state, type) {
        state[type][PAGES] = [];
        state[type][PAGE_OFFSET] = 0;
    },
    NEXT_PAGINATE_PAGES_OFFSET_SET(state, TYPE = DISCOVER_PAGES) {
        try {
            state[TYPE][POST_OFFSET] =
                Number(state[TYPE][POST_OFFSET]) + Number(state[TYPE][POST_LIMIT]);
        } catch (e) {
            console.error(e.message, "unable to set next offset of page posts");
        }
    },
    SET_PAGE_CATEGORIES(state, categories) {
        try {
            state[PAGE_CATEGORIES] = categories;
        } catch (e) {
            console.error(e.message, "unable to set page categories");
        }
    },
    SET_USER_PAGES(state, pages) {
        try {
            state[USER_PAGES][PAGES] = pages;
        } catch (e) {
            console.error(e.message, "unable to set page categories");
        }
    },
    SET_DISCOVER_PAGES(state, pages) {
        try {
            state[DISCOVER_PAGES][PAGES] = pages;
        } catch (e) {
            console.error(e.message, "unable to set page categories");
        }
    },
    SET_LIKED_PAGES(state, pages) {
        try {
            state[LIKED_PAGES][PAGES] = pages;
        } catch (e) {
            console.error(e.message, "unable to set page categories");
        }
    },
    SET_FAVORITE_PAGE_LISTING(state, pages) {
        try {
            state[FAVORITE_PAGE_LISTING] = pages;
        } catch (e) {
            console.error(e.message, "unable to set favorite page listing");
        }
    },
    JOIN_USER_PAGES_WITH_OTHER_PAGES(state, payload) {
        let pages = state[USER_PAGES][PAGES];
        state[USER_PAGES][PAGES] = [...pages, ...payload];
    },
    JOIN_DISCOVER_PAGES_WITH_OTHER_PAGES(state, payload) {
        let pages = state[DISCOVER_PAGES][PAGES];
        state[DISCOVER_PAGES][PAGES] = [...pages, ...payload];
    },
    JOIN_LIKED_PAGES_WITH_OTHER_PAGES(state, payload) {
        let pages = state[LIKED_PAGES][PAGES];
        state[LIKED_PAGES][PAGES] = [...pages, ...payload];
    },
    SET_USER_PAGES_TOTAL(state, payload) {
        try {
            state[USER_PAGES][TOTAL_PAGES] = payload;
        } catch (e) {
            console.error(e.message, "unable to SET Total post counts");
        }
    },
    SET_DISCOVER_PAGES_TOTAL(state, payload) {
        try {
            state[DISCOVER_PAGES][TOTAL_PAGES] = payload;
        } catch (e) {
            console.error(e.message, "unable to SET Total post counts");
        }
    },
    SET_LIKED_PAGES_TOTAL(state, payload) {
        try {
            state[LIKED_PAGES][TOTAL_PAGES] = payload;
        } catch (e) {
            console.error(e.message, "unable to SET Total post counts");
        }
    },
    CHANGE_LOADING_OF_SPECIFIC_CATEGORY(state, {type, loadingState}) {
        state[type][LOADING] = loadingState;
    },
    SET_PAGE_ACTIVE_TAB(state, payload) {
        try {
            state[PAGE_ACTIVE_TAB] = payload;
        } catch (e) {
            console.error(e.message, "unable to SET Check for Post in new Tab");
        }
    },
    setArtistFollowers(state, payload) {
        try {
            state[Artist_Followers_List] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist  followers");
        }
    },
    join_with_setArtistFollowers(state, payload) {
        try {
            let artistFollowers = state[Artist_Followers_List];
            state[Artist_Followers_List] = [...artistFollowers, ...payload];
        } catch (e) {
            console.error(e.message, "unable to get artist followers");
        }
    },
    setArtistFollowersTotal(state, payload) {
        try {
            state[Artist_Followers_Total] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist followers total");
        }
    },
    join_with_setSimilarArtists(state, payload) {
        try {
            let similarArtists = state[Similar_Artists_List];
            state[Similar_Artists_List] = [...similarArtists, ...payload];
        } catch (e) {
            console.error(e.message, "unable to get page similar artists");
        }
    },
    setSimilarArtists(state, payload) {
        try {
            state[Similar_Artists_List] = payload;
        } catch (e) {
            console.error(e.message, "unable to get page similar artists");
        }
    },
    setPageEvents(state, payload) {
        try {
            state[PAGE_EVENTS] = payload;
        } catch (e) {
            console.error(e.message, "unable to get page events");
        }
    },
    setSimilarArtistsTotal(state, payload) {
        try {
            state[Similar_Artists_Total] = payload;
        } catch (e) {
            console.error(e.message, "unable to get page similar artist total");
        }
    },
    setArtistFollowings(state, payload) {
        try {
            state[Artist_Followings_List] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist  followings list");
        }
    },
    join_with_setArtistFollowings(state, payload) {
        try {
            let artistFollowings = state[Artist_Followings_List];
            state[Artist_Followings_List] = [...artistFollowings, ...payload];
        } catch (e) {
            console.error(e.message, "unable to get artist followings list");
        }
    },
    setArtistFollowingsTotal(state, payload) {
        try {
            state[Artist_Followings_Total] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist followings total");
        }
    },
    setArtistFollowingUserType(state, payload) {
        try {
            state[Artist_Followings_User_Type] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist followings user type");
        }
    },
    setArtistFollowerUserType(state, payload) {
        try {
            state[Artist_Followers_User_Type] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist followers user type");
        }
    },
    setArtistMutualFollowingUserType(state, payload) {
        try {
            state[Artist_Mutual_Followings_User_Type] = payload;
        } catch (e) {
            console.error(
                e.message,
                "unable to get artist mutual followings user type"
            );
        }
    },
    setArtistMutualFollowings(state, payload) {
        try {
            state[Artist_Mutual_Followings_List] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist mutual followings list");
        }
    },
    join_with_setArtistMutualFollowings(state, payload) {
        try {
            let artistMutualFollowings = state[Artist_Mutual_Followings_List];
            state[Artist_Mutual_Followings_List] = [
                ...artistMutualFollowings,
                ...payload
            ];
        } catch (e) {
            console.error(e.message, "unable to get artist mutual followings list");
        }
    },
    setArtistMutualFollowingsTotal(state, payload) {
        try {
            state[Artist_Mutual_Followings_Total] = payload;
        } catch (e) {
            console.error(e.message, "unable to get artist followings total");
        }
    },
    SET_PAGE_ROLES(state, payload) {
        try {
            state[PAGE_ROLES] = payload;
        } catch (e) {
            console.error(e.message, "unable to set roles");
        }
    },
    SET_PAGE_PARTNER_ROLES(state, payload) {
        try {
            state[PAGE_PARTNER_ROLES] = payload;
        } catch (e) {
            console.error(e.message, "unable to set roles");
        }
    },
    SET_SEARCH_PARTNERS_DATA(state, payload ){
        try{
            state[SEARCH_PARTNERS] = payload
        } catch (e) {
            console.error(e.message, 'Unable to set friends search data')
        }
    },
    SET_SEARCHED_ARTISTS(state, payload) {
        try {
            state[SEARCH_ARTISTS] = payload;
        } catch (e) {
            console.error(e.message, "unable to set searched artist");
        }
    },
    SET_SELECTED_PAGE_CATEGORIES(state, payload) {
        try {
            state[SELECTED_PAGE_CATEGORIES].unshift(payload);
        } catch (e) {
            console.error(e.message, 'unable to set page categories')
        }
    },
    SET_SELECTED_CATEGORY(state, payload) {
        try {
            state[SELECTED_FILTERS][SELECTED_CATEGORY] = payload
        } catch (e) {
            console.error(e.message, 'unable to set selected category')
        }
    },
    SET_SELECTED_SUBCATEGORY(state, payload) {
        try {
            state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] = payload
        } catch (e) {
            console.error(e.message, 'unable to set selected subcategory')
        }
    },
    SET_SELECTED_LOCATION(state, payload) {
        try {
            state[SELECTED_FILTERS][SELECTED_LOCATION] = payload
        } catch (e) {
            console.error(e.message, 'unable to set selected location')
        }
    },
    SET_SELECTED_GENRE(state, payload) {
        try {
            state[SELECTED_FILTERS][SELECTED_GENRE] = payload
        } catch (e) {
            console.error(e.message, 'unable to set selected genre')
        }
    },
    SET_SELECTED_SORT(state, payload) {
        try {
            state[SELECTED_FILTERS][SELECTED_SORT] = payload
        } catch (e) {
            console.error(e.message, 'unable to set selected sort')
        }
    },
    SET_PAGES_TYPE(state, payload) {
        try {
            state[PAGES_TYPE] = payload
        } catch (e) {
            console.error(e.message, 'unable to set pages type')
        }
    },
    SET_SELECTED_FILTERS_EMPTY(state) {
        try {
            state[SELECTED_FILTERS][SELECTED_CATEGORY] = ''
            state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] = ''
            state[SELECTED_FILTERS][SELECTED_GENRE] = ''
            state[SELECTED_FILTERS][SELECTED_LOCATION] = ''
            state[SELECTED_FILTERS][SELECTED_SORT] = 'latest'
        } catch (e) {
            console.error(e.message, 'unable to set selected selected filters empty')
        }
    },
};

const getters = {
    getPageCategories(state) {
        let _state = Utils.parseState(state);
        return _state[PAGE_CATEGORIES];
    },
    getUserPages(state) {
        let _state = Utils.parseState(state);
        return _state[USER_PAGES];
    },
    getSelectedPageCategories(state) {
        let _state = Utils.parseState(state);
        return _state[SELECTED_PAGE_CATEGORIES];
    },
    getFavouritePages(state) {
        let _state = Utils.parseState(state);
        return _state[LIKED_PAGES];
    },
    getExplorePages(state) {
        let _state = Utils.parseState(state);
        return _state[DISCOVER_PAGES];
    },
    getPageActiveTab(state) {
        let _state = Utils.parseState(state);
        return _state[PAGE_ACTIVE_TAB];
    },
    getArtistFollowerList(state) {
        return state[Artist_Followers_List];
    },
    getArtistFollowerTotal(state) {
        return state[Artist_Followers_Total];
    },
    getArtistFollowingsList(state) {
        return state[Artist_Followings_List];
    },
    getArtistFollowingsTotal(state) {
        return state[Artist_Followings_Total];
    },
    getArtistMutualFollowingsList(state) {
        return state[Artist_Mutual_Followings_List];
    },
    getArtistMutualFollowingsTotal(state) {
        return state[Artist_Mutual_Followings_Total];
    },
    getArtistFollowingUserType(state) {
        return state[Artist_Followings_User_Type];
    },
    getArtistFollowerUserType(state) {
        return state[Artist_Followers_User_Type];
    },
    getArtistMutualFollowingUserType(state) {
        return state[Artist_Mutual_Followings_User_Type];
    },
    getPageSimilarArtistsList(state) {
        return state[Similar_Artists_List];
    },
    getPageEvents(state) {
        return state[PAGE_EVENTS];
    },
    getPageSimilarArtistTotal(state) {
        return state[Similar_Artists_Total];
    },
    getPageRoles(state) {
        let _state = Utils.parseState(state);
        return _state[PAGE_ROLES];
    },
    getPagePartnerRoles(state) {
        let _state = Utils.parseState(state);
        return _state[PAGE_PARTNER_ROLES];
    },
    getSearchedPartnersData(state) {
        let _state = Utils.parseState(state);
        return _state[SEARCH_PARTNERS]
    },
    getSearchedArtists(state) {
        let _state = Utils.parseState(state);
        return _state[SEARCH_ARTISTS];
    },
    getSelectedCategory(state) {
        let _state = Utils.parseState(state);
        return _state[SELECTED_FILTERS][SELECTED_CATEGORY];
    },
    getSelectedSubCategory(state) {
        let _state = Utils.parseState(state);
        return _state[SELECTED_FILTERS][SELECTED_SUBCATEGORY];
    },
    getSelectedLocation(state) {
        let _state = Utils.parseState(state);
        return _state[SELECTED_FILTERS][SELECTED_LOCATION];
    },
    getSelectedGenre(state) {
        let _state = Utils.parseState(state);
        return _state[SELECTED_FILTERS][SELECTED_GENRE];
    },
    getSelectedSort(state) {
        let _state = Utils.parseState(state);
        return _state[SELECTED_FILTERS][SELECTED_SORT];
    },
    getPagesType(state) {
        let _state = Utils.parseState(state);
        return _state[PAGES_TYPE];
    },
    getFavoritePageListing(state) {
        let _state = Utils.parseState(state);
        return _state[FAVORITE_PAGE_LISTING];
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
