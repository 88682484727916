<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M6.66699 4.6665H4.66699V10.6665H6.66699V4.6665Z" stroke="#47505B" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M11.333 4.6665H9.33301V7.99984H11.333V4.6665Z" stroke="#47505B" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Company Info</p>
          <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <form class="setting-form">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <!-- <div class="row">
               <div class="col-12">
                  <div class="form-group">
                     <label class="label-title">Current City</label>
                     <input type="text" class="input-field-about">
                  </div>
               </div>
            </div> -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label-title">Company Name</label>
                  <input type="text" class="form-control" v-model="company_name" placeholder="Company Name">
                  <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('company_name')">{{ _handleErrorMessage("company_name") }}</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label-title">Owner Name</label>
                  <input type="text" class="form-control" v-model="owner_name" placeholder="First and Last name">
                  <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('owner_name')">{{ _handleErrorMessage("owner_name") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label-title">Timezone</label>
                  <div>
                    <SelectDropdown
                      :options="timezones"
                      v-model="timezone"
                      @change="((value) => timezone = value)"
                      :style="'width: 350px; border: 1px solid #E6EAF0;'"
                    />
                  </div>
                  <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('timezone')">{{ _handleErrorMessage("timezone") }}</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label-title">Phone</label>
                  <input type="text" class="form-control" v-model="phone" placeholder="Phone number">
                  <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('phone')">{{ _handleErrorMessage("phone") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="label-title">Address</label>
                  <div @click="check = true" class="address-map-container">
                    <input v-if="!check" type="text" class="form-control" v-model="address" placeholder="Location">
                    <GoogleMapAndLocation v-else class="address-map-field" v-model="address" @getLocation="getLocation" />
                  </div>
                  <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('address')">{{ _handleErrorMessage("address") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label-title">VAT ID</label>
                  <input type="text" class="form-control" v-model="vat_id" placeholder="Your VAT ID">
                  <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('vat_id')">{{ _handleErrorMessage("vat_id") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="button" @click="submit" class="save-btn">{{ loading ? "Please Wait..." : "Save Changes" }}</button>
              </div>
            </div>
          </form>
        </div>

      </div>
      <full-page-loader :show="loading"/>
    </div>
  </div>
</template>
<script type="application/javascript">
import {mapActions, mapMutations, mapGetters} from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import {EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR} from '../../../store/types';
import GoogleMapAndLocation from '../../../components/Common/Google Location/GoogleMapAndLocation.vue';
import {
  get_company_info_api
} from "../../../apis/APIs";
import EyeIcon from '../../../assets/svgs/EyeIcon.vue';
import SelectDropdown from "../../Common/Dropdown/SelectDropdown.vue";
export default {
  data() {
    return {
      check: false,
      timezones: Utils.getTimezones(),
      company_name: "",
      owner_name: "",
      timezone: "",
      phone: "",
      address: "",
      latitude:"",
      longitude:"",
      city:"",
      country:"",
      vat_id: "",
      message: "",
      success_message: "",
      loading: false,
      company_data: {}
    }
  },
  components: {
    GoogleMapAndLocation,
    EyeIcon,
    SelectDropdown,
  },
  async mounted() {
    await this.fetchCompanyData();
    this.init();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props"
    }),
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions([
      "company_info"
    ]),
    ...mapMutations([
      "updateEditProfileUIConstraints"
    ]),
    async fetchCompanyData() {
      try {
        this.loading = true;
        const res = await get_company_info_api();
        const {success, information} = res.data || {};
        const data=information
        if (success === 1) {
          if (data) {
            this.company_data = data;
          }else{
            this.company_data = {};
          }
        }
        this.loading = false;
      } catch (error) {
          console.log(error)
      }
    },
     async getLocation(data) {
            this.address = data.location;
            this.latitude = data.latitude;
            this.longitude = data.longitude;
            this.city = data.city;
            this.country = data.country;
    },
    init() {
      const {
        company_name = "",
        phone = "",
        timezone = "",
        owner_name = "",
        address = "",
        vat_id = ""
      } = this.company_data || {};
    
      this.company_name = company_name;
      this.owner_name = owner_name;
      this.timezone = this.timezones.find((time)=> time.value == timezone).label;
      this.phone = phone;
      this.address = address;
      this.vat_id = vat_id;
      this.loading = false;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      return message;
    },
    submit(e) {
      if (e) e.preventDefault();
      const {loading, company_name, owner_name, timezone, phone, address,latitude, longitude, city, country, vat_id} = this;

      if (loading) return;

      let body = {
        company_name,
        owner_name,
        timezone,
        phone,
        address,
        latitude,
        longitude,
        city,
        country,
        vat_id
      };
      Helper.validate(Object.keys(body), body)
          .then(async ({status, response}) => {
            if (status) {
              this.updateEditProfileUIConstraints({
                [EDIT_PROFILE_ERRORS]: []
              });
              try {
                this.message = "";
                this.loading = true;
                const {status, message} = await this.company_info(body);
                this.loading = false;
                switch (status) {
                  case SUCCESS:
                    this.success_message = message;
                    await this.fetchCompanyData();
                    setTimeout(() => {
                      this.success_message = ""
                    }, 5000);
                    break;
                  case ERROR:
                    this.message = message;
                    break;
                }
              } catch (e) {
                this.message = e && e.message ? e.message : "Please try again.";
              }
            } else this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: response && response.length ? response : []
            });
          })
          .catch(err => console.log(err));
    }
  }
};
</script>
<style lang="scss">
.address-map-container{
  border-radius: 4px; 
  border: 1px solid #ced4da;
  .pac-target-input {
    padding-left: 10px;
  }
}
</style>