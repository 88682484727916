<template>
  <div>
    <div class="wrEdit">
      <p>
        <span>Event Capacity:</span>
        <input v-if="showCapacity" type="number" class="inpCapacity" v-model="capacityValue" /><span class="nmbCapacity"
          v-if="!showCapacity">{{ capacity }} </span>
        max
      </p>
      <div v-if="showCapacity" class="saveEditBtn">
        <button class="btnSave" @click="saveCapacity">SAVE</button>
        <div @click="dontSave">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4L12 12" stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <button v-if="!showCapacity" @click="showCapacity = !showCapacity" style="width: 150px;letter-spacing: 0.5px">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.332 2.00004C11.5071 1.82494 11.715 1.68605 11.9438 1.59129C12.1725 1.49653 12.4177 1.44775 12.6654 1.44775C12.913 1.44775 13.1582 1.49653 13.387 1.59129C13.6157 1.68605 13.8236 1.82494 13.9987 2.00004C14.1738 2.17513 14.3127 2.383 14.4074 2.61178C14.5022 2.84055 14.551 3.08575 14.551 3.33337C14.551 3.58099 14.5022 3.82619 14.4074 4.05497C14.3127 4.28374 14.1738 4.49161 13.9987 4.66671L4.9987 13.6667L1.33203 14.6667L2.33203 11L11.332 2.00004Z"
            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        EDIT
      </button>
    </div>
    <div class="addTicket" v-if="capacity != '0'" style="width: 150px;margin-left:auto">
      <span>{{ ticketCapacityError }}</span>
      <button @click="showAddLine = !showAddLine">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.0013 12.8333C10.223 12.8333 12.8346 10.2216 12.8346 6.99996C12.8346 3.7783 10.223 1.16663 7.0013 1.16663C3.77964 1.16663 1.16797 3.7783 1.16797 6.99996C1.16797 10.2216 3.77964 12.8333 7.0013 12.8333Z"
            stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 4.66663V9.33329" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.66797 7H9.33464" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span style="margin-top: 1px;">
          ADD TICKET
        </span>
      </button>
    </div>
    <div class="trName">
      <span style="width: 160px">Ticket Name</span>
      <span style="width: 70px">Availability</span>
      <span style="width: 120px">Price</span>
      <span style="width: 60px">Status</span>
      <span style="width: 110px">Actions</span>
    </div>
    <perfect-scrollbar>
      <div class="wrDataTickets" v-if="capacity != '0'">
        <div v-for="item in dataTicket" :key="item.id" class="ticketInfo bg">
          <span style="width: 160px"><input v-if="item.editTicket" type="text" v-model="item.name" />
            <div v-if="!item.editTicket">{{ item.name }}</div>
          </span>
          <span style="width: 70px" class="availability">
            <input v-if="item.editTicket" type="number" v-model="item.quantity" />
            <p v-if="!item.editTicket" style="margin: 0">
              {{ item.quantity }}
            </p>
          </span>
          <div class="price">
            <span>
              <input v-if="item.editTicket" type="number" v-model="item.price" />
              <span v-if="!item.editTicket">{{ item.price }}</span>
            </span>
            <div class="symbol">
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 5C2 5 2 4.37 2 4C2 3.63 2.7 3 4 3C5.3 3 5.39 3.64 5.4 4H7.4C7.38648 3.31875 7.14146 2.66241 6.70525 2.13896C6.26905 1.61551 5.66764 1.25614 5 1.12V0H3V1.09C1 1.42 0 2.71 0 4C0 5.12 0.52 7 4 7C6 7 6 7.68 6 8C6 8.32 5.38 9 4 9C2.16 9 2 8.14 2 8H0C0 8.92 0.66 10.55 3 10.92V12H5V10.92C7 10.58 8 9.29 8 8C8 6.88 7.48 5 4 5Z"
                  fill="#D1433A" />
              </svg>
            </div>
          </div>
          <span style="width: 60px" :class="{
            storange: item.status === 'Inactive',
            stgreen: item.status === 'Active',
          }">{{ item.status }}</span>
          <div style="width: 110px" class="btnActions">
            <div @click="changeStatus(item.id)">
              <svg v-if="item.status === 'Active'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66667 2.66675H4V13.3334H6.66667V2.66675Z" fill="#47505B" />
                <path d="M11.9987 2.66675H9.33203V13.3334H11.9987V2.66675Z" fill="#47505B" />
              </svg>
              <svg v-if="item.status === 'Inactive'" width="14" height="16" viewBox="0 0 14 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.945 14.8685C1.79896 14.8649 1.65553 14.8291 1.525 14.7635C1.37619 14.676 1.25074 14.5538 1.15933 14.4074C1.06792 14.261 1.01326 14.0946 1 13.9225L1 1.95149C1.00785 1.78456 1.05473 1.62179 1.13688 1.47626C1.21902 1.33072 1.33414 1.20648 1.473 1.11349C1.61905 1.03089 1.78575 0.991979 1.95327 1.00137C2.1208 1.01077 2.2821 1.06808 2.418 1.16649L11.606 7.15149C11.7353 7.23816 11.8413 7.35534 11.9146 7.49269C11.9879 7.63003 12.0262 7.78331 12.0262 7.93899C12.0262 8.09466 11.9879 8.24795 11.9146 8.38529C11.8413 8.52264 11.7353 8.63982 11.606 8.72649L2.418 14.7135C2.27976 14.8124 2.11495 14.8675 1.945 14.8715"
                  stroke="#47505B" stroke-width="2" />
              </svg>
            </div>
            <div @click="editTick(item.id)">
              <svg v-if="!item.editTicket" width="14" height="14" viewBox="0 0 14 14" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.91797 1.74997C10.0712 1.59676 10.2531 1.47523 10.4532 1.39232C10.6534 1.3094 10.868 1.26672 11.0846 1.26672C11.3013 1.26672 11.5159 1.3094 11.716 1.39232C11.9162 1.47523 12.0981 1.59676 12.2513 1.74997C12.4045 1.90318 12.526 2.08507 12.609 2.28524C12.6919 2.48542 12.7346 2.69997 12.7346 2.91664C12.7346 3.13331 12.6919 3.34786 12.609 3.54803C12.526 3.74821 12.4045 3.9301 12.2513 4.08331L4.3763 11.9583L1.16797 12.8333L2.04297 9.62497L9.91797 1.74997Z"
                  fill="#47505B" />
              </svg>
              <svg v-if="item.editTicket" version="1.0" xmlns="http://www.w3.org/2000/svg" width="14pt" height="9pt"
                viewBox="0 0 1400.000000 900.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)" fill="#47505B" stroke="none">
                  <path d="M3395 8988 c-22 -5 -71 -16 -110 -25 -174 -40 -337 -133 -476 -272
                    -101 -101 -172 -205 -221 -322 -31 -75 -45 -125 -79 -277 -12 -57 -12 -7127 0
                    -7184 35 -157 48 -202 81 -279 135 -316 424 -545 775 -612 86 -17 7184 -17
                    7270 0 351 67 640 296 775 612 33 77 46 122 81 279 5 26 9 1222 9 3088 l0
                    3045 -21 87 c-59 239 -234 468 -773 1010 -572 577 -834 781 -1078 841 l-87 21
                    -3053 -1 c-1840 -1 -3069 -5 -3093 -11z m5827 -366 c81 -39 148 -110 180 -190
                    l23 -57 0 -1275 c0 -1230 -1 -1277 -19 -1325 -27 -72 -71 -131 -124 -170 -98
                    -71 107 -66 -2477 -63 l-2330 3 -50 27 c-62 32 -139 113 -168 176 l-22 47 0
                    1300 0 1300 27 57 c48 103 152 182 262 198 28 4 1082 7 2341 6 l2290 -1 67
                    -33z m1038 -4153 c66 -31 132 -95 168 -164 l27 -50 0 -1830 0 -1830 -25 -50
                    c-52 -105 -154 -178 -272 -196 -84 -12 -6232 -12 -6316 0 -118 18 -220 91
                    -272 196 l-25 50 0 1830 0 1830 24 45 c46 88 104 141 189 176 l57 24 3195 -3
                    3195 -2 55 -26z" />
                  <path d="M7707 8298 c-16 -12 -17 -92 -15 -1208 l3 -1195 515 0 515 0 3 1199
                    c2 953 0 1201 -10 1208 -7 4 -234 8 -504 8 -377 0 -494 -3 -507 -12z" />
                </g>
              </svg>
            </div>
            <div @click="deleteTicket(item.id)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L4 12" stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 4L12 12" stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div v-if="showAddLine" class="ticketInfo bg addTic">
      <span style="width: 160px"><input type="text" v-model="addTicketData.name" placeholder="Ticket title " /></span>
      <span style="width: 70px" class="availability"><input type="number" v-model="addTicketData.quantity" /></span>
      <div class="price">
        <span>
          <input type="number" v-model="addTicketData.price" />
        </span>
        <div class="symbol">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 5C2 5 2 4.37 2 4C2 3.63 2.7 3 4 3C5.3 3 5.39 3.64 5.4 4H7.4C7.38648 3.31875 7.14146 2.66241 6.70525 2.13896C6.26905 1.61551 5.66764 1.25614 5 1.12V0H3V1.09C1 1.42 0 2.71 0 4C0 5.12 0.52 7 4 7C6 7 6 7.68 6 8C6 8.32 5.38 9 4 9C2.16 9 2 8.14 2 8H0C0 8.92 0.66 10.55 3 10.92V12H5V10.92C7 10.58 8 9.29 8 8C8 6.88 7.48 5 4 5Z"
              fill="#D1433A" />
          </svg>
        </div>
      </div>
      <span style="width: 20px" :class="{
        storange: addTicketData.status === 'Inactive',
        stgreen: addTicketData.status === 'Active',
      }">{{ addTicketData.status }}</span>
      <div style="width: 150px" class="btnActions">
        <button class="btnSave" @click="addNewTicket">SAVE</button>
        <div @click="clearTicet">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4L12 12" stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tickets",
  data() {
    return {
      addTicketData: {
        id: "",
        name: "",
        quantity: "",
        price: "",
        status: "Inactive",
        editTicket: false,
      },
      dataTicket: [
        {
          id: 1,
          name: "Early Bird",
          quantity: "",
          price: "",
          status: "Inactive",
          editTicket: false,
        },
        {
          id: 2,
          name: "General Admission",
          quantity: "",
          price: "",
          status: "Inactive",
          editTicket: false,
        },
        {
          id: 3,
          name: "Final release",
          quantity: "",
          price: "",
          status: "Inactive",
          editTicket: false,
        },
      ],
      showAddLine: false,
      showCapacity: false,
      capacity: "0",
      capacityValue: "0",
      info: {
        capacity: null,
        tickets: [],
      },
      ticketCapacityError: "",
      quantityTotal: "",
    };
  },
  computed: {
    capacityCount() {
      return this.dataTicket.map((item) => {
        return item.quantity
      }).reduce((accumulator, item) => { return accumulator + Number(item) }, 0);
    },
  },
  watch: {
    'item.quantity': function (value) {
      value = this.dataTicket.reduce((accumulator, item) => { return accumulator + Number(item) }, 0)
      this.validateLength(value)
    },
  },

  // watch: {
  //   'item.quantity': function (value) {
  //     value = this.dataTicket.map(item => { return item.quantity }).reduce(((accumulator, item) => { return accumulator + Number(item.quantity) }, 0));
  //     this.validateLength(value);
  //     console.log(value);
  //   }
  // },
  methods: {
    quantityCount() {
      return this.capacityCount
    },
    validateLength(value) {
      if (this.capacityCount > value) {
        this.msg['email'] = "ticket increases";
      } else {
        this.msg['email'] = 'Invalid Email Address';
      }
    },

    handleTickets() {
      // if(Number(this.capacity) < this.capacityCount){
      this.info.capacity = this.capacity;
      if (this.info.capacity) {
        this.info.tickets = this.dataTicket
          .filter((el) => (el.quantity && el.price ? el : null))
          .map((el) => {
            return {
              name: el.name,
              quantity: el.quantity,
              price: el.price,
              status: el.status,
            };
          });
      }
      this.$emit("setNewDataEvent", this.info);
      // }else{
      //   console.log("Ticket Capacity Increases");
      // }
    },


    // this.ticketCapacityError = ""
    // } else {
    //   console.log("Tickets capacity increases");
    //   this.ticketCapacityError = "Tickets capacity increases";
    // }

    // if (this.info.capacity < this.quantityTotal) {
    //   this.ticketCapacityError = "";
    //   this.$emit("setNewDataEvent", this.info);
    // } else {
    //   console.log("Tickets capacity increases");
    //   this.ticketCapacityError = "Tickets capacity increases";
    // }

    changeStatus(id) {
      this.dataTicket.forEach((el) => {
        if (id === el.id) {
          el.status === "Inactive"
            ? (el.status = "Active")
            : el.status === "Active"
              ? (el.status = "Inactive")
              : null;
        }
      });
    },
    saveCapacity() {
      this.capacity = this.capacityValue;
      this.showCapacity = !this.showCapacity;
    },
    dontSave() {
      this.showCapacity = !this.showCapacity;
      this.capacityValue = this.capacity;
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    editTick(id) {
      // this.dataTicket.forEach((el) =>el.editTicket=false)
      this.dataTicket.forEach((el) => {
        if (el.id === id && el.editTicket === true) {
          el.editTicket = false;
        } else if (el.id === id) {
          el.editTicket = true;
        }
      });
    },
    deleteTicket(id) {
      this.dataTicket = this.dataTicket.filter((el) => el.id != id);
    },
    addNewTicket() {
      if (
        this.addTicketData.name &&
        this.addTicketData.quantity &&
        this.addTicketData.price
      ) {
        this.addTicketData.id = this.uuidv4();
        this.dataTicket.push({ ...this.addTicketData });
        this.addTicketData = {
          id: "",
          name: "",
          quantity: "",
          price: "",
          status: "Inactive",
          editTicket: false,
        };
        this.showAddLine = !this.showAddLine;
      }
    },
    clearTicet() {
      this.addTicketData = {
        id: "",
        name: "",
        quantity: "",
        price: "",
        status: "Inactive",
        editTicket: false,
      };
      this.showAddLine = !this.showAddLine;
    },
  },
};
</script>

<style lang="scss" scoped>
.saveEditBtn {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    cursor: pointer;
    background: #e6eaf0 !important;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btnSave {
  font-family: HelveticaNeueBold;
  width: 110px;
  height: 30px;
  outline: none;
  background: #ffffff !important;
  border: 1px solid #D1433A;
  box-sizing: border-box;
  border-radius: 2px;
  color: #D1433A !important;

  &:hover {
    background: #D1433A !important;
    color: #ffffff !important;
  }
}

.inpCapacity {
  margin: 0 10px 0 32px;
  width: 80px;
  height: 30px;
  padding: 5px;
  border: 1px solid #D1433A;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  font-size: 11px;
  font-family: HelveticaNeueBold;
  border-radius: 4px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 30px;

  svg {
    margin-right: 9px;
  }
}

.wrEdit {
  font-family: HelveticaNeueBold;
  color: #47505b;
  margin: 40px 0;
  width: 100%;
  background: #f9fafb;
  border: 1px solid #e6eaf0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;

  p {
    font-size: 15px;
    margin: 0;
  }

  span {
    min-width: 80px;
    padding: 6px;
    opacity: 0.9;
    height: 32px;
    display: inline-block;
    font-size: 15px;
  }

  .nmbCapacity {
    margin: 0 10px 0 32px;
  }

  button {
    background: #e6eaf0;
    color: #47505b;

    &:hover {
      background: #d1d9e3;
    }
  }
}

.addTic {
  input {
    font-family: HelveticaNeueMedium;
  }
}

.addTicket {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    border: 1px solid #D1433A;
    color: #D1433A;
    background: #fff;

    &:hover {
      background: #D1433A;
      color: #fff;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}

.wrDataTickets {
  // overflow-y: scroll;
  height: 220px;
  width: 100%;
  min-height: 173px;
  height: unset
}

.trName {
  margin: 38px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 8px;

  span {
    font-family: HelveticaNeueBold;
    color: #8b949f;
    font-size: 11px;
  }
}

.ticketInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 5px 0;

  .btnActions {
    display: flex;
    justify-content: space-between;

    div {
      cursor: pointer;
      background: #e6eaf0;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #d1d9e3;
      }
    }
  }

  span {
    color: #071526;
    font-family: HelveticaNeueBold;
    font-size: 13px;
  }

  .availability {
    color: #47505b;
    font-family: HelveticaNeueNormal;

    p {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e6eaf0;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  .price {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    box-sizing: border-box;
    border-radius: 4px;

    input {
      border-right: none;
      height: 28px;
      border-radius: 4px 0 0 4px;
    }

    span {
      width: 70px;
      color: #47505b;
      font-family: HelveticaNeueMedium;
      border-radius: 2px 0px 0px 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      width: 50px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e6eaf0;
      border-radius: 0px 2px 2px 0px;
    }
  }

  input {
    width: 100% !important;
    border: 1px solid #D1433A;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 13px;
    padding: 3px 5px;
  }
}

.bg {
  background: #f5f7f9;
}

.stgreen {
  color: #15ca86 !important;
}

.storange {
  color: #fdb390 !important;
}
</style>
