<template>
  <div class="wr_events" v-if="$parent.active_tab === 'events'">
    <div class="ticket-info-tabs">
      <div>
        <div
          @click="activeTab = 'upcoming'"
          class="single-tab"
          :class="{ active_tabs: activeTab === 'upcoming' }"
        >
          UPCOMING<span :class="{ zeroEvent: upcomingEvents.length === 0 }">
            {{ upcomingEvents.length }}
          </span>
        </div>
        <div
          @click="activeTab = 'past'"
          class="single-tab"
          :class="{ active_tabs: activeTab === 'past' }"
        >
          PAST<span :class="{ zeroEvent: pastEvents.length === 0 }">
            {{ pastEvents.length }}
          </span>
        </div>
      </div>
      <div class="search-container">
        <input 
          type="text" 
          placeholder="Search" 
          v-model="searchQuery"
          class="search-input"
          @keyup.enter.prevent="handleSearch"
        >
        <span class="search-icon" @click="handleSearch">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#8F9CAE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 14L11.1 11.1" stroke="#8F9CAE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
    </div>

    <div v-if="isLoading" class="loading-state">
      <!-- Add your loading spinner or placeholder here -->
    </div>
    <div v-else-if="currentEvents.length < 1" class="update-info" :class="{'pb-23 mb-3' : !currentUserIsAdmin}">
      <div class="row">
        <div class="col">
          <div class="no-content-here-company">
            <div class="no-content-here no-event-here">
              <div class="no-detail-content">
                <div class="category-content-icon">
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M47.5 10H12.5C9.73858 10 7.5 12.2386 7.5 15V50C7.5 52.7614 9.73858 55 12.5 55H47.5C50.2614 55 52.5 52.7614 52.5 50V15C52.5 12.2386 50.2614 10 47.5 10Z"
                      stroke="#47505B"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M40 5V15"
                      stroke="#47505B"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M20 5V15"
                      stroke="#47505B"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M7.5 25H52.5"
                      stroke="#47505B"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <div class="no-detail-content-heading" v-if="!currentUserIsAdmin">
                  No Event Available!
                </div>
                <div v-if="currentUserIsAdmin">
                  <div class="no-detail-content-heading" >
                  You haven’t set up any Events yet
                </div>
                <div class="no-detail-content-subheading" >
                  Create your first Event now!
                </div>
                <div class="update-info-btn">
                <button data-toggle="modal"
                        data-target="#create-event-modal"
                        @click="$store.commit('SET_EVENT_MODAL', true)">
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.4999 0C4.7714 0 4.99149 0.220095 4.99149 0.491596V8.5084C4.99149 8.7799 4.7714 9 4.4999 9C4.2284 9 4.0083 8.7799 4.0083 8.5084V0.491596C4.0083 0.220095 4.2284 0 4.4999 0Z"
                          fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 4.50039C0 4.22888 0.220095 4.00879 0.491596 4.00879H8.5084C8.7799 4.00879 9 4.22888 9 4.50039C9 4.77189 8.7799 4.99198 8.5084 4.99198H0.491596C0.220095 4.99198 0 4.77189 0 4.50039Z"
                          fill="white" />
                  </svg>
                  ADD NEW EVENT
                </button>
              </div>
                </div>
    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="events-container">
      <EventsCard
        v-for="(event, index) in displayEvents"
        :key="index"
        :event="event"
      />

<!--      <EventsContainer-->
<!--        :eventsType="activeTab === 'upcoming' ? 'Upcoming Events' : 'Past Events'"-->
<!--        :events="{ data: displayEvents }"-->
<!--        :isFullPage="isFullPage"-->
<!--        :hideMoreOption="true"-->
<!--        :hideTitle="true"-->
<!--        :isEventsPage="false"-->
<!--      />-->

      <div class="col" v-if="shouldShowLoadMore">
        <div class="show_more_btn" style="margin-bottom: 80px">
          <div class="show-more musicbar_more">
            <a @click="loadMore" href="javascript:;">
              <span class="show_more_bg">
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW MORE
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import EventsCard from "../../../Events/components/EventsCard.vue";

export default {
  components: {
    EventsCard,
  },
  props: {
    isFullPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: 'upcoming',
      searchQuery: '',
      isLoading: false,
      upcomingDisplayEvents: [],
      pastDisplayEvents: []
    }
  },
  computed: {
    upcomingEvents() {
      return this.$store.getters.getCurrentPageUpcomingEvents || [];
    },
    pastEvents() {
      return this.$store.getters.getCurrentPagePastEvents || [];
    },
    currentEvents() {
      return this.activeTab === 'upcoming' ? this.upcomingEvents : this.pastEvents;
    },
    displayEvents() {
      return this.activeTab === 'upcoming' ? this.upcomingDisplayEvents : this.pastDisplayEvents;
    },
    currentUserIsAdmin() {
      return !!(this.current_page && this.current_page.current_user && this.current_page.current_user === 'admin')
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    shouldShowLoadMore() {
      if (this.activeTab === 'upcoming') {
        return this.upcomingEvents.length < (this.$store.getters.getCurrentPageUpcomingEventsTotal || 0);
      } else {
        return this.pastEvents.length < (this.$store.getters.getCurrentPagePastEventsTotal || 0);
      }
    }
  },
  watch: {
    upcomingEvents: {
      immediate: true,
      handler(newEvents) {
        this.upcomingDisplayEvents = newEvents;
      }
    },
    pastEvents: {
      immediate: true,
      handler(newEvents) {
        this.pastDisplayEvents = newEvents;
      }
    }
  },
  methods: {
    async loadMore() {
      const page_id = this.$route.params.id;
      this.isLoading = true;
      
      try {
        const currentTab = this.activeTab;
        
        await this.$store.dispatch(
          currentTab === 'upcoming' ? 'getPageUpcomingEvents' : 'getPagePastEvents',
          {
            page_id,
            type: currentTab,
            search: this.searchQuery,
            showMore: true
          }
        );
        
        this.$parent.active_tab = 'events';
        
      } catch (error) {
        console.error('Error loading more events:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleSearch(showMore = false) {
      if (event) {
        event.preventDefault();
      }
      
      const page_id = this.$route.params.id;
      this.isLoading = true;
      
      try {
        const currentTab = this.activeTab;
        
        await this.$store.dispatch(
          currentTab === 'upcoming' ? 'getPageUpcomingEvents' : 'getPagePastEvents',
          {
            page_id,
            type: currentTab,
            search: this.searchQuery,
            showMore: false
          }
        );

        this.$parent.change_active_tab('events');
        this.activeTab = currentTab;
        
      } catch (error) {
        console.error('Error searching events:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wr_events {
  width: 100%;
  
  .events-container {
    width: 770px;
    display: flex;
    flex-wrap: wrap;
    gap: 1.85rem;
  }
}

.ticket-info-tabs {
  margin-bottom: 27px;
  padding: 0 20px;
  background: #fff;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  div {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 5px;
    
    .single-tab {
      cursor: pointer;
      margin-right: 42px;
      font-family: HelveticaNeueBold;
      height: 100%;
      display: flex;
      color: #c4ccd6;
      align-items: center;
      border-bottom: 3px solid #fff;
      
      span {
        margin-left: 6px;
        min-width: 26px;
        background: #D1433A;
        border-radius: 4px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .active_tabs {
      color: #47505b;
      border-color: #D1433A;
    }
  }

  .search-container {
    position: relative;
    
    .search-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      cursor: pointer;
      pointer-events: auto;
      
      &:hover {
        svg path {
          stroke: #D1433A;
        }
      }
    }

    .search-input {
      width: 300px;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #E4E9F0;
      border-radius: 4px;
      font-size: 14px;
      color: #47505B;
      padding-right: 40px;
      
      &::placeholder {
        color: #8F9CAE;
      }
      
      &:focus {
        outline: none;
        border-color: #D1433A;
      }
    }
  }
}

.zeroEvent {
  background: #c4ccd6 !important;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
</style>
