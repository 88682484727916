<template>
  <div class="post-section-like " :style="backgroundColor" :class="{'active':userReact}"
       @click="reactOrUnReact($event)">
<!--    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <path-->
<!--          d="M15.762 3.28005C15.3695 2.85662 14.9036 2.52073 14.3909 2.29156C13.8781 2.0624 13.3285 1.94444 12.7735 1.94444C12.2184 1.94444 11.6688 2.0624 11.1561 2.29156C10.6433 2.52073 10.1774 2.85662 9.78493 3.28005L8.96999 4.15923L8.15505 3.28005C7.35707 2.45347 6.29053 1.99729 5.18432 2.00942C4.07811 2.02155 3.02039 2.50101 2.23814 3.34492C1.45589 4.18883 1.01146 5.32992 1.00022 6.52333C0.988977 7.71673 1.41182 8.86733 2.17801 9.72822L2.99295 10.6074L8.96999 17.0556L14.947 10.6074L15.762 9.72822C16.1545 9.30485 16.4658 8.80222 16.6782 8.24902C16.8907 7.69583 17 7.10292 17 6.50414C17 5.90535 16.8907 5.31244 16.6782 4.75925C16.4658 4.20605 16.1545 3.70342 15.762 3.28005V3.28005Z"-->
<!--          stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" :style="stroke"-->
<!--          class="artist-timeline-action-svg"></path>-->
<!--    </svg>-->
    <svg class="" width="16" height="14" viewBox="0 0 16 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path class="post-section-like-svg"
            d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
    </svg>
    {{
      reactionCount
    }}
  </div>

</template>
<script>
import {mapGetters} from 'vuex';

require("vue-chat-emoji/dist/vue-chat-emoji.min.css");

export default {
  data() {
    return ({
      like_loading: false,
      message: "",
      success_message: ""
    })
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: ''
    },
    page_type: {
      type: String,
      default: ''
    },
    announcement_check: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    reactionCount() {
      let count = 0
      if(this.item && this.item.reactions && Array.isArray(this.item.reactions))
      {
        for (const reaction of this.item.reactions) {
          count = Number(count) + Number(reaction.count)
        }
      }
      return count
    },
    userReact() {
      return this.item.reacted
    }
  },
  methods: {
    init() {
    },
    reactOrUnReact(e) {
      if (e) e.stopPropagation();

      if (this.page_type === 'artist' || this.page_type === 'company' || this.page_type === 'clubroom' || this.page_type === 'partyroom') {
        this.$store.dispatch('postReact', {
          index: this.index,
          postPage: this.page_type,
          announcement_check: this.announcement_check
        })
      } else if (this.page_type === 'single_post') {
        this.$store.dispatch('postReact', {
          postPage: this.page_type
        })
      } else {
        this.$store.dispatch('postReact', {
          index: this.index
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.post-section-like.active, .viewFeeds .post-section-like{
  width: 70px !important;
  height: 30px !important;
}
</style>
