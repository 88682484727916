<template>
  <div :class="['modal fade modal-add-playlist',(showModal) ? 'show d-block' : '']" id="add_modal_playlist" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" aria-modal="true" style="z-index: +111 !important;">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close"  @click="emptyData" v-on:click="openPlaylistModal($event, false, null); ">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="title d-flex justify-content-center align-items-center mb-0" style="border-bottom: 1px solid #E6EAF0;">
          <div class="short-border title-style mb-3">
            ADD TO PLAYLISTS
          </div>
        </div>
        <div @click="toggleDropdown"
             style="border-top: none !important;"
             class="create-new-playlist d-flex flex-row" data-toggle="collapse" role="button"
             :aria-expanded="(showDropdown === true)" aria-controls="collapseExample">
          <div class="cover cover-style">
            <div class="svg-icon">
              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6.58398V24.084" stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.25 15.334H23.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <div class="title">Create new playlist</div>
            <div class="subtitle">Click here to create new playlist</div>
          </div>
        </div>
        <div :class="['tracks-albums', (check === 'create-new-playlist') ? 'd-block': 'd-none', (showDropdown === true) ? 'show' : '']">
          <div class="create-wrapper collapse show" id="create-play">
            <div class="d-flex flex-row justify-content-between align-items-end m-lr-30">
              <div class="flex-grow-1 m-r-20 playlist-title">
                <label for="basic-url">Playlist Title</label>
                <input type="text" id="basic-url" aria-describedby="basic-addon3" v-model="createPlaylist.title">
                <div class="invalid-feedback d-block" v-if="$v.createPlaylist.title.$dirty">
                  <span v-if="($v.createPlaylist.title.required !== null && !$v.createPlaylist.title.required)">
                    This is a required field.
                  </span>
                </div>
              </div>
              <div class="dropdown playlist-select show-tick filters-select for-overview pg-input-right">
                <select v-model="selectedGenre" class="filters-select selectpicker for-overview" title="All Genres"
                        data-max-options="1" dropupauto="false" tabindex="-98">
                </select>
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="All Genres">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">{{ selectedGenre }}</div>
                    </div>
                  </div>
                </button>
                <div class="invalid-feedback d-block" v-if="$v.createPlaylist.title.$dirty">
                    <span v-if="($v.createPlaylist.genre.required !== null && !$v.createPlaylist.genre.required)">
                      This is a required field.
                    </span>
                </div>
                <div class="dropdown-menu overview-genre-drop-down">
                  <perfect-scrollbar>
                    <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true">
                      <ul class="dropdown-menu inner show" role="presentation">
                        <li @click="removeAllSelectedGenre()">
                          <a role="option"
                             :class="{'active selected ':(selectedGenre === 'Select Genre')}"
                             class="dropdown-item " id="bs-select-9-0" tabindex="0"
                             aria-selected="false" aria-setsize="6"
                             aria-posinset="1">
                            <span class=" bs-ok-default check-mark"></span>
                            <span class="text">Select Genre</span>
                          </a>
                        </li>
                      </ul>
                      <ul class="dropdown-menu inner show" role="presentation">
                        <li @click="selectOrUnSelectGenre(genre)" v-for="(genre,index) in genres" :key="index"><a
                          role="option" :class="[(createPlaylist.genre === genre.value) ? 'active selected' : '']"
                          class="dropdown-item " id="bs-select-9-0" tabindex="0"
                          aria-selected="false" aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">{{ genre.text }}</span></a></li>
                      </ul>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
            <div class="privacy-block d-flex flex-row align-items-center m-lr-30">
              <div class="privacy-title">Privacy:</div>
              <div>
                <input
                  type="checkbox"
                  class="checkbox"
                  id="public" 
                  value="public"
                  @click="updatePrivacy('public')"
                  :checked="createPlaylist.privacy ==='public'"
                />
                <label class="form-check-label checkbox-label" for="public">Public</label>
                <!-- <input
                  class=""
                  type="checkbox"
                  id="public" value="public"
                  @click="updatePrivacy('public')"
                  :checked="createPlaylist.privacy ==='public'">
                <label class="form-check-label checkbox-label" for="public">Public</label> -->
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="private"
                  value="private"
                  @click="updatePrivacy('private')"
                  :checked="createPlaylist.privacy === 'private'">
                <label class="form-check-label checkbox-label" for="private">Private</label>
              </div>
              <div class="text-center mb-3 d-flex justify-content-center" v-if="form_create_loader">
                <b-spinner variant="primary"></b-spinner>
              </div>
              <div class="btn btn-create ml-auto" @click="createNewPlaylist">
                Create
              </div>
            </div>
            <div class="new-song-list m-lr-30" v-if="createPlaylistSongsArray.length > 0">
              <div
                class="new-song-item d-flex flex-row align-items-center"
                v-for="(song_album, index) in createPlaylistSongsArray" :key="index"
              >
                <div class="cover"></div>
                <div class="list-title">
                  {{ (song_album.page) ? song_album.page.title: 'Artist Name' }}
                </div>
                <div class="list-subtitle">- {{ (song_album.title) ? song_album.title : 'Song Name' }}</div>
                <div @click="removeFromArray(song_album)" class="list-remove ml-auto">
                  <div class="svg-icon">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.7">
                        <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                              d="M13.4348 0.565217C13.747 0.877378 13.747 1.38349 13.4348 1.69565L1.69572 13.4348C1.38356 13.7469 0.877451 13.7469 0.56529 13.4348C0.25313 13.1226 0.25313 12.6165 0.56529 12.3043L12.3044 0.565217C12.6166 0.253056 13.1227 0.253056 13.4348 0.565217Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.565217 0.565642C0.877378 0.253482 1.38349 0.253482 1.69565 0.565642L13.4348 12.3048C13.7469 12.6169 13.7469 13.123 13.4348 13.4352C13.1226 13.7474 12.6165 13.7474 12.3043 13.4352L0.565217 1.69608C0.253056 1.38392 0.253056 0.877803 0.565217 0.565642Z"
                              fill="#47505B"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="search-playlist">
            <input type="text" placeholder="Search tracks and albums" v-model="tracksSearchKey" @keyup="getTracksAndAlbums">
          </div>
          <div class="text-center mb-3 d-flex justify-content-center" v-if="getTracksAndAlbumsLoader === true">
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div :class="['song-list albums-style',(filterTracksAndAlbums.length > 5) ? 'create-playlist-scroll' : '']">
            <perfect-scrollbar>
              <div
                v-for="(song_album, index) in filterTracksAndAlbums"
                class="list-item d-flex flex-row" :class="{'active': isTrackAdded(song_album.id)}" :key="index"
              >
                <div class="cover">
                  <div class="svg-icon">
                    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.2">
                        <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <div class="item-title">{{(song_album.page) ? song_album.page.title : 'Artist Name'}}</div>
                  <div class="item-subtitle">{{ (song_album.title) ? song_album.title : 'Artist Name' }}</div>
                </div>
                <div class="add-playlist-wrapper ml-auto align-self-center"
                >
                  <div class="lock">
                    <div class="svg-icon">
                      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                              fill="white" stroke="white" stroke-width="0.5"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="add-playlist-btn" @click="addToNewPlaylist(song_album, index)">
                    {{ isTrackAdded(song_album.id) ? 'Added': 'Add to playlist'}}
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div :class="['for-hide', (check === 'add-to-playlist') ? 'd-block' : 'd-none' ]">
          <div class="search-playlist">
            <input type="text" placeholder="Search playlists" v-model="term" @keyup="getPlaylists">
          </div>
          <div class="text-center mb-3 d-flex justify-content-center" v-if="getPlaylistLoader">
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div :class="['song-list', (myPlaylists.length > 5) ? 'playlist-scroll' : '']">
            <perfect-scrollbar>
              <div v-for="(play_list, index) in myPlaylists" class="list-item d-flex flex-row "
                   :class="{'active': isAddedToPlaylist(play_list.id)}"  :key="index">
                <div class="cover">
                  <div class="svg-icon">
                    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.2">
                        <path
                            d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                            stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        <path
                            d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                            stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <div class="item-title">
                    {{ (play_list.title) ? play_list.title : 'PlayList Title' }}
                  </div>
                  <div class="item-subtitle d-flex align-items-center">
                    <div class="svg-icon">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="c-fill-1"
                              d="M5.5 11.25V4.75C5.5 4.33579 5.16421 4 4.75 4C4.33579 4 4 4.33579 4 4.75V11.25C4 11.6642 4.33579 12 4.75 12C5.16421 12 5.5 11.6642 5.5 11.25Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1"
                              d="M17.5 11.25V4.75C17.5 4.33579 17.1642 4 16.75 4C16.3358 4 16 4.33579 16 4.75V11.25C16 11.6642 16.3358 12 16.75 12C17.1642 12 17.5 11.6642 17.5 11.25Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1"
                              d="M9.5 15.25V0.75C9.5 0.335786 9.16421 0 8.75 0C8.33579 0 8 0.335786 8 0.75V15.25C8 15.6642 8.33579 16 8.75 16C9.16421 16 9.5 15.6642 9.5 15.25Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1"
                              d="M13.5 13.25V2.75C13.5 2.33579 13.1642 2 12.75 2C12.3358 2 12 2.33579 12 2.75V13.25C12 13.6642 12.3358 14 12.75 14C13.1642 14 13.5 13.6642 13.5 13.25Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1"
                              d="M1.5 13.25V2.75C1.5 2.33579 1.16421 2 0.75 2C0.335786 2 0 2.33579 0 2.75V13.25C0 13.6642 0.335786 14 0.75 14C1.16421 14 1.5 13.6642 1.5 13.25Z"
                              fill="#47505B"></path>
                      </svg>
                    </div>
                    {{ play_list.song_count }}
                  </div>
                </div>
                <div v-if="spinnerIndex === index" class="text-center d-flex align-items-center justify-content-center ml-auto pr-2">
                  <b-spinner class="spinner-color" small label="Small Spinner"></b-spinner>
                </div>
                <div class="add-playlist-wrapper ml-auto align-self-center">
                  <div class="lock">
                    <div class="svg-icon">
                      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                              fill="white" stroke="white" stroke-width="0.5"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="add-playlist-btn" @click="addToPlaylist(play_list, index)">
                    {{ isAddedToPlaylist(play_list.id) ? 'Added': 'Add to playlist'}}
                  </div>
                </div>
              </div>
<!--              <div class="list-item d-flex flex-row active lock-active">-->
<!--                <div class="cover">-->
<!--                  <div class="svg-icon">-->
<!--                    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <g opacity="0.2">-->
<!--                        <path-->
<!--                            d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"-->
<!--                            stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"-->
<!--                            stroke-linejoin="round"></path>-->
<!--                        <path-->
<!--                            d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"-->
<!--                            stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"-->
<!--                            stroke-linejoin="round"></path>-->
<!--                        <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"-->
<!--                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>-->
<!--                      </g>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="d-flex flex-column justify-content-center">-->
<!--                  <div class="item-title"><span>Lisabel</span> - Work of Art</div>-->
<!--                  <div class="item-subtitle d-flex align-items-center">-->
<!--                    <div class="svg-icon">-->
<!--                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path class="c-fill-1"-->
<!--                              d="M5.5 11.25V4.75C5.5 4.33579 5.16421 4 4.75 4C4.33579 4 4 4.33579 4 4.75V11.25C4 11.6642 4.33579 12 4.75 12C5.16421 12 5.5 11.6642 5.5 11.25Z"-->
<!--                              fill="#47505B"></path>-->
<!--                        <path class="c-fill-1"-->
<!--                              d="M17.5 11.25V4.75C17.5 4.33579 17.1642 4 16.75 4C16.3358 4 16 4.33579 16 4.75V11.25C16 11.6642 16.3358 12 16.75 12C17.1642 12 17.5 11.6642 17.5 11.25Z"-->
<!--                              fill="#47505B"></path>-->
<!--                        <path class="c-fill-1"-->
<!--                              d="M9.5 15.25V0.75C9.5 0.335786 9.16421 0 8.75 0C8.33579 0 8 0.335786 8 0.75V15.25C8 15.6642 8.33579 16 8.75 16C9.16421 16 9.5 15.6642 9.5 15.25Z"-->
<!--                              fill="#47505B"></path>-->
<!--                        <path class="c-fill-1"-->
<!--                              d="M13.5 13.25V2.75C13.5 2.33579 13.1642 2 12.75 2C12.3358 2 12 2.33579 12 2.75V13.25C12 13.6642 12.3358 14 12.75 14C13.1642 14 13.5 13.6642 13.5 13.25Z"-->
<!--                              fill="#47505B"></path>-->
<!--                        <path class="c-fill-1"-->
<!--                              d="M1.5 13.25V2.75C1.5 2.33579 1.16421 2 0.75 2C0.335786 2 0 2.33579 0 2.75V13.25C0 13.6642 0.335786 14 0.75 14C1.16421 14 1.5 13.6642 1.5 13.25Z"-->
<!--                              fill="#47505B"></path>-->
<!--                      </svg>-->
<!--                    </div>-->
<!--                    14-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="add-playlist-wrapper ml-auto align-self-center">-->
<!--                  <div class="lock">-->
<!--                    <div class="svg-icon">-->
<!--                      <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <g clip-path="url(#clip0_10669_568136)">-->
<!--                          <path d="M3.61111 7.93874H9.38889V5.75692C9.38889 4.95389 9.10677 4.26829 8.54253 3.70011C7.9783 3.13192 7.29745 2.84783 6.5 2.84783C5.70255 2.84783 5.0217 3.13192 4.45747 3.70011C3.89323 4.26829 3.61111 4.95389 3.61111 5.75692V7.93874ZM13 9.02965V15.5751C13 15.8781 12.8947 16.1357 12.684 16.3478C12.4734 16.56 12.2176 16.666 11.9167 16.666H1.08333C0.782407 16.666 0.52662 16.56 0.315972 16.3478C0.105324 16.1357 0 15.8781 0 15.5751V9.02965C0 8.72662 0.105324 8.46905 0.315972 8.25692C0.52662 8.0448 0.782407 7.93874 1.08333 7.93874H1.44444V5.75692C1.44444 4.36299 1.94097 3.16602 2.93403 2.16602C3.92708 1.16602 5.11574 0.666016 6.5 0.666016C7.88426 0.666016 9.07292 1.16602 10.066 2.16602C11.059 3.16602 11.5556 4.36299 11.5556 5.75692V7.93874H11.9167C12.2176 7.93874 12.4734 8.0448 12.684 8.25692C12.8947 8.46905 13 8.72662 13 9.02965Z" fill="#D1433A"/>-->
<!--                        </g>-->
<!--                        <defs>-->
<!--                          <clipPath id="clip0_10669_568136">-->
<!--                            <rect width="13" height="16" fill="white" transform="translate(0 0.666016)"/>-->
<!--                          </clipPath>-->
<!--                        </defs>-->
<!--                      </svg>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="add-playlist-btn">-->
<!--                    Added-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { addSongToPlaylist, createPlaylist } from "../../../apis/APIs";
import { ERROR, SUCCESS } from "../../../store/types";
import mixin from "../../../mixins/sweetAlert";
export default {
  data() {
    return {
      createPlaylist:{
        title: null,
        genre: null,
        privacy: 'public',
        artwork: null,
        type: 'User',
        songsAddToPlaylist: []
      },
      genres: [],
      selectedGenre: 'Select Genre',
      limit: 10,
      offset: 0,
      term:'',
      tracksSearchKey:'',
      showDropdown : false,
      tempArray:[],
      addPlaylistSpinner: false,
      spinnerIndex :null,
      createPlaylistSongsArray:[],
      added : false,
      tracksArray: [],
      addToTracksSpinner: false,
      form_create_loader : false
    }
  },
  components: {},
  computed: {
    showModal(){
      return this.$store.getters.getPlaylistModal;
    },
    myPlaylists(){
      return this.$store.getters.getPlaylists;
    },
    tracksAndAlbums() {
      return this.$store.getters.getTracksAndAlbums;
    },
    getPlaylistLoader(){
      return this.$store.getters.getPlaylistLoader;
    },
    getTracksAndAlbumsLoader(){
      return this.$store.getters.getTracksAndAlbumsLoader;
    },
    filterTracksAndAlbums(){
      return this.tracksAndAlbums.filter(function(e) {
        return e.page != null;
      });
    },
    check:{
      set(val){
        this.$store.commit('Update_Playlist_Modal_Status',val)
      },
      get(){
        return this.$store.getters.getPlaylistModalCheck;
      }
    },
    addToPlayListSongsArray(){
      return this.$store.getters.getAddToPlaylistSongsArray;
    },
    accessToken() {
      return this.$store.getters.getAccessToken
    }
  },
  watch:{
    $route() {
      if(this.$route.name !== null && this.$route.name !== 'EmbedComponent' && this.accessToken) {
        this.getGenres();
        this.getPlaylists();
        this.getTracksAndAlbums();
      }
    }
  },
  mounted(){
  },
  validations: {
    createPlaylist: {
      genre: {
        required
      },
      title: {
        required
      },
    }
  },
  methods: {
    ...mapActions([
      "get_geners"
    ]),
    async createNewPlaylist(){
      this.$v.createPlaylist.$touch();
      if (this.$v.createPlaylist.$anyError)
      {
        return;
      }else{
        this.form_create_loader = true
        this.tracksArray.push(this.addToPlayListSongsArray.id);
        this.createPlaylist.songsAddToPlaylist = this.tracksArray;
        if(this.createPlaylist.songsAddToPlaylist.length > 0){
          // this.$store.dispatch('createPlaylist', {createPlaylist:this.createPlaylist})
          try {
            const { data } = await createPlaylist(this.createPlaylist);
            if (data.success === 1) {
              let obj = {
                playlist_id: data.information.id,
                song_id: this.createPlaylist.songsAddToPlaylist
              }
              const { res } = await addSongToPlaylist(obj);
                this.createPlaylist.title = null;
                this.tracksArray = [];
                this.selectedGenre = 'Select Genre';
                this.createPlaylist.privacy= 'public';
                this.createPlaylistSongsArray = [this.addToPlayListSongsArray];
                this.notificationToast(true, 'Playlist', data.message, 5000, 'success');
                this.form_create_loader = false
            } else {
               this.notificationToast(true, 'Playlist', data.message, 5000, 'error');
            }
          }
          catch (error) {

          }
        }else{
           this.notificationToast(true, 'Playlist', 'Please Add Any Song', 5000, 'warning')
        }
      }
    },
    closeModal: function () {
      this.$parent.closeModal();
    },
    updatePrivacy(val){
      this.createPlaylist.privacy = val;
    },
    async getGenres() {
      // try {
      //   const {status, information} =  await this.get_geners();
      //   this.genres = information && information.length ? information.map(genre => {
      //     return {
      //       value: genre.id,
      //       text: genre.name,
      //     }
      //   }) : [];
      // } catch (error) {
      //   console.error(error)
      // }
      try {
        if (!this.$store.getters.getGenres && !this.$store.getters.getGenres.length > 0) {
          await this.$store.dispatch('get_geners')
        }
        const data =  (this.$store.getters.getGenres && this.$store.getters.getGenres.length > 0) ? this.$store.getters.getGenres : [];
        this.genres = data && data.length ? data.map(genre => {
          return {
            value: genre.id,
            text: genre.name,
          }
        }) : [];
      } catch (error) {
        console.error(error)
      }
    },
    selectOrUnSelectGenre(val) {
      this.createPlaylist.genre = val.value;
      this.selectedGenre = val.text;
    },
    removeAllSelectedGenre() {
      this.selectedGenre = 'Select Genre';
      this.createPlaylist.genre = null;
    },
    getPlaylists(){
      let payload = {
        term : this.term,
        offset : this.offset,
        limit : this.limit
      }
      this.$store.dispatch('myPlaylists', payload)
    },
    getTracksAndAlbums(){
      let payload = {
        term : this.tracksSearchKey,
        offset : this.offset,
        limit : this.limit
      }
      this.$store.dispatch('songsAndAlbums', payload)
    },
    async addToPlaylist(playlist, index){
        try {
          if(this.isAddedToPlaylist(playlist.id)){
            console.log("Already added in playlist");
          }else{
            this.spinnerIndex = index;
            this.addPlaylistSpinner = true;
            const songsArray = [];
            if (this.addToPlayListSongsArray.type === 'song') {
              songsArray.push(this.addToPlayListSongsArray.id);
            } else if (this.addToPlayListSongsArray.type === 'album') {
              var album_songs = this.addToPlayListSongsArray.album_songs;
              for (let i = 0; i < album_songs.length; i++) {
                songsArray.push(album_songs[i].id);
              }
            }
            let payload = {
              playlist_id: playlist.id,
              song_id: songsArray
            }
            const { data } = await addSongToPlaylist(payload);
            if(data.success){
              this.tempArray.push(playlist.id)
              this.spinnerIndex = null;
              this.addPlaylistSpinner = false;
              playlist.song_count += 1;
            }
          }

        }catch (error) {
          this.spinnerIndex = null;
        }
    },
    addToNewPlaylist(song){
      let task = this.createPlaylistSongsArray.filter(track => track.id === song.id)[0]
      if(task){

      }else{
        this.createPlaylistSongsArray.push(song);
        this.tracksArray.push(song.id)
      }
    },
    toggleDropdown(){
      this.showDropdown = !this.showDropdown;
      if(this.check === 'create-new-playlist'){
        this.check = 'add-to-playlist';
      }else if(this.check === 'add-to-playlist'){
        this.check = 'create-new-playlist';
        if(this.added === false){
          if(this.addToPlayListSongsArray){
            if(this.addToPlayListSongsArray.length !== 0){
              this.createPlaylistSongsArray = [...[this.addToPlayListSongsArray], ...this.createPlaylistSongsArray];
            }
          }
        }
        this.added = true;
      }
    },
    isAddedToPlaylist(id){
      const index = this.tempArray.findIndex(ele => ele === id)
      return (index !== -1)
    },
    isTrackAdded(id){
      const index = this.tracksArray.findIndex(ele => ele === id)
      return (index !== -1)
    },
    removeFromArray(obj){
      let task = this.createPlaylistSongsArray.filter(obj =>obj.id === obj.id)[0]
      let index = this.createPlaylistSongsArray.indexOf(task)
      this.createPlaylistSongsArray.splice(index, 1)
      let task2 = this.tracksArray.filter(obj =>obj.id === obj.id)[0]
      let index2 = this.tracksArray.indexOf(task2)
      this.tracksArray.splice(index2, 1)
    },
    emptyData(){
      this.added = false;
      this.createPlaylistSongsArray = [];
      this.tempArray = [];
      this.tracksArray = [];
      this.tracksSearchKey = '';
      this.term = '';
      this.createPlaylist.title = '';
      this.createPlaylist.genre = null;
      this.$store.commit("Set_Add_To_Playlist_Songs_Array", null);
    }
  }
}
</script>
<style scoped>
.form-check-label {
  padding-left: 8px !important;
  padding-right: 16px !important;

}
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  border-radius: 4px;
  top: 3px;
  position: relative;
}

.checkbox:checked {
  background-color: #D1433A;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:not(:checked) {
  background-color: #FFF8F8;
  background-image: none;
  
}
.cover-style{
  background: #D1433A1A !important;
  border: 1px solid #D1433A;
}
.modal.modal-add-playlist .modal-content .title .title-style:before {
  background-color: #fff !important;
}
.modal.modal-add-playlist .modal-content .create-new-playlist {
  border-top: 1px solid #E6EAF0;
  border-bottom: 1px solid #E6EAF0;
}
.modal .modal-content .close-button {
  background-color: #fff !important;
}
.checkbox-label{
  font-weight: bold;
}
.form-check-input {
  width: 16px !important;
  height: 16px !important;
}
.form-check .form-check-label {
 padding-left: 8px !important;
 padding-right: 16px !important;
}
.playlist-select>select{
  position: absolute!important;
  bottom: 0;
  left: 50%;
  /*display: block!important;*/
  width: 0.5px!important;
  height: 100%!important;
  padding: 0!important;
  opacity: 0!important;
  border: none;
  z-index: 0!important;
}
.playlist-select>.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.playlist-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.playlist-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}
.playlist-select .show>.dropdown-menu {
  left: inherit!important;
  transform: inherit!important;
}
.playlist-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.playlist-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px;
}
.playlist-select .dropdown-menu li {
  position: relative;
}
.playlist-select .dropdown-menu li a span.check-mark {
  display: none;
}
.playlist-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}
.playlist-select .dropdown-menu li a span.text {
  display: inline-block;
}
.playlist-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.playlist-select:not(.input-group-btn), .playlist-select[class*=col-] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
.modal.modal-add-playlist .modal-content .song-list .list-item .add-playlist-wrapper .lock {
  background-color: transparent;
}
</style>
