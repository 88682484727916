import { render, staticRenderFns } from "./ActivitySongs.vue?vue&type=template&id=33dea72b&scoped=true"
import script from "./ActivitySongs.vue?vue&type=script&lang=js"
export * from "./ActivitySongs.vue?vue&type=script&lang=js"
import style0 from "./ActivitySongs.vue?vue&type=style&index=0&id=33dea72b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33dea72b",
  null
  
)

export default component.exports