<template>
  <div>
    <gmap-autocomplete
      id="google-location"
      :placeholder="placeholder ? placeholder : 'Location'"
      @place_changed="initMarker"
      ref="geoRef"
    ></gmap-autocomplete>
  </div>
</template>

<script>
export default {
  name: "GoogleMapAndLocation",
  props: ["placeholder"],
  data() {
    return {
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
    };
  },
  mounted() {
    this.locateGeoLocation();
  },
  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
      this.getCityAndCountry();
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.locationMarkers.push({ position: marker });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    getCityAndCountry() {
      if (this.existingPlace) {
        let country = null,
          countryCode = null,
          city = null,
          cityAlt = null;
        for (
          let r = 0, rl = this.existingPlace.address_components.length;
          r < rl;
          r += 1
        ) {
          let result = this.existingPlace.address_components[r];

          if (!city && result.types[0] === "locality") {
            city = result.long_name;
          } else if (
            !city &&
            !cityAlt &&
            result.types[0] === "administrative_area_level_1"
          ) {
            cityAlt = result.long_name;
          } else if (!country && result.types[0] === "country") {
            country = result.long_name;
            countryCode = result.short_name;
          }

          if (city && country) {
            break;
          }
        }
        this.$emit("setLocationFilter", "location", {
          city: city ? city : "",
          country: country ? country : "",
        });
        this.$emit("getLocation", {
          city: city ? city : country,
          country: country ? country : "",
          latitude: this.center.lat,
          longitude: this.center.lng,
          location: this.existingPlace.formatted_address,
          locationName: this.existingPlace.name
        });
      }
    },
    clearInputFiled() {
      document.getElementById("google-location").value = "";
    },
  },
};
</script>

<style lang="scss" scoped>

.pac-target-input {
  position: relative;
  width: 100%;
  border: none;
  padding: 4px 4px 4px 35px;
  font-weight: 400;
  color: #47505b;
  height: 36px;
  font-family: HelveticaNeueMedium;
}
</style>
