import Vue from "vue";
import {checkEmail, signup} from "../apis/APIs";
import * as Utils from "../components/utils/Utils";
import {
    EMPTY,
    ERROR,
    MESSAGE,
    SERVER_PRESENT,
    SIGNUP_ERRORS,
    SIGNUP_FORM,
    SIGNUP_FORM_CONFIRM_PASSWORD,
    SIGNUP_FORM_DOB_DAY,
    SIGNUP_FORM_DOB_MONTH,
    SIGNUP_FORM_DOB_YEAR,
    SIGNUP_FORM_EMAIL,
    SIGNUP_FORM_FIRST_NAME,
    SIGNUP_FORM_GENDER,
    SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY,
    SIGNUP_FORM_LAST_NAME,
    SIGNUP_FORM_PASSWORD,
    SIGNUP_FORM_LOCATION,
    SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER,
    SIGNUP_FORM_USERNAME,
    SIGNUP_KEY,
    SIGNUP_REQEUST_LOADING,
    SIGNUP_REQUEST_STATUS,
    STATUS,
    SUCCESS,
    SYSTEM_DATA_IS_AUTHENTICATED,
    USER_DATA
} from "./types";
import * as SMStorage from "../apis/SMStorage";
import moment from 'moment-timezone';
import store from "./index";

const INITIAL_STATE = {
    [SIGNUP_KEY]: {
        [SIGNUP_FORM]: {
            [SIGNUP_FORM_FIRST_NAME]: "",
            [SIGNUP_FORM_LAST_NAME]: "",
            [SIGNUP_FORM_EMAIL]: "",
            [SIGNUP_FORM_USERNAME]: "",
            [SIGNUP_FORM_GENDER]: "",
            [SIGNUP_FORM_DOB_DAY]: "",
            [SIGNUP_FORM_DOB_MONTH]: "",
            [SIGNUP_FORM_DOB_YEAR]: "",
            [SIGNUP_FORM_PASSWORD]: "",
            [SIGNUP_FORM_CONFIRM_PASSWORD]: "",
            [SIGNUP_FORM_LOCATION]: "",
            [SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER]: true,
            [SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY]: true
        },
        [USER_DATA]: {},
        [SIGNUP_REQEUST_LOADING]: false,
        [SIGNUP_REQUEST_STATUS]: {
            [STATUS]: EMPTY,
            [MESSAGE]: ""
        },
        [SIGNUP_ERRORS]: []
    }
};

export const state = {...INITIAL_STATE};

export const actions = {
    async check_user_exists({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                const signup_key = state[SIGNUP_KEY];
                const form_data = signup_key && signup_key[SIGNUP_FORM] ? signup_key[SIGNUP_FORM] : {};

                const email = form_data && form_data[SIGNUP_FORM_EMAIL] ? form_data[SIGNUP_FORM_EMAIL] : "";

                const body = {email};

                commit("updateSignupUIConstraints", {
                    [SIGNUP_REQEUST_LOADING]: true
                });

                const {data} = await checkEmail(body);

                const {success, message} = data || {};
                if (success === 0) {
                    commit("updateSignupUIConstraints", {
                        [SIGNUP_REQEUST_LOADING]: false
                    });
                    resolve({
                        status: SUCCESS,
                        message: ""
                    });
                } else if (success === 1) {
                    commit("updateSignupUIConstraints", {
                        [SIGNUP_REQEUST_LOADING]: false
                    });
                    resolve({
                        status: SERVER_PRESENT,
                        message: ""
                    });
                } else {
                    commit("updateSignupUIConstraints", {
                        [SIGNUP_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                commit("updateSignupUIConstraints", {
                    [SIGNUP_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        });
    },

    async register_user({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                const signup_key = state[SIGNUP_KEY];
                const form_data = signup_key && signup_key[SIGNUP_FORM] ? signup_key[SIGNUP_FORM] : {};
                
                const email = form_data && form_data[SIGNUP_FORM_EMAIL] ? form_data[SIGNUP_FORM_EMAIL] : "";
                const firstname = form_data && form_data[SIGNUP_FORM_FIRST_NAME] ? form_data[SIGNUP_FORM_FIRST_NAME] : "";
                const lastname = form_data && form_data[SIGNUP_FORM_LAST_NAME] ? form_data[SIGNUP_FORM_LAST_NAME] : "";
                const gender = form_data && form_data[SIGNUP_FORM_GENDER] ? form_data[SIGNUP_FORM_GENDER] : "";
                const day = form_data && form_data[SIGNUP_FORM_DOB_DAY] ? form_data[SIGNUP_FORM_DOB_DAY] : "";
                const month = form_data && form_data[SIGNUP_FORM_DOB_MONTH] ? form_data[SIGNUP_FORM_DOB_MONTH] : "";
                const year = form_data && form_data[SIGNUP_FORM_DOB_YEAR] ? form_data[SIGNUP_FORM_DOB_YEAR] : "";
                const location = form_data && form_data[SIGNUP_FORM_LOCATION] ? form_data[SIGNUP_FORM_LOCATION] : "";

                const username = form_data && form_data[SIGNUP_FORM_USERNAME] ? form_data[SIGNUP_FORM_USERNAME] : "";
                const password = form_data && form_data[SIGNUP_FORM_PASSWORD] ? form_data[SIGNUP_FORM_PASSWORD] : "";
                const confirm_password = form_data && form_data[SIGNUP_FORM_CONFIRM_PASSWORD] ? form_data[SIGNUP_FORM_CONFIRM_PASSWORD] : "";

                const i_agree = form_data && form_data[SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY] ? form_data[SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY] : false;
                const subscribe_to_playground = form_data && form_data[SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER] ? form_data[SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER] : false;

                // const dob = new Date(year, month, day);
                // dob.setHours(0);
                // dob.setSeconds(0, 0);
                // dob.setMinutes(0);


                var formdata = new FormData();
                formdata.append("password", password);
                formdata.append("email", email);
                formdata.append("first_name", firstname);
                formdata.append("last_name", lastname);
                formdata.append("username", username);
                formdata.append("gender", gender);
                formdata.append("location", location);
                // formdata.append("age", moment(dob).format("YYYY-MM-DD"));
                formdata.append("agree", i_agree);
                formdata.append("password_confirmation", confirm_password);
                formdata.append("enable_newsletter", subscribe_to_playground);
                formdata.append("dob_day", day);
                formdata.append("dob_month", month);
                formdata.append("dob_year", year);

                // confirm_password,
                // i_agree,
                // subscribe_to_playground,


                commit("updateSignupUIConstraints", {
                    [SIGNUP_REQEUST_LOADING]: true
                });

                const {data} = await signup(formdata);
                commit("updateUserUIConstraints", {
                    [USER_DATA]: {}
                });


                const {success, message, information} = data || {};


                if (success === 1) {
                    commit("updateUserUIConstraints", {
                        [USER_DATA]: information
                    });
                    commit("updateSignupUIConstraints", {
                        [SIGNUP_REQEUST_LOADING]: false,
                    });
                    resolve({
                        status: SUCCESS,
                        message: "Registration Successful"
                    });
                } else {
                    commit("updateSignupUIConstraints", {
                        [SIGNUP_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: Utils.getErrorFromApi(data)
                    });
                }
            } catch (error) {
                commit("updateSignupUIConstraints", {
                    [SIGNUP_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    errors: error.response.data.errors,
                    message: Utils.getErrorFromApi((error.response) ? error.response.data : error.data)
                });
            }
        })
    },
    async set_user_data({state, commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                await SMStorage.storeSMLoginData(data);
                const userData = await SMStorage.getSMLoginData();
                commit("updateUserUIConstraints", ({
                    [USER_DATA]: userData && userData.response ? userData.response : userData
                }));

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Update user data ===> error", error);
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    updateSignupFormData(state, obj) {
        try {

            let _state = Utils.parseState(state);
            const formData = _state[SIGNUP_KEY];

            // const data = Object.assign(formData[SIGNUP_FORM], obj);

            const data = obj && Object.keys(obj).length === 0
              ? INITIAL_STATE[SIGNUP_KEY][SIGNUP_FORM] // Reset to initial state
              : Object.assign({}, formData[SIGNUP_FORM], obj);

            mutations.updateSignupState(state, Object.assign(formData, {
                [SIGNUP_FORM]: data
            }));
        } catch (error) {
            Utils.log("Update Form Data ===> error ", error);
        }
    },

    updateSignupUIConstraints(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[SIGNUP_KEY];
            const data = Object.assign(formData, obj);

            mutations.updateSignupState(state, data);
        } catch (error) {
            Utils.log("Update UI Constraints ===> error ", error);
        }
    },
    updateSignupState(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            let formData = _state[SIGNUP_KEY];

            state[SIGNUP_KEY] = Object.assign(formData, obj);
        } catch (error) {
            Utils.log("Update Signup State ===> error ", error);
        }
    },
    resetSignupState() {
        for (let f in state) {
            Vue.set(state, f, INITIAL_STATE[f]);
        }
    },
    clearSignupData(state, obj) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[SIGNUP_KEY];
            const data = Object.assign(formData[SIGNUP_FORM], obj);

            mutations.updateSignupState(state, Object.assign(formData, {
                [SIGNUP_FORM]: data
            }));
        } catch (error) {
            Utils.log("Update Form Data ===> error ", error);
        }
    },
};

const getters = {
    signup_email_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const signup_key = _state[SIGNUP_KEY];
        const form_data = signup_key && signup_key[SIGNUP_FORM] ? signup_key[SIGNUP_FORM] : {};

        const email = form_data && form_data[SIGNUP_FORM_EMAIL] ? form_data[SIGNUP_FORM_EMAIL] : "";

        const errors = signup_key && signup_key[SIGNUP_ERRORS] ? signup_key[SIGNUP_ERRORS] : [];
        const loading = signup_key && signup_key[SIGNUP_REQEUST_LOADING] ? signup_key[SIGNUP_REQEUST_LOADING] : false;

        return {
            email,
            errors,
            loading
        }
    },
    signup_user_info_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const signup_key = _state[SIGNUP_KEY];
        const form_data = signup_key && signup_key[SIGNUP_FORM] ? signup_key[SIGNUP_FORM] : {};

        const first_name = form_data && form_data[SIGNUP_FORM_FIRST_NAME] ? form_data[SIGNUP_FORM_FIRST_NAME] : "";
        const last_name = form_data && form_data[SIGNUP_FORM_LAST_NAME] ? form_data[SIGNUP_FORM_LAST_NAME] : "";
        const gender = form_data && form_data[SIGNUP_FORM_GENDER] ? form_data[SIGNUP_FORM_GENDER] : "";
        const day = form_data && form_data[SIGNUP_FORM_DOB_DAY] ? form_data[SIGNUP_FORM_DOB_DAY] : "";
        const month = form_data && form_data[SIGNUP_FORM_DOB_MONTH] ? form_data[SIGNUP_FORM_DOB_MONTH] : 0;
        const year = form_data && form_data[SIGNUP_FORM_DOB_YEAR] ? form_data[SIGNUP_FORM_DOB_YEAR] : "";

        const errors = signup_key && signup_key[SIGNUP_ERRORS] ? signup_key[SIGNUP_ERRORS] : [];
        const loading = signup_key && signup_key[SIGNUP_REQEUST_LOADING] ? signup_key[SIGNUP_REQEUST_LOADING] : false;

        return {
            first_name,
            last_name,
            gender,
            day,
            month,
            year,

            loading,
            errors
        }
    },
    signup_user_settings_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const signup_key = _state[SIGNUP_KEY];
        const form_data = signup_key && signup_key[SIGNUP_FORM] ? signup_key[SIGNUP_FORM] : {};

        const username = form_data && form_data[SIGNUP_FORM_USERNAME] ? form_data[SIGNUP_FORM_USERNAME] : "";
        const password = form_data && form_data[SIGNUP_FORM_PASSWORD] ? form_data[SIGNUP_FORM_PASSWORD] : "";
        const confirm_password = form_data && form_data[SIGNUP_FORM_CONFIRM_PASSWORD] ? form_data[SIGNUP_FORM_CONFIRM_PASSWORD] : "";

        const i_agree = form_data && form_data[SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY] ? form_data[SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY] : false;
        const subscribe_to_playground = form_data && form_data[SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER] ? form_data[SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER] : false;

        const errors = signup_key && signup_key[SIGNUP_ERRORS] ? signup_key[SIGNUP_ERRORS] : [];
        const loading = signup_key && signup_key[SIGNUP_REQEUST_LOADING] ? signup_key[SIGNUP_REQEUST_LOADING] : false;

        return {
            username,
            password,
            confirm_password,

            i_agree,
            subscribe_to_playground,

            errors,
            loading
        }
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
