import {
    ADMINS,
    ADVERTISER,
    AFFILIATION_TO_ARTIST,
    AGREEMENT,
    ANNOUNCEMENT_LIMIT,
    ANNOUNCEMENT_OFFSET,
    ANNOUNCEMENT_TOTAL,
    ARTIST_NAME,
    CURRENT_PAGE,
    EDITORS,
    EMAIL,
    ERROR,
    EXT,
    FRIENDS_SEARCH,
    FULL_NAME,
    LOADING,
    MEMBER_REQUESTS,
    MEMBER_REQUESTS_LIMIT,
    MEMBER_REQUESTS_OFFSET,
    MODERATORS,
    PAGE,
    PAGE_ANNOUNCEMENTS,
    PAGE_PAST_EVENTS,
    PAGE_POST_TYPE_FILTER,
    PAGE_POSTS,
    PARTNER_ARTIST,
    PARTNER_BOOKING_AGENT,
    PARTNER_DISTRIBUTOR,
    PARTNER_INVITES,
    PARTNER_LISTING,
    PARTNER_MANAGEMENT,
    PARTNER_PUBLISHING,
    PARTNER_RECORD_LABEL,
    PARTNER_SPONSOR,
    PAST_EVENT_LIMIT,
    PAST_EVENT_OFFSET,
    PAST_EVENT_TOTAL,
    PHONE_NO,
    POST_LIMIT,
    POST_LIST,
    POST_OFFSET,
    POST_TOTAL,
    POST_TYPE,
    SUCCESS,
    TEMP_SONG_FORM,
    UPCOMING_EVENT_LIMIT,
    UPCOMING_EVENT_OFFSET,
    UPCOMING_EVENT_TOTAL,
    UPCOMING_PAGE_EVENTS,
    VERIFICATION_DESCRIPTION,
    VERIFICATION_FORM
} from "../types";
import {
    deletePosts,
    getAdminAndModerators, getAnnouncement, getCurrentPageMemberRequests,
    getPage, getPageEvents,
    getPageVerification, getPartnerInvites, getPartners,
    postPageVerification,
    searchProfileFriends
} from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";
import Vue from "vue";
import createFormData from "../../mixins/common";

const INITIAL_STATE = {
    [CURRENT_PAGE]: {
        [PAGE_POSTS]: [],
        [UPCOMING_PAGE_EVENTS]: [],
        [PAGE_PAST_EVENTS]: [],
        [POST_OFFSET]: 0,
        [POST_LIMIT]: 8,
        [POST_TOTAL]: 0,
        [PAGE_ANNOUNCEMENTS]: [],
        [ANNOUNCEMENT_OFFSET]: 0,
        [ANNOUNCEMENT_LIMIT]: 8,
        [ANNOUNCEMENT_TOTAL]: 0,
        [MEMBER_REQUESTS]: [],
        [MEMBER_REQUESTS_OFFSET]: 0,
        [MEMBER_REQUESTS_LIMIT]: 10,
        [UPCOMING_EVENT_OFFSET]: 0,
        [UPCOMING_EVENT_LIMIT]: 12,
        [UPCOMING_EVENT_TOTAL]: 0,
        [PAST_EVENT_OFFSET]: 0,
        [PAST_EVENT_LIMIT]: 12,
        [PAST_EVENT_TOTAL]: 0,
        [POST_TOTAL]: 0,
        [PAGE]: null,
        [ADMINS]: [],
        [MODERATORS]: [],
        [ADVERTISER]: [],
        [EDITORS]: [],
        [PARTNER_ARTIST]: [],
        [PARTNER_BOOKING_AGENT]: [],
        [PARTNER_DISTRIBUTOR]: [],
        [PARTNER_MANAGEMENT]: [],
        [PARTNER_PUBLISHING]: [],
        [PARTNER_RECORD_LABEL]: [],
        [PARTNER_SPONSOR]: [],
        [PARTNER_INVITES]:[],
        [PARTNER_LISTING]: [],
        [VERIFICATION_FORM] : {
            [EMAIL]: null,
            [FULL_NAME]: null,
            [ARTIST_NAME]: null,
            [PHONE_NO]: null,
            [EXT]: null,
            [AFFILIATION_TO_ARTIST]: null,
            [VERIFICATION_DESCRIPTION]: null,
            [AGREEMENT]: null,
        },
        [FRIENDS_SEARCH]: [],
        [LOADING]: false,
        [PAGE_POST_TYPE_FILTER]:"",

    },
}

export const state = {...INITIAL_STATE};
export const actions = {
    async getPage({state, commit, dispatch,getters}, {page_id, showMore = false, type = ''}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                if (showMore) {
                    commit('NEXT_PAGINATE_POSTS_OFFSET_SET')
                }
                else{
                    if(!type) {
                        commit('CLEAR_CURRENT_PAGE_DATA')
                    }
                }
                const {data} = await getPage(page_id, state[CURRENT_PAGE][POST_OFFSET], state[CURRENT_PAGE][POST_LIMIT], type);
                const {information} = data
                if (showMore) {
                    commit('JOIN_PAGE_POSTS_WITH_OTHER_POSTS', information.posts.data)
                    // commit('SET_PAGE_DATA', information.data)

                } else {
                    commit('GET_PAGE_POSTS', information.posts.data)
                    commit('SET_PAGE_DATA', information.data)
                }
                commit('SET_PAGE_TOTAL_POSTS', information.posts.total)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getCurrentPageMemberRequest({state, commit, dispatch}, {page_id, showMore = false}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                if (showMore) {
                    commit('NEXT_PAGINATE_MEMBER_REQUESTS_OFFSET_SET')
                }
                else{
                    commit('CLEAR_CURRENT_PAGE_MEMBER_REQUEST_DATA')
                }
                const {data} = await getCurrentPageMemberRequests(page_id, state[CURRENT_PAGE][MEMBER_REQUESTS_OFFSET], state[CURRENT_PAGE][MEMBER_REQUESTS_LIMIT]);
                const {information} = data || {}
                if (showMore) {
                    const { data } = information || {}
                    commit('JOIN_MEMBER_REQUESTS_WITH_OTHER_MEMBER_REQUESTS', information.data)
                } else {
                    const { data } = information || {}
                    commit('SET_MEMBER_REQUESTS', data)
                    commit('SET_PAGE_DATA', data)
                }
                commit('SET_PAGE_TOTAL_POSTS', information.total)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data: information
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getAnnoncements({state, commit, dispatch}, {page_id, showMore = false, type = ''}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                if (showMore) {
                    commit('NEXT_PAGINATE_ANNOUNCEMENTS_OFFSET_SET')
                }
                else{
                    if(!type) {
                        commit('CLEAR_CURRENT_PAGE_DATA')
                    }
                }
                const {data} = await getAnnouncement(page_id, state[CURRENT_PAGE][ANNOUNCEMENT_OFFSET], state[CURRENT_PAGE][ANNOUNCEMENT_LIMIT], type);
                const {information} = data
                if (showMore) {
                    commit('JOIN_ANNOUNCEMENTS_WITH_OTHER_ANNOUNCEMENTS', information.data)
                    // commit('SET_PAGE_DATA', information.data)

                } else {
                    commit('GET_PAGE_ANNOUNCEMENTS', information.data)
                }
                commit('SET_PAGE_TOTAL_ANNOUNCEMENTS', information.total)
                // dispatch('loading', false)
                commit('SET_PAGE_ACTIVE_TAB', 'announcement')
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },

    async getPageUpcomingEvents({state, commit, dispatch}, payload)
    {
        console.log('Before reset:', state[CURRENT_PAGE][UPCOMING_EVENT_OFFSET]);
        if (!payload.showMore) {
            commit('RESET_UPCOMING_EVENTS_OFFSET');
            console.log('After reset:', state[CURRENT_PAGE][UPCOMING_EVENT_OFFSET]);
        }
        return new Promise(async (resolve, reject) => {
            try {
                if (payload.showMore) {
                    commit('NEXT_PAGINATE_UPCOMING_EVENTS_OFFSET_SET')
                } else {
                    commit('RESET_UPCOMING_EVENTS_OFFSET')
                    if(!payload.search) {
                        commit('CLEAR_CURRENT_PAGE_DATA')
                    }
                }
                const {data} = await getPageEvents(payload.page_id, state[CURRENT_PAGE][UPCOMING_EVENT_OFFSET], state[CURRENT_PAGE][UPCOMING_EVENT_LIMIT],payload.type,payload.search);
                const {information} = data
                if (payload.showMore) {
                    commit('JOIN_EVENTS_WITH_OTHER_UPCOMING_EVENTS', information.data)
                } else {
                    commit('GET_PAGE_UPCOMING_EVENTS', information.data)
                }
                commit('SET_PAGE_TOTAL_UPCOMING_EVENTS', information.total)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getPagePastEvents({state, commit, dispatch},payload)
    {
        return new Promise(async (resolve, reject) => {
            try {
                if (payload.showMore) {
                    commit('NEXT_PAGINATE_PAST_EVENTS_OFFSET_SET')
                } else {
                    commit('RESET_PAST_EVENTS_OFFSET')
                    if(!payload.search) {
                        commit('CLEAR_CURRENT_PAGE_DATA')
                    }
                }
                const {data} = await getPageEvents(payload.page_id, state[CURRENT_PAGE][UPCOMING_EVENT_OFFSET], state[CURRENT_PAGE][UPCOMING_EVENT_LIMIT],payload.type,payload.search);
                const {information} = data
                if (payload.showMore) {
                    commit('JOIN_EVENTS_WITH_OTHER_PAST_EVENTS', information.data)
                    // commit('SET_PAGE_DATA', information.data)

                } else {
                    commit('GET_PAGE_PAST_EVENTS', information.data)
                }
                commit('SET_PAGE_TOTAL_PAST_EVENTS', information.total)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getAdminAndModerators({state, commit, dispatch}, page_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)

                const {data} = await getAdminAndModerators(page_id);
                const {information} = data
                commit('SET_CURRENT_PAGE_ADMINS', information.admin.data)
                commit('SET_CURRENT_PAGE_MODERATORS', information.moderators.data)
                commit('SET_CURRENT_PAGE_EDITORS', information.editor.data)
                commit('SET_CURRENT_PAGE_ADVERTISER', information.advertiser.data)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },

    async getPagePartners({state, commit, dispatch}, page_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)

                const {data} = await getPartners(page_id);
                const {information} = data
                commit('SET_CURRENT_PAGE_PARTNER_LISTING', information)
                commit('SET_CURRENT_PAGE_PARTNER_ARTIST', information.Artist)
                commit('SET_CURRENT_PAGE_PARTNER_BOOKING_AGENT', information.Booking_Agent)
                commit('SET_CURRENT_PAGE_PARTNER_DISTRIBUTOR', information.Distributor)
                commit('SET_CURRENT_PAGE_PARTNER_MANAGEMENT', information.Management)
                commit('SET_CURRENT_PAGE_PARTNER_PUBLISHING', information.Publishing)
                commit('SET_CURRENT_PAGE_PARTNER_RECORD_LABEL', information.Record_Label)
                commit('SET_CURRENT_PAGE_PARTNER_SPONSOR', information.Sponsor)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getPagePartnerInvites({state, commit, dispatch}, page_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)

                const {data} = await getPartnerInvites(page_id);
                const {information} = data
                commit('SET_CURRENT_PAGE_PARTNER_INVITES', information.data)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getPageVerification({state, commit, dispatch}, page_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)

                const {data} = await getPageVerification(page_id);
                const {information} = data
                commit('SET_CURRENT_PAGE_VERIFICATION_VERIFY', information)
                commit('SET_CURRENT_PAGE_VERIFICATION_INFORMATION', information)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async searchFriendsForAdminSection({state, commit, dispatch}, friend_name) {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await searchProfileFriends(0, 25, 'friends', friend_name );
                const {information} = data
                commit('SET_FRIENDS_SEARCH_DATA', information.data)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async postPageVerification({state, commit, dispatch}, page_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)

                const postFormData = state[CURRENT_PAGE][VERIFICATION_FORM];
                const formData = createFormData(postFormData);
                const {data} = await postPageVerification(page_id, formData);
                const {information, success, message} = data
                commit('SET_CURRENT_PAGE_VERIFICATION_VERIFY', information)
                commit('SET_CURRENT_PAGE_VERIFICATION_INFORMATION', information)
                dispatch('loading', false)
                resolve({
                    status: success,
                    message: message,
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async deleteCurrentPagePost({state, commit, dispatch}, { post_id, announcement_check = false}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const {data} = await deletePosts(post_id);
                const {success} = data
                if (Number(success)) {
                    dispatch('removeCurrentPagePost', {post_id: post_id, announcement_check: announcement_check})
                }
                // commit('ADD_POST_IN_POSTS', information)
                commit('SET_CONTENT', null)
                dispatch('loading', false)
                resolve({data});
            } catch (error) {
                console.log(error, 'error')
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async removeCurrentPagePost({state, commit}, {postId, announcement_check = false}) {
        let posts =[]
        if (announcement_check) {
            posts = state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS]
        } else  {
            posts = state[CURRENT_PAGE][PAGE_POSTS]
        }
        if (posts.length > 0) {
            const replacedPostIndex = posts.findIndex(e => e.id === postId)
            if (replacedPostIndex !== null) {
                posts.splice(replacedPostIndex, 1);
                // Vue.set(posts, replacedPostIndex, newPost);
                if (announcement_check) {
                    commit('REPLACE_CURRENT_PAGE_ANNOUNCEMENT_POST_IN_LIST', posts)
                } else {
                    commit('REPLACE_CURRENT_PAGE_POST_IN_LIST', posts)
                }
            }
        }
    },
}

export const mutations = {
    CLEAR_CURRENT_PAGE_DATA(state) {
        try {

            state[CURRENT_PAGE][POST_OFFSET] = 0;
            state[CURRENT_PAGE][POST_LIMIT] = 8;
            state[CURRENT_PAGE][POST_TOTAL] = 0;
            state[CURRENT_PAGE][ANNOUNCEMENT_OFFSET] = 0;
            state[CURRENT_PAGE][ANNOUNCEMENT_LIMIT] = 8;
            state[CURRENT_PAGE][ANNOUNCEMENT_TOTAL] = 0;
            state[CURRENT_PAGE][UPCOMING_EVENT_OFFSET] = 0;
            state[CURRENT_PAGE][UPCOMING_EVENT_LIMIT] = 12;
            state[CURRENT_PAGE][UPCOMING_EVENT_TOTAL] = 0;
            state[CURRENT_PAGE][PAST_EVENT_OFFSET] = 0;
            state[CURRENT_PAGE][PAST_EVENT_LIMIT] = 12;
            state[CURRENT_PAGE][PAST_EVENT_TOTAL] = 0;
            state[CURRENT_PAGE][PAGE] = null;
            state[CURRENT_PAGE][PAGE_POSTS] = [];
            state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS] = [];
            state[CURRENT_PAGE][UPCOMING_PAGE_EVENTS] = [];
            state[CURRENT_PAGE][PAGE_PAST_EVENTS] = [];
            state[CURRENT_PAGE][ADMINS] = [];
            state[CURRENT_PAGE][MODERATORS]= [];
            // state[CURRENT_PAGE][VERIFICATION_FORM] = {};

        } catch (e) {
            console.error(e.message, 'unable to clear current data')
        }
    },
    CLEAR_CURRENT_PAGE_MEMBER_REQUEST_DATA(state) {
        try {

            state[CURRENT_PAGE][MEMBER_REQUESTS_OFFSET] = 0;
            state[CURRENT_PAGE][MEMBER_REQUESTS_LIMIT] = 8;
            state[CURRENT_PAGE][MEMBER_REQUESTS] = [];
        } catch (e) {
            console.error(e.message, 'unable to clear current page member request data')
        }
    },
    JOIN_PAGE_POSTS_WITH_OTHER_POSTS(state, payload) {
        let posts = state[CURRENT_PAGE][PAGE_POSTS]
        state[CURRENT_PAGE][PAGE_POSTS] = [...posts, ...payload]
    },
    JOIN_MEMBER_REQUESTS_WITH_OTHER_MEMBER_REQUESTS(state, payload) {
        let member_request = state[CURRENT_PAGE][MEMBER_REQUESTS]
        state[CURRENT_PAGE][MEMBER_REQUESTS] = [...member_request, ...payload]
    },
    JOIN_ANNOUNCEMENTS_WITH_OTHER_ANNOUNCEMENTS(state, payload) {
        let posts = state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS]
        state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS] = [...posts, ...payload]
    },
    JOIN_EVENTS_WITH_OTHER_UPCOMING_EVENTS(state, payload) {
        let events = state[CURRENT_PAGE][UPCOMING_PAGE_EVENTS]
        state[CURRENT_PAGE][UPCOMING_PAGE_EVENTS] = [...events, ...payload]
    },
    JOIN_EVENTS_WITH_OTHER_PAST_EVENTS(state, payload) {
        let posts = state[CURRENT_PAGE][PAGE_PAST_EVENTS]
        state[CURRENT_PAGE][PAGE_PAST_EVENTS] = [...posts, ...payload]
    },
    SET_PAGE_DATA(state, page) {
        try {
            state[CURRENT_PAGE][PAGE] = page

        } catch (e) {
            console.error(e.message, 'unable to set page data')
        }
    },
    SET_PAGE_JOIN_STATUS(state, payload) {
        try {
            state[CURRENT_PAGE][PAGE].join_status = payload

        } catch (e) {
            console.error(e.message, 'unable to set page data')
        }
    },
    REPLACE_CURRENT_PAGE_POST_IN_LIST(state, posts) {
        try {
            state[CURRENT_PAGE][PAGE_POSTS] = posts
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    REPLACE_CURRENT_PAGE_ANNOUNCEMENT_POST_IN_LIST(state, posts) {
        try {
            state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS] = posts
        } catch (e) {
            console.error(e.message, 'unable to set announcement post access option')
        }
    },
    GET_PAGE_POSTS(state, payload) {
        try {
            state[CURRENT_PAGE][PAGE_POSTS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store posts')
        }
    },
    SET_MEMBER_REQUESTS(state, payload) {
        try {
            state[CURRENT_PAGE][MEMBER_REQUESTS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store member requests')
        }
    },
    GET_PAGE_ANNOUNCEMENTS(state, payload) {
        try {
            state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store announcements')
        }
    },
    GET_PAGE_UPCOMING_EVENTS(state, payload) {
        try {
            state[CURRENT_PAGE][UPCOMING_PAGE_EVENTS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store upcoming events')
        }
    },
    GET_PAGE_PAST_EVENTS(state, payload) {
        try {
            state[CURRENT_PAGE][PAGE_PAST_EVENTS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store past events')
        }
    },

    GET_PAGE_SINGLE_POST(state, { payload, index } ) {
        try {
            const posts = Object.assign([], state[CURRENT_PAGE][PAGE_POSTS]);
            posts[index] = payload
            state[CURRENT_PAGE][PAGE_POSTS] = posts
        } catch (e) {
            console.error(e.message, 'unable to store single post')
        }
    },
    SET_CURRENT_PAGE_ANNOUNCEMENT_POST(state, { payload, index } ) {
        try {
            const posts = Object.assign([], state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS]);
            posts[index] = payload
            state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS] = posts
        } catch (e) {
            console.error(e.message, 'unable to store page announcement post')
        }
    },
    SET_PAGE_TOTAL_POSTS(state, payload) {
        try {
            state[CURRENT_PAGE][POST_TOTAL] = payload
        } catch (e) {
            console.error(e.message, 'unable to SET Total post counts')
        }
    },
    SET_PAGE_TOTAL_ANNOUNCEMENTS(state, payload) {
        try {
            state[CURRENT_PAGE][ANNOUNCEMENT_TOTAL] = payload
        } catch (e) {
            console.error(e.message, 'unable to SET Total post counts')
        }
    },
    SET_PAGE_TOTAL_UPCOMING_EVENTS(state, payload) {
        try {
            state[CURRENT_PAGE][UPCOMING_EVENT_TOTAL] = payload
        } catch (e) {
            console.error(e.message, 'unable to SET Total upcoming events counts')
        }
    },
    SET_PAGE_TOTAL_PAST_EVENTS(state, payload) {
        try {
            state[CURRENT_PAGE][PAST_EVENT_TOTAL] = payload
        } catch (e) {
            console.error(e.message, 'unable to SET Total past events counts')
        }
    },
    ADD_POST_OF_PAGE_IN_POSTS(state, payload) {
        try {
            state[CURRENT_PAGE][PAGE_POSTS].unshift(payload)
        } catch (e) {
            console.error(e.message, 'unable to Add posts')
        }
    },
    ADD_ANNOUNCEMENT_POST_OF_PAGE_IN_POSTS(state, payload) {
        try {
            state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS].unshift(payload)
        } catch (e) {
            console.error(e.message, 'unable to Add announcement posts')
        }
    },
    NEXT_PAGINATE_POSTS_OFFSET_SET(state, TYPE = CURRENT_PAGE) {
        try {
            state[TYPE][POST_OFFSET] = Number(state[TYPE][POST_OFFSET]) + Number(state[TYPE][POST_LIMIT])
        } catch (e) {
            console.error(e.message, 'unable to set next offset of page posts')
        }
    },
    NEXT_PAGINATE_MEMBER_REQUESTS_OFFSET_SET(state) {
        try {
            state[CURRENT_PAGE][MEMBER_REQUESTS_OFFSET] = Number(state[CURRENT_PAGE][MEMBER_REQUESTS_OFFSET]) + Number(state[CURRENT_PAGE][MEMBER_REQUESTS_LIMIT])
        } catch (e) {
            console.error(e.message, 'unable to set next offset of member requests')
        }
    },
    NEXT_PAGINATE_ANNOUNCEMENTS_OFFSET_SET(state, TYPE = CURRENT_PAGE) {
        try {
            state[TYPE][ANNOUNCEMENT_OFFSET] = Number(state[TYPE][ANNOUNCEMENT_OFFSET]) + Number(state[TYPE][ANNOUNCEMENT_LIMIT])
        } catch (e) {
            console.error(e.message, 'unable to set next offset of page posts')
        }
    },
    NEXT_PAGINATE_UPCOMING_EVENTS_OFFSET_SET(state, TYPE = CURRENT_PAGE) {
        try {
            state[TYPE][UPCOMING_EVENT_OFFSET] = Number(state[TYPE][UPCOMING_EVENT_OFFSET]) + Number(state[TYPE][UPCOMING_EVENT_LIMIT])
        } catch (e) {
            console.error(e.message, 'unable to set next offset of page posts')
        }
    },
    NEXT_PAGINATE_PAST_EVENTS_OFFSET_SET(state, TYPE = CURRENT_PAGE) {
        try {
            state[TYPE][PAST_EVENT_OFFSET] = Number(state[TYPE][PAST_EVENT_OFFSET]) + Number(state[TYPE][PAST_EVENT_LIMIT])
        } catch (e) {
            console.error(e.message, 'unable to set next offset of page posts')
        }
    },
    FOLLOWERS_COUNT_CHANGE(state, value) {
        let page =state[CURRENT_PAGE][PAGE]
        if(page){
            page.followers_count=page.followers_count+Number(value)
            Vue.set(state[CURRENT_PAGE][PAGE], 'followers_count', page.followers_count);
        }

    },
    SET_CURRENT_PAGE_ADMINS(state, payload) {
        try {
            state[CURRENT_PAGE][ADMINS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store admins')
        }
    },
    SET_CURRENT_PAGE_MODERATORS(state, payload) {
        try {
            state[CURRENT_PAGE][MODERATORS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store moderators')
        }
    },
    SET_CURRENT_PAGE_EDITORS(state, payload) {
        try {
            state[CURRENT_PAGE][EDITORS] = payload
        } catch (e) {
            console.error(e.message, 'unable to store moderators')
        }
    },
    SET_CURRENT_PAGE_ADVERTISER(state, payload) {
        try {
            state[CURRENT_PAGE][ADVERTISER] = payload
        } catch (e) {
            console.error(e.message, 'unable to store advertisers')
        }
    },
    SET_CURRENT_PAGE_PARTNER_ARTIST(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_ARTIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner artist')
        }
    },
    SET_CURRENT_PAGE_PARTNER_BOOKING_AGENT(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_BOOKING_AGENT] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner booking agent')
        }
    },
    SET_CURRENT_PAGE_PARTNER_DISTRIBUTOR(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_DISTRIBUTOR] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner distributor')
        }
    },
    SET_CURRENT_PAGE_PARTNER_MANAGEMENT(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_MANAGEMENT] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner management')
        }
    },
    SET_CURRENT_PAGE_PARTNER_PUBLISHING(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_PUBLISHING] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner publishing')
        }
    },
    SET_CURRENT_PAGE_PARTNER_RECORD_LABEL(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_RECORD_LABEL] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner record label')
        }
    },
    SET_CURRENT_PAGE_PARTNER_SPONSOR(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_SPONSOR] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner sponsor')
        }
    },
    SET_CURRENT_PAGE_PARTNER_LISTING(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_LISTING] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner sponsor')
        }
    },
    SET_CURRENT_PAGE_PARTNER_INVITES(state, payload) {
        try {
            state[CURRENT_PAGE][PARTNER_INVITES] = payload
        } catch (e) {
            console.error(e.message, 'unable to store partner artist')
        }
    },
    SET_CURRENT_PAGE_VERIFICATION_VERIFY(state, payload ){
        try{
            state[CURRENT_PAGE][VERIFICATION_FORM][EMAIL] = payload[EMAIL]
            state[CURRENT_PAGE][VERIFICATION_FORM][FULL_NAME] = payload[FULL_NAME]
        } catch (e) {
            console.error(e.message, 'Unable to set verification verify form data')
        }
    },
    SET_CURRENT_PAGE_VERIFICATION_INFORMATION(state, payload ){
        try{
            state[CURRENT_PAGE][VERIFICATION_FORM][ARTIST_NAME] = payload[ARTIST_NAME]
            state[CURRENT_PAGE][VERIFICATION_FORM][PHONE_NO] = payload[PHONE_NO]
            state[CURRENT_PAGE][VERIFICATION_FORM][EXT] = payload[EXT]
            state[CURRENT_PAGE][VERIFICATION_FORM][AFFILIATION_TO_ARTIST] = payload[AFFILIATION_TO_ARTIST]
            state[CURRENT_PAGE][VERIFICATION_FORM][AGREEMENT] = payload[AGREEMENT]
            state[CURRENT_PAGE][VERIFICATION_FORM][VERIFICATION_DESCRIPTION] = payload[VERIFICATION_DESCRIPTION]
        } catch (e) {
            console.error(e.message, 'Unable to set verification information form data')
        }
    },

    SET_FRIENDS_SEARCH_DATA(state, payload ){
        try{
            state[CURRENT_PAGE][FRIENDS_SEARCH] = payload
        } catch (e) {
            console.error(e.message, 'Unable to set friends search data')
        }
    },
    RESET_ANNOUNCEMENT_OFFSET_AND_LIMIT(state) {
        try {
            state[CURRENT_PAGE][ANNOUNCEMENT_OFFSET] = 0
            state[CURRENT_PAGE][ANNOUNCEMENT_LIMIT] = 8
        } catch (e) {
            console.error(e.message, 'Unable to reset offset and limit')
        }
    },
    RESET_UPCOMING_EVENTS_OFFSET(state) {
        try {
            state[CURRENT_PAGE][UPCOMING_EVENT_OFFSET] = 0;
        } catch (e) {
            console.error(e.message, 'unable to reset upcoming events offset')
        }
    },
    RESET_PAST_EVENTS_OFFSET(state) {
        try {
            state[CURRENT_PAGE][PAST_EVENT_OFFSET] = 0;
        } catch (e) {
            console.error(e.message, 'unable to reset past events offset')
        }
    }

}
const getters = {
    getCurrentPage(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PAGE];
    },
    getCurrentPagePosts(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PAGE_POSTS];
    },
    getCurrentPageAnnouncements(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PAGE_ANNOUNCEMENTS];
    },
    getCurrentPageUpcomingEvents(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][UPCOMING_PAGE_EVENTS];
    },
    getCurrentPageUpcomingEventsTotal(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][UPCOMING_EVENT_TOTAL];
    },
    getCurrentPagePastEvents(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PAGE_PAST_EVENTS];
    },
    getCurrentPagePastEventsTotal(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PAST_EVENT_TOTAL];
    },
    getCurrentPageFollowersCount(state) {
        let _state = Utils.parseState(state);
        return (_state[CURRENT_PAGE][PAGE] && _state[CURRENT_PAGE][PAGE].followers_count) ? _state[CURRENT_PAGE][PAGE].followers_count : 0
    },
    getCurrentPageTracksCount(state) {
        let _state = Utils.parseState(state);
        return (_state[CURRENT_PAGE][PAGE] && _state[CURRENT_PAGE][PAGE].total_tracks) ? _state[CURRENT_PAGE][PAGE].total_tracks : 0
    },
    getCurrentPagePlaysCount(state) {
        let _state = Utils.parseState(state);
        return (_state[CURRENT_PAGE][PAGE] && _state[CURRENT_PAGE][PAGE].total_song_plays) ? _state[CURRENT_PAGE][PAGE].total_song_plays : 0
    },
    getCurrentPageMembersCount(state) {
        let _state = Utils.parseState(state);
        return (_state[CURRENT_PAGE][PAGE] && _state[CURRENT_PAGE][PAGE].members) ? _state[CURRENT_PAGE][PAGE].members : 0
    },
    getCurrentPagePostsCount(state) {
        let _state = Utils.parseState(state);
        return (_state[CURRENT_PAGE][PAGE] && _state[CURRENT_PAGE][PAGE].posts) ? _state[CURRENT_PAGE][PAGE].posts : 0
    },
    getCurrentPageMediasCount(state) {
        let _state = Utils.parseState(state);
        return (_state[CURRENT_PAGE][PAGE] && _state[CURRENT_PAGE][PAGE].medias) ? _state[CURRENT_PAGE][PAGE].medias : 0
    },
    getCurrentPageAdmins(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][ADMINS];
    },
    getCurrentPageModerators(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][MODERATORS];
    },
    getCurrentPageEditors(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][EDITORS];
    },
    getCurrentPageAdvertiser(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][ADVERTISER];
    },
    getCurrentPagePartnerArtist(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_ARTIST];
    },
    getCurrentPagePartnerBookingAgent(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_BOOKING_AGENT];
    },
    getCurrentPagePartnerDistributor(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_DISTRIBUTOR];
    },
    getCurrentPagePartnerManagement(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_MANAGEMENT];
    },
    getCurrentPagePartnerPublishing(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_PUBLISHING];
    },
    getCurrentPagePartnerRecordLabel(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_RECORD_LABEL];
    },
    getCurrentPagePartnerSponsor(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_SPONSOR];
    },
    getCurrentPagePartnerListing(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_LISTING];
    },
    getCurrentPagePartnerInvites(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][PARTNER_INVITES];
    },
    getCurrentPageVerificationFormData(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][VERIFICATION_FORM]
    },
    getSearchedFriendsData(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][FRIENDS_SEARCH]
    },
    getCurrentPageMemberRequests(state) {
        let _state = Utils.parseState(state);
        return _state[CURRENT_PAGE][MEMBER_REQUESTS]
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
