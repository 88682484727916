<template>
  <div>
    <div v-if="getActiveFullChatType && (Object.keys(getActiveChatUser).length !== 0 || Object.keys(getActiveGroup).length !== 0)">
      <div class="heading d-flex align-items-center">
        <button class="back-btn" @click="backToPreviousState">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9L1 5L5 1" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <div class="title">
          {{ (getActiveFullChatType === "single") ? "User Info" : "Group Info" }}
        </div>
      </div>
      <div class="body" v-if="getActiveFullChatType ==='single'">
        <div class="user-card" v-if="getActiveFullChatType ==='single'">
          <div class="chat-new d-flex flex-row last cursor-pointer ">
            <div class="cover-image">
              <div class="img-wrapper">
                <img
                  :src="getActiveChatUser.image"
                  alt="" />
              </div>
            </div>
            <div class="content-block d-flex flex-row w-100">
              <div>
                <div class="title">{{ getActiveChatUser.name }}</div>
                <div class="subtitle unread">Member</div>
                <div class="last-active">{{ convertDateIntoHumanReadableForm(getActiveChatUser.last_active) }}</div>
              </div>
              <div class="d-flex flex-column align-items-end justify-content-end ml-auto">
                <div class="svg-icon">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07935 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                      stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns-div">
          <button class="large-btn">
            <span> View Contract</span>
          </button>
          <button class="large-btn">
            <span>Schedule Meeting</span>
          </button>
          <button class="small-btn">
            <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
                fill="#47505B" />
              <path
                d="M7.29199 3C8.12042 3 8.79199 2.32843 8.79199 1.5C8.79199 0.671573 8.12042 0 7.29199 0C6.46357 0 5.79199 0.671573 5.79199 1.5C5.79199 2.32843 6.46357 3 7.29199 3Z"
                fill="#47505B" />
              <path
                d="M13.084 3C13.9124 3 14.584 2.32843 14.584 1.5C14.584 0.671573 13.9124 0 13.084 0C12.2556 0 11.584 0.671573 11.584 1.5C11.584 2.32843 12.2556 3 13.084 3Z"
                fill="#47505B" />
            </svg>
          </button>
        </div>
        <div class="collapse-list">
          <div id="accordion">
            <div class="list-main-div" v-for="(list, index) in dropdown_list" :key="index">
              <div class="card">
                <div class="card-header" :class="{'no-border' : !list.check}" id="headingOne"
                     @click="removeBorder(index)">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" :data-target="'#collapse-'+index"
                            aria-expanded="false" aria-controls="collapseOne">
                      {{ list.title }}
                    </button>
                  </h5>
                </div>

                <div :id="'collapse-'+index" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <div v-if="list.title === 'Group Members'" class="group-members d-flex align-items-center">
                      <div class="group-member-div">
                        <div class="group-member">
                          <div class="cover-image">
                            <div class="img-wrapper">
                              <img src="../../../../assets/img/1.png" alt="" />
                            </div>
                            <div class="online"></div>
                          </div>
                          <div class="detail-block w-100">
                            <div class="title">User Name</div>
                            <div class="option-svg">
                              <svg width="3" height="15" viewBox="0 0 3 15" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                                  fill="#8B949F" />
                                <path
                                  d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                                  fill="#8B949F" />
                                <path
                                  d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                                  fill="#8B949F" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="group-member">
                          <div class="cover-image">
                            <div class="img-wrapper">
                              <img src="../../../../assets/img/1.png" alt="" />
                            </div>
                            <div class="online"></div>
                          </div>
                          <div class="detail-block w-100">
                            <div class="title">User Name</div>
                            <div class="option-svg">
                              <svg width="3" height="15" viewBox="0 0 3 15" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                                  fill="#8B949F" />
                                <path
                                  d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                                  fill="#8B949F" />
                                <path
                                  d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                                  fill="#8B949F" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="group-member">
                          <div class="cover-image">
                            <div class="img-wrapper">
                              <img src="../../../../assets/img/1.png" alt="" />
                            </div>
                            <div class="online"></div>
                          </div>
                          <div class="detail-block w-100">
                            <div class="title">User Name</div>
                            <div class="option-svg">
                              <svg width="3" height="15" viewBox="0 0 3 15" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                                  fill="#8B949F" />
                                <path
                                  d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                                  fill="#8B949F" />
                                <path
                                  d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                                  fill="#8B949F" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="list.title === 'Tags' " class="check-box d-flex align-items-center">
                      <div class="check-box-div">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#D1433A" />
                          </svg>
                          <label class="form-check-label" for="flexCheckDefault">Offer</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#EE3F3F" />
                          </svg>
                          <label class="form-check-label" for="flexCheckDefault">Negotiation</label>
                        </div>
                      </div>
                      <div class="ml-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#15CA86" />
                          </svg>
                          <label class="form-check-label" for="flexCheckDefault">Interview</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#6540CE" />
                          </svg>
                          <label class="form-check-label" for="flexCheckDefault">Contract</label>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="list.title === 'Personal Info' " class="personal-info-box d-flex align-items-center">
                      <div class="personal-info-div">
                        <div class="details">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.6689 11.28V13.28C14.6697 13.4657 14.6316 13.6494 14.5572 13.8195C14.4829 13.9897 14.3738 14.1424 14.237 14.2679C14.1001 14.3934 13.9386 14.489 13.7627 14.5485C13.5869 14.6079 13.4005 14.63 13.2156 14.6133C11.1641 14.3904 9.19357 13.6894 7.46223 12.5666C5.85145 11.5431 4.48579 10.1774 3.46223 8.56665C2.33555 6.82745 1.6344 4.84731 1.41557 2.78665C1.39891 2.60229 1.42082 2.41649 1.4799 2.24107C1.53898 2.06564 1.63395 1.90444 1.75874 1.76773C1.88354 1.63102 2.03544 1.52179 2.20476 1.447C2.37409 1.37221 2.55713 1.33349 2.74223 1.33332H4.74223C5.06577 1.33013 5.37943 1.4447 5.62474 1.65567C5.87006 1.86664 6.03029 2.15961 6.07557 2.47998C6.15998 3.12003 6.31653 3.74847 6.54223 4.35332C6.63193 4.59193 6.65134 4.85126 6.59817 5.10057C6.545 5.34988 6.42148 5.57872 6.24223 5.75998L5.39557 6.60665C6.3446 8.27568 7.72654 9.65761 9.39557 10.6066L10.2422 9.75998C10.4235 9.58074 10.6523 9.45722 10.9016 9.40405C11.151 9.35087 11.4103 9.37029 11.6489 9.45998C12.2537 9.68568 12.8822 9.84223 13.5222 9.92665C13.8461 9.97234 14.1418 10.1355 14.3533 10.385C14.5647 10.6345 14.677 10.953 14.6689 11.28Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span> number</span>
                        </div>
                        <div class="details">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.667 1.33325V3.99992" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path d="M5.33301 1.33325V3.99992" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path d="M2 6.66675H14" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" />
                          </svg>
                          <span> {{ (getActiveChatUser.dob) ? getActiveChatUser.dob : " " }}</span>
                        </div>
                      </div>
                      <div class="ml-auto">
                        <div class="details">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.66634 2.66675H13.333C14.0663 2.66675 14.6663 3.26675 14.6663 4.00008V12.0001C14.6663 12.7334 14.0663 13.3334 13.333 13.3334H2.66634C1.93301 13.3334 1.33301 12.7334 1.33301 12.0001V4.00008C1.33301 3.26675 1.93301 2.66675 2.66634 2.66675Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.6663 4L7.99967 8.66667L1.33301 4" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span> {{ getActiveChatUser.email | sliceEmail }}</span>
                        </div>
                        <div class="details">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 7.33325L14.6667 1.33325L8.66667 13.9999L7.33333 8.66659L2 7.33325Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span> {{ (getActiveChatUser.city) ? getActiveChatUser.city : " " }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="list.title === 'Privacy & Support'" class="privacy-and-support-box">
                      <div class="details">
                        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.85714 1L4.04762 4.04762H1V8.61905H4.04762L7.85714 11.6667V1Z" stroke="#071526"
                                stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M17.0001 4.04761L12.4287 8.61904" stroke="#071526" stroke-linecap="round"
                                stroke-linejoin="round" />
                          <path d="M12.4287 4.04761L17.0001 8.61904" stroke="#071526" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        <span> Mute Notifications</span>
                      </div>
                      <div class="details">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.3582 9.73104V6.45697C13.3853 5.71864 13.268 4.98231 13.0136 4.2931C12.7591 3.60389 12.3729 2.97631 11.8785 2.44884C11.3841 1.92137 10.792 1.50511 10.1385 1.22558C9.48496 0.946046 8.78377 0.809129 8.07789 0.823219C7.38951 0.793454 6.70238 0.907896 6.05683 1.15983C5.41129 1.41176 4.82033 1.79611 4.31865 2.29031C3.81696 2.78451 3.41466 3.37861 3.13535 4.03774C2.85604 4.69687 2.70535 5.40775 2.69213 6.12866C2.6999 6.07077 2.642 8.05782 2.642 9.73104C2.642 11.8492 2.15113 12.3431 1 14.1583H15.1575C14.0038 12.3412 13.3582 11.908 13.3582 9.73104Z"
                            stroke="#071526" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M6.17773 15.9075C6.39888 16.1865 6.67495 16.4124 6.98712 16.5695C7.29928 16.7267 7.64017 16.8115 7.98653 16.8182C8.31185 16.8249 8.63345 16.7448 8.92102 16.5855C9.20859 16.4261 9.45266 16.1928 9.63026 15.9075"
                            stroke="#071526" stroke-linecap="round" />
                        </svg>
                        <span> Ignore messages</span>
                      </div>
                      <div class="details cursor-pointer" @click="redirectToSettingPageAndSetCheckAndTab($event, 'Blocking')">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                            stroke="#071526" />
                          <path d="M3.40039 14.6001L14.6004 3.40015" stroke="#071526" />
                        </svg>
                        <span> Block </span>
                      </div>
                      <div class="details">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                            stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M9 5.80005V9.00005" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M9 12.2H9.008" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span> Report </span>
                      </div>
                    </div>
                    <div v-else class="shared-media-box">
                      <div class="photos-and-video">
                        <div class="photo-and-video-heading">
                          <span>Photos & Video</span>
                          <span class="total ml-auto">{{ getActiveAllImages.length }}</span>
                        </div>
                        <div class="photo-and-video-data">
                          <img v-for="(image, index) in getActiveAllImages.slice(0,4)" :key="index"
                               :src="image.attachments[0].image" @click="openChatAttachmentModal($event,index)" />
                        </div>
                      </div>
                      <div class="others">
                        <span>Music & Samples</span>
                        <span class="total ml-auto">{{ getActiveAllAudios.length }}</span>
                      </div>
                      <div class="others">
                        <span>Documents & Files</span>
                        <span class="total ml-auto">{{ getActiveAllFiles.length }}</span>
                      </div>
                      <div class="others">
                        <span>Links</span>
                        <span class="total ml-auto">{{ getActiveAllLinks.length }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else-if="getActiveFullChatType ==='group'">
        <div class="user-card">
          <div class="chat-new d-flex flex-row last cursor-pointer">
            <div class="cover-image">
              <div v-if="members_list_images" class="group-img-wrapper">
                <div v-for="(image , index) in members_list_images.slice(0,3)" :key="index" class="single-img-wrap">
                  <img :src="image" alt="" />
                </div>
                <div class="single-img-wrap count">
                  +{{ members_list_images.length > 3 ? members_list_images.length - 3 : "0" }}
                </div>
              </div>
              <div v-else class="group-img-wrapper">
                <div class="single-img-wrap">
                  <img src="" alt="" />
                </div>
                <div class="single-img-wrap">
                  <img src="" alt="" />
                </div>
                <div class="single-img-wrap">
                  <img src="" alt="" />
                </div>
                <div class="single-img-wrap count"> +0</div>
              </div>
            </div>
            <div class="content-block d-flex flex-row w-100 group-card-content">
              <div>
                <div class="title">{{ ((getActiveGroup && getActiveGroup.title) ? getActiveGroup.title : "Group Name") | shortTheLengthOfString }}
                </div>
                <div class="last-active">{{ convertDateIntoHumanReadableForm(getActiveGroup.last_message_at) }}</div>
                <div class="room-type">
                  <div class="svg-icon">
                    <svg
                      style="margin-top: -2px"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 5.95835C11.502 6.72827 11.3221 7.48778 10.975 8.17502C10.5634 8.99854 9.93069 9.6912 9.14768 10.1754C8.36467 10.6597 7.46231 10.9163 6.54167 10.9167C5.77174 10.9187 5.01223 10.7388 4.325 10.3917L1 11.5L2.10833 8.17502C1.76121 7.48778 1.58133 6.72827 1.58333 5.95835C1.58369 5.03771 1.84036 4.13535 2.32459 3.35234C2.80882 2.56933 3.50148 1.9366 4.325 1.52502C5.01223 1.17789 5.77174 0.998009 6.54167 1.00002H6.83333C8.0492 1.0671 9.19761 1.58029 10.0587 2.44135C10.9197 3.30241 11.4329 4.45081 11.5 5.66668V5.95835Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <span>Text Room</span>
                </div>
              </div>
              <div class="favor-wrap">
                <div class="svg-icon">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07935 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                      stroke="#47505B"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget about">
          <div class="widget-title">About</div>
          <div class="text">
            {{ ((getActiveGroup && getActiveGroup.description) ? getActiveGroup.description : "About") | shortTheLengthOfAbout }}
          </div>
        </div>
        <div class="widget Members">
          <div class="widget-title">
            Members
            <span>{{ (getActiveAllGroupMembers && getActiveAllGroupMembers.length > 0) ? getActiveAllGroupMembers.length : "0"
              }}</span>
          </div>
          <div v-if="getActiveAllGroupMembers">
            <div class="item" v-for="(member, index) in (member_show_all) ? getActiveAllGroupMembers : getActiveAllGroupMembers.slice(0,3)" :key="index">
              <div class="img-wrap">
                <div class="img-cover">
                  <img :src="(member.user && member.user.image) ? member.user.image : ''" alt="" />
                </div>
                <div v-if="member.user && member.user.online" class="status"></div>
              </div>
              <div class="item-content">
                <div class="item-title">{{ (member.user && member.user.name) ? member.user.name : "User Name" }}</div>
                <div class="item-subtitle">Singer, Songwriter, Producer</div>
              </div>
            </div>
          </div>
          <div class="show-all" @click="member_show_all = !member_show_all">{{(member_show_all) ? 'Show Less' : 'Show All'}}
            <span>({{ (getActiveAllGroupMembers && getActiveAllGroupMembers.length > 0) ? getActiveAllGroupMembers.length : "0"
              }})</span></div>
        </div>
        <div class="custom-collapse" role="tablist">
          <b-card no-body>
            <b-button block v-b-toggle.accordion-1>Privacy & Support</b-button>
            <b-collapse
              id="accordion-1"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card>
                  <ul class="privacy-support">
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M18.8571 15L15.0476 18.0476H12V22.619H15.0476L18.8571 25.6667V15Z"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M27.9992 18.0477L23.4277 22.6192"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M23.4277 18.0477L27.9992 22.6192"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      Mute Notifications
                    </li>
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="4"
                            fill="transparent"
                          />
                          <path
                            class="c-line-1"
                            d="M24.3582 20.7309V17.4568C24.3853 16.7185 24.268 15.9822 24.0136 15.293C23.7591 14.6038 23.3729 13.9762 22.8785 13.4487C22.3841 12.9212 21.792 12.505 21.1385 12.2255C20.485 11.9459 19.7838 11.809 19.0779 11.8231C18.3895 11.7933 17.7024 11.9078 17.0568 12.1597C16.4113 12.4116 15.8203 12.796 15.3186 13.2902C14.817 13.7844 14.4147 14.3785 14.1353 15.0376C13.856 15.6967 13.7054 16.4076 13.6921 17.1285C13.6999 17.0707 13.642 19.0577 13.642 20.7309C13.642 22.8491 13.1511 23.3429 12 25.1581H26.1575C25.0038 23.3411 24.3582 22.9079 24.3582 20.7309Z"
                            stroke="#071526"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M17.1777 26.9073C17.3989 27.1864 17.675 27.4122 17.9871 27.5694C18.2993 27.7266 18.6402 27.8114 18.9865 27.8181C19.3119 27.8248 19.6334 27.7447 19.921 27.5854C20.2086 27.426 20.4527 27.1927 20.6303 26.9073"
                            stroke="#071526"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      Ignore messages
                    </li>
                    <li @click="redirectToSettingPageAndSetCheckAndTab($event, 'Blocking')">
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="4"
                            fill="transparent"
                          />
                          <path
                            class="c-line-1"
                            d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
                            stroke="#071526"
                          />
                          <path
                            class="c-line-1"
                            d="M14.4004 25.6001L25.6004 14.4001"
                            stroke="#071526"
                          />
                        </svg>
                      </div>
                      Block
                    </li>
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="4"
                            fill="transparent"
                          />
                          <path
                            class="c-line-1"
                            d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M20 16.8V20"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M20 23.2H20.008"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      Report
                    </li>
                  </ul>
                </b-card>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="custom-collapse" role="tablist">
          <b-card no-body>
            <b-button block v-b-toggle.accordion-2>Shared Media & Files</b-button>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card>
                  <div class="media-title">
                    Photos & Video
                    <span>{{ (getActiveAllImages && getActiveAllImages.length) ? getActiveAllImages.length : "0" }}</span>
                  </div>
                  <div class="media-items-row">
                    <div v-for="(image, index) in getActiveAllImages.slice(0,4)" :key="index" class="img-wrap"
                         :class="{'with-more':(checkIfSharedMediaImagesOnLastIndex(index) && getActiveAllImages.length > 3)}">
                      <img :src="(image.attachments && image.attachments.length > 0 && image.attachments[0].file) ? image.attachments[0].file : ''" @click="openChatAttachmentModal($event,index)" alt="" />
                      <span v-if="checkIfSharedMediaImagesOnLastIndex(index) && getActiveAllImages.length > 3"
                            @click="openChatAttachmentModal($event,index)">+{{ (getActiveAllImages && getActiveAllImages.length > 4) ? getActiveAllImages.length - 4 : "0" }}</span>
                    </div>
                  </div>
                  <div class="media-title">
                    Music & Samples
                    <span>{{ (getActiveAllAudios && getActiveAllAudios.length) ? getActiveAllAudios.length : "" }}</span>
                  </div>
                  <div class="media-title">
                    Documents & Files
                    <span>{{ (getActiveAllFiles && getActiveAllFiles.length) ? getActiveAllFiles.length : "0" }}</span>
                  </div>
                  <div class="media-title">
                    Links
                    <span>{{ (getActiveAllFiles && getActiveAllFiles.length) ? getActiveAllLinks.length : "0" }}</span>
                  </div>
                </b-card>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="heading d-flex align-items-center">
        <div class="title">{{(getLeftBarListingActiveTab == 'single') ? 'User Info' : 'Group Info'}}</div>
      </div>
      <div class="body empty-body-with-search-icon">
        <div class="empty-item">
          <div class="svg-icon">
            <svg
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="70" height="70" fill="transparent" />
              <g clip-path="url(#clip0_3500_20521)">
                <path
                  d="M49.0329 43.7899C52.664 38.835 54.2904 32.6917 53.5866 26.5892C52.8828 20.4867 49.9007 14.8749 45.237 10.8766C40.5734 6.87834 34.572 4.7884 28.4336 5.02493C22.2951 5.26145 16.4724 7.807 12.1302 12.1523C7.78801 16.4976 5.24663 22.3222 5.01449 28.4608C4.78236 34.5994 6.8766 40.5992 10.8782 45.2601C14.8798 49.9209 20.4937 52.8989 26.5968 53.5983C32.6998 54.2978 38.8419 52.667 43.7942 49.0324H43.7904C43.9029 49.1824 44.0229 49.3249 44.1579 49.4636L58.5954 63.9011C59.2986 64.6048 60.2525 65.0003 61.2472 65.0006C62.242 65.001 63.1962 64.6062 63.8998 63.903C64.6035 63.1999 64.999 62.246 64.9993 61.2512C64.9997 60.2565 64.6048 59.3023 63.9017 58.5986L49.4642 44.1611C49.3301 44.0254 49.186 43.9001 49.0329 43.7861V43.7899ZM50.0004 29.3749C50.0004 32.0834 49.467 34.7654 48.4305 37.2677C47.394 39.7701 45.8747 42.0438 43.9595 43.959C42.0443 45.8742 39.7706 47.3934 37.2683 48.4299C34.7659 49.4664 32.084 49.9999 29.3754 49.9999C26.6669 49.9999 23.9849 49.4664 21.4826 48.4299C18.9803 47.3934 16.7066 45.8742 14.7914 43.959C12.8762 42.0438 11.3569 39.7701 10.3204 37.2677C9.28392 34.7654 8.75044 32.0834 8.75044 29.3749C8.75044 23.9048 10.9234 18.6587 14.7914 14.7908C18.6593 10.9229 23.9053 8.74989 29.3754 8.74989C34.8455 8.74989 40.0916 10.9229 43.9595 14.7908C47.8275 18.6587 50.0004 23.9048 50.0004 29.3749Z"
                  fill="#47505B"
                />
              </g>
              <defs>
                <clipPath id="clip0_3500_20521">
                  <rect
                    width="60"
                    height="60"
                    fill="white"
                    transform="translate(5 5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="search-text">Select {{(getLeftBarListingActiveTab == 'single') ? 'User' : 'Group'}} to see info</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAndGroupInfoComponent",
  data() {
    return {
      dropdown_list: [
        {
          check: true,
          title: "Tags"
        },
        {
          check: true,
          title: "Personal Info"
        },
        {
          check: true,
          title: "Privacy & Support"
        },
        {
          check: true,
          title: "Shared Media"
        }
      ],
      group_dropdown_list: [
        {
          check: true,
          title: "Group Members"
        },
        {
          check: true,
          title: "Privacy & Support"
        },
        {
          check: true,
          title: "Shared Media"
        }
      ],
      checking_id: "",
      members_list_images: [],
      member_show_all: false
    };
  },
  filters: {
    sliceEmail(email) {
      if (email && email.length > 13) {
        return email.slice(0, 13) + "...";
      } else {
        return email;
      }
    },
    getFilteredMessage(data) {
      if (
        data.message.state.messageSets.length > 0 &&
        data.message.state.messageSets[0].messages.length > 0
      ) {
        const messages = data.message.state.messageSets[0].messages;
        const lastIndex = messages.length - 1;
        if (messages[lastIndex].attachments.length > 0) {
          if (
            messages[lastIndex].attachments[0].type === "image/png" ||
            messages[lastIndex].attachments[0].type === "image/jpeg" ||
            messages[lastIndex].attachments[0].type === "image/jpg"
          ) {
            return "sent an image";
          } else {
            return "sent an attachment";
          }
        } else {
          return messages[lastIndex].text.length > 20
            ? messages[lastIndex].text.slice(0, 20) + "..."
            : messages[lastIndex].text.slice(0, 20);
        }
      }
    },
    shortTheLengthOfString: function(string) {
      return (string && string.length > 12) ? string.slice(0, 12)+'...' : string
    },
    shortTheLengthOfAbout: function(string) {
      return (string && string.length > 50) ? string.slice(0, 50)+'...' : string
    }
  },
  watch: {
    getActiveAllGroupMembers() {
      this.setMembersListImages();
      this.member_show_all = false
    }
  },
  computed: {
    getActiveChatUser() {
      return this.$store.getters.getActiveFullChatUser;
    },
    getActiveFullChatType() {
      return this.$store.getters.getActiveFullChatType;
    },
    getActiveAllImages() {
      return this.$store.getters.getActiveAllImages;
    },
    getActiveAllFiles() {
      return this.$store.getters.getActiveAllFiles;
    },
    getActiveAllAudios() {
      return this.$store.getters.getActiveAllAudios;
    },
    getActiveAllLinks() {
      return this.$store.getters.getActiveAllLinks;
    },
    getActiveAllVoices() {
      return this.$store.getters.getActiveAllVoices;
    },
    getActiveAllGifs() {
      return this.$store.getters.getActiveAllGifs;
    },
    getActiveGroup() {
      return this.$store.getters.getActiveGroup;
    },
    getActiveAllGroupMembers() {
      return this.$store.getters.getActiveAllGroupMembers.members;
    },
    getNextActiveChatType() {
      return this.$store.getters.getActiveFullChatPreviousType;
    },
    getLeftBarListingActiveTab() {
      return this.$store.getters.getLeftBarListingActiveTab
    }
  },
  methods: {
    removeBorder(index) {
      if (this.checking_id === index) {
        this.dropdown_list[index].check = true;
      } else {
        if (this.checking_id) {
          this.dropdown_list[this.checking_id].check = true;
        }
        this.dropdown_list[index].check = false;
        this.checking_id = index;
      }
    },
    openChatAttachmentModal(event, index) {
      event.stopPropagation();
      if (this.getActiveAllImages.slice(0, 4).length === index + 1) {
        const self = this;
        self.$store.commit("SET_CHAT_ATTACHMENT_MODAL", true);
      }
    },
    checkIfSharedMediaImagesOnLastIndex(index) {
      if (this.getActiveAllImages.slice(0, 4).length === index + 1) {
        return true;
      } else {
        return false;
      }
    },
    backToPreviousState() {
      this.$store.commit("SET_ACTIVE_FULL_CHAT_PREVIOUS_TYPE", "");
    },
    setMembersListImages() {
      if (this.getActiveAllGroupMembers && this.getActiveAllGroupMembers.length > 0) {
        const images = this.getActiveAllGroupMembers.map((e) => e.user.image);
        this.members_list_images = images;
      }
    },
    redirectToSettingPageAndSetCheckAndTab(event,tab) {
      if(tab) {
        if(tab === 'Privacy') {
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_CHECK', true)
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_TAB', tab)
        } else if(tab === 'Blocking') {
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_CHECK', true)
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_TAB', tab)
        } else {
        }
        this.redirectToSettingPage(event)
      }
    }
  }
};
</script>

<style scoped lang="scss">
.heading {
  width: inherit;
  height: 79px;
  padding: 0 20px 0 20px;

  .back-btn {
    box-sizing: border-box;
    background: #F5F7F9;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    border: none;

    svg {
      margin: auto;
    }
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #071526;
    margin-left: 18px;
  }
}

.body {
  padding: 0 20px 0 20px;

  .user-card {
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    padding: 10px 17px 10px 10px;
    height: 100px;
    width: 310px;

    &.inner-collapse {
      margin-bottom: 20px;
    }

    &.offer-type {
      height: 70px;
      padding: 14px 20px;
    }

    .chat-new {
      max-width: inherit;
      background: transparent;
      margin-bottom: unset;
      padding: unset;
      height: 80px;

      &:hover {
        background: unset;
      }

      .favor-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        cursor: pointer;
        margin-left: auto;
      }

      .cover-image {
        max-height: 80px;

        .img-wrapper {
          height: 80px;
          width: 80px;
        }

        img {
          width: inherit;
        }
      }

      .content-block {
        margin-left: 10px;

        &.group-card-content {

          .room-type {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .svg-icon {
              margin-bottom: unset;
            }

            span {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              color: #D1433A;
              margin-left: 10px;
            }
          }

          .last-active {
            margin-top: unset;
          }
        }

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #071526;
        }

        .subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #8B949F;
        }

        .last-active {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #8B949F;
        }

        .svg-icon {
          margin-bottom: 8px;
        }
      }
    }
  }

  .btns-div {
    height: 70px;
    padding: 20px 0 20px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6EAF0;

    .large-btn {
      background: #F5F7F9;
      border-radius: 2px;
      border: none;
      width: 130px;
      height: 30px;
      margin-right: 10px;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #D1433A;
        margin: auto;
      }
    }

    .small-btn {
      background: #F5F7F9;
      border-radius: 2px;
      width: 30px;
      height: 30px;
      border: none;

      svg {
        margin: auto;
      }
    }
  }

  .collapse-list {

    .list-main-div {
      &:last-child {
        .card {
          .collapse {
            border-bottom: unset;
          }
        }
      }

      .card {
        border: unset;
        background: inherit;

        .no-border {
          border-bottom: unset !important;
        }

        .card-header {
          background: inherit;
          padding-left: 0;
          border-bottom: 1px solid #E6EAF0;

          h5 {
            button {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #071526;
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        .collapsing {
          transition: height .15s ease;
          display: none;
        }

        .collapse {
          border-bottom: 1px solid #E6EAF0;

          .card-body {
            padding-left: 0;
            padding-right: 0;

            .group-members {
              display: flex;
              align-items: center;

              .group-member-div {
                width: 100%;

                .group-member {
                  display: flex;
                  height: 50px;

                  .cover-image {
                    max-height: 30px;
                    display: flex;
                    align-items: center;
                    margin: auto;

                    .img-wrapper {
                      height: 30px;
                      width: 30px;

                      img {
                        margin: 0%;
                        width: inherit;
                        border-radius: 50%;
                      }
                    }
                  }

                  .detail-block {
                    margin-left: 11px;
                    display: flex;
                    align-items: center;

                    .title {
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 17px;
                      color: #47505B;
                      opacity: 0.9;
                    }

                    .option-svg {
                      display: flex;
                      align-items: center;
                      margin-left: auto;
                      margin-right: 13px;
                    }
                  }
                }
              }
            }

            .check-box {

              .check-box-div {

              }

              .ml-auto {
                margin-right: 1.8rem;
              }

              .form-check {
                display: flex;
                align-items: center;

                svg {
                  margin-left: 14px;
                }

                label {
                  padding-left: 8px;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 16px;
                  opacity: 0.9;
                  color: #79808a;

                  &.active {
                    color: #071526;
                  }
                }

                input {
                  height: 16px;
                  width: 16px;

                  &.form-check-input {
                    border-color: #C4CCD6;
                    border-radius: 1px;

                    &:checked {
                      background-color: #fff;
                      border-color: #D1433A;
                    }

                    &:checked[type=checkbox] {
                      background-image: url("../../../../../src/assets/icons/CheckBoxTick.svg");
                    }
                  }
                }
              }
            }

            .personal-info-box {

              .personal-info-div {

              }

              .ml-auto {
                margin-right: 1.8rem;
              }

              .details {
                display: flex;
                align-items: center;

                &:nth-child(1) {
                  margin-bottom: 18px;
                }

                svg {
                  margin-right: 15px;
                }

                span {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 16px;
                  color: #8B949F;
                }
              }
            }

            .privacy-and-support-box {

              .details {
                display: flex;
                align-items: center;
                height: 40px;
                margin-left: -20px;
                margin-right: -20px;
                padding-left: 32px;

                &:hover {
                  background: #D1433A1A;

                  svg {
                    path {
                      stroke: #D1433A;
                    }
                  }
                }

                svg {
                  margin-right: 22px;
                }

                span {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 16px;
                  color: #071526;
                }
              }
            }

            .shared-media-box {

              .photos-and-video {
                height: 130px;
                border-bottom: 1px solid #E6EAF0;

                .photo-and-video-heading {
                  display: flex;
                  align-items: center;
                  height: 50px;

                  span {
                    &:nth-child(1) {
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      color: #071526;
                    }
                  }

                  .total {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: right;
                    color: #8B949F;
                  }
                }

                .photo-and-video-data {
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;

                  img {
                    height: 60px;
                    width: 60px;
                    border-radius: 4px;
                    margin-right: 20px;
                    cursor: pointer;

                    &:last-child {
                      margin-right: unset;
                      filter: blur(1px);
                      -webkit-filter: blur(1px);
                    }
                  }
                }
              }

              .others {
                display: flex;
                align-items: center;
                height: 50px;
                border-bottom: 1px solid #E6EAF0;

                span {
                  &:nth-child(1) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: #071526;
                  }
                }

                .total {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: right;
                  color: #8B949F;
                }
              }
            }
          }
        }
      }
    }
  }
}

.for-group {
  .content-block {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-family: "HelveticaNeueMedium";
      font-size: 16px;
      line-height: 20px;
      color: #071526;
      margin-bottom: 4px;
    }

    .room-type {
      display: flex;
      flex-direction: row;
      margin-top: 28px;
      align-items: center;

      .svg-icon {
        margin-bottom: 0;
      }

      span {
        font-family: "HelveticaNeueMedium";
        font-size: 13px;
        line-height: 16px;
        color: #D1433A;
        margin-left: 9px;
      }
    }
  }
}

.group-img-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  .single-img-wrap {
    background-color: grey;
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      object-fit: cover;
    }

    &.count {
      background-color: #D1433A;
      font-family: "HelveticaNeueBold";
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      padding: 8px 0 0 8px;
    }
  }
}

.widget {
  padding: 15px 0;
  border-bottom: 1px solid #e6eaf0;

  .widget-title {
    font-family: "HelveticaNeueBold";
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      color: #8b949f;
    }
  }

  .text {
    font-family: "HelveticaNeueNormal";
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    letter-spacing: 0.5px;
  }

  .item {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;

    .img-wrap {
      width: 40px;
      height: 40px;
      position: relative;
      margin-right: 9px;

      .img-cover {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background-color: #ccc;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .status {
        background: #1cec9e;
        border: 2px solid #ffffff;
        border-radius: 4px;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 100%;
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .item-title {
        font-family: "HelveticaNeueBold";
        font-size: 13px;
        line-height: 16px;
        color: #D1433A;
        opacity: 0.9;
        margin-bottom: 5px;
      }

      .item-subtitle {
        font-family: "HelveticaNeueNormal";
        font-size: 11px;
        line-height: 13px;
        color: #718195;
        letter-spacing: 0.5px;
      }
    }
  }

  .show-all {
    margin-top: 5px;
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: #D1433A;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transition: all 0.3s;
      opacity: 0.8;
    }
  }
}

.custom-collapse {
  border-bottom: 1px solid #e6eaf0;

  &.border-none {
    border-bottom: none !important;
  }

  .btn {
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-color: #e6eaf0;
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    text-align: left;
    padding: 15px 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L6.321 5.76L1.23 1' stroke='%2347505B' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 12px;
      height: 7px;
      z-index: 1;
      top: 50%;
      transform: translatey(-50%);
      right: 0;
    }

    &.not-collapsed {
      &:after {
        transform: rotate(180deg);
        margin-top: -4px;
      }
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: transparent !important;
      color: #071526 !important;
    }
  }

  .card {
    border: none;

    .card-body {
      padding: 0;

      .privacy-support {
        margin: 0;
        padding: 0;
        list-style: none;
        margin: 0 -20px;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          transition: all 0.3s;
          background-color: #fff;
          cursor: pointer;
          padding: 0 20px;
          font-family: "HelveticaNeueNormal";
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #071526;

          &:hover {
            transition: all 0.3s;
            background-color: #D1433A1A;

            &:hover {
              .svg-icon {
                .c-line-1 {
                  stroke: #D1433A;
                  transition: all 0.3s;
                }
              }
            }
          }

          .svg-icon {
            margin-right: 9px;

            .c-line-1 {
              stroke: #071526;
              transition: all 0.3s;
            }
          }
        }
      }

      .offer-info {
        margin: -3px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          font-family: "HelveticaNeueBold";
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #47505b;
          margin-bottom: 14px;

          span {
            font-family: "HelveticaNeueNormal";
            margin-left: 4px;
          }
        }
      }
    }

    .media-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: "HelveticaNeueNormal";
      font-weight: normal;
      letter-spacing: 0.5px;
      font-size: 13px;
      line-height: 20px;
      padding: 15px 0;
      border-top: 1px solid #e6eaf0;

      span {
        font-family: "HelveticaNeueBold";
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8b949f;
      }
    }

    .media-items-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px 20px 0;

      .img-wrap {
        position: relative;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        overflow: hidden;
        background-color: #ccc;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        &.with-more {
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            font-family: "HelveticaNeueBold";
            font-weight: normal;
            font-size: 18px;
            line-height: 20px;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

.offer-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #e6eaf0;

  .btn-offer {
    width: 130px;
    height: 30px;
    background-color: #f5f7f9;
    border-radius: 2px;
    font-family: "HelveticaNeueMedium";
    font-weight: normal;
    font-size: 12px;
    color: #D1433A;
    transition: all 0.3s;

    &:hover {
      background-color: #ecf0ff;
      transition: all 0.3s;
    }
  }

  .btn-dropdown {
    width: 30px;
    height: 30px;
    background-color: #f5f7f9;
    border-radius: 2px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #ecf0ff;
      transition: all 0.3s;
    }
  }
}

.body {
  &.empty-body-with-search-icon {
    .empty-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 770px;
      height: 100%;
      background: #f5f7f9;
      border-radius: 5px;
      height: calc(100vh - 232px);

      .search-text {
        font-family: "HelveticaNeueBold";
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #071526;
        margin-top: 24px;
      }
    }
  }

  &.empty-body {
    .empty-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // min-height: 770px;
      height: 100%;
      height: calc(100vh - 232px);

    }

    .search-text {
      font-family: "HelveticaNeueNormal";
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #8b949f;
      letter-spacing: 0.5px;
    }
  }
}
</style>
