<template>
  <div class="landing">
    <LandingHeader />

    <div class="register-container">
      <div class="img-container">
        <img :src="require(`@/assets/img/svg/register-img.png`)" />
      </div>
      <div class="register-form">
        <p class="form-title">Register</p>
        <p class="form-subtitle">Join the social music network</p>
        
        <div>
          <Error 
            v-if="errors.length" 
            :text="errors[0].message" 
          />
          <!-- Name Fields -->
          <div class="flex">
            <div class="input-container">
              <input 
                type="text" 
                placeholder="First name" 
                name="signup_form_first_name"  
                v-model="first_name"
                @change="onChange"
              />
            </div>
            <div class="input-container">
              <input 
                type="text" 
                placeholder="Last name" 
                name="signup_form_last_name"  
                v-model="last_name"
                @change="onChange"
              />
            </div>
          </div>
        
          <!-- Username and Password Fields -->
          <div class="flex">
            <div class="input-container">
              <input 
              type="text" 
              placeholder="Username" 
              name="signup_form_username" 
              @change="onChange"
              @keypress="handleUserNameChange"
              autocomplete="off"
              v-model="username"
            />
            </div>
            <div class="input-container">
              <div class="input-wrapper">
                <input  
                  :type="type"
                  placeholder="Password"
                  v-model="password"
                  @change="onChange" 
                  autocomplete="off"
                  name="signup_form_password" 
                />
                <div class="icon-wrapper" @click="hideAndShowPassword">
                  <img v-if="showPassword" class="hide-password" :src="require(`@/assets/icons-files/showp.png`)" />
                  <img v-else :src="require(`@/assets/icons-files/hidep.png`)"  />
                </div>
              </div>
            </div>
          </div>
        
          <!-- Email Field -->
          <div class="email-field">
            <div class="input-container">
              <input 
                type="email" 
                v-model="email"
                @change="onChange"  
                placeholder="Email address"  
                name="signup_form_email"
              />
            </div>
          </div>

          <div class="birthday">
            <span class="birthday-select">
              <label>Birthday</label>
              <div class="birthday-dropdown">
                <SelectDropdown
                  :options="days"
                  v-model="day"
                  @change="((value) => day = value)"
                  :style="'width: 96px; border: 1px solid #D1433A; border-radius: 10px;'"
                  hide-cross-icon
                  backgroundColor="#FFF8F8"
                  border-radius="10px"
                  placeholder="29"
                />
                <div class="svg-icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="birthday-dropdown">
                <SelectDropdown
                  :options="months"
                  v-model="month"
                  @change="((value) => month = value)"
                  :style="'width: 96px; border: 1px solid #D1433A; border-radius: 10px;'"
                  hide-cross-icon
                  backgroundColor="#FFF8F8"
                  border-radius="10px"
                  placeholder="Apr"
                />
                <div class="svg-icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="birthday-dropdown">
                <SelectDropdown
                  :options="years"
                  v-model="year"
                  @change="((value) => year = value)"
                  :style="'width: 96px; border: 1px solid #D1433A; border-radius: 10px;'"
                  hide-cross-icon
                  backgroundColor="#FFF8F8"
                  border-radius="10px"
                  placeholder="1994"
                />
                <div class="svg-icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </span>
            <div class="gender-select">
              <div class="radio-group">
                <input 
                  type="radio" 
                  class="radio" 
                  name="gender" 
                  value="M" 
                  v-model="gender" 
                  @input="event => gender = event.target.value" 
                />
                <label>Male</label>
                <input 
                  type="radio" 
                  class="radio" 
                  name="gender" 
                  value="F" 
                  v-model="gender" 
                  @input="event => gender = event.target.value"
                />
                <label>Female</label>
                <input 
                  type="radio" 
                  class="radio" 
                  name="gender" 
                  value="O" 
                  v-model="gender" 
                  @input="event => gender = event.target.value"
                />
                <label>Other</label>
              </div>
            </div>
             
          </div>

          <!-- Location Field -->
          <div class="location">
            <span>Location</span>
            <div class="input-container">
              <input 
                type="text" 
                placeholder="e.g. London, UK"
                v-model="location"
                @change="onChange"  
                name="signup_form_location"
                autocomplete="on"
              />
            </div>
          </div>

          <!-- Social Login Icons -->
          <div class="social-login">
            <p class="login-with">Or login with</p>
            <span class="social-icons">
              <img class="icon" :src="require(`@/assets/img/svg/t-icon.svg`)" />
              <img class="icon" :src="require(`@/assets/img/svg/insta-icon.svg`)" />
              <img class="icon" :src="require(`@/assets/img/svg/twitter-icon.svg`)" />
              <img class="icon" :src="require(`@/assets/img/svg/facebook-icon.svg`)" />
              <img class="icon" :src="require(`@/assets/img/svg/linkedin-icon.svg`)" />
            </span>
          </div>
         
          <!-- reCAPTCHA Placeholder -->
          <div class="recaptcha">
          </div>
        </div>
        <div class="terms">
          <input
            type="checkbox"
            id="checkbox"
            class="checkbox"
            @change="(e)=> {
              i_agree=e.target.checked;
            }"
            v-model="i_agree" 
          />
          <span>
            I have read and agree to the <a href="#">Terms of Use</a>, <a href="#">Data Policy</a>, and <a href="#">Cookie Policy</a>. You may receive SMS notifications from us and can opt out at any time.
          </span>
        </div>

        <!-- Submit Button -->
        <button type="submit" class="signup-btn" v-on:click="registerUser">{{ loading ? "Please Wait..." : "SIGN UP" }}</button>

        <div class="footer-text">PLAYY. © 2024</div>
        <AuthHandler ref="auth-handler"/>
      </div>
    </div>

    <VerifyEmail
      v-if="currentModal == 'verify-email'"
      @closeModal="closeModal"
      @newModal="newModal"
    />

    <RegisterCompleteModal
      v-if="currentModal == 'verification-status'"
      @closeModal="closeModal"
      @newModal="newModal"
    />

  </div>
</template>
<script type="application/javascript">
  import LandingHeader from "@/components/Landing/LandingHeader.vue";
  import * as Utils from '@/components/utils/Utils.js';
  import VerifyEmail from "@/components/Landing/modals/VerifyEmail.vue";
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import * as Helper from '@/apis/Helper.js';
  import RegisterCompleteModal from "@/components/Landing/modals/RegisterCompleteModal.vue";
  import {
    SIGNUP_ERRORS,
    SIGNUP_FORM_GENDER,
    SIGNUP_FORM_DOB_DAY,
    SIGNUP_FORM_DOB_YEAR,
    SIGNUP_FORM_DOB_MONTH, 
    SIGNUP_FORM_CONFIRM_PASSWORD,
    SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY,
  } from "../store/types.js";
  import AuthHandler from "../components/Landing/modals/AuthHandler.vue";
  import Error from "@/components/Error.vue";
  import SelectDropdown from "../components/Common/Dropdown/SelectDropdown.vue";

  export default {
    components: {
      AuthHandler,
      LandingHeader,
      VerifyEmail,
      RegisterCompleteModal,
      Error,
      SelectDropdown
    },
    data() {
    return ({
      first_name: "",
      last_name: "",
      gender: null,
      i_agree: null,
      location: "",
      username: "",
      password: "",
      email: "",

      days: Utils.getMonthDays(new Date().getFullYear(), new Date().getMonth()),
      dayName: "",
      day: "",

      months: Utils.getMonths(),
      monthName: "",
      month: "",

      years: Utils.getYears(),
      yearName: "",
      year: "",

      currentModal: "",
      modalFor: "",

      message: "",

      showPassword: false,
      type: 'password'
    })
  },
  watch: {
    month(newMonth) {
      this.updateDays(this.year, newMonth);
    },
    year(newYear) {
      this.updateDays(newYear, this.month);
    },
  },
  computed: {
    ...mapGetters({
      form_data: "signup_user_info_form_map_to_props",
      affiliation_token: "getAffiliationTokenValue"
    }),
    loading() {
      const {loading = false} = this.form_data || {};

      return loading;
    },
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    },
  },
  mounted() {
    const { restorePassword, emailConfirmation } = this.$route.query;
    if (restorePassword) {
      this.openModal('reset-password-security-code', 'register');
    } else if(emailConfirmation){
      this.openModal('verify-email', 'register');
    }
    const {gender, day, year, month, last_name, first_name, username, location, password, i_agree} = this.form_data;

    if (day) {
      const index = this.days.findIndex(ele => ele.value === day);

      if (index !== -1) {
        this.day = day;
        this.dayName = this.days[index].name;
      }
    }

    if (month) {
      const index = this.months.findIndex(ele => ele.index === month);

      if (index !== -1) {
        this.month = month;
        this.monthName = this.months[index].name;
      }
    }

    if (year) {
      const index = this.years.findIndex(ele => ele.value === year);

      if (index !== -1) {
        this.year = year;
        this.yearName = this.years[index].name;
      }
    }

    if (first_name) {
      this.first_name = first_name;
    }

    if (last_name) {
      this.last_name = last_name;
    }

    if (username) {
      this.username = username;
    }

    if (password) {
      this.password = password;
    }

    if (location) {
      this.location = location;
    }

    if (i_agree) {
      this.i_agree = i_agree;
    }
  },
  methods: {
    ...mapActions([
      "register_user",
      "check_user_exists",
    ]),
    ...mapMutations([
      "updateSignupFormData",
      "updateSignupUIConstraints"
    ]),
    updateDays(year, month) {
      this.days = Utils.getMonthDays(year, month);
    },
    hideAndShowPassword() {
      if(this.showPassword) {
        this.type = 'password'
        this.showPassword =  false
      } else {
        this.type = 'text'
        this.showPassword =  true
      }
    },
    openModal: function (modalType, modalFor) {
      this.$refs["auth-handler"].openModal(modalType, modalFor)
    },
    handleUserNameChange(e) {
      let {key} = e;

      
      const value = key === "." ? key : key.replace(/\W/gi, "");
      
      if (value) return true;

      return e.preventDefault();
    },
    onChange(e) {
      const {name, value} = e.target || {};
      
      this.updateSignupFormData({
        [name]: value
      });
    },
    clearFormData() {
      this.year = ''
      this.day = '';
      this.month = '';
      this.getYear = '';
      this.first_name = '';
      this.last_name = '';
      this.gender = '';
      this.username = '';
      this.password = '';
      this.location = '';
      this.i_agree = null;
      this.updateSignupFormData({});
    },
    registerUser() {
      const {loading, first_name, last_name, email, gender, day, year, month, username, password, i_agree, location} = this;
          
      if (loading) return;

      const body = {
        first_name,
        last_name,
        username,
        password,
        email,
        day,
        month: month.toString(),
        year,
        gender,
        location,
        i_agree,
      };

      Helper.validate(Object.keys(body), body)
          .then(async ({status, response}) => {
            if (status) {
              const dob = new Date(year, month, day);

              if (dob > new Date()) {
                return this.updateSignupUIConstraints({
                  [SIGNUP_ERRORS]: [{
                    fieldName: "day",
                    message: "Invalid date of birth"
                  }]
                });
              }

              this.updateSignupUIConstraints({
                [SIGNUP_ERRORS]: []
              });

              this.updateRegisterFormData();
              try {
                await this.register_user();
                this.clearFormData();
                this.newModal("verify-email");
                this.updateSignupFormData({
                  [SIGNUP_FORM_USERNAME]: '',
                  [SIGNUP_FORM_PASSWORD]: '',
                  [SIGNUP_FORM_CONFIRM_PASSWORD]: ''
                });
              } catch (e) {
                this.message = e && e.message ? e.message : "Please try again.";

                e.errors.forEach((error) => {
                    this.updateSignupUIConstraints({
                      [SIGNUP_ERRORS]: [
                        ...this.errors,
                        {
                          fieldName: error.field,
                          message: error.message,
                        }
                      ]
                    });
                });
              }
            } else this.updateSignupUIConstraints({
              [SIGNUP_ERRORS]: response && response.length ? response : []
            });
          })
          .catch(err => console.log(err));
    },
    updateRegisterFormData() {
      this.updateSignupFormData({
        [SIGNUP_FORM_DOB_YEAR]: this.year,
        [SIGNUP_FORM_DOB_MONTH]: String(this.month),
        [SIGNUP_FORM_DOB_DAY]: this.day,
        [SIGNUP_FORM_GENDER]: this.gender,
        [SIGNUP_FORM_CONFIRM_PASSWORD]: this.password,
        [SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY]: this.i_agree,
      });
    },
    closeModal (modalType) {
      this.currentModal = "";
    },
    newModal (modalType) {
      this.currentModal = modalType;
    }
    
  }
}
</script>


<style scoped lang="scss">
.birthday-dropdown {
  width: 96px;
  position: relative;
  font-family: HelveticaNeueNormal, sans-serif !important;
  font-weight: normal !important;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.birthday-dropdown .svg-icon {
  position: absolute;
  right: 8px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper input {
  width: 100%;
  box-sizing: border-box;
}

.icon-wrapper {
  position: absolute;
  top: 50%; 
  right: 10px; 
  transform: translateY(-50%);
  cursor: pointer;
}

.icon-wrapper img{
  opacity: 0.5;
}

.error-div{
  margin-left: 27%;
  align-self: center;
  width: 100%;
  margin-bottom: 5px;
}

.email-field .input-container, .location > .input-container{
  width: 100%;
}

.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  margin-right: 13px;
}

.checkbox:checked {
  background-color: #D1433A;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:not(:checked) {
  background-color: #FFF8F8;
  background-image: none;
  
}

.landing header {
  border-bottom: 1px solid #D1433A;
  background-color: #FFF8F8;
}

.landing{
  height: 100%;
  background-color: #FFF8F8;
}

.register-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #D1433A;
  height: 100%;
  overflow-y: auto;
  background-color: #FFF8F8;

  @media (min-height: 1144px) {
    height: 100vh;
  }
}

.birthday-select label, .location span {
  margin-right: 27.5px;
}

.birthday {
  display: flex;
  flex-direction: column;
  gap: 16.5px;
  margin-bottom: 19px;
  margin-top: 21.5px;
  align-items: flex-start;
}

.birthday-select > label, .location > span {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: center;
  width: 92px;
}

.location {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.register-form .radio {  
  width: 16px;
  height: 16px;
  margin-right: 13px;
  margin-left: 20px;
  border-radius: 50%;
  border: 2px solid #C4CCD6;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #FFF8F8;

  &:checked {
    background-color: #FFF8F8;
    border-color: #D1433A;
    opacity: 1;
  }
}

.radio:checked::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #D1433A;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gender-select {
  align-self: center;
  color: #47505B;
  display: flex;
  flex-direction: column;
}

.gender-select > .error {
  color: #D1433A;
  margin-left: 23px;

  @media (min-width: 2048px) {
    margin-left: 0px;
  }
}

.radio-group {
  display: flex;
  left: 12px ;
  position: relative;
}

.gender-select label {
  font-size: 13px;
  color: #47505B;
}

.terms {
  width: 100%;
  display: inline-block;
  color:  #47505B;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 34px;
  margin-top: 20px;
}

.terms a {
  color: #D1433A;
}

.radio  {
  -webkit-appearance: none; 
  appearance: none;
}

.terms span {
  display: inline-block;
  vertical-align: middle; 
  width: 417px; 
}

.icon {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #D1433A12;
  display: inline-block;
  border-radius: 7%;
}

.social-icons .icon img {
  width: 10px;
  height: 15px;
}

.img-container {
  width: 60%;
  height: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.register-form {
  width: 451px;
  margin-left: 139px;
  margin-right: 140px;
  margin-top: 101px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-title {
  color: #D1433A;
  font-family: 'Lato', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-subtitle {
  color: #D1433A;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.7;
}

.flex {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 215px;
  position: relative;
}

input {
  width: 215px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background: #FFF;
  padding-left: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  caret-color: #D1433A;
  background-color: #FFF8F8;
}

input::placeholder{
  font-weight: 400;
  opacity: 0.9;
  color: #A4AFBC;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
}

select option[value=""] {
  display: none;
}

.email-field input {
  width: 450px;
}

.location input {
  width: 331px;
}

.email-field,
.location,
.social-login {
  width: 100%;
  display: flex;
  align-items: center;
}

.social-login {
  gap: 70px;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 5px;
  width: 232px;
}

.signup-btn:hover {
  transition: all 0.3s;
  opacity: 0.8;
}

.signup-btn {
  width: 176px;
  height: 30px;
  flex-shrink: 0;
  background-color: #D1433A;
  color: #FFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
}

.login-with {
  color: #D1433A;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px !important;
}

.birthday-select {
  display: flex;
  gap: 10px;
}


.birthday-select select {
  width: 96px;
  height: 40px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
}

.footer-text {
  position: absolute; 
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: #D1433A;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.submit-btn {
  padding: 7px 15px;
  background-color: #D1433A;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 176px;
  height: 40px;
  text-align: center;
}

select {
  appearance: none; 
  -webkit-appearance: none; 
  -moz-appearance: none;
  padding-right: 17.4px; 
  background-color: white;
  position: relative;
  background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2212%22 height%3D%227%22 viewBox%3D%220 0 12 7%22 fill%3D%22none%22%3E%3Cpath d%3D%22M11 1L6.321 5.76L1.23 1%22 stroke%3D%22%23071526%22 stroke-width%3D%221.3%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center; 
  background-color: #FFF8F8;
}

select::-ms-expand {
  display: none;
}
</style>