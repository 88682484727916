<template>
  <div class="wrapAudience">
    <div class="titleDetalsRightPart">
      <h3>Audience</h3>
      <div class="showAll" v-if="totalInterestedMembers > 3">
        Show All ({{ totalInterestedMembers }})
        <span>
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.01562 1L6.1515 5.173L1.01562 9.713"
              stroke="#47505B"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
    <div v-for="member in interestedMembers" :key="member.id" class="user">
      <div class="userContent">
        <div class="imgDiv">
          <img :src="member.artwork_url" :alt="member.full_name" />
        </div>
        <div class="userInfo">
          {{ member.full_name || member.name }}
          <p>Is Interested {{ formatTimeAgo(getPivotCreatedAt(member)) }}</p>
        </div>
      </div>
      
      <template v-if="member.id !== currentUserId">
        <svg v-if="member.friend_check_status && member.friend_check_status.status === 'confirmed'"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="3.25" stroke="#416CFF" stroke-width="1.5"/>
          <path d="M19.0345 21.1555V19.7762C19.0345 19.0446 18.7439 18.3429 18.2265 17.8256C17.7092 17.3082 17.0075 17.0176 16.2759 17.0176H10.7586C10.027 17.0176 9.32533 17.3082 8.80798 17.8256C8.29064 18.3429 8 19.0446 8 19.7762V21.1555" stroke="#416CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.5164 14.2585C15.04 14.2585 16.2751 13.0234 16.2751 11.4998C16.2751 9.97629 15.04 8.74121 13.5164 8.74121C11.9929 8.74121 10.7578 9.97629 10.7578 11.4998C10.7578 13.0234 11.9929 14.2585 13.5164 14.2585Z" stroke="#416CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <svg v-else-if="member.friend_check_status && member.friend_check_status.status === 'pending'"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="3.25" stroke="#8F9CAE" stroke-width="1.5"/>
          <path d="M19.0345 21.1555V19.7762C19.0345 19.0446 18.7439 18.3429 18.2265 17.8256C17.7092 17.3082 17.0075 17.0176 16.2759 17.0176H10.7586C10.027 17.0176 9.32533 17.3082 8.80798 17.8256C8.29064 18.3429 8 19.0446 8 19.7762V21.1555" stroke="#8F9CAE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.5164 14.2585C15.04 14.2585 16.2751 13.0234 16.2751 11.4998C16.2751 9.97629 15.04 8.74121 13.5164 8.74121C11.9929 8.74121 10.7578 9.97629 10.7578 11.4998C10.7578 13.0234 11.9929 14.2585 13.5164 14.2585Z" stroke="#8F9CAE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <svg v-else
          @click="sendFriendRequest(member)"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="cursor: pointer;"
        >
          <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="3.25" stroke="#416CFF" stroke-width="1.5"/>
          <path d="M19.0345 21.1555V19.7762C19.0345 19.0446 18.7439 18.3429 18.2265 17.8256C17.7092 17.3082 17.0075 17.0176 16.2759 17.0176H10.7586C10.027 17.0176 9.32533 17.3082 8.80798 17.8256C8.29064 18.3429 8 19.0446 8 19.7762V21.1555" stroke="#416CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.5164 14.2585C15.04 14.2585 16.2751 13.0234 16.2751 11.4998C16.2751 9.97629 15.04 8.74121 13.5164 8.74121C11.9929 8.74121 10.7578 9.97629 10.7578 11.4998C10.7578 13.0234 11.9929 14.2585 13.5164 14.2585Z" stroke="#416CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21.3906 11.4736V15.9125" stroke="#416CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.25 13.6934H23.6889" stroke="#416CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  computed: {
    ...mapGetters({
      single_Event_Members: 'GET_SINGLE_EVENT_Members',
      user: 'user_map_to_props'
    }),
    currentUserId() {
      if (this.user && this.user.user_data) {
        return this.user.user_data.id;
      }
      return null;
    },
    interestedMembers() {
      if (this.single_Event_Members && 
          this.single_Event_Members.interested && 
          this.single_Event_Members.interested.data) {
        return this.single_Event_Members.interested.data.slice(0, 3);
      }
      return [];
    },
    totalInterestedMembers() {
      if (this.single_Event_Members && 
          this.single_Event_Members.interested && 
          this.single_Event_Members.interested.data) {
        return this.single_Event_Members.interested.data.length;
      }
      return 0;
    }
  },
  methods: {
    ...mapActions({
      getSingleEventMembers: 'getSingleEventMembers',
      addFriendApi: 'addFriendApi'
    }),
    sendFriendRequest(payload) {
      this.$store.dispatch("addFriendApi", payload);
      this.sentRequest = true;
    },
    formatTimeAgo(date) {
      if (!date) return '';
      return moment(date).fromNow();
    },
    getPivotCreatedAt(member) {
      return member && member.pivot && member.pivot.created_at 
        ? member.pivot.created_at 
        : member.created_at;
    },
    async fetchEventMembers() {
      this.isLoading = true;
      this.error = null;
      try {
        const eventId = this.$route.params.id;
        console.log('Fetching members for event:', eventId);
        await this.getSingleEventMembers(eventId);
        console.log('Members after fetch:', this.single_Event_Members);
      } catch (error) {
        console.error('Failed to fetch event members:', error);
        this.error = 'Failed to load members';
      } finally {
        this.isLoading = false;
      }
    }
  },
  created() {
    this.fetchEventMembers();
  }
};
</script>

<style lang="scss" scoped>
.wrapAudience {  
  .user {
    display: flex;
    justify-content: space-between;
    .userContent {
      display: flex;
      .imgDiv {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #c3c3c3;
        margin-right: 10px;
        
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .userInfo {
        font-size: 13px;
        color: #071526;
        font-family: HelveticaNeueBold;
        p {
          font-family: HelveticaNeueNormal;
          color: #47505b;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
