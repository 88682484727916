<template>
  <div class="row" style="margin-left: 0.1rem !important;">
    <!-- {{listData}} -->
    <div v-for="(albums, index) in filteredAlbums" :key="index" class="col-md-artist-photo">
      <div class="photo-block" v-if="!singleAlbum">
        <img class="cursor-pointer" :src="albums.artwork.last_url" @click="singleAlbumOpen(albums.slug, albums.title)"
          @error="onPostImageError">
        <div class="photo-block-name">{{ albums.title ? albums.title : 'Title' }}</div>
      </div>
    </div>

    <!--  -->

    <!-- <div v-for="(album, index) in filteredAlbums" :key="index" class="col-md-artist-photo">
  <div class="photo-block">
    <img class="cursor-pointer" :src="album.artwork.last_url" @click="singleAlbumOpen(album.slug, album.title)" @error="onPostImageError">
    <div class="photo-block-name">{{ album.title ? album.title : 'Title' }}</div>
  </div>
</div> -->

    <div>
      <SingleAlbum v-if="singleAlbum" :singleAlbumData="singleAlbumData" :title="title" :slug="slug"
        @closeSingleAlbum="closeSingleAlbum" @showMore="showMore" />
    </div>
  </div>
</template>

<script type="application/javascript">
import SingleAlbum from '../albums/SingleAlbum.vue'
import Vue from "vue";
import VueImg from "v-img";
const vueImgConfig = {
  sourceButton: false,
}
Vue.use(VueImg, vueImgConfig);
export default {
  name: "mediaPhotosAlbumsListing",
  data() {
    return {
      singleAlbum: false,
      singleAlbumData: [],
      title: '',
      loading: false,
      slug: ''
    }
  },

  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    filteredAlbums() {
      return this.listData.filter(album => !this.singleAlbum && album.artwork.total > 0);
    }
  },
  components: {
    SingleAlbum
  },
  watch: {
    listData() {
      this.getMediaPhotosAlbumsList();
    }
  },
  methods: {
    getMediaPhotosAlbumsList() {
      this.$emit('getMediaPhotosAlbums')
    },
    async singleAlbumOpen(slug, title) {
      try {
        this.$emit('setShowMore');
        this.$emit('toggleAllPhotos')
        this.singleAlbum = true;
        this.title = title;
        this.slug = slug;
        this.$store.commit('SET_MEDIA_ALBUM', this.slug)
        this.$store.commit("SET_MEDIA_OFFSET", 0);
        this.$store.commit("GET_MEDIA", []);
        const data = await this.$store.dispatch('getSelectedPhoto', false)
        this.singleAlbumData = data.data.information.data;
      }
      catch (error) {
        this.loading = false
        this.singleAlbum = false
      }
    },
    closeSingleAlbum() {
      this.$emit('toggleAllPhotos')
      this.singleAlbum = false;
    },
    async showMore() {
      if (this.singleAlbumData.length <= 8) {
        let offset = this.$store.getters.getMediaOffset;
        let limit = this.$store.getters.getMediaLimit;
        offset = offset + limit;
        this.$store.commit("SET_MEDIA_OFFSET", offset);
        const data = await this.$store.dispatch('getSelectedPhoto', true)
        let arr = this.singleAlbumData.concat(data.data.information.data)
        this.singleAlbumData = arr;
      }
    }
  }
}
</script>

<style scoped></style>
