<template>
  <div class="app-container main-container_full">
    <div class="d-flex" style="justify-content: center;overflow-y: hidden;" >
      <LeftBar/>
      <MusicDiscussion v-if="getActiveFullChatType === 'clubroom' && !checkChatViewAllListing" />
      <PartyRoom v-else-if="getChatLeftBarListingActiveTab === 'partyroom' && !getViewAllChatListing && !checkChatViewAllListing" />
      <ChatBox v-else/>
      <RightBar/>
      <MainLaunchRoom v-if="get_active_launch_Room"/>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import LeftBar from "./LeftBar";
import ChatBox from "./ChatBox";
import RightBar from "./RightBar";
import PartyRoom from './PartyRoom.vue'
import MusicDiscussion from "./MusicDiscussion";
import MainLaunchRoom from "./Components/launchRoom/MainLaunchRoom.vue";
import {mapGetters, mapMutations,} from "vuex"
export default {
  name: "ChatSubContainer",
  data() {
    return {
      currentUrl: "",
    };
  },
  components: {
    LeftBar,
    ChatBox,
    RightBar,
    MainLaunchRoom,
    PartyRoom,
    MusicDiscussion
  },
  props: {
    ShowMusicFooter: Boolean,
  },
  beforeMount() {
    this.showCon();
  },
  methods: {
    ...mapMutations(['SET_ACTIVE_LAUNCH_ROOM']),
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
  },
  computed:{
    ...mapGetters(['get_active_launch_Room']),
    getActiveFullChatType() {
      return this.$store.getters.getActiveFullChatType;
    },
    getChatLeftBarListingActiveTab() {
      return this.$store.getters.getLeftBarListingActiveTab
    },
    getViewAllChatListing() {
      return this.$store.getters.getViewAllChatListing
    },
    checkChatViewAllListing() {
      return this.$store.getters.getViewAllChatListing;
    },
  }
};
</script>
