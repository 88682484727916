<template>
  <div class="row fixAlbumMoreMain" >
    <div v-if="!singleAlbum">
      <div class="col-md-6 cursor-pointer"  v-for="(albums,index) in listData" :key="index" @click="singleAlbumOpen(albums.slug, albums.title)">
        <div class="Albumb-photo-party-detail" >
          <div class="party-image back-1">
            <img :src="albums.artwork.last_url"/>
            <div class="view-camera">
              <span>{{ albums.artwork.total ? albums.artwork.total : '0'}}</span>
              <div class="camera-icons">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
                  <path
                    d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="party-image back-22">
            <img :src="albums.artwork.last_url ? albums.artwork.last_url: require(`@/assets/img/albumb-1.png`)"/>
          </div>
          <div class="party-image back-33">
            <img :src="albums.artwork.last_url ? albums.artwork.last_url: require(`@/assets/img/albumb-1.png`)"/>
          </div>
          <div class="party-name">{{ albums.title ? albums.title : 'Title' }}</div>
        </div>
      </div>
    </div>
    <div>
      <SingleAlbum v-if="singleAlbum" :singleAlbumData="singleAlbumData" :title="title" :slug="slug" @closeSingleAlbum="closeSingleAlbum" @showMore="showMore" />
    </div>
  </div>
</template>

<script type="application/javascript">
import SingleAlbum from "../../../../../Home/Profile/media/albums/SingleAlbum";
import { getPageSelectedPhotos } from "../../../../../../apis/APIs";
export default {
  name: "mediaAlbumsListing",
  data(){
    return {
      singleAlbum: false,
      singleAlbumData: [],
      title: '',
      loading: false,
      slug: '',
      offset: 0,
      limit: 8,
      total: 0
    }
  },
  components:{
    SingleAlbum
  },
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    },
    totalMediaAlbums: {

    }
  },
  watch: {
    listData () {
      this.getMediaPhotosAlbumsList();
    }
  },
  methods: {
    getMediaPhotosAlbumsList () {
      this.$emit('getMediaPhotosAlbums')
    },
    // getMediaAlbumsList () {
    //   this.$emit('getMediaAlbumsList')
    // },
    async singleAlbumOpen(slug,title){
      try{
        this.$emit('setShowMore');
        this.$emit('toggleAllPhotos')
        this.singleAlbum = true;
        this.title = title;
        this.slug = slug;
        this.$store.dispatch('loading', true)
        const data = await getPageSelectedPhotos(slug, this.offset, this.limit, this.$route.params.id)
        this.total = data.data.information.total
        this.singleAlbumData =  data.data.information.data;
        this.$store.dispatch('loading', false)
      }
      catch (error){
        this.loading = false
        this.singleAlbum = false
      }
    },
    closeSingleAlbum(){
      this.$emit('toggleAllPhotos')
      this.singleAlbum = false;
    },
    async showMore() {
      if(this.total > 8){
        this.offset = this.offset + this.limit;
        const data = await getPageSelectedPhotos(this.slug, this.offset, this.limit, this.$route.params.id)
        this.singleAlbumData =  [...this.singleAlbumData, ...data.data.information.data];
      }
    }
  }
}
</script>

<style scoped>

</style>
