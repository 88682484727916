<template>
  <div class="Signup_pop_overlay">
    <div
      class="modal fade login-type show"
      id="login"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px"
      aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup fixArrowMain">
            <div class="work-experience-heading ">
              <h1>{{ is_edit ? "Edit" : "Add" }} Education</h1>
              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="svg-color"
                    d="M13.5 4.5L4.5 13.5"
                    stroke="#47505B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    class="svg-color"
                    d="M4.5 4.5L13.5 13.5"
                    stroke="#47505B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
              <div
                v-if="success_message"
                class="alert alert-success"
                role="alert"
              >
                {{ success_message }}
              </div>
              <div class="row">
                <div class="col">
                  <div class="w-poup-fields">
                    <label>School Name</label>
                    <input
                      type="text"
                      v-model="school_name"
                      placeholder="School Name"
                    />
                    <label
                      class="error_msg error_msg_red"
                      v-if="_handleErrorMessage('school_name')"
                      >{{ _handleErrorMessage("school_name") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div>
                    <label>From</label>
                    <div class="d-flex">
                      <div class="fromto-select education-select">
                        <SelectDropdown
                          :options="years"
                          v-model="from"
                          @change="((value) => from = value)"
                          :style="'width: 149px; border: 1px solid #E6EAF0;'"
                          hideCrossIcon
                        />
                        <label
                          class="error_msg error_msg_red"
                          v-if="
                            _handleErrorMessage('from') ||
                            _handleErrorMessage('to')
                          "
                          >{{
                            _handleErrorMessage("from") ||
                            _handleErrorMessage("to")
                          }}</label
                        >
                        <div class="svg-icon">
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L5 5L9 1"
                              stroke="#D1433A"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <label>To</label>
                    <div class="d-flex">
                      <div class="fromto-select education-select">
                        <SelectDropdown
                          :options="years"
                          v-model="to"
                          @change="((value) => to = value)"
                          :style="'width: 149px; border: 1px solid #E6EAF0;'"
                          hideCrossIcon
                        />
                        <div class="svg-icon">
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L5 5L9 1"
                              stroke="#D1433A"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <div>
                    <label>Degree</label>
                    <div class="d-flex">
                      <div class="fromto-select education-select">
                        <SelectDropdown
                          :options="degrees_list"
                          v-model="degree"
                          @change="((value) => degree = value)"
                          :style="'width: 328px; border: 1px solid #E6EAF0;'"
                          hideCrossIcon
                        />
                        <div class="svg-icon">
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L5 5L9 1"
                              stroke="#D1433A"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <label
                      class="error_msg error_msg_red"
                      v-if="_handleErrorMessage('degree')"
                    >{{ _handleErrorMessage("degree") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <div class="w-poup-fields">
                    <label class="description-label"
                      >Description (optional)</label
                    >
                    <textarea
                      class="textarea-field"
                      v-model="description"
                      placeholder="About your education experience"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row popup_button px-0">
                <div class="skip_btn work-cancel work-cancel col-4">
                  <button v-on:click="closeModal()">CANCEL</button>
                </div>

                <div class="next_step_btn ad-work-exp col-4">
                  <button @click="submit()">
                    {{
                      loading
                        ? "Please Wait..."
                        : `${is_edit ? "EDIT" : "ADD"} EDUCATION`
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import {
  addEducationOnArtistPage,
  addExperienceOnArtistPage,
  degree_tool_skill_api,
  edit_user_education_api,
  get_user_education_api,
  updateEducationOnArtistPage,
  user_education_api,
  user_work_api,
} from "../../../apis/APIs";
import {
  EDIT_PROFILE_ERRORS,
  SUCCESS,
  ERROR,
  USER_DATA,
} from "../../../store/types";
import SelectDropdown from "../../Common/Dropdown/SelectDropdown.vue";

export default {
  data() {
    return {
      education_id: "",

      school_name: "",
      from: "",
      to: "",

      months: Utils.getMonths(),
      years: Utils.getYears(),

      degree: "",

      degrees_list: [],
      list_loading: false,

      description: "",

      is_edit: false,

      message: "",
      success_message: "",
      loading: false,
    };
  },
  components: {
    SelectDropdown,
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    },
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    pageID: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    closeModal: function () {
      this.$parent.closeWindow();
    },
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
              status: true,
              message:
                this.errors[
                  this.errors.findIndex((ele) => ele.fieldName === key)
                ].message,
            }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    init() {
      this.fetchSkillsToolsData();
      this.initData();
    },
    initData() {
      try {
        if (this.pageID) {
          const { degree_name, degree, description, from, id, title, to } =
            this.item || {};
          if (!id) return;
          this.education_id = id;
          this.school_name = title;
          this.from = from;
          this.to = to;
          this.degree = degree_name ? degree_name.id : degree.id;
          this.description = description;
          this.is_edit = true;
        } else {
          const {
            degree_name,
            degree,
            description,
            from_year,
            id,
            school_name,
            to_year,
          } = this.item || {};
          if (!id) return;
          this.education_id = id;
          this.school_name = school_name;
          this.from = from_year;
          this.to = to_year;
          this.degree = degree_name ? degree_name.id : degree.id;
          this.description = description;
          this.is_edit = true;
        }
      } catch (error) {
        console.error("error in setting data", error);
      }
    },
    async fetchSkillsToolsData() {
      try {
        this.list_loading = true;
        const res = await degree_tool_skill_api();
        const { success, degrees } = res.data || {};
        this.list_loading = false;

        if (success === 1) {
          if (degrees && degrees.length){ 
            degrees.forEach(item => {
              item.value = item.id,
              item.label = item.name
            });
            this.degrees_list = degrees;
          }
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    onChange(e) {
      const { name, value } = e.target || {};

      this.updateEditProfileFormData({
        [name]: value,
      });
    },
    submit(e) {
      if (e) e.preventDefault();

      const { user_data } = this.user || {};
      // const { id, user_picture, geners, following } = user_data || {};

      const {
        loading,
        school_name,
        from,
        to,
        degree,
        description,
        education_id,
        is_edit,
      } = this;

      if (loading) return;

      const body = {
        school_name,
        from,
        to,
        degree
      };

      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: [],
            });
            try {
              this.message = "";

              if (parseInt(from) > parseInt(to)) {
                return (this.message = "Invalid selected years");
              }

              this.$store.dispatch("loading", true);
              var formdata = new FormData();
              formdata.append("title", school_name);
              formdata.append("from", from);
              formdata.append("to", to);
              formdata.append("degree", degree);
              formdata.append("description", description);

              if (education_id && is_edit) {
                let res;
                if (this.pageID) {
                  res = await updateEducationOnArtistPage(
                    this.education_id,
                    formdata
                  );
                } else {
                  res = await edit_user_education_api(formdata, education_id);
                }
                this.$store.dispatch("loading", false);
                const { success, message } = res.data || {};
                if (success === 1) {
                  this.success_message = message;
                  if (this.pageID) {
                    const { information } = res.data || {};
                    this.$store.commit("SET_PAGE_DATA", information);
                    this.$parent.closeWindow();
                  } else {
                    this.$parent.fetchEducationData();
                    this.$parent.closeWindow();
                  }
                  setTimeout(() => (this.success_message = ""), 5000);
                } else {
                  this.message = message;
                }
              } else {
                let res;
                if (this.pageID) {
                  res = await addEducationOnArtistPage(this.pageID, formdata);
                } else {
                  res = await user_education_api(formdata);
                }
                this.$store.dispatch("loading", false);
                const { success, message } = res.data || {};
                if (success === 1) {
                  this.success_message = message;
                  if (this.pageID) {
                    const { information } = res.data || {};
                    this.$store.commit("SET_PAGE_DATA", information);
                    // this.$parent.closeWindow();
                  } else {
                    this.$parent.fetchEducationData();
                    // this.$parent.closeWindow();
                  }
                  setTimeout(() => {
                    this.$parent.closeWindow();
                  }, 1000);
                  setTimeout(() => (this.success_message = ""), 5000);
                } else {
                  this.message = message;
                }
              }
            } catch (e) {
              this.$store.dispatch("loading", false);
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]:
                response && response.length ? response : [],
            });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
