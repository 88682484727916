<template>
  <div v-if="mediaPhotosList.length>=1" class="photo-layout singlePagePhotoMain media-tabs photo_tab">
    <div class="row albumsMain">
      <media-photos-albums-listing @getMediaPhotosAlbumsList="getMediaPhotosAlbums" :listData="mediaPhotosAlbumsList" @toggleAllPhotos="toggleAllPhotos" />
    </div>
    <div v-if="showAll" class="row allPhotosMain">
      <div class="col-md-artist-photo-full">
        <div class="all-add-photo" style="margin: 14px 14px 15px 14px;">
          <h1>All Photos</h1>
          <button style="margin-top: 2px;" v-on:click="openPhotoModel" v-if="current_page_user === 'admin'">Add Photos</button>
        </div>
      </div>
      <media-photos-listing @getMediaPhotosLists="getMediaPhotos" :listData="mediaPhotosList" :totalMediaPhotos="mediaPhotosCount"/>
      <div class="col-12">
        <div v-if="mediaPhotosList.length >= 8 && mediaPhotosList.length != mediaPhotosCount" class="artist-show-more" v-on:click="setLimit">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V11.0003" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M11 6L0.999665 6" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
          <span>
            SHOW MORE
          </span>
        </div>
      </div>
    </div>
    <AddPhotosModal v-if="showModal" @closePhotoModel="closePhotoModel"/>
    <full-page-loader :show="loading"/>
  </div>
  <div v-else class="no-content-here no-event-here no-page-style">
    <div class="no-detail-content">
      <div class="category-content-icon">
        <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M45.9167 7.5H12.0833C9.41396 7.5 7.25 9.73858 7.25 12.5V47.5C7.25 50.2614 9.41396 52.5 12.0833 52.5H45.9167C48.586 52.5 50.75 50.2614 50.75 47.5V12.5C50.75 9.73858 48.586 7.5 45.9167 7.5Z" stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20.543 25C22.545 25 24.168 23.3211 24.168 21.25C24.168 19.1789 22.545 17.5 20.543 17.5C18.5409 17.5 16.918 19.1789 16.918 21.25C16.918 23.3211 18.5409 25 20.543 25Z" stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M50.7487 37.5L38.6654 25L12.082 52.5" stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <!--        <svg v-if="$route.params.type === 'pages'" width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M2 52V34.4991" stroke="#47505B" stroke-width="3" stroke-linejoin="round"/>-->
<!--          <path d="M2 34.5016C2 34.5016 4.50012 32.0015 12.0005 32.0015C19.5009 32.0015 24.5011 37.0017 32.0015 37.0017C39.5018 37.0017 42.0019 34.5016 42.0019 34.5016V4.50012C42.0019 4.50012 39.5018 7.00024 32.0015 7.00024C24.5011 7.00024 19.5009 2 12.0005 2C4.50012 2 2 4.50012 2 4.50012V34.5016Z" stroke="#47505B" stroke-width="3" stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--        <img v-else :src="require(`@/assets/icons/club_icon_explore_pages.svg`)" style="height:60px; width: 60px"/>-->
      </div>
      <AddPhotosModal v-if="showModal" @closePhotoModel="closePhotoModel"/>
      <div class="no-detail-content-heading">
        You don’t have any Media yet
      </div>
      <div class="no-detail-content-subheading">
        Upload your Images or Videos now!
      </div>
      <div v-if="currentUserIsAdmin" class="add-new-product-buttons button-photos">
        <button @click="openPhotoModel">
<!--          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <g clip-path="url(#clip0_1604_17059)">-->
<!--              <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.3181 11.6816 1.33333 7.99967 1.33333C4.31778 1.33333 1.33301 4.3181 1.33301 8C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M8 5.33333V10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M5.33301 8H10.6663" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </g>-->
<!--            <defs>-->
<!--              <clipPath id="clip0_1604_17059">-->
<!--                <rect width="16" height="16" fill="white"/>-->
<!--              </clipPath>-->
<!--            </defs>-->
<!--          </svg>-->
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.91536 4.66667L6.9987 1.75L4.08203 4.66667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 1.75V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Upload Media
        </button>
      </div>
    </div>
  </div>
</template>

<script  type="application/javascript">
import mediaPhotosListing from "@/components/PageManager/Pages/ArtistMoreSection/media/photos/mediaPhotosListing.vue";
import mediaPhotosAlbumsListing from "@/components/PageManager/Pages/ArtistMoreSection/media/photos/mediaPhotosAlbumsListing.vue";
import {
  getAllMediaPhotosAlbumsOfPages,
  getAllMediaPhotosOfPages,
} from "../../../../../../apis/APIs";
import AddPhotosModal from "../../../../../Home/Profile/AddPhotosModal";

export default {
  name: "mediaPhotos",
  props:{
    current_page_user:String,
    user_id:{
      type:Number,
      Default:null,
    }
  },
  data() {
    return {
      mediaPhotosList: [],
      mediaPhotosAlbumsList: [],
      offSetLimit: 0,
      limit: 8,
      loading: false,
      dataLoaded: false,
      mediaPhotosCount: '',
      addPhoto: false,
      check: true,
      showModal: false,
      showAll: true
    }
  },
  components: {
    mediaPhotosListing,
    mediaPhotosAlbumsListing,
    AddPhotosModal
  },
  mounted() {
    this.getMediaPhotos();
    this.getMediaPhotosAlbums();
  },

  computed:{
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    currentUserIsAdmin() {
      return !!(this.current_page && this.current_page.current_user && this.current_page.current_user === "admin");
    },
  },

  methods: {
    async getMediaPhotos() {
      try {
        this.loading = true
        let data = await getAllMediaPhotosOfPages(this.offSetLimit, this.limit, this.$route.params.id);
        this.mediaPhotosList =  data.data.information.data;
        this.mediaPhotosCount = data.data.information.total;
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    async getMediaPhotosAlbums() {
      try {
        this.loading = true
        let data = await getAllMediaPhotosAlbumsOfPages(this.$route.params.id);
        this.mediaPhotosAlbumsList =  (Array.isArray(data.data.information))?data.data.information:[];
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    setLimit () {
      this.limit = this.mediaPhotosList.length + 8;
      this.getMediaPhotos();
    },
    createPhotoPost () {
      this.$refs.addPhoto.toggleAddPhotoModal();
    },
    updateMediaTabAlbumsAndPhotos () {
      this.getMediaPhotos();
      this.getMediaPhotosAlbums();
    },
    openPhotoModel(){
      this.showModal = true
    },
    closePhotoModel(){
      this.showModal = false
    },
    toggleAllPhotos(){
      this.showAll = !this.showAll
    },
  }
}
</script>

<style scoped>

.no-page-style {
  margin-top: 30px !important;
}

.button-photos{
  button {
    height: 40px !important;
    width: 230px !important;
  }
}

</style>
