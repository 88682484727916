<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="">
        <div>
          <div>
            <div class="register-margin">
              <div class="getting_started_steps">
                <div class="getting_stared_header">
                  <h1 class="getting_stared_title">Getting Started</h1>
                  <p class="getting_stared_subtitle">This information will let us know more about you</p>
                </div>
                <div class="get_started_tabs">
                  <nav>
                    <div class="nav nav-tabs custom_tab" id="nav-tab" role="tablist">
                      <a v-on:click="nextTab('upload-photo', '1')"
                         :class="['nav-item nav-link', (currentTab == 'upload-photo') ? 'active':'']" id="nav-home-tab"
                         data-toggle="tab" href="#Upload_Photo" role="tab" aria-controls="nav-home"
                         :aria-selected="(currentTab == 'upload-photo')?true:false"><p
                          style="margin-bottom: 0"> 1. </p> Upload Photo</a>
                      <a v-on:click="nextTab('update-info', '1')"
                         :class="['nav-item nav-link', (currentTab == 'update-info') ? 'active':'']"
                         id="nav-profile-tab" data-toggle="tab" href="#Update_Info" role="tab"
                         aria-controls="nav-profile" :aria-selected="(currentTab == 'update-info')?true:false"><p
                          style="margin-bottom: 0"> 2. </p> Update
                        Info</a>
                      <a v-on:click="nextTab('genres', '1')"
                         :class="['nav-item nav-link', (currentTab == 'genres') ? 'active':'']" id="nav-Genres-tab"
                         data-toggle="tab" href="#Genres" role="tab" aria-controls="nav-Genres"
                         :aria-selected="(currentTab == 'genres')?true:false"><p style="margin-bottom: 0">
                        3. </p> Choose Genres
                      </a>
                      <a v-on:click="nextTab('add-friends', '1')"
                         :class="['nav-item nav-link', (currentTab == 'add-friends') ? 'active':'']"
                         id="nav-contact-tab" data-toggle="tab" href="#Add_Friends" role="tab"
                         aria-controls="nav-contact" :aria-selected="(currentTab == 'add-friends')?true:false"><p
                          style="margin-bottom: 0"> 4. </p> Follow
                        Artists</a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div :class="['tab-pane fade', (currentTab == 'upload-photo') ? 'active show':'']" id="Upload_Photo"
                         role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="row">
                        <div class="col">
                          <div class="step">1. Upload Your Photo</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="upload-image">
                            <div class="upload_section">
                              <input @click="openImageAndCoverModel('profile')" id="user_profile_image">
                              <img :src="photo_src"
                                   style="background: white; border-radius: 50%; height: 160px; width: 160px;"
                                   v-if="photo_src"/>
                              <span class="svg_icon" v-if="!photo_src">
                                <svg width="160" height="160" viewBox="0 0 160 160" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="65" cy="65" r="65" fill="#D1433A" fill-opacity="0.1"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                          <upload-profile-and-cover :picType="profileAndCoverModelType"
                                                    :show="profileAndCoverModel"
                                                    :imageCheck="true"
                                                    @getImage="setImage"
                                                    @profileAndCoverValueChange="changeProfileAndCoverValue"/>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 mt-50">
                          <div class="skip_btn">
                            <button @click="nextTab('update-info', '1')">SKIP</button>
                          </div>
                        </div>
                        <div class="col-md-7 mt-50">
                          <div class="next_step_btn">
                            <button v-if="photo" @click="uploadImage">
                              {{ photo_uploading ? "image uploading..." : "UPLOAD IMAGE" }}
                            </button>
                            <button v-else @click="nextTab('update-info', '1')">
                              {{ photo_uploading ? "please wait..." : "NEXT STEP" }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="['tab-pane fade', (currentTab == 'update-info') ? 'active show':'']" id="Update_Info"
                         role="tabpanel" aria-labelledby="nav-profile-tab">
                      <GSUpdateProfile @nextTab="nextTab"/>
                    </div>
                    <div :class="['tab-pane fade', (currentTab == 'genres') ? 'active show':'']" id="Genres"
                         role="tabpanel" aria-labelledby="nav-Genres-tab">
                      <div class="step-3-section">
                        <div class="genres-1">
                          <div class="row">
                            <div class="col">
                              <div class="step mb-4">3. Choose your favourite genres</div>
                            </div>
                          </div>
                          <div v-if="gener_message && selected_geners.length == 0" class="alert alert-danger" role="alert">
                            {{ gener_message }}
                          </div>
                          <div class="row">
                            <div class="col-md-4" v-for="(eachOp, index) in geners" :key="index">
                              <div class="genres_checkbox_outer">
                                <input :id="eachOp.id" type="checkbox" :checked="is_selected_gener(eachOp.id)"
                                       :name="eachOp.id" @change="onGenersChange(eachOp.id)" :value="eachOp.id">
                                <label :for="eachOp.id" class="genres_box">
                                  <div v-if="eachOp.icon_url" class="genres_icon svg-icon">
                                    <img :src="eachOp.icon_url" height="30" width="30" viewBox="0 0 30 30"/>
                                  </div>
                                  <div class="genres_info">
                                    <p class="genres_title">{{ eachOp.name }}</p>
                                  </div>
                                  <div class="genres_checkbox">
                                    <div class="genres_checkbox_lab">
                                      <svg width="7px" height="5px" viewBox="0 0 13 10" version="1.1"
                                           xmlns="http://www.w3.org/2000/svg"
                                           xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <g id="checked(2)" fill="#D1433A" fill-rule="nonzero">
                                            <polygon id="Path" class="chekmark-check"
                                                     points="0.664935065 5.38032418 4.76358812 9.48680081 12.6337662 1.62439985 11.5038734 0.510131007 4.76358812 7.24259274 1.7791807 4.25820853"></polygon>
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="sub-geners" v-if="selected_geners && selected_geners.length > 0">
                          <div class="row">
                            <div class="col">
                              <div class="sub-geners-tag">
                                <ul>
                                  <li v-for="(eachOp, index) in sub_geners" :key="index"
                                      @click="selectSubGeners(eachOp.gener_id, eachOp.id)"
                                      :class="is_selected_sub_gener(eachOp.id)"><a
                                      href="javascript:;">{{ eachOp.name }}</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="clear-history" @click="clearAll()">
                                <button>Clear all history</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 mt_30">
                          <div class="skip_btn">
                            <button @click="nextTab('add-friends', '1')">SKIP</button>
                          </div>
                        </div>
                        <div class="col-md-7 mt_30">
                          <div class="next_step_btn">
                            <button v-on:click="submitGeners">{{
                                submit_geners_loading ? "Please Wait..." : "CONTINUE"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="['tab-pane fade', (currentTab == 'add-friends') ? 'active show':'']" id="Add_Friends"
                         role="tabpanel" aria-labelledby="nav-contact-tab">
                      <div class="row">
                        <div class="col">
                          <div class="step step-4">4. Follow Artists</div>
                          <div class="follow-artists-text">Follow 3 artists</div>
                        </div>
                      </div>
                      <div v-if="artist_message && selected_artists.length < 3" class="alert alert-danger" role="alert">
                        {{ artist_message }}
                      </div>
                      <div class="row">
                        <div class="col " v-for="(eachOp, index) in artists" :key="index"
                             @click="selectArtists(eachOp.id)">
                          <div class="friend-detail">
                            <div class="widget-item max-width">
                              <div class="cover-image">
                                <div class="img-wrapper"><img :src="eachOp.artwork_url" alt=""></div>
                                <div class="status">
                                  <svg width="8" height="7" viewBox="0 0 8 7" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.94219 5.26891L1.81219 6.81691L2.62619 4.30991L0.492188 2.76091H3.12819L3.94219 0.253906L4.76019 2.76091H7.39219L5.26219 4.30991L6.07619 6.81691L3.94219 5.26891Z"
                                        fill="white"></path>
                                  </svg>
                                </div>
                              </div>
                              <div class="item-desc">
                                <div class="name">{{ eachOp.name }}</div>
                                <div class="info">
                                  <div>
                                    <div class="svg-icon">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                              d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                              stroke-linejoin="round"></path>
                                          <path
                                              d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                              stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    0000
                                  </div>
                                  <div>
                                    <div class="svg-icon">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                              d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                              stroke-linejoin="round"></path>
                                          <path
                                              d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                              stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    0000
                                  </div>
                                </div>
                              </div>
                              <div class="action" :class="is_selected_artist(eachOp.id)">
                                <div class="svg-icon">
                                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path class="c-fill-1"
                                          d="M1 8.00987C3.757 8.00987 6 10.2552 6 13.015C6 13.5676 6.448 14.0161 7 14.0161C7.552 14.0161 8 13.5676 8 13.015C8 9.15105 4.86 6.00781 1 6.00781C0.448 6.00781 0 6.45627 0 7.00884C0 7.56141 0.448 8.00987 1 8.00987Z"
                                          fill="#D1433A"></path>
                                    <path class="c-fill-1"
                                          d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                                          fill="#D1433A"></path>
                                    <path class="c-fill-1"
                                          d="M1 14.0157C1.55228 14.0157 2 13.5676 2 13.0147C2 12.4619 1.55228 12.0137 1 12.0137C0.447715 12.0137 0 12.4619 0 13.0147C0 13.5676 0.447715 14.0157 1 14.0157Z"
                                          fill="#D1433A"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="show-more-friends">
                            <button @click="show_more" v-if="total > artists.length">
                                             <span>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M6.00024 1V11.0003" stroke="#071526" stroke-width="1.5"
                                                         stroke-linecap="round" stroke-linejoin="round"></path>
                                                   <path d="M11.0002 6L0.999909 6" stroke="#071526" stroke-width="1.5"
                                                         stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                             </span>
                              Show More
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 mt_30">
                          <div class="skip_btn">
                            <button @click="skipGetting">{{ skip_loading ? "Please Wait..." : "SKIP" }}</button>
                          </div>
                        </div>
                        <div class="col-md-7 mt_30">
                          <div class="next_step_btn">
                            <button v-on:click="submitArtists()">
                              {{ submit_artists_loading ? "Please Wait..." : "FINISH" }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import GSUpdateProfile from "./GSUpdateProfile.vue";
import {mapGetters, mapMutations, mapActions} from 'vuex';
import {
  update_profile_photo,
  get_geners_list,
  get_artists_list,
  update_geners_api,
  update_artists_api,
  profile_completed, update_subgeners_api, uploadProfileAndCover, addPost
} from "../../../apis/APIs";
import {SYSTEM_DATA_IS_WELCOME_PAGE, USER_DATA} from '../../../store/types';
import AppConfig from "../../../configs/AppConfig";
import UploadProfileAndCover from "../Profile/componenets/uploadProfileAndCover";


export default {
  data() {
    return {
      currentTab: 'upload-photo',
      genres2: false,
      subGenres: false,
      total: 0,
      photo: undefined,
      photo_src: undefined,
      photo_uploading: false,

      geners: [],
      sub_geners: [],
      geners_list_loading: false,

      artists: [],
      artist_loading: false,

      selected_geners: [],
      selected_sub_geners: [],

      start_point: 0,
      end_point: 6,

      selected_artists: [],

      skip_loading: false,

      update_info_submit: false,

      gener_message: "",
      artist_message: "",
      submit_geners_loading: false,
      submit_artists_loading: false,
      profileAndCoverModel: false,
      profileAndCoverModelType: '',
      tabCheck: false
    }
  },
  watch: {
    user() {
      const {user_data} = this.user || {};
      const {artwork_url} = user_data || {};
      this.photo_src = artwork_url;
    }
  },
  components: {
    GSUpdateProfile,
    UploadProfileAndCover
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    })
  },
  mounted() {
    const {user_data} = this.user || {};
    const {id, artwork_url, genres, following} = user_data || {};

    if (id && artwork_url) {
      this.photo_src = `${artwork_url}`;
    }

    this.open_tab();

    //Featch all the geners and artists
    this.geners_list(() => {
      if (genres && genres.length > 0) {

        let sub_geners = [];
        genres.forEach(ele => {
          let {child} = ele;
          child = child && child.length ? child.filter(value => value.id) : [];

          if (child && child.length) {
            child = child.map(value => ({gener_id: ele.id, ...value}));
            sub_geners = [...sub_geners, ...child];
          }

          this.onGenersChange(ele.id);
        });

        if (sub_geners && sub_geners.length) {
          sub_geners.forEach(ele => {
            this.selectSubGeners(ele.gener_id, ele.id);
          })
        }
      }
    });
    this.artists_list();
  },
  methods: {
    ...mapActions([
      "fetchUserData"
    ]),
    ...mapMutations([
      "updateSystemDataUIConstraints",
      "updateUserUIConstraints"
    ]),
    openImageAndCoverModel(type) {
      this.profileAndCoverModel = true
      this.profileAndCoverModelType = type
      this.$store.commit('SET_CROPPER', false)
    },
    login: function (event) {
      let email = this.email;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    },
    open_tab() {
      const {user_data} = this.user || {};
      const {id, artwork_url, website_url, genres} = user_data || {};

      if (String(artwork_url).includes('common/default')) {
        return this.nextTab('upload-photo', '1')
      }

      if (!website_url && !this.update_info_submit) {
        return this.nextTab('update-info', '1');
      }

      if (!genres[0]) {
        return this.nextTab('genres', '1');
      }

      return this.nextTab('add-friends', '1');
      // nextTab('add-friends', '1')
    },
    async artists_list() {
      try {
        this.artist_loading = true;
        const query = `?offset=${this.start_point}&limit=${this.end_point}`;
        const res = await get_artists_list(query);
        const {success, information} = res.data || {};
        this.total=information.total
        if (success === 1) {
          if (information.data && information.data.length) {
            this.artists = [...this.artists, ...information.data];
          }
        }
        this.artist_loading = false;
      } catch (error) {
        this.artist_loading = false;
      }
    },
    show_more() {
      this.start_point = this.end_point;
      this.end_point = this.end_point + this.start_point;
      this.artists_list();
    },
    async geners_list(cb) {
      try {
        this.geners_list_loading = true;
        const res = await get_geners_list();
        const {success, information} = res.data || {};
        if (success === 1) {
          this.geners = information;
          if (typeof cb === "function") {
            cb();
          }
        }
        this.geners_list_loading = false;
      } catch (error) {
        this.geners_list_loading = false;
      }
    },
    closeLoginModal: function () {
      this.$store.commit("setGlobalVar", {vari: "isLogin", valu: 'none'})
    },
    goBack: function () {
      this.$store.commit("registerData", {vari: "registerStep", valu: 'email'})

    },
    finishRequest: function () {
      this.$store.commit("gettingStarted", {vari: "isLogin", valu: 'restorePassword'})
    },
    nextTab: function (tab, a) {
      console.log(tab, a);
      this.currentTab = tab;
    },
    showMore: function () {
      if (!this.genres2) {
        this.genres2 = true;
      } else if (!this.subGenres) {
        this.subGenres = true;
      }
    },
    changeProfileAndCoverValue(value, image) {
      this.profileAndCoverModel = value;
    },
    setImage(crop_image, image, file_name) {
      this.photo_src = image
      this.photo = crop_image
    },
    nextStep() {
      if (this.tabCheck) {
        this.nextTab('update-info', '1');
        this.open_tab();
      } else {
        this.open_tab();
      }
    },
    async uploadImage() {
      console.log("user_map_to_props ===> ", this.user, this.photo);

      if (this.photo) {
        var formdata = new FormData();
        formdata.append("artwork", this.photo);

        this.photo_uploading = true
        try {
          const res = await uploadProfileAndCover(formdata);
          const {success, information,message} = res.data;
          this.photo_uploading = false;
          if (success === 1) {
            this.fetchUserData()
            this.$store.commit("updateUserUIConstraints", ({
              [USER_DATA]: information
            }));
            await this.uploadPost(this.photo);
            this.photo = undefined
            this.nextTab('update-info', '1');
            this.open_tab();
          }
          else{
            this.makeToast('error','Upload profile',message)
          }
        } catch (error) {
          this.photo_uploading = false;
          this.makeToast('error','Upload profile',"Please Try again")
        }
      } else if (this.photo_src) {
        this.open_tab();
      }

    },
    updateImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;

      if (file) {
        this.photo = file;
        this.photo_src = URL.createObjectURL(file);
      }
    },
    onGenersChange(id) {
      const index = this.selected_geners.findIndex(ele => ele === id);
      const gener_index = this.geners.findIndex(ele => ele.id === id);

      console.log("ongenerschange ===> init", index, gener_index, this.selected_geners);

      if (index === -1) {
        let sub_geners_data = this.geners[gener_index].subgenre;

        if (sub_geners_data && sub_geners_data.length) {
          sub_geners_data = sub_geners_data.map(ele => ({gener_id: id, ...ele}));
          this.sub_geners = [...this.sub_geners, ...sub_geners_data];
        }
        console.log("Sub Genres ===> ", this.sub_geners );
        this.gener_message = '';
        return this.selected_geners.push(id);
      }

      this.selected_geners.splice(index, 1);

      if (this.sub_geners) {
        this.sub_geners = this.sub_geners.filter(ele => ele.gener_id !== id);
      }
    },
    selectSubGeners(gener_id, id) {
      const index = this.selected_sub_geners.findIndex(ele => ele.id === id);
      console.log("onsubgenerschange ===> ", index, this.selected_sub_geners);

      if (index === -1) {
        return this.selected_sub_geners.push({gener_id, id});
      }

      this.selected_sub_geners.splice(index, 1);
    },
    clearAll() {
      this.selected_geners = [];
      this.sub_geners = [];
      this.selected_sub_geners = [];
    },
    selectArtists(id) {
      const index = this.selected_artists.findIndex(ele => ele === id);

      if (index === -1) {
        this.artist_message = '';
        return this.selected_artists.push(id);
      }

      this.selected_artists.splice(index, 1);
    },
    is_selected_artist(id) {
      const index = this.selected_artists.findIndex(ele => ele === id);

      return index !== -1 ? "artists-active" : "";
    },
    is_selected_gener(id) {
      const index = this.selected_geners.findIndex(ele => ele === id);

      return index !== -1 ? true : false;
    },
    is_selected_sub_gener(id) {
      const index = this.selected_sub_geners.findIndex(ele => ele.id === id);
      console.log('Index Check', index);
      return index !== -1 ? "active-sub-gener" : "";
    },
    async skipGetting() {
      try {
        if (this.skip_loading) return;

        const {user_data} = this.user || {};
        const {id, artwork_url} = user_data || {};
        this.skip_loading = true;
        const res = await profile_completed();
        const {success} = res.data
        await this.fetchUserData();
        if (success === 1) {
          this.$store.commit("completeGetStartedPage", false)
          this.updateSystemDataUIConstraints({
            [SYSTEM_DATA_IS_WELCOME_PAGE]: false
          });
        }
        this.skip_loading = false;
      } catch (error) {
        this.skip_loading = false;
      }
    },
    async submitGeners() {
      try {
        const {user_data} = this.user || {};
        const {id} = user_data || {};

        if (this.submit_geners_loading) return;

        const selected_geners = this.selected_geners && this.selected_geners.length ? this.selected_geners : [];
        const selected_sub_geners = this.selected_sub_geners && this.selected_sub_geners.length ? this.selected_sub_geners.map(ele => ele.id) : [];

        this.gener_message = "";

        if (selected_geners.length == 0 ) {
          return this.gener_message = "Please select a genre to proceed further.";
        }

        this.submit_geners_loading = true;

        var formdata = new FormData();
        for (let i = 0; i < selected_geners.length; i++) {
          let genre_data = selected_geners[i];
          formdata.append("genreIds[" + i + "]", genre_data);
        }
        formdata.append("id", id);
        var subgenreData = new FormData();
        for (let i = 0; i < selected_sub_geners.length; i++) {
          let subgenre_data = selected_sub_geners[i];
          subgenreData.append("subGenreIds[" + i + "]", subgenre_data);
        }
        subgenreData.append("id", id);
        const {data} = await update_geners_api(formdata);
        await update_subgeners_api(subgenreData);
        const {success, message, information} = data
        console.log(data)
        if (success === 1) {
          this.$store.commit("updateUserUIConstraints", ({
            [USER_DATA]: information
          }));
          this.nextTab('add-friends', '1');
        }

        this.submit_geners_loading = false;
      } catch (error) {
        this.submit_geners_loading = false;
      }
    },
    async submitArtists() {
      try {
        const {user_data} = this.user || {};
        const {id} = user_data || {};

        console.log(this.submit_artists_loading)

        if (this.submit_artists_loading) return;

        const selected_artists = this.selected_artists && this.selected_artists.length ? this.selected_artists : [];

        this.artist_message = "";

        if ((!this.selected_artists && this.selected_artists.length == 0) || (this.selected_artists && this.selected_artists.length < 3)) {
          return this.artist_message = "Please select at least three artists.";
        }

        this.submit_artists_loading = true;
        // const query = `?id=${id}&following_id=${selected_artists}&follow=1`;

        var formdata = new FormData();
        formdata.append("id", id);
        for (let i = 0; i < selected_artists.length; i++) {
          let artist_data = selected_artists[i];
          formdata.append("artistIds[" + i + "]", artist_data);
        }

        const {data} = await update_artists_api(formdata);
        const {success, message, information} = data

        if (success === 1) {
          this.$store.commit("updateUserUIConstraints", ({
            [USER_DATA]: information
          }));
          this.submitGettingStartedPages(() => {
            this.updateSystemDataUIConstraints({
              [SYSTEM_DATA_IS_WELCOME_PAGE]: false
            });
          });
        } else {
          this.artist_message = message;
        }

        this.submit_artists_loading = false;
      } catch (error) {
        this.submit_artists_loading = false;
      }
    },
    async submitGettingStartedPages(cb = () => {
    }) {
      try {
        const {user_data} = this.user || {};
        const {id, artwork_url} = user_data || {};
        const res = await profile_completed();
        const {success} = res.data
        if (success === 1) {
          this.$store.commit("completeGetStartedPage", false)
          await this.fetchUserData();
          this.skip_loading = true;
          cb();
        }
      } catch (error) {
        console.log(error)
        this.skip_loading = true;

        cb();
      }
    },
    async uploadPost(image) {
      try {
        const form = new FormData();
        form.append("artwork[" + 0 + "]", image);
        form.append("full_content", null);
        form.append("category", "image");
        form.append("access", "public");
        form.append("post_type", "profile_photo");
        const { data } = await addPost(form);
        this.loading = false;
        const { success, message, information } = data || {};
        if (success === 1) {
          this.$store.commit("ADD_POST_IN_POSTS", information);
        } else {
          console.error(message, "Error in creating post");
        }
      } catch (error) {
        console.error(error, "Error in creating post");
      }
    },
  }
}
</script>
