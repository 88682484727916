<template>
  <div v-if="show" id="preloader">
    <div class="classic-circle-loader"></div>
  </div>
</template>

<script>
export default {
  name: "FullPageLoader",
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
#preloader {
  background-color: rgba(230, 230, 230, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.classic-circle-loader {
  width: 40px;
  height: 40px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #d1433a; 
  border-radius: 50%; 
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>