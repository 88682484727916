<template>
  <div class="events-overview-search-container">
    <div class="events-overview-search-box">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
          stroke="#8B949F"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 12.501L14.5 14.501"
          stroke="#8B949F"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <input
        type="text"
        placeholder="Search Artists, Clubs, Promoters, Venues"
        v-model="searchInput"
      />
    </div>
    <div class="events-overview-search-location-box">
      <svg width="16" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00198 7.19655C0.981413 6.39275 1.12154 5.59296 1.41405 4.84399C1.70656 4.09503 2.14561 3.41197 2.70549 2.83487C3.26536 2.25776 3.93477 1.79821 4.67453 1.48315C5.41429 1.16808 6.20949 1.0038 7.01354 1H7.10606C7.90786 0.999273 8.7019 1.15667 9.44281 1.46316C10.1837 1.76966 10.8569 2.21925 11.4239 2.78621C11.9908 3.35317 12.4404 4.02637 12.7469 4.76728C13.0534 5.50819 13.2108 6.30229 13.2101 7.10409V7.19655C13.1791 8.90277 12.4821 10.5292 11.2679 11.7284L7.10606 17L2.85169 11.6358C1.64937 9.87862 1.00198 8.86129 1.00198 7.19655" stroke="#8B949F" stroke-width="1.5"/>
        <path d="M7.10373 9.1387C8.27853 9.1387 9.2309 8.22774 9.2309 7.10402C9.2309 5.98029 8.27853 5.06934 7.10373 5.06934C5.92893 5.06934 4.97656 5.98029 4.97656 7.10402C4.97656 8.22774 5.92893 9.1387 7.10373 9.1387Z" stroke="#8B949F" stroke-width="1.5" stroke-miterlimit="10"/>
      </svg>
      <div class="google-location-box">
        <GoogleMapAndLocation @getLocation="getLocation" />
      </div>
    </div>

    <!-- <div class="search flex location">
      <svg
        width="14"
        height="19"
        viewBox="0 0 14 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00198 7.19655C0.981413 6.39275 1.12154 5.59296 1.41405 4.84399C1.70656 4.09503 2.14561 3.41197 2.70549 2.83487C3.26536 2.25776 3.93477 1.79821 4.67453 1.48315C5.41429 1.16808 6.20949 1.0038 7.01354 1H7.10606C7.90786 0.999273 8.7019 1.15667 9.44281 1.46316C10.1837 1.76966 10.8569 2.21925 11.4239 2.78621C11.9908 3.35317 12.4404 4.02637 12.7469 4.76728C13.0534 5.50819 13.2108 6.30229 13.2101 7.10409V7.19655C13.1791 8.90277 12.4821 10.5292 11.2679 11.7284L7.10606 17L2.85169 11.6358C1.64937 9.87862 1.00198 8.86129 1.00198 7.19655"
          stroke="#8B949F"
          stroke-width="1.5"
        />
        <path
          d="M7.10373 9.1387C8.27853 9.1387 9.2309 8.22774 9.2309 7.10402C9.2309 5.98029 8.27853 5.06934 7.10373 5.06934C5.92893 5.06934 4.97656 5.98029 4.97656 7.10402C4.97656 8.22774 5.92893 9.1387 7.10373 9.1387Z"
          stroke="#8B949F"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </svg>
      <v-select
        class="v-select-main-class v-select-without-border"
        label="name"
        v-model="location"
        :options="countries"
        :reduce="(country) => country.name"
        placeholder="Location"
      >
        <template slot="option" slot-scope="country">
          <div class="d-center w-full">
            {{ country.name }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="country">
          <div class="selected d-center">
            {{ country.name }}
          </div>
        </template>
      </v-select>
    </div> -->
    <div class="events-overview-search-date-box" @click="showPiker">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.60156 4.3V0" stroke="#8B949F" stroke-width="1.5" />
        <path d="M11.3984 4.3V0" stroke="#8B949F" stroke-width="1.5" />
        <path
          d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
          stroke="#8B949F"
          stroke-width="1.5"
        />
        <path
          d="M3.10156 6.90039H13.9016"
          stroke="#8B949F"
          stroke-width="1.5"
        />
      </svg>
      <div class="date-picker">
        <DatePickerComponent
          :value="datePicker"
          :main-class="'events-datepicker'"
          :confirm="true"
          :format="'D MMM,YYYY'"
          :open="show"
          :show-week-number="false"
          :placeholder="'Date'"
          :popup-class="'events-search-datepicker-popup'"
          :type="'date'"
          :confirm-text="'DONE'"
          @dateInput="selectValue"
        />
      </div>
    </div>
    <button class="events-overview-search-find-events-btn" @click="searchEvents">FIND EVENTS</button>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import "vue-select/dist/vue-select.css";
import "/public/css/vue-select-style.scss";
import GoogleMapAndLocation from "@/components/Common/Google Location/GoogleMapAndLocation";
import DatePickerComponent from "../../DesignGuide/Music/Components/DatePickerComponent.vue";

export default {
  components: {
    DatePickerComponent,
    GoogleMapAndLocation,
  },
  data() {
    return {
      searchInput: "",
      datePicker: null,
      show: false,
      displayText: null,
      callDate: "",
      output: {
        year: null,
        startOfWeek: null,
        endOfWeek: null,
        dateSelected: null,
      },
      countries: [],
      location: {
        country: "",
        city: "",
      },
    };
  },
  mounted() {
    this.get_countries_list();
  },
  methods: {
    ...mapMutations(["set_Events_Searech_For"]),
    ...mapActions([
      "getCountriesList",
      "addSearchHistoryCityContry",
      "getSearchHistoryCityContry",
    ]),
    async getLocation(data) {
      this.gMapDataReq = false;
      this.location.country = data.country;
      this.location.city = data.city;
      await this.addSearchHistoryCityContry({
        city: data.city,
        country: data.country,
      });
      await this.getSearchHistoryCityContry();
    },
    async get_countries_list() {
      try {
        const { status, information } = await this.getCountriesList();
        this.countries = information && information.length ? information : [];
      } catch (error) {
        console.log(error);
      }
    },
    selectValue(date) {
      this.parseValue(date);
      this.show = false;
    },
    parseValue(date) {
      if (date) {
        this.output.year = moment(date).year();
        this.output.startOfWeek = moment(date)
          .startOf("week")
          .format("DD-MM-YYYY");
        this.output.endOfWeek = moment(date).endOf("week").format("DD-MM-YYYY");
        this.output.dateSelected = moment(date).format();
        this.displayText = moment(date).format("Do MMM,YYYY");
        this.callDate = moment(date).format(" Do-MMM-YYYY");
        this.$emit("selectedValue", this.output);
      }
      return this.output;
    },
    showPiker() {
      this.show = !this.show;
      // setTimeout(() => {
      //   document.querySelectorAll(".cell").forEach((el) => {
      //     el.style.borderRadius = "50%";
      //     el.classList.forEach((element) => {
      //       if (element === "today") {
      //         el.style.color = "#D1433A";
      //       }
      //     });
      //   });
      // });
    },
    searchEvents() {
      this.set_Events_Searech_For({
        search: this.searchInput,
        date: this.callDate.trim(),
        country: this.location.country,
        city: this.location.city,
        event_type:
          !this.$route.path.includes("/events/page/overview") &&
          this.$route.meta.event_Type_id,
      });
      this.$emit("explorEvents", this.GET_EVENTS_SEARCH_FOR);
      this.$emit("changeFilterKey");
    },
  },
  computed: {
    ...mapGetters(["GET_EVENTS_SEARCH_FOR"]),
  },
};
</script>

<style lang="scss" scoped>
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
}
.events-overview-search-container {
  .vs__search::placeholder {
    font-family: HelveticaNeueMedium,sans-serif;
    color: #8b949f;
    font-size: 15px;
    line-height: 18px;
  }
  .date {
    position: relative;
    width: 169px;
    .events-datepicker {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  input {
    color: #47505b !important;
    border: none;
    width: 100%;
  }
  & .button {
    button {
      background: linear-gradient(270deg, #0075ff -9.47%, #ae4ea9 98.54%);
      border-radius: 4px;
      border: none;
      color: #ffffff;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      width: 250px;
      height: 40px;
      max-width: 100%;
    }
  }
}
</style>

<style lang="scss">
.search-container {
  .mx-calendar-header-label {
    color: #D1433A;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    font-family: HelveticaNeueBold;
  }
  .mx-btn-icon-left,
  .mx-btn-icon-right {
    border-radius: 4px !important;
  }
  .mx-table-date td,
  .mx-table-date th {
    border-radius: 50%;
  }
  .vs__actions {
    padding: 4px 0 0 3px !important;
  }
  .vs__selected {
    white-space: nowrap;
    width: 93px;
    overflow: hidden;
    color: #47505b;
  }
  .vs__selected-options {
    position: relative;
    height: 20px;
    input {
      width: 100%;
      position: absolute;
    }
  }
}
.pac-container {
  padding-top: 10px !important;
  margin-top: 5px !important;
  font-family: HelveticaNeueNormal;
  border: none !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
}
</style>