<template>
  <div class="js-container-hover grid_charts country-card grid-chart" :class="{'small-width':small}">
    <div class="card slider shop-selling">
      <a  class="cursor-pointer" @click="redirectToChannel('country','top-100-by-country',country.name,country.id,type)">
        <div class="card-cover">
          <div :class="['country-cover chart-item', 'color-'+colorCode]">
            <div class="top-text-chart">TOP 100</div>
            <div class="center-text">Charts</div>
<!--            <div class="bottom-text">{{country.name | setCountryNameShort}}</div>-->
            <div class="bottom-text ">
                        <span :title="country.name" data-toggle="tooltip"  class="tooltip" style="text-overflow: ellipsis;width: 100%;background-color: #fff0 !important;
    text-align: center;    opacity: 1;">{{ (country.name.length > 14) ? country.name.slice(0,14) +'...': country.name }}</span>
            </div>
          </div>
        </div>
      </a>
      <div class="card-description-chart">
        <a href="#"> </a>
        <div class="title marquee js-marquee">
          <a href="#" style="left: 0px;"> </a>
          <div>
            <a href="#" style="left: 0px;"> </a>
            <a class="top-heading-chart" href="#" style="left: 0px;">Top 100: {{country.name |setCountryNameShort}}</a>
          </div>
        </div>
        <div class="description marquee js-marquee">
          <div>
            <a href="#" style="left: 0px;">
              <span class="top-subheading-chart">Playy</span>
              <span class="subheading-chart">Music</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridChart",
  props:{
    colorCode:{
      type:Number,
      Default:0
    },
    small:{
      type:Boolean,
      default:false
    },
    country:{
      type:Object,
      default () {
        return {
          name: 'Global',
          id: 'global'
        }
      }
    },
    type:{
      type:String,
      default:'singles'
    }
  },
  filters:{
    setCountryNameShort(val){
      if(val.includes("United Kingdom")){
        return "UK"
      }
      else if(val.includes("United States")){
        return "USA"
      }
      else{
        return val
      }
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  },
  methods:{
    redirectToCountryData(){
      this.$router.push('/music/page/chart-top-selling')
    }
  }
}
</script>

<style scoped lang="scss">
.grid-chart{
  max-width: 250px;
  .country-cover{
    border-top-left-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 250px;
    border-top-right-radius: 4px;
       &:before{
         width: 142px;
         height: 142px;
       }
    .center-text{
      margin: 80px 0 64px;
    }
  }
  .card{
    .card-description-chart{
      background-color: white;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .top-heading-chart{
        margin-top: 0;
      }
      .description{
        margin-bottom: 10px!important;
      }
    }
  }
}
.bottom-text{
  text-transform: uppercase;
}
.small-width{
  width: 170px !important;
  .country-cover{
    height: 170px !important;
        &:before{
          width: 100px!important;
          height: 100px!important;
        }
    .center-text{
      margin: 38px 0 41px!important;
    }
  }
}
</style>
