<template>
  <div class="">
    <div class="row" :hidden="check">
      <div class="col" @click="toggleCreatePostModal">
        <div class="post-artist-setcion">
          <div class="user-post-filed-image">
            <img :src="picture ? picture : require(`@/assets/img/user-placeholder.png`)"/>
          </div>
          <div class="post-something-field">
            <input type="text" v-model="content" name="create_post_form_post" placeholder="What is on your mind? #Hashtag...@Mention... Link" autocomplete="off"
                   readonly/>
            <!-- <span class="post_emoji" @click="toggleEmoji">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z" fill="#47505B"/>
              <path d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z" fill="#47505B"/>
              </svg>
            </span> -->
            <span class="post_emoji" @click="toggleEmoji" v-if="emoji">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                            stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                            stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                            fill="#D1433A"/>
                        <path
                            d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                            fill="#D1433A"/>
                        </svg>
                    </span>

            <span class="post_emoji" @click="toggleEmoji" v-if="!emoji">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                            stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                            stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                            fill="#8B949F"/>
                        <path
                            d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                            fill="#8B949F"/>
                        </svg>
                    </span>
          </div>

          <div class="send-btn">
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path d="M24.3336 12.7282L12.6664 12.7282" stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <path d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z" stroke="white"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="18" height="18" fill="white" transform="translate(13.7266) rotate(45)"></rect>
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>
      </div>
    </div>
    <div class="Signup_pop_overlay" v-if="is_create_post">
      <div class="modal fade show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
           style="display: block;" aria-modal="true">

        <div class="modal-dialog">
          <div class="modal-content" style="width: 572px;">
            <div class="">
              <div class="post-option-container" id="create-post-option-container">
                <div class="row">
                  <div class="col">
                    <div class="post-artist-setcion">
                      <div class="user-post-filed-image feed-user-post-filed-image">
                        <img :src="picture ? picture : require(`@/assets/img/user-placeholder.png`)"/>
                      </div>
                      <MentionedAndTagBox :checkPost="''" :selectedTab="selected_tab" @userPageLink="setPageUserLinks">
                        <template v-slot:input>
                          <div class="post-something-field">
                            <input v-if="selected_tab !== 'check_in'" @keydown="removeMentionsOnBackSpace($event)" type="text" v-model="content" name="create_post_form_post"
                                   placeholder="What is on your mind? #Hashtag...@Mention... Link" autocomplete="off" ref="Input" @click="emoji = false" />
                            <span class="post_emoji" @click="toggleEmoji" v-if="emoji && selected_tab !== 'check_in'">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                    stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                                                    stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                                                    fill="#D1433A"/>
                                                <path
                                                    d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                                                    fill="#D1433A"/>
                                                </svg>
                                            </span>
                            <span class="post_emoji" @click="toggleEmoji" v-if="!emoji">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                    stroke="#8B949F" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                                                    stroke="#8B949F" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                                                    fill="#8B949F"/>
                                                <path
                                                    d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                                                    fill="#8B949F"/>
                                                </svg>
                                            </span>
                            <div v-if="selected_tab === 'check_in'" class="check-in-location">
                              <GoogleMapAndLocation @getLocation="getLocation" />
                            </div>
                          </div>
                        </template>
                      </MentionedAndTagBox>
                      <div class="send-btn" @click="addPost" v-if="!loading">
                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path d="M24.3336 12.7282L12.6664 12.7282" stroke="white" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z"
                                  stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="18" height="18" fill="white"
                                    transform="translate(13.7266) rotate(45)"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="send-btn" v-else>
                        <b-icon style="margin-left: 0!important;" icon="arrow-clockwise" animation="spin" font-scale="2" variant="light"></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="background-color: #E6EAF0; margin-top: 0.8rem; height: 1px;"></div>
                <p class="text-center" v-if="loading">Please Wait...</p>
                <p class="text-center error_msg error_msg_red" v-if="error_message">{{
                    error_message
                  }}</p>
                <div v-if="!current_tab " class="multiple-options-setion">
                  <div class="row">
                    <div class="post-col">
                      <div class="post-option-select">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                      <path
                                                          d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
                                                          stroke="#0CAACD"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                                                          stroke="#0CAACD"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      </svg>
                                                  </span>
                          <p>Live Video</p>

                        </a>
                      </div>
                    </div>
                    <div class="post-col">
                      <div class="post-option-select" :class="selected_tab === 'upload_photos' ? 'selected-div' : ''"
                           @click="updateTab('upload_photos')">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                      <path
                                                          d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                                                          stroke="#D1433A"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M7.08398 8.33301C7.77434 8.33301 8.33398 7.77336 8.33398 7.08301C8.33398 6.39265 7.77434 5.83301 7.08398 5.83301C6.39363 5.83301 5.83398 6.39265 5.83398 7.08301C5.83398 7.77336 6.39363 8.33301 7.08398 8.33301Z"
                                                          stroke="#D1433A"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M17.4993 12.4997L13.3327 8.33301L4.16602 17.4997"
                                                          stroke="#D1433A"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      </svg>
                                                  </span>
                          <p>Upload Photos</p>
                          <span v-if="selected_tab === 'upload_photos'">selected</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="post-col">
                      <div class="post-option-select" :class="selected_tab === 'upload_video' ? 'selected-div' : ''"
                           @click="updateTab('upload_video')">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                                                                                    <path
                                                                                                                        d="M16.516 1.66699H3.48268C2.47936 1.66699 1.66602 2.48034 1.66602 3.48366V16.517C1.66602 17.5203 2.47936 18.3337 3.48268 18.3337H16.516C17.5193 18.3337 18.3327 17.5203 18.3327 16.517V3.48366C18.3327 2.48034 17.5193 1.66699 16.516 1.66699Z"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M5.83398 1.66699V18.3337"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M14.166 1.66699V18.3337"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M1.66602 10H18.3327"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M1.66602 5.83301H5.83268"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M1.66602 14.167H5.83268"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M14.166 14.167H18.3327"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        d="M14.166 5.83301H18.3327"
                                                                                                                        stroke="#FC7C5F"
                                                                                                                        stroke-width="1.5"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                    />
                                                                                                                    </svg>
                                                  </span>
                          <p>Upload Video</p>
                          <span v-if="selected_tab === 'upload_video'">selected</span>
                        </a>
                      </div>
                    </div>
                    <!--                    <div class="post-col">-->
                    <!--                      <div class="post-option-select" :class="selected_tab === 'upload_video' ? 'selected-div' : ''"-->
                    <!--                           @click="updateTab('upload_video')">-->
                    <!--                        <a href="javascript:;">-->
                    <!--                                                      <span class="post-option-icon">-->
                    <!--                                                          <svg-->
                    <!--                                                              width="20"-->
                    <!--                                                              height="20"-->
                    <!--                                                              viewBox="0 0 20 20"-->
                    <!--                                                              fill="none"-->
                    <!--                                                              xmlns="http://www.w3.org/2000/svg"-->
                    <!--                                                          >-->
                    <!--                                                          <path-->
                    <!--                                                              d="M16.516 1.66699H3.48268C2.47936 1.66699 1.66602 2.48034 1.66602 3.48366V16.517C1.66602 17.5203 2.47936 18.3337 3.48268 18.3337H16.516C17.5193 18.3337 18.3327 17.5203 18.3327 16.517V3.48366C18.3327 2.48034 17.5193 1.66699 16.516 1.66699Z"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M5.83398 1.66699V18.3337"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M14.166 1.66699V18.3337"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M1.66602 10H18.3327"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M1.66602 5.83301H5.83268"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M1.66602 14.167H5.83268"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M14.166 14.167H18.3327"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          <path-->
                    <!--                                                              d="M14.166 5.83301H18.3327"-->
                    <!--                                                              stroke="#FC7C5F"-->
                    <!--                                                              stroke-width="1.5"-->
                    <!--                                                              stroke-linecap="round"-->
                    <!--                                                              stroke-linejoin="round"-->
                    <!--                                                          />-->
                    <!--                                                          </svg>-->
                    <!--                                                      </span>-->
                    <!--                          <p>Upload Video</p>-->
                    <!--                          <input type="file" :disabled="selected_tab === 'upload_video' ? false : true"-->
                    <!--                                 accept="video/mp4,video/x-m4v,video/*" name="fileToUpload" @change="updateVideo"-->
                    <!--                                 id="fileToUploadVideo">-->
                    <!--                          &lt;!&ndash; <span v-if="selected_tab === 'upload_video'">selected</span> &ndash;&gt;-->
                    <!--                        </a>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div class="post-col">
                      <div class="post-option-select" :class="selected_tab === 'article' ? 'selected-div' : ''"
                           @click="openNewArticle()">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                      <path
                                                          d="M11.6673 1.66699H5.00065C4.55862 1.66699 4.1347 1.84259 3.82214 2.15515C3.50958 2.46771 3.33398 2.89163 3.33398 3.33366V16.667C3.33398 17.109 3.50958 17.5329 3.82214 17.8455C4.1347 18.1581 4.55862 18.3337 5.00065 18.3337H15.0006C15.4427 18.3337 15.8666 18.1581 16.1792 17.8455C16.4917 17.5329 16.6673 17.109 16.6673 16.667V6.66699L11.6673 1.66699Z"
                                                          stroke="#15CA86"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M11.666 1.66699V6.66699H16.666"
                                                          stroke="#15CA86"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M13.3327 10.833H6.66602"
                                                          stroke="#15CA86"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M13.3327 14.167H6.66602"
                                                          stroke="#15CA86"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M8.33268 7.5H7.49935H6.66602"
                                                          stroke="#15CA86"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      </svg>
                                                  </span>
                          <p>Write Article</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="post-col">
                      <div class="post-option-select"
                           :class="selected_tab === 'feelings_activity' ? 'selected-div' : ''"
                           @click="updateTab('feelings_activity')">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                      <path
                                                          d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
                                                          stroke="#FC7C5F"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M6.66602 11.667C6.66602 11.667 7.91602 13.3337 9.99935 13.3337C12.0827 13.3337 13.3327 11.667 13.3327 11.667"
                                                          stroke="#FC7C5F"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M7.5 7.5H7.50833"
                                                          stroke="#FC7C5F"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M12.5 7.5H12.5083"
                                                          stroke="#FC7C5F"
                                                          stroke-width="1.5"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      </svg>
                                                  </span>
                          <p>Feelings / Activity</p>
                          <!-- <span v-if="selected_tab === 'feelings_activity'">selected</span> -->
                        </a>
                      </div>
                    </div>

                    <div class="post-col">
                      <div class="post-option-select" :class="selected_tab === 'colored_post' ? 'selected-div' : ''"
                           @click="updateTab('colored_post')">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                      <path
                                                          d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                                                          fill="url(#paint0_linear)"
                                                      ></path>
                                                      <defs>
                                                          <linearGradient
                                                              id="paint0_linear"
                                                              x1="18.9205"
                                                              y1="10"
                                                              x2="1.19318"
                                                              y2="10"
                                                              gradientUnits="userSpaceOnUse"
                                                          >
                                                          <stop stop-color="#0075FF"></stop>
                                                          <stop offset="0.913952" stop-color="#0CAACD"></stop>
                                                          </linearGradient>
                                                      </defs>
                                                      </svg>
                                                  </span>
                          <p>Colored Post</p>
                          <!-- <span v-if="selected_tab === 'colored_post'">selected</span> -->
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="post-col">
                      <div class="post-option-select" :class="selected_tab === 'check_in' ? 'selected-div' : ''"
                           @click="updateTab('check_in')">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="18"
                                                          height="22"
                                                          viewBox="0 0 18 22"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                      <path
                                                          d="M9.05212 1C6.89919 1 4.87441 1.87142 3.34942 3.46049C1.83298 5.04101 1 7.1384 1 9.37249C1 11.6066 1.83298 13.704 3.34942 15.2845L8.68048 20.8419C8.77873 20.9445 8.91115 21 9.05212 21C9.19308 21 9.3255 20.9445 9.42375 20.8419L14.7548 15.2845C16.2713 13.704 17.1042 11.6066 17.1042 9.37249C17.1042 7.1384 16.2713 5.04101 14.7548 3.46049C13.2298 1.87142 11.205 1 9.05212 1ZM14.0115 14.5711L9.05212 19.7399L4.0927 14.5711C1.34173 11.7048 1.34173 7.03588 4.0927 4.16959C5.41692 2.78556 7.18112 2.0252 9.05212 2.0252C10.9231 2.0252 12.6873 2.78556 14.0115 4.16959C16.7668 7.03588 16.7668 11.7006 14.0115 14.5711Z"
                                                          fill="#FF9900"
                                                          stroke="#FF9900"
                                                          stroke-width="0.5"
                                                      />
                                                      <path
                                                          d="M9.05135 4.69922C7.09492 4.69922 5.50586 6.28828 5.50586 8.24471C5.50586 10.2011 7.09492 11.7902 9.05135 11.7902C11.0078 11.7902 12.5968 10.2011 12.5968 8.24471C12.5968 6.28828 11.0078 4.69922 9.05135 4.69922ZM9.05135 10.765C7.66306 10.765 6.53106 9.63301 6.53106 8.24471C6.53106 6.85642 7.66306 5.72442 9.05135 5.72442C10.4396 5.72442 11.5716 6.85642 11.5716 8.24471C11.5716 9.63301 10.4396 10.765 9.05135 10.765Z"
                                                          fill="#FF9900"
                                                          stroke="#FF9900"
                                                          stroke-width="0.4"
                                                      />
                                                      </svg>
                                                  </span>
                          <p>Check In</p>
                          <span v-if="selected_tab === 'check_in'">selected</span>
                        </a>
                      </div>
                    </div>
                    <div class="post-col">
                      <div class="post-option-select" :class="selected_tab === 'create_poll' ? 'selected-div' : ''"
                           @click="updateTab('create_poll')">
                        <a href="javascript:;">
                                                  <span class="post-option-icon">
                                                      <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                          <path
                                                              d="M17.6747 13.2417C17.1445 14.4955 16.3153 15.6002 15.2596 16.4595C14.2038 17.3187 12.9537 17.9063 11.6184 18.1707C10.2831 18.4352 8.90337 18.3685 7.59981 17.9766C6.29624 17.5846 5.10854 16.8793 4.14053 15.9223C3.17253 14.9653 2.4537 13.7857 2.04688 12.4867C1.64006 11.1877 1.55765 9.8088 1.80685 8.47059C2.05605 7.13238 2.62926 5.87559 3.47639 4.81009C4.32351 3.74459 5.41875 2.90283 6.66635 2.3584"
                                                              stroke="#EE3F3F"
                                                              stroke-width="1.5"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                          />
                                                          <path
                                                              d="M18.3333 10.0003C18.3333 8.90598 18.1178 7.82234 17.699 6.8113C17.2802 5.80025 16.6664 4.88159 15.8926 4.10777C15.1187 3.33395 14.2001 2.72012 13.189 2.30133C12.178 1.88254 11.0943 1.66699 10 1.66699V10.0003H18.3333Z"
                                                              stroke="#EE3F3F"
                                                              stroke-width="1.5"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                          />
                                                      </svg>
                                                  </span>
                          <p>Create Poll</p>
                          <!-- <span v-if="selected_tab === 'create_poll'">selected</span> -->
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ChooseAudience v-if="current_tab === 'sponsor_types'"/>
              <ColoredPost ref="colorPostComponent" v-if="current_tab === 'colored_post'" @createColoredPost="addPost"/>
              <FeelingActivity v-if="current_tab === 'feelings_activity'"/>
              <CreatePoll ref="pollComponent" v-if="current_tab === 'create_poll'"/>
              <UploadPhoto v-if="current_tab === 'upload_photos'"/>
              <UploadVideo v-if="current_tab === 'upload_video'"/>
              <CreatArticle v-if="current_tab === 'create_article'"/>
              <CheckIn v-if="current_tab === 'check_in'" />
              <div>
                <b-overlay :show="emoji" no-wrap @shown="onShown" @hidden="onHidden" style="top: 138px">
                  <template #overlay>
                    <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="text-center p-3"
                    >
                      <VEmojiPicker @select="selectEmoji" :emojiWithBorder="false" :emojiSize="26" :continuousList="true" :emojisByRow="6"/>
                    </div>
                  </template>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
        <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: -1"
             @click="toggleCreatePostModal"/>
      </div>
    </div>
  </div>

</template>
<script>
import ChooseAudience from './ChooseAudience.vue';
import ColoredPost from './ColoredPost.vue';
import FeelingActivity from './FeelingActivity.vue';
import CreatePoll from './CreatePoll.vue';
import UploadPhoto from './UploadPhoto.vue';
import UploadVideo from './UploadVideo.vue';
import CreatArticle from '../Article/CreateNewArticle.vue';
import MentionedAndTagBox from "@/components/Common/UiList/MentionedAndTagBox"
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { VEmojiPicker } from 'v-emoji-picker';
import {CREATE_POST_FORM_CREATE_VIDEO, SUCCESS} from '../../../store/types';
import {readURL} from '../../utils/Utils';
import CheckIn from './CheckIn.vue';
import company from "../../PageManager/Pages/Company/Company";
import GoogleMapAndLocation from "../../../components/Common/Google Location/GoogleMapAndLocation.vue";

require("vue-chat-emoji/dist/vue-chat-emoji.min.css");

export default {
  props: {
    check: Boolean,
    postCheck: Boolean,
    picture: String,
    global_type: {
      type: String,
      default: null
    },
    from_event: {
      type: Boolean,
      default: false
    },
    event_id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return ({
      current_tab: "",
      selected_tab: "",
      emoji: false,
      is_create_post: false,
      active_post_type: "",
      tabsCategories: {
        'upload_video': 'video',
        'upload_photos': 'image',
        'feelings_activity': 'activity',
        'colored_post': 'default',
        'create_poll': 'poll',
        'create_article': 'article',
        'check_in': 'activity'
      },
      content: null,
      checkingPage: null,
      pageUserLinks: [],
      pageNameLength:[],
    });
  },
  computed: {
    ...mapGetters({
      form_data: "create_post_form_map_to_props",
      "user": "user_map_to_props"
    }),
    post_value() {
      const {form_data} = this;
      return form_data && form_data.post_value ? form_data.post_value : "";
    },
    video_name() {
      const {form_data} = this;
      return form_data && form_data.video_name ? form_data.video_name : "";
    },
    error_message() {
      return this.$store.getters.getPostError
    },
    errors() {
      const {form_data} = this;
      return form_data && form_data.errors && form_data.errors.length ? form_data.errors : [];
    },
    loading() {
      return this.$store.getters.getLoadingState
    },
    //newPostContent

    postContent: {
      set(newValue) {
        let payload = {
          content: newValue,
          links: this.pageUserLinks,
          lengthName: this.pageNameLength
        }
        this.$store.commit('SET_CONTENT', payload)
      },
      get() {
        return this.$store.getters.getContent
      }
    }
  },
  components: {
    MentionedAndTagBox,
    ChooseAudience,
    ColoredPost,
    FeelingActivity,
    CreatePoll,
    UploadPhoto,
    UploadVideo,
    CreatArticle,
    VEmojiPicker,
    CheckIn,
    GoogleMapAndLocation
  },
  mounted() {
    this.checkingPage = this.global_type ? this.global_type : '';
    this.$store.commit('SET_POST_ERROR', null);
  },
  methods: {
    ...mapActions([
      "create_post",
      "create_poll",
      "create_album",
      "create_video",

      "reset_color_post",
      "reset_upload_photo",
      "reset_create_poll",
      "reset_upload_video",
      "addSearchHistoryCityContry",
      "getSearchHistoryCityContry",
    ]),
    ...mapMutations([
      "updateCreatePostFormData",
      "resetPostState",
    ]),
    toggleEmoji() {
      this.emoji = !this.emoji
    },
    updateTab(value) {
      if (value === "feelings_activity") return this.current_tab = value;

      if (this.selected_tab === value) {
        switch (value) {
          case "upload_photos":
            this.selected_tab = "";
            return this.reset_upload_photo();
          case "upload_video":
            this.selected_tab = "";
            return this.reset_upload_video();
          case "colored_post":
            this.selected_tab = "";
            return this.reset_color_post();
          case "check_in":
            this.selected_tab = "";
            break;
          case "create_poll":
            this.selected_tab = "";
            return this.reset_create_poll();
          default:
            break;
        }
      }

      if (this.selected_tab) return;

      this.selected_tab = value;
      this.current_tab = value;
    },
    goBack() {
      this.current_tab = "";
      this.selected_tab = "";
    },
    onChange(e) {
      const {name, value} = e.target || {};

      this.updateCreatePostFormData({
        [name]: value
      });
    },
    onShown() {
      this.$refs.dialog.focus()
    },
    onHidden() {
      this.$refs.Input.focus()
    },
    selectEmoji(emoji) {
      const {data, key} = emoji || {};
      this.content = (this.content) ? `${this.content} ${data}` : ` ${data}`
    },
    async addPost() {
      this.emoji = false;
      try {
        if (this.current_tab === 'create_poll') {
          this.$refs.pollComponent.savePollOptionInStore()
        }
        if (this.current_tab === 'colored_post') {
          this.$refs.colorPostComponent.saveContentToStore()
        }

        if (this.validatePost()) {
          if (this.from_event && this.event_id) {
            // For event posts, don't set category here since it's handled in the store
            await this.$store.dispatch('post', { 
              event_id: this.event_id
            });
          } else if (this.checkingPage === 'page') {
            await this.$store.dispatch('post', { 
              global_type: this.global_type, 
              page_id: this.$route.params.id, 
              announcement: this.announcement 
            });
          } else {
            await this.$store.dispatch('post', {});
          }

          this.$emit('getTrending');
          if (this.check) {
            this.$emit('updateMediaTab');
          }
          this.toggleCreatePostModal();
          await this.$store.dispatch('clearData');
        }
      } catch (e) {
        console.error('Post error:', e);
      }
    },
    validatePost() {
      this.postContent = this.content
      this.$store.commit('SET_POST_ERROR', null)
      let validated = true
      if (!(this.postContent) && this.current_tab === '') {
        this.$store.commit('SET_POST_ERROR', 'Please Write something')
        validated = false
      } else {
        switch (this.current_tab) {
          case "upload_photos":
            if (!(this.$store.getters.getPictures.length > 0)) {
              this.$store.commit('SET_POST_ERROR', 'Please Select some Pictures')
              validated = false
              break
            }
            if (this.$store.getters.getType === 'album' && !(this.$store.getters.getAlbumTitle)) {
              this.$store.commit('SET_POST_ERROR', 'Please write some Album title ')
              validated = false
              break
            }
            break
          case "upload_video":
            if (!(this.$store.getters.getVideos.length > 0)) {
              this.$store.commit('SET_POST_ERROR', 'Please Select some Video')
              validated = false
              break
            }
            break
          case "create_poll":
            if (!(this.$store.getters.getPollOptions.length > 0)) {
              this.$store.commit('SET_POST_ERROR', 'Please add some poll')
              validated = false
              break
            }
            break
          case "feelings_activity":
            if (!(this.$store.getters.getType)) {
              this.$store.commit('SET_POST_ERROR', 'Please Select some Activity')
              validated = false
              break
            }
            if (!(this.$store.getters.getTypeDescription)) {
              this.$store.commit('SET_POST_ERROR', 'Please Add feeling')
              validated = false
              break
            }
            break
          case "colored_post":
            // if (!(this.$store.getters.getContent)) {
            //   this.$store.commit('SET_POST_ERROR', 'Write some text')
            //   validated = false
            //   break
            // }
            if (!(this.$store.getters.getShortContent)) {
              this.$store.commit('SET_POST_ERROR', 'Write some  Color text')
              validated = false
              break
            }
            if (!(this.$store.getters.getTypeDescription)) {
              this.$store.commit('SET_POST_ERROR', 'Please select some color ')
              validated = false
              break
            }

            break
          default:
            break;
        }
      }
      if (validated) {
        this.content = null

      }
      return validated
    },
    toggleCreatePostModal() {
      this.is_create_post = !this.is_create_post;
      this.content = null
      this.current_tab = "";
      this.selected_tab = "";
      this.$store.commit('SET_POST_ERROR', null);
      this.addPhoto = false;
      this.resetPostState();
    },
    // toggleAddPhotoModal() {
    //   this.is_create_post = !this.is_create_post;
    //   this.current_tab = "upload_photos";
    //   this.selected_tab = "upload_photos";
    //   this.addPhoto = false;
    //   this.resetPostState();
    //   this.updateTab('upload_photos')
    // },
    async openNewArticle() {
      if(this.fromUserProfile){
        this.$emit('OpenCreateArticle')
        this.toggleCreatePostModal()
        await this.$store.dispatch('clearData')
        return
      }
      let subType = ''
      if (this.global_type === 'page') {
        if (this.page_type === 'clubroom') {
          subType = 'club'
        } else if (this.page_type === 'partyroom') {
          subType = 'party'
        } else if (this.page_type === 'artist') {
          subType = 'artist'
        } else {
          subType = 'company'
        }
        this.$router.push(`/home/page/create-new-article/${this.global_type}/${subType}/${this.$route.params.id}`);
      } else{
        this.$router.push("/home/page/create-new-article");
      }
    },
    async updateVideo(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;

      if (!file) return;

      const data = await readURL(file);

      this.updateCreatePostFormData({
        [CREATE_POST_FORM_CREATE_VIDEO]: {
          video: data,
          video_name: file.name
        }
      })
    },
    setPageUserLinks(links, length){
      this.pageUserLinks = []
      this.pageNameLength = []
      this.pageUserLinks = [...this.pageUserLinks, ...links]
      this.pageNameLength = [...this.pageNameLength, ...length]
    },
    async getLocation(data) {
      this.user_location = data.locationName;
      this.$store.commit("SET_TYPE_DESCRIPTION", this.user_location);
      this.$store.commit("SET_TYPE", "check_in");
    },
  },
  watch: {
    current_tab(value) {
      if (value === '') {
        this.$store.commit('SET_CATEGORY', 'default')
        this.$store.dispatch('clearData')
      } else {
        this.$store.commit('SET_CATEGORY', this.tabsCategories[value])
      }
    },
    is_create_post() {
      if(!this.is_create_post) {
        this.emoji = false
      }
    }
  }
};
</script>
<style>
  .Signup_pop_overlay{
    z-index: 1000 !important;
  }
</style>
<style scoped lang="scss">
.post-something-field {
  input {
    border-radius: 8px!important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
    margin: 1.75rem auto;
  }
}
.modal {
  .modal-dialog {
    width: 571px;
  }
}
</style>
