<template>
  <div class="container-fluid sale-items m-t-50">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="title-h4"></h4>
      </div>
    </div>
    <div class="row ">
      <div class="col-sm-2 js-container-hover grid_charts" v-for="n in 18" :key="n">
        <a href="#"> </a>
        <div class="card slider shop-selling">
          <a href="#">
            <div class="card-cover">
              <div :class="['cover chart-item', 'color-'+n]">
                <div class="top-text-chart">TOP 100</div>
                <div class="center-text">Charts</div>
                <div class="bottom-text">UK</div>
              </div>
            </div>
          </a>
          <div class="card-description-chart">
            <a href="#"> </a>
            <div class="title marquee js-marquee">
              <a href="#" style="left: 0px;"> </a>
              <div>
                <a href="#" style="left: 0px;"> </a>
                <a class="top-heading-chart" href="#" style="left: 0px;">Top 100: United Kingdom</a>
              </div>
            </div>
            <div class="description marquee js-marquee">
              <div>
                <a class="top-subheading-chart" href="#" style="left: 0px;">
                  Playy Music
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import ListOption from './ListOption'
export default {
  data() {
    return {
      optionWidth: 0,
      isHovering: false,
    }
  },
  components: {
    //   ListOption
  },
  computed: {},
  methods: {
    isHoveringFun: function (val, index) {
      this.songList[index].isHovering = val;
    }
  }
}
</script>
