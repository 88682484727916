<template>
<div class="js-container-hover grid_charts country-card">
  <div class="card slider shop-selling">
    <a  class="cursor-pointer" @click="redirectToChannel('country','top-100-by-country',country.name,country.id,type)">
      <div class="card-cover">
        <div :class="['cover chart-item', 'color-'+colorCode]">
          <div class="top-text-chart">TOP 100</div>
          <div class="center-text">Charts</div>
          <div class="bottom-text">{{country.name | setCountryNameShort}}</div>
        </div>
      </div>
    </a>
    <div class="card-description-chart">
      <a href="#"> </a>
      <div class="title marquee js-marquee">
        <a href="#" style="left: 0px;"> </a>
        <div>
          <a href="#" style="left: 0px;"> </a>
          <a class="top-heading-chart" href="#" style="left: 0px;">Top 100: {{country.name}}</a>
        </div>
      </div>
      <div class="description marquee js-marquee">
        <div>
          <a class="top-subheading-chart" href="#" style="left: 0px;">
            Playy Music
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ChartCountryTile",
  props:{
    colorCode:{
      type:Number,
      Default:0
    },
    country:{
      type:Object,
      default () {
        return {
          name: 'Global',
          id: 'global'
        }
      }
    },
    type:{
      type:String,
      default:'songs'
    }
  },
  filters:{
    setCountryNameShort(val){
      if(val.includes("United Kingdom")){
       return "UK"
      }
      else if(val.includes("United States")){
       return "USA"
      }
      else{
        return val
      }
    }
  },
  methods:{
    redirectToCountryData(){
      this.$router.push('/music/page/chart-top-selling')
    }
  }
}
</script>

<style scoped lang="scss">
.bottom-text{
  text-transform: uppercase;
}
</style>
