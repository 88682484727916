<template>
  <div class="Signup_pop_overlay" v-if="modelShow">
    <div
      class="modal fade login-type show"
      id="upload-profile-and-cover"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style="display: block;"
      aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup body">
            <div class="work-experience-heading">
              <!-- picture -->
              <h1>Upload {{ (picType === "profile" || picType === "page_profile") ? "Image" : "Cover" }}</h1>
              <div class="close-popup-btn" v-on:click="closeModel">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="svg-color"
                    d="M13.5 4.5L4.5 13.5"
                    stroke="#47505B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    class="svg-color"
                    d="M4.5 4.5L13.5 13.5"
                    stroke="#47505B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
              <div
                v-if="success_message"
                class="alert alert-success"
                role="alert"
              >
                {{ success_message }}
              </div>
              <div class="row">
                <div v-if="!profilePreview" class="col-12">
                  <div class="row image_modal_main_div">
                    <div class=" image_modal mb-3 mt-2" v-if="!imageCheck" :class="(imageCheck) ?'image_modal_other':'col-6 image_modal_profile'">
                      <button
                        :class="
                          isSelected
                            ? 'w-100 d-flex align-items-center justify-content-center active'
                            : 'w-100 d-flex align-items-center justify-content-center'
                        "
                        :disabled="imageCheck"
                        @click="getSelectedPhotos"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 3.8C1 3.05739 1.295 2.3452 1.8201 1.8201C2.3452 1.295 3.05739 1 3.8 1H12.2C12.9426 1 13.6548 1.295 14.1799 1.8201C14.705 2.3452 15 3.05739 15 3.8V12.2C15 12.9426 14.705 13.6548 14.1799 14.1799C13.6548 14.705 12.9426 15 12.2 15H3.8C3.05739 15 2.3452 14.705 1.8201 14.1799C1.295 13.6548 1 12.9426 1 12.2V3.8Z"
                            stroke="#47505B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.54883 7.30005C6.51533 7.30005 7.29883 6.51655 7.29883 5.55005C7.29883 4.58355 6.51533 3.80005 5.54883 3.80005C4.58233 3.80005 3.79883 4.58355 3.79883 5.55005C3.79883 6.51655 4.58233 7.30005 5.54883 7.30005Z"
                            stroke="#47505B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.76703 8.43471L3.79883 15H12.2919C13.0098 15 13.6984 14.7148 14.206 14.2072C14.7136 13.6995 14.9988 13.011 14.9988 12.2931V12.2C14.9988 11.8738 14.8763 11.7485 14.6558 11.507L11.8348 8.43051C11.7034 8.28709 11.5434 8.17265 11.3652 8.09449C11.1871 8.01633 10.9946 7.97617 10.8 7.97657C10.6054 7.97696 10.4131 8.01791 10.2352 8.09679C10.0574 8.17567 9.89792 8.29076 9.76703 8.43471V8.43471Z"
                            stroke="#47505B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span class="ml-2">SELECT</span>
                      </button>
                    </div>
                    <div class=" image_modal mb-3 mt-2" :class="imageCheck ?'col-12 image_modal_other':'col-6 image_modal_profile'">
                      <input
                        type="file"
                        ref="profileInput"
                        accept="image/*"
                        @change="previewProfile"
                        class="d-none"
                        :id="uploadInputId"
                      />
                      <button
                        class="w-100 d-flex align-items-center justify-content-center"
                        @click="
                          $refs.profileInput.click(), (isSelected = false)
                        "
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.4375 8.6626C0.553532 8.6626 0.664812 8.70869 0.746859 8.79074C0.828906 8.87279 0.875 8.98407 0.875 9.1001V11.2876C0.875 11.5197 0.967187 11.7422 1.13128 11.9063C1.29538 12.0704 1.51794 12.1626 1.75 12.1626H12.25C12.4821 12.1626 12.7046 12.0704 12.8687 11.9063C13.0328 11.7422 13.125 11.5197 13.125 11.2876V9.1001C13.125 8.98407 13.1711 8.87279 13.2531 8.79074C13.3352 8.70869 13.4465 8.6626 13.5625 8.6626C13.6785 8.6626 13.7898 8.70869 13.8719 8.79074C13.9539 8.87279 14 8.98407 14 9.1001V11.2876C14 11.7517 13.8156 12.1968 13.4874 12.525C13.1592 12.8532 12.7141 13.0376 12.25 13.0376H1.75C1.28587 13.0376 0.840752 12.8532 0.512563 12.525C0.184374 12.1968 0 11.7517 0 11.2876V9.1001C0 8.98407 0.0460936 8.87279 0.128141 8.79074C0.210188 8.70869 0.321468 8.6626 0.4375 8.6626Z"
                            fill="#47505B"
                            stroke="#47505B"
                            stroke-width="0.5"
                          />
                          <path
                            d="M6.6908 1.0028C6.73144 0.962057 6.77972 0.929732 6.83287 0.907676C6.88603 0.885621 6.94301 0.874268 7.00055 0.874268C7.0581 0.874268 7.11508 0.885621 7.16823 0.907676C7.22138 0.929732 7.26966 0.962057 7.3103 1.0028L9.9353 3.6278C10.0175 3.70995 10.0636 3.82137 10.0636 3.93755C10.0636 4.05373 10.0175 4.16515 9.9353 4.2473C9.85315 4.32945 9.74173 4.3756 9.62555 4.3756C9.50937 4.3756 9.39795 4.32945 9.3158 4.2473L7.43805 2.36867V10.0626C7.43805 10.1786 7.39196 10.2899 7.30991 10.3719C7.22786 10.454 7.11658 10.5001 7.00055 10.5001C6.88452 10.5001 6.77324 10.454 6.69119 10.3719C6.60915 10.2899 6.56305 10.1786 6.56305 10.0626V2.36867L4.6853 4.2473C4.64463 4.28798 4.59634 4.32024 4.54319 4.34226C4.49004 4.36427 4.43308 4.3756 4.37555 4.3756C4.31803 4.3756 4.26106 4.36427 4.20792 4.34226C4.15477 4.32024 4.10648 4.28798 4.0658 4.2473C4.02513 4.20662 3.99286 4.15833 3.97084 4.10519C3.94883 4.05204 3.9375 3.99508 3.9375 3.93755C3.9375 3.88002 3.94883 3.82306 3.97084 3.76991C3.99286 3.71677 4.02513 3.66848 4.0658 3.6278L6.6908 1.0028Z"
                            fill="#47505B"
                            stroke="#47505B"
                            stroke-width="0.5"
                          />
                        </svg>
                        <span class="ml-2">UPLOAD</span>
                      </button>
                    </div>
                    <div
                      class="col-12 image_modal_delete mb-3 px-1"
                      v-if="!!profileImage && !isSelected && !imageCheck"
                    >
                      <button
                        class="w-100 d-flex align-items-center justify-content-center"
                        @click="deleteImage(picType)"
                      >
                        <span class="ml-2">DELETE</span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="text-center mb-3 d-flex justify-content-center"
                    v-if="spinner"
                  >
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                  <div class="" v-if="isSelected && !spinner">
                    <div class="image_modal_view">
                      <ul>
                        <li v-for="(photo, index) in listData" :key="index">
                          <img
                            :src="photo.artwork_url"
                            alt="image"
                            @click="passSelectImageToPreview(photo.artwork_url)"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-else class="col-12">
                  <div class="next_step_btn  my-3 btnDiscard">
                    <button @click="previewCancel()" class="w-100">
                      Discard
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12" v-if="!!profileImage && showCropper">
                  <div v-if="picType === 'profile' || picType === 'page_profile'">
                    <cropper
                      :ref="'profile-cropper'+uploadInputId"
                      class="cropper"
                      :src="profileImage"
                      :stencil-props="{
                        aspectRatio: 1,
                      }"
                      @change="change"
                      :stencil-component="$options.components.Stencil"
                      :id="'profile-cropper-'+uploadInputId"
                    />
                  </div>
                  <div v-else>
                    <cropper
                      :ref="'cover-cropper'+this.uploadInputId"
                      class="cropper"
                      :src="profileImage"
                      :stencil-props="{
                        aspectRatio: 18 / 6,
                        movable: true,
                        resizable: true,
                      }"
                      @change="change"
                      :id="'cover-cropper-'+this.uploadInputId"
                    />
                  </div>
                </div>
                <span class="image-error" style="color: red">{{ imageError }}</span>
              </div>
              <div class="row popup_button">
                <div class="col-6 pl-0">
                  <div class="skip_btn work-cancel ">
                    <button v-on:click="closeModel">CANCEL</button>
                  </div>
                </div>
                <div class="col-6 pr-0">
                  <div class="next_step_btn ">
                    <button @click="getImageAndUpload()" :disabled="loading || profilePreview === null" :class="{'button-disabled' : !profilePreview }">
                      {{ loading ? "Please Wait..." : `UPDATE IMAGE` }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <full-page-loader :show="loading" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Stencil from "./cropperComponents/CircleStencil";
import {
  uploadProfileAndCover,
  addPost,
  getSelectedPhotos,
  getPageSelectedPhotos,
  DeleteUploadedProfileAndCover,
} from "../../../../apis/APIs";

export default {
  name: "uploadProfileAndCover",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Stencil,
  },
  props: {
    picType: {
      type: String,
      default: "profile",
    },
    show: {
      type: Boolean,
      default: false,
    },
    imageCheck: {
      type: Boolean,
      default: false,
    },
    coverImage: {
      type: Object,
      default: null,
    },
    media:{
      type: Object,
      default: null,
    },
    uploadInputId: {
      type: String,
      default: 'profilePicUpload',
    },
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    profileImage() {
      if (this.profilePreview) {
        return this.profilePreview;
      }
      if (this.picType === "profile") {
        if (this.media) {
          return this.media.original_url;
      }
        else if (this.user.user_data && this.user.user_data.profile_photo) {
          return this.user.user_data.artwork_url;
        }
      } else if (this.coverImage && this.coverImage.image) {
        if ((this.picType === "cover" || this.picType === 'page_profile') && this.coverImage.defaultImage) {
          return this.coverImage.image;
        }
      } else {
        if (this.user.user_data && this.user.user_data.cover_photo) {
          return this.user.user_data.cover_photo_url;
        }
      }

      return false;
    },
    modelShow: {
      set(newValue) {
        this.$emit("profileAndCoverValueChange", newValue);
      },
      get() {
        return this.show;
      },
    },
    showCropper() {
      return this.$store.getters.getCropperState;
    },
  },
  data() {
    return {
      profilePreview: null,
      image: null,
      message: "",
      success_message: "",
      loading: false,
      file_name: "",
      listData: [],
      spinner: false,
      isSelected: false,
      imageError: "",
    };
  },
  watch: {
    show(value) {
      if(value)
        this.previewCancel()
    },
  },
  methods: {
    change({ coordinates, canvas }) {
      // console.log(coordinates, canvas);
    },
    previewProfile: function(event) {
      this.$store.commit("SET_CROPPER", true);
      let input = event.target;
      var index = 0;
      this.file_name = input.files[0].name;
      if (input.files && input.files[index].size < 2000000) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setTimeout(() => {
            this.profilePreview = e.target.result;
          }, 0);

        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.imageError = "";
      } else if (input.files[0].size > 2000000) {
        this.imageError = "Image size should be less than 2 MB";
      }
    },
    previewCancel() {
      this.isSelected = false;
      this.profilePreview = false;
      this.image = null;
      if (this.imageCheck === true) {
        this.$store.commit("SET_CROPPER", false);
      }
    },
    async getImageAndUpload() {
      try {
        this.loading = true;
        const { canvas } =
          (this.picType === "profile" || this.picType === "page_profile")
            ? this.$refs["profile-cropper"+this.uploadInputId].getResult()
            : this.$refs["cover-cropper"+this.uploadInputId].getResult();
        if (canvas) {
          canvas.toBlob((blob) => this.upload(blob), "image/jpeg");
        }
      } catch (error) {
        this.loading = false;
        console.error(error);
        this.profilePreview = null;
        this.image = null;
      }
    },
    async upload(image) {
      console.log('hey');
      try {
        this.loading = true;
        const form = new FormData();
        this.picType !== "profile"
          ? form.append("cover_photo", image)
          : form.append("artwork", image);
        if (this.imageCheck) {
          this.returnImage(image, this.isSelected);
          this.loading = false;
          this.profilePreview = null;
          this.image = null;
          this.file_name = null;
          this.modelShow = false;
          this.$store.commit("SET_CROPPER", false);
          this.isSelected = false;
          // await this.makeToast('success', `${(this.picType !== 'profile') ? "Cover " : "Image"} updated!`);
          return;
        }
        const { data } = await uploadProfileAndCover(form);
        this.loading = false;
        const { success, message, information } = data || {};
        if (success === 1) {
          this.profilePreview = null;
          this.image = null;
          this.modelShow = false;
          await this.$store.dispatch("set_user_data", information);
          await this.makeToast(
            "success",
            `${this.picType !== "profile" ? "Cover " : "Profile"} updated!`,
            `${
              this.picType !== "profile" ? "Cover " : "Profile "
            } uploaded successfully!`
          );
          this.loading = false;
          if (!this.isSelected) {
            await this.uploadPost(image);
          }
          this.$store.commit("SET_CROPPER", true);
          this.isSelected = false;
        } else {
          await this.notificationToast(true, 'Error', message, 5000, 'error')
        }
      } catch (error) {
        this.loading = false;
        console.error(error);
        await this.notificationToast(true, 'Error', error.message, 5000, 'error')
        this.profilePreview = null;
        this.image = null;
      }
    },
    async uploadPost(image) {
      try {
        const form = new FormData();
        form.append("artwork[" + 0 + "]", image);
        form.append("full_content", null);
        form.append("category", "image");
        form.append("access", "public");
        this.picType !== "profile"
          ? form.append("post_type", "cover_photo")
          : form.append("post_type", "profile_photo");
        const { data } = await addPost(form);
        this.loading = false;
        const { success, message, information } = data || {};
        if (success === 1) {
          this.$store.commit("ADD_POST_IN_POSTS", information);
        } else {
          console.error(message, "Error in creating post");
        }
      } catch (error) {
        console.error(error, "Error in creating post");
      }
    },
    closeModel() {
      this.profilePreview = null;
      this.image = null;
      this.modelShow = false;
      this.listData = [];
      this.isSelected = false;
      this.$store.commit("SET_CROPPER", true);
    },
    percentsRestriction() {
      return {
        minWidth: 1000,
        minHeight: 200,
        maxWidth: 1000,
        maxHeight: 200,
      };
    },
    returnImage(image, is_Selected) {
      this.$emit(
        "getImage",
        image,
        this.profilePreview,
        this.file_name,
        is_Selected
      );
    },
    passSelectImageToPreview(image) {
      this.$store.commit("SET_CROPPER", true);

      this.profilePreview = image;
    },
    async getSelectedPhotos() {
      try {
        this.$store.commit("SET_CROPPER", false);
        this.isSelected = true;
        this.spinner = true;
        if (this.picType !== "profile" && !this.imageCheck) {
          let data = await getSelectedPhotos("cover-photos", 0, 25);
          this.listData = data.data.information.data;
        } else if (this.imageCheck && this.coverImage.image) {
          if (this.picType === "cover") {
            const page_id = this.$route.params.id;
            const { data } = await getPageSelectedPhotos(
              "cover-photos",
              0,
              25,
              page_id
            );
            const { information } = data || {};
            this.listData = information.data;
          }
        } else {
          let data = await getSelectedPhotos("profile-pictures", 0, 25);
          this.listData = data.data.information.data;
        }
        this.spinner = false;
      } catch (error) {
        this.spinner = false;
        this.isSelected = false;
        this.$store.commit("SET_CROPPER", true);
        console.log(error.message);
      }
    },
    async deleteImage(type = "profile") {
      try {
        const result = await this.confirmationToast(
          "warning",
          `Are you sure you want to delete ${
            type !== "profile" ? "Cover " : "Profile"
          } photo!`,
          "You won't be able to revert this!",
          "Yes, delete it!",
          "#3085d6",
          "#d33"
        );
        if (result.isConfirmed) {
          await this.$store.dispatch("loading", true);
          const form = new FormData();
          type !== "profile"
            ? form.append("type", "cover_photo")
            : form.append("type", "profile_photo");
          if (this.imageCheck && this.coverImage.image) {
            const page_id = this.$route.params.id;
            form.append("page_id", page_id);
          }
          const { data } = await DeleteUploadedProfileAndCover(form);
          const { success, message, information } = data || {};
          if (success === 1) {
            this.profilePreview = null;
            this.image = null;
            this.modelShow = false;
            if (this.imageCheck && this.coverImage.image) {
              await this.$store.commit("SET_PAGE_DATA", information);
            } else {
              await this.$store.dispatch("set_user_data", information);
            }
            await this.$store.dispatch("loading", false);
            // await this.makeToast(
            //   "success",
            //   `${type !== "profile" ? "Cover " : "Profile"} Deleted!`,
            //   `${
            //     type !== "profile" ? "Cover " : "Profile "
            //   } Deleted successfully!`
            // );
            await this.notificationToast(true, `${type !== "profile" ? "Cover " : "Profile"} Deleted!`, `${
              type !== "profile" ? "Cover " : "Profile "
            } Deleted successfully!`, 5000, 'success')
          } else {
            await this.notificationToast(true, 'Error', message, 5000, 'error')
          }
        }
      } catch (error) {
        await this.$store.dispatch("loading", false);
        console.error(error);
        await this.notificationToast(true, 'Error', error.message, 5000, 'error')
        this.profilePreview = null;
        this.image = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/assets/style/home/profile/components/uploadProfileAndCover";
</style>
<style lang="scss" scoped>
.btnDiscard {
  button {
    border: 1px solid #D1433A !important;
    box-sizing: border-box !important;
    color: #D1433A;
    border-radius: 4px;
    background-color: #ffffff;
    &:hover{
      color: #ffffff;
      background-color:#D1433A;
    }
  }
}
.button-disabled {
  background: lightgrey!important;
  &:hover {
    background: lightgrey!important;
  }
}
</style>
