<script>
export default {
  name: "TabComponent",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
  },
  methods: {
    changeTabsAccordingType(tab) {
      this.$emit("changeTabsAccordingType", tab);
    },
    changeViewAccordingType(view) {
      this.$emit("changeViewAccordingType", view);
    },
  },
};
</script>

<template>
  <div>
    <div class="events-favorites-tabs-section">
      <div class="event-favorites-tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          @click="changeTabsAccordingType(tab.value)"
          :class="{ 'event-favorites-tab': true, active: activeTab === tab.value }"
        >
            <span :class="{ 'event-favorites-tab-title': true, active: activeTab === tab.value}">
              {{ tab.name }}
            </span>
          <span :class="{ 'event-favorites-tab-count': true , 'zero': tab.count === 0 }">
              {{ tab.count }}
            </span
            >
        </div>
      </div>
      <div class="event-favorites-views-btn">
          <span @click="changeViewAccordingType('grid')"
                class="event-favorites-views-btn-grid"
                :class="{ active: view === 'grid' }"
          >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 5.25V0.75H13.25V5.25H8.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square"/>
              <path d="M0.75 5.25V0.75H5.25V5.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square"/>
              <path d="M8.75 13.25V8.75H13.25V13.25H8.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square"/>
              <path d="M0.75 13.25V8.75H5.25V13.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
          </span>
        <span @click="changeViewAccordingType('list')"
              class="event-favorites-views-btn-list"
              :class="{ active: view === 'list' }"
        >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.75 4.25V0.75H13.25V4.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square"/>
              <path d="M0.75 13.25V9.75H13.25V13.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
          </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../assets/style/components/tabcomponent.scss";
</style>