<template>
  <div class="about-work about-section-page">
    <div class="row">
      <div class="col">
        <div class="about-work-text ">
          <h1 class="about-title fixAboutTitle">Partners</h1>
        </div>
      </div>
    </div> 
    <div class="mt-3 px-4" v-if="getPartners.length">
      <div  v-for="(partners, index) in getPartners" :key="index" class="row mb-3">
        <div class="col-12 d-flex">
          <div class="member_info partner">
            <div class="member_info_avatar">

              <img :src="(partners && partners.artwork_url) ? partners.artwork_url : require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
              <span class="member_status hint_green"></span>
            </div>
            <div class="flex-column d-flex justify-content-center">
              <div class="item-desc">
                <div class="name">{{(partners && partners.title) ? partners.title : 'Company name'}}</div>
                <div class="info">
                  <div>
                    <div class="svg-icon">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.9">
                          <path
                              d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        </g>
                      </svg>
                    </div>
                    {{(partners && partners.members) ? partners.members : '0000'}}
                  </div>
                  <div>
                    <div class="svg-icon">
                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.9">
                          <path
                              d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                              stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg>
                    </div>
                    {{(partners && partners.total_tracks) ? partners.total_tracks : '0000'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="partner_label ml-auto">
            {{(partners && partners.company_name) ? partners.company_name : 'Publishing company'}}
          </div>
          <div class="follow-icon">
            <div class="svg-icon">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-fill-1"
                      d="M1 8.00987C3.757 8.00987 6 10.2552 6 13.015C6 13.5676 6.448 14.0161 7 14.0161C7.552 14.0161 8 13.5676 8 13.015C8 9.15105 4.86 6.00781 1 6.00781C0.448 6.00781 0 6.45627 0 7.00884C0 7.56141 0.448 8.00987 1 8.00987Z"
                      fill="#D1433A"></path>
                <path class="c-fill-1"
                      d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                      fill="#D1433A"></path>
                <path class="c-fill-1"
                      d="M1 14.0157C1.55228 14.0157 2 13.5676 2 13.0147C2 12.4619 1.55228 12.0137 1 12.0137C0.447715 12.0137 0 12.4619 0 13.0147C0 13.5676 0.447715 14.0157 1 14.0157Z"
                      fill="#D1433A"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-3 px-4">
      <div>No partners available</div>
    </div>
  </div>
</template>

<script>
import partners from "../../Partners";

export default {
  name: "Partners",
  computed: {
    getPartners() {
      const data = this.$store.getters.getCurrentPagePartnerListing
      let Artist = data.Artist.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Artist'
        }
      })
      let Booking_Agent = data.Booking_Agent.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Booking Agent'
        }
      })
      let Distributor = data.Distributor.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Distributor'
        }
      })
      let Management = data.Management.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Management'
        }
      })
      let Publishing = data.Publishing.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Publishing'
        }
      })
      let Record_Label = data.Record_Label.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Record Label'
        }
      })
      let Sponsor = data.Sponsor.map((e) => {
        return {
          members: e.members,
          total_tracks: e.total_tracks,
          artwork_url: e.artwork_url,
          id: e.id,
          title: e.title,
          join_status: e.join_status,
          company_name: 'Sponsor'
        }
      })
      return [...Artist, ...Distributor, ...Booking_Agent, ...Management, ...Publishing, ...Record_Label, ...Sponsor]
    }
  }
}
</script>

<style scoped lang="scss">
.follow-icon {
  border: 1.3px solid #D1433A;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto!important;
  cursor: pointer;
  transition: all .3s
}
.follow-icon:hover .svg-icon .c-fill-1 {
  fill: #fff !important;
}
.follow-icon:hover {
  background-color: #D1433A;
  transition: all .3s;
}

.partner {
   .name {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: rgba(7, 21, 38, .9);
    opacity: .9;
    margin-bottom: 5px
  }

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(71, 80, 91, .9)
  }

   .info div {
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-direction: row
  }

   .svg-icon {
    margin-right: 4px
  }
}
</style>
