<template>
  <div class="row video-container">
    <div v-for="(videos,index) in listData" :key="index" class="col-md-6">
      <div class="video-list-section">
        <video :src="videos.artwork_url" controls></video>
        <!-- <div class="play-icons"><img :src="require(`@/assets/img/p.png`)"/></div> -->
      </div>
      <div class="video-content">
        <div class="v-description">
          <h1>{{videos.name}}</h1>
          <p>{{ formattedTimes[index] }}</p>
        </div>
        <div class="video-time">15:43</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
export default {
  name: "mediaVideosListing",
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    },
    totalMediaVideos: {
    }
  },
  watch: {
    listData () {
      this.getMediaVideosList();
    }
  },
  methods: {
    getMediaVideosList () {
      this.$emit('getMediaAlbums')
    }
  },
  computed : {
    formattedTimes() {
      return this.listData.map(video => moment(video.created_at).fromNow());
    },
  }
}
</script>

<style lang="scss" scoped>
.video-container{
  .video-list-section {
    video {
      width: 350px;
      height: 200px;
      border-radius: 2px;
    }
  }
}
</style>
