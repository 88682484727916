<template>
  <div class="wr_users">
    <div class="wr_search_sector wrSearchUsersHvr">
      <div class="input_div">
        <input type="text" placeholder="Search for people to message" v-model="search" @input="searchFriend" />
      </div>
      <div class="wr_search_users">
        <perfect-scrollbar>
          <div
            v-for="(item, index1) in searchUsers"
            :key="index1"
            class="item_serch"
            @click="selectAndUnSelectUser(item)"
          >
            <div class="search_users">
              <div class="div_img">
                <img :src="(item && item.image) ? item.image : ''" alt="" />
                <div class="active_user"></div>
              </div>
              <div>{{ item.name }}</div>
            </div>
            <div v-if="checkIfUserIsSelected(item)">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.09581 0.176061C8.35756 0.421737 8.3706 0.833088 8.12492 1.09484L3.43197 6.09484C3.30912 6.22573 3.13761 6.29998 2.95809 6.3C2.77858 6.30002 2.60705 6.2258 2.48418 6.09493L0.177128 3.63794C-0.068602 3.37624 -0.055655 2.96489 0.206046 2.71916C0.467746 2.47343 0.8791 2.48638 1.12483 2.74808L2.95793 4.70032L7.17704 0.205165C7.42271 -0.0565852 7.83406 -0.0696158 8.09581 0.176061Z"
                  fill="#D1433A"
                />
              </svg>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="wr_selected">
      <div class="title_selected">
        <h5>SELECTED</h5>
        <span>{{ selectedUser.length }}</span>
      </div>
      <div class="wr_selected_user">
        <perfect-scrollbar>
          <div v-for="(item, index2) in selectedUser" :key="index2" class="user">
            <div class="div_img">
              <img :src="(item && item.image) ? item.image : ''" alt="" />
              <div class="active_user"></div>
            </div>
            <div>{{ item.name }}</div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedUser: [],
      searchUsers: [],
      search:"",
      selectedUserIds:[],
      notAddedMemberList: []
    };
  },
  watch: {
    'getMyFriendsList': {
      immediate: true,
      handler(newVal) {
        if (this.getActiveAllGroupMembers && this.getAddMemberCheck) {
          this.setMembersWhichAreNotPresentInArray()
        } else {
          this.searchUsers = newVal
        }
      }
    },
  },
  computed: {
    getMyFriendsList() {
      return this.$store.getters.getGetStreamFriends
    },
    getActiveAllGroupMembers() {
      return this.$store.getters.getActiveAllGroupMembers.members;
    },
    getAddMemberCheck() {
      return this.$store.getters.getAddMemberCheck
    },
    getActiveGroup() {
      return this.$store.getters.getActiveGroup;
    },
    getActiveChatUser() {
      return this.$store.getters.getActiveFullChatUser;
    }
  },
  mounted() {
    this.setUserForGroupCreation()
  },
  methods: {
    selectAndUnSelectUser(user) {
      if (!this.checkIfUserIsSelected(user)) {
        this.selectedUser.push(user)
      } else {
        if ((this.getAddMemberCheck && this.getActiveChatUser && this.getActiveChatUser.id !== user.id) || (!this.getAddMemberCheck && !this.getActiveChatUser)) {
          const index = this.selectedUser.findIndex(e => e.id === user.id)
          this.selectedUser.splice(index, 1)
        }
      }
    },
    checkIfUserIsSelected(user) {
      return this.selectedUser.some(e => e.id === user.id)
    },
    searchFriend(){
      let searchWord = this.search
      let friends = []
      if (this.getActiveAllGroupMembers && this.getAddMemberCheck) {
        friends = this.notAddedMemberList
      } else {
        friends = this.getMyFriendsList
      }
      this.searchUsers = friends.filter(o => o.name.toLowerCase().includes(searchWord.toLowerCase()))
    },
    setUserInfo() {
      let success=0

      if(this.selectedUser.length > 0)
      {
        this.selectedUserIds = this.selectedUser.map((el) => el.id);

        success=1
        const payload={
          room_users_ids:this.selectedUserIds,
        };
        this.$store.commit("SET_LAUNCH_ROOM_USERS", payload);
      }
      return success
    },
    setMembersWhichAreNotPresentInArray() {
      const membersIds = this.getActiveAllGroupMembers.map((e) => {
        return e.user_id
      })
      const members = this.getMyFriendsList.filter((e) => {
        return !(membersIds.some( x => x === e.id))
      })
      this.notAddedMemberList = members
      this.searchUsers = members
    },
    async addMembers() {
      if (this.getActiveGroup && this.selectedUser && this.selectedUser.length > 0) {
        const userIds = this.selectedUser.map((e) => {
          return e.id
        })
        await this.addMembersInGroup(this.getActiveGroup, userIds)
        this.$emit('closeModal')
      } else {
        // this.vueNotification('Add Member', 'Please select at least one member to add!', 'warning')
        await this.notificationToast(true, `Add Member`, `Please select at least one member to add!`, 5000, 'warning')
      }
    },
    setUserForGroupCreation() {
      if (this.getAddMemberCheck && Object.keys(this.getActiveChatUser).length !== 0) {
        const check = this.searchUsers.some(e => e.id === this.getActiveChatUser.id)
        if (check) {
          this.selectedUser.push(this.getActiveChatUser)
        } else {
          this.searchUsers.push(this.getActiveChatUser)
          this.selectedUser.push(this.getActiveChatUser)
        }
      }
    },
    getSelectedUsers() {
      return this.selectedUser.map((e) => {
        return e.id
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.wr_users {
  display: flex;
  .wr_search_sector {
    padding: 20px 13px 0 0;
    width: 54%;
    border-bottom: 1px solid #e6eaf0;
    .wr_search_users {
      height: 309px;
      .ps {
        height: 100%;
      }
    }
    .input_div {
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      width: 100%;
      padding: 0px 20px;
      height: 30px;
      font-size: 13px;
      font-family: HelveticaNeueNormal;
      margin-bottom: 10px;
      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        border-radius: 4px;
      }
    }
    .item_serch {
      padding: 0 20px 0 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6eaf0;
      align-items: center;
      .search_users {
        display: flex;
        align-items: center;
      }
      &:hover {
        background: #D1433A1A;
      }
    }
  }
  .wr_selected {
    width: 50%;
    background: #f5f6f7;
    overflow: hidden;
    padding: 25px 49px 0 41px;
    .title_selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 20px;
      h5 {
        color: #47505b;
        font-size: 12px;
        margin: 0;
      }
      span {
        width: 26px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D1433A;
        border-radius: 2px;
        color: #ffffff;
      }
    }
    .wr_selected_user {
      height: 307;
      padding-bottom: 20px;
      .ps {
        height: 100%;
      }
      .user {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }
  .div_img {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 9px;
    background: #c4c4c4;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    div {
      right: 0;
      bottom: 0;
      border: 1px solid #ffffff;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #1cec9e;
    }
  }
}
</style>
