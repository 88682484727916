<template>
  <div class="row">
    <div class="col-md-12 pt-3">
      <div class="birthday-a-card">
        <div class="birthday-card-body card-margin">
          <div class="col-md-12">
            <a>
              <svg
                v-if="icon === 'heart'"
                class="icon-center"
                width="58"
                height="50"
                viewBox="0 0 58 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.6609 5.48014L51.661 5.48031C52.9235 6.7419 53.9248 8.23965 54.608 9.88801L55.9937 9.31368L54.608 9.88801C55.2912 11.5364 55.6429 13.3031 55.6429 15.0872C55.6429 16.8714 55.2912 18.6381 54.608 20.2864C53.9248 21.9348 52.9235 23.4325 51.661 24.6941L51.6609 24.6942L48.7504 27.6032L28.4643 47.8792L8.17809 27.6032L5.27864 24.7052C2.82071 22.141 1.4646 18.717 1.5007 15.166C1.53686 11.61 2.96624 8.20968 5.48233 5.69486C7.99844 3.18001 11.4008 1.75106 14.9592 1.71492C18.5128 1.67883 21.9389 3.03454 24.5044 5.49128L27.4039 8.38926C27.9896 8.97469 28.9389 8.97469 29.5247 8.38926L32.4352 5.48022L32.4352 5.48014C33.6975 4.21834 35.1961 3.21738 36.8455 2.53445C38.4948 1.85151 40.2627 1.5 42.0481 1.5C43.8334 1.5 45.6013 1.85151 47.2507 2.53445C48.9 3.21738 50.3986 4.21834 51.6609 5.48014Z"
                  stroke="#47505B"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                v-if="icon === 'follow'"
                class="icon-center"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.65"
                  y="0.65"
                  width="48.7"
                  height="48.7"
                  rx="3.35"
                  stroke="#47505B"
                  stroke-width="1.3"
                />
                <path
                  class="st0"
                  d="M15.0007 26.6825C19.5957 26.6825 23.334 30.4247 23.334 35.0244C23.334 35.9453 24.0807 36.6928 25.0007 36.6928C25.9207 36.6928 26.6673 35.9453 26.6673 35.0244C26.6673 28.5844 21.434 23.3457 15.0007 23.3457C14.0807 23.3457 13.334 24.0931 13.334 25.0141C13.334 25.935 14.0807 26.6825 15.0007 26.6825Z"
                  fill="#47505B"
                />
                <path
                  class="st0"
                  d="M15.0007 16.6698C25.1098 16.6698 33.334 24.9024 33.334 35.022C33.334 35.9429 34.0807 36.6904 35.0007 36.6904C35.9207 36.6904 36.6673 35.9429 36.6673 35.022C36.6673 23.063 26.9482 13.333 15.0007 13.333C14.0807 13.333 13.334 14.0804 13.334 15.0014C13.334 15.9223 14.0807 16.6698 15.0007 16.6698Z"
                  fill="#47505B"
                />
                <path
                  class="st0"
                  d="M15.0007 36.6913C15.9211 36.6913 16.6673 35.9443 16.6673 35.0229C16.6673 34.1015 15.9211 33.3545 15.0007 33.3545C14.0802 33.3545 13.334 34.1015 13.334 35.0229C13.334 35.9443 14.0802 36.6913 15.0007 36.6913Z"
                  fill="#47505B"
                />
              </svg>
              <svg
                v-if="icon === 'play'"
                class="icon-center"
                width="60"
                height="54"
                viewBox="0 0 60 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 10.25C7.5 10.7473 7.69754 11.2242 8.04918 11.5758C8.40081 11.9275 8.87772 12.125 9.375 12.125H50.625C51.1223 12.125 51.5992 11.9275 51.9508 11.5758C52.3025 11.2242 52.5 10.7473 52.5 10.25C52.5 9.75272 52.3025 9.27581 51.9508 8.92418C51.5992 8.57254 51.1223 8.375 50.625 8.375H9.375C8.87772 8.375 8.40081 8.57254 8.04918 8.92418C7.69754 9.27581 7.5 9.75272 7.5 10.25ZM15 2.75C15 3.24728 15.1975 3.72419 15.5492 4.07583C15.9008 4.42746 16.3777 4.625 16.875 4.625H43.125C43.6223 4.625 44.0992 4.42746 44.4508 4.07583C44.8025 3.72419 45 3.24728 45 2.75C45 2.25272 44.8025 1.77581 44.4508 1.42417C44.0992 1.07254 43.6223 0.875 43.125 0.875H16.875C16.3777 0.875 15.9008 1.07254 15.5492 1.42417C15.1975 1.77581 15 2.25272 15 2.75ZM25.3687 23.66C25.0849 23.4826 24.7588 23.3844 24.4242 23.3756C24.0896 23.3669 23.7588 23.4478 23.4661 23.61C23.1733 23.7723 22.9293 24.0099 22.7595 24.2983C22.5896 24.5867 22.5 24.9153 22.5 25.25V44C22.5 44.3347 22.5896 44.6633 22.7595 44.9517C22.9293 45.2401 23.1733 45.4777 23.4661 45.64C23.7588 45.8022 24.0896 45.8831 24.4242 45.8744C24.7588 45.8656 25.0849 45.7674 25.3687 45.59L40.3688 36.215C40.6384 36.0465 40.8608 35.8121 41.015 35.5339C41.1691 35.2558 41.25 34.943 41.25 34.625C41.25 34.307 41.1691 33.9942 41.015 33.7161C40.8608 33.4379 40.6384 33.2035 40.3688 33.035L25.3687 23.66Z"
                  fill="#47505B"
                />
                <path
                  d="M5.625 53.375C4.13316 53.375 2.70242 52.7824 1.64752 51.7275C0.592632 50.6726 0 49.2418 0 47.75L0 21.5C0 20.0082 0.592632 18.5774 1.64752 17.5225C2.70242 16.4676 4.13316 15.875 5.625 15.875H54.375C55.8668 15.875 57.2976 16.4676 58.3525 17.5225C59.4074 18.5774 60 20.0082 60 21.5V47.75C60 49.2418 59.4074 50.6726 58.3525 51.7275C57.2976 52.7824 55.8668 53.375 54.375 53.375H5.625ZM54.375 49.625C54.8723 49.625 55.3492 49.4275 55.7008 49.0758C56.0525 48.7242 56.25 48.2473 56.25 47.75V21.5C56.25 21.0027 56.0525 20.5258 55.7008 20.1742C55.3492 19.8225 54.8723 19.625 54.375 19.625H5.625C5.12772 19.625 4.65081 19.8225 4.29917 20.1742C3.94754 20.5258 3.75 21.0027 3.75 21.5V47.75C3.75 48.2473 3.94754 48.7242 4.29917 49.0758C4.65081 49.4275 5.12772 49.625 5.625 49.625H54.375Z"
                  fill="#47505B"
                />
              </svg>
              <svg
                v-if="icon === 'coupon'"
                class="icon-center"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 37.9358L2.85561 38.7914L9.32621 45.2619L10.1818 46.1176L11.0375 45.2619C13.1741 43.1254 16.6015 43.1255 18.738 45.2619C20.8746 47.3986 20.8746 50.8259 18.738 52.9625L17.8823 53.8181L18.738 54.6737L25.2086 61.1443L26.0642 62L26.9198 61.1443L61.1445 26.9198L62.0001 26.0642L61.1445 25.2085L54.6738 18.7379L53.8182 17.8824L52.9626 18.7379C50.8261 20.8745 47.3987 20.8746 45.2621 18.7379C43.1256 16.6014 43.1255 13.174 45.2621 11.0374L46.1177 10.1818L45.2621 9.3262L38.7915 2.85563L37.9358 2L37.0802 2.85563L2.85561 37.0801L2 37.9358ZM5.42249 37.9358L27.2406 16.1176L29.8075 18.6845L31.5187 16.9732L28.9518 14.4064L37.9358 5.42246L42.7754 10.262C40.533 13.3342 40.781 17.6793 43.5508 20.4492C46.3206 23.219 50.6525 23.4533 53.7246 21.2112L58.5776 26.0642L49.5936 35.0481L47.0268 32.4813L45.3155 34.1925L47.8824 36.7593L26.0641 58.5775L21.2112 53.7246C23.4533 50.6525 23.219 46.3205 20.4492 43.5507C17.6794 40.7809 13.3342 40.533 10.262 42.7754L5.42249 37.9358ZM33.2834 22.1604L34.9412 23.8182L36.6524 22.1069L34.9946 20.4492L33.2834 22.1604ZM40.1818 29.0588L41.8396 30.7166L43.5508 29.0053L41.893 27.3476L40.1818 29.0588Z"
                  fill="#47505B"
                  stroke="#47505B"
                  stroke-width="2"
                />
              </svg>
              <svg v-if="icon === 'search'"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.0324 38.7899C47.6636 33.835 49.2899 27.6917 48.5861 21.5892C47.8823 15.4867 44.9002 9.87491 40.2366 5.87663C35.5729 1.87834 29.5715 -0.211598 23.4331 0.0249262C17.2947 0.261451 11.4719 2.807 7.1297 7.15229C2.78752 11.4976 0.246138 17.3222 0.0140065 23.4608C-0.218125 29.5994 1.87611 35.5992 5.87773 40.2601C9.87935 44.9209 15.4932 47.8989 21.5963 48.5983C27.6993 49.2978 33.8414 47.667 38.7937 44.0324H38.7899C38.9024 44.1824 39.0224 44.3249 39.1574 44.4636L53.5949 58.9011C54.2981 59.6048 55.252 60.0003 56.2467 60.0006C57.2415 60.001 58.1957 59.6062 58.8993 58.903C59.603 58.1999 59.9985 57.246 59.9988 56.2512C59.9992 55.2565 59.6044 54.3023 58.9012 53.5986L44.4637 39.1611C44.3296 39.0254 44.1855 38.9001 44.0324 38.7861V38.7899ZM44.9999 24.3749C44.9999 27.0834 44.4665 29.7654 43.43 32.2677C42.3935 34.7701 40.8742 37.0438 38.959 38.959C37.0438 40.8742 34.7701 42.3934 32.2678 43.4299C29.7655 44.4664 27.0835 44.9999 24.3749 44.9999C21.6664 44.9999 18.9844 44.4664 16.4821 43.4299C13.9798 42.3934 11.7061 40.8742 9.79087 38.959C7.87566 37.0438 6.35644 34.7701 5.31993 32.2677C4.28343 29.7654 3.74995 27.0834 3.74995 24.3749C3.74995 18.9048 5.92293 13.6587 9.79087 9.79081C13.6588 5.92287 18.9049 3.74989 24.3749 3.74989C29.845 3.74989 35.0911 5.92287 38.959 9.79081C42.827 13.6587 44.9999 18.9048 44.9999 24.3749Z"
                  fill="#47505B"
                />
              </svg>
            </a>
          </div>
          <div class="col-md-12 pt-3">
            <h6 class="birthday-h-font">
              {{ title }}
            </h6>
          </div>
          <div class="col-md-12 pt-2">
            <p class="birthday-p1-font">
              {{ text }}
            </p>
          </div>
          <div class="col-md-12 px-2 pt-3 text-center" >
            <button @click="redirectBack" class="birthday-card-btn width230" type="button">
              <span>{{ buttonText }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  name: "MyMusicEmptyCard",
  props: ["title", "text", "icon", "buttonText"],
  data() {
    return {};
  },
  methods:{
    redirectBack(){
      if(this.buttonText === 'EXPLORE EVENTS'){
        this.$router.push('/events/page/overview')
        return
      }
      this.$emit("redirectFunction")
    }
  }
};
</script>
<style lang="scss">
.birthday-a-card {
  .card-margin {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}
</style>
<style lang="scss" scoped>
.birthday-card-btn:hover{
  transition: all 0.3s;
  opacity: 0.8;
}
</style>
