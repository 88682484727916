<template>
  <div class="wr_EME_BasicInfo">
    <section class="w-770 events-container-info">
      <div class="left">
        <div class="left-title">
          <label for="name"
            ><p>Event Name</p>
            <input type="text" v-model="info.eventName" @input="requireName" />
            <p v-if="eventNameReq" class="required">This field is required.</p>
          </label>
          <label for="location"
            ><p>Location</p>
            <GoogleMapAndLocation @getLocation="getLocation" />
          </label>
        </div>
        <div class="left-date flex">
          <div class="flex">
            <div>
              <div class="start-date">
                <h4>Start Date</h4>
                <div
                  class="date-picker-container"
                  @click="showDatePicker('showS')"
                >
                  <p class="mb-0" v-if="info.startDate">
                    {{ isMoment(info.startDate) }}
                  </p>
                  <p class="mb-0" v-else>DD/MM/YYYY</p>
                  <date-picker
                    v-model="info.startDate"
                    type="date"
                    :show-week-number="false"
                    :open="show"
                    :confirm="true"
                    confirm-text="DONE"
                    @confirm="selectValue"
                  ></date-picker>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.60156 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M11.3984 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.10156 6.90039H13.9016"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
              <p v-if="startDateReq" class="required">
                This field is required.
              </p>
            </div>
            <div>
              <div class="start-time ml-10">
                <h4>Start Time</h4>
                <div
                  class="date-picker-container w-140"
                  @click="showSTime = !showSTime"
                >
                  <p class="mb-0" v-if="info.startTime">{{ info.startTime }}</p>
                  <p class="mb-0" v-else>Start Time</p>
                  <date-picker
                    v-model="info.startTime"
                    type="time"
                    format="hh:mm a"
                    value-type="format"
                    :open="showSTime"
                    :confirm="true"
                    confirm-text="DONE"
                    @confirm="showSTime = false"
                  ></date-picker>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <p v-if="startTimeReq" class="required ml-10">
                This field is required.
              </p>
            </div>
          </div>
          <div class="flex">
            <div>
              <div class="start-date">
                <h4>End Date</h4>
                <div
                  class="date-picker-container"
                  @click="showDatePicker('showE')"
                >
                  <p class="mb-0" v-if="info.endDate">
                    {{ isMoment(info.endDate) }}
                  </p>
                  <p class="mb-0" v-else>DD/MM/YYYY</p>
                  <date-picker
                    v-model="info.endDate"
                    type="date"
                    :show-week-number="false"
                    :open="showEDate"
                    :confirm="true"
                    confirm-text="DONE"
                    @confirm="showEDate = false"
                  ></date-picker>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.60156 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M11.3984 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.10156 6.90039H13.9016"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
              <p v-if="endDateReq" class="required">This field is required.</p>
            </div>
            <div>
              <div class="start-time ml-10">
                <h4>End Time</h4>
                <div
                  class="date-picker-container w-140"
                  @click="showETime = !showETime"
                >
                  <p class="mb-0" v-if="info.endTime">{{ info.endTime }}</p>
                  <p class="mb-0" v-else>End Time</p>
                  <date-picker
                    v-model="info.endTime"
                    type="time"
                    format="hh:mm a"
                    value-type="format"
                    :open="showETime"
                    :confirm="true"
                    :show-second="false"
                    confirm-text="DONE"
                    @confirm="showETime = false"
                  ></date-picker>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <p v-if="endTimeReq" class="required ml-10">
                This field is required.
              </p>
            </div>
          </div>
        </div>
        <div class="left-select flex">
          <div class="left-select-genre">
            <h4>Genre</h4>
            <div
              class="left-select-co"
              @click="genresPopup = !genresPopup"
              v-click-outside="hideGenresPopup"
            >
              <p v-if="info.selectedGenres.length < 1">Choose genre</p>
              <div v-else class="genres-select-wraper">
                <span v-for="item in info.selectedGenres" :key="item.id"
                  >{{ item.name }}
                  <svg
                    @click.stop="removeGenre(item.id)"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 2.5L2.5 7.5"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 2.5L7.5 7.5"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#47505B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div v-if="genresPopup" class="genres-popup">
                <perfect-scrollbar>
                  <div
                    class="item"
                    v-for="genre in genres"
                    :key="genre.id"
                    @click.stop="handleGenres(genre)"
                  >
                    {{ genre.name }}
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
            <p v-if="selectedGenresReq" class="required">
              This field is required.
            </p>
          </div>
          <div class="left-select-venue">
            <h4>Venue Type</h4>
            <div
              class="left-select-co"
              @click="venuePopup = !venuePopup"
              v-click-outside="hideVenue"
            >
              <p v-if="!info.selectedVenue">Choose Venue</p>
              <p v-else>{{ info.selectedVenue.title }}</p>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#47505B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                v-if="venuePopup"
                class="genres-popup"
                @click.stop="venuePopup = false"
              >
                <perfect-scrollbar>
                  <div
                    class="item"
                    v-for="venue in venues"
                    :key="venue.id"
                    @click="handleVenue(venue)"
                  >
                    {{ venue.title }}
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
            <p v-if="selectedVenueReq" class="required">
              This field is required.
            </p>
          </div>
        </div>
        <div class="left-editor">
          <h4>Description</h4>
          <!-- <ckeditor
            class="newEvent-editor"
            v-model="info.editorData"
            :config="editorConfig"
          ></ckeditor> -->
          <quill-editor ref="myTextEditor" v-model="info.editorData" :config="customToolbar">
          </quill-editor>
        </div>
        <button class="saveBtn blue_btn for_hover_blue_btn">
          SAVE CHANGES
        </button>
      </div>
    </section>
    <section class="w-370">
      <div class="right-cover">
        <h4>Cover</h4>
        <div class="upload">
          <!-- <div class="imgInfo">
              <h3>Your image must be:</h3>
              <ul>
                <li v-for="item in imgformat" :key="item">
                  <p>{{ item }}</p>
                </li>
              </ul>
            </div> -->
          <div v-if="!cover" class="square">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.1668 6.66667L10.0002 2.5L5.8335 6.66667"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 2.5V12.5"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input type="file" ref="cover" @change="handleCover" />
          </div>
          <div v-else class="square">
            <img :src="cover" alt="cover" />
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.417 1.08301L22.7503 5.41634L18.417 9.74967"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.25 11.917V9.75033C3.25 8.60105 3.70655 7.49885 4.5192 6.6862C5.33186 5.87354 6.43406 5.41699 7.58333 5.41699H22.75"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.58333 24.9167L3.25 20.5833L7.58333 16.25"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.75 14.083V16.2497C22.75 17.3989 22.2935 18.5011 21.4808 19.3138C20.6681 20.1265 19.5659 20.583 18.4167 20.583H3.25"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input type="file" ref="cover" @change="handleCover" />
          </div>
        </div>
      </div>
      <!-- <p v-if="imgCoverReq" class="required">This field is required.</p> -->
      <span class="image-error" style="color: red">{{ imageError }}</span>
      <div class="wr_Privacy_and_category">
        <div class="category">
          <p>Category</p>
          <div
            class="left-select-co"
            @click="eventPopup = !eventPopup"
            v-click-outside="hideCategory"
          >
            <p v-if="!info.selectedEvent">Choose Category</p>
            <p v-else>{{ info.selectedEvent.title }}</p>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#47505B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div
              v-if="eventPopup"
              class="genres-popup"
              @click.stop="eventPopup = false"
            >
              <perfect-scrollbar>
                <div
                  class="item"
                  v-for="event in eventTypes"
                  :key="event.id"
                  @click="handleEvent(event)"
                >
                  {{ event.title }}
                </div>
              </perfect-scrollbar>
            </div>
          </div>
          <p v-if="selectedEventReq" class="required">
            This field is required.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "/public/css/vue2-date-picker.scss";
import { mapActions } from "vuex";
// import CKEditor from "ckeditor4-vue";
import GoogleMapAndLocation from "@/components/Common/Google Location/GoogleMapAndLocation";
import mixin from "../../../../mixins/sweetAlert";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css';

export default {
  components: {
    DatePicker,
    // ckeditor: CKEditor.component,
    GoogleMapAndLocation,
    quillEditor
  },
  mixins: [mixin],
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      eventNameReq: false,
      // selectedArtistReq: false,
      gMapDataReq: false,
      selectedEventReq: false,
      startDateReq: false,
      startTimeReq: false,
      endDateReq: false,
      endTimeReq: false,
      selectedGenresReq: false,
      selectVenueReq: false,
      show: false,
      showSTime: false,
      showETime: false,
      showEDate: false,
      genresPopup: false,
      venuePopup: false,
      selectedVenueReq: false,
      // editorConfig: {},
      // cover: null,
      genres: [],
      venues: [],
      activePrivacy: {},
      eventArtist: false,
      eventPopup: false,
      eventTypes: [],
      // pageId: "",
      // pageImg: "",
      info: {
        eventName: "",
        page_id: "",
        selectedArtist: null,
        gMapData: null, //{}
        selectedEvent: null,
        startDate: null,
        startTime: null,
        endTime: null,
        endDate: null,
        selectedGenres: [],
        selectedVenue: null, //[]
        editorData: null,
      },
      // imgCoverReq: false,
      cover: null,
      // imgCover: null,
      // imgformat: [
      //   "Dimensions: 770px x 370px",
      //   "PNG or JPG format",
      //   "Maximum size: 1mb",
      //   "RGB format",
      // ],
      // asd: {
      imgCover: null,
      // },
      imageError: "",
    };
  },
  async created() {
    this.getGenres();
    this.getVenue();
    this.getETypes();
    await this.getMyPagesByType();
  },
  watch: {
    "info.startDate"() {
      this.startDateReq = false;
    },
    "info.startTime"() {
      this.startTimeReq = false;
    },
    "info.endDate"() {
      this.endDateReq = false;
    },
    "info.endTime"() {
      this.endTimeReq = false;
    },
  },
  methods: {
    ...mapActions([
      "getGenersType",
      "getVenueTypes",
      "getEventTypes",
      "getMyPagesByType",
    ]),
    handleCover(e) {
      if (e.target.files[0].size < 2000000) {
        this.imgCover = e.target.files[0];
        this.cover = URL.createObjectURL(this.imgCover);
        // this.imgCoverReq = false;
        this.imageError = "";
      } else {
        this.imageError = "Image size must be less than 2mb";
      }
    },
    handleArtWork() {
      // if (!this.imgCover) {
      //   this.imgCoverReq = true;
      // }

      this.$emit("setNewDataEvent", this.info);
    },
    requireName() {
      this.eventNameReq = false;
    },
    showDatePicker(payload) {
      if (payload === "showS") {
        this.show = !this.show;
      } else if (payload === "showE") {
        this.showEDate = !this.showEDate;
      }
    },
    // handleInformation() {
    //   let infoValid = Object.entries(this.info);
    //   infoValid.forEach((el) => {
    //     if (
    //       (el[1] === "" || el[1] === null || el[1].length < 1) &&
    //       el[0] != "editorData"
    //     ) {
    //       this[el[0] + "Req"] = true;
    //     }
    //   });
    //   this.$emit("setNewDataEvent", this.info);
    // },
    hideArtist() {
      this.eventArtist = false;
    },
    hideVenue() {
      this.venuePopup = false;
    },
    hideGenresPopup() {
      this.genresPopup = false;
    },
    hideCategory() {
      this.eventPopup = false;
    },
    async getGenres() {
      try {
        const { information } = await this.getGenersType();
        this.genres =
          information && information.length
            ? information.map((genre) => {
                return {
                  id: genre.id,
                  name: genre.name,
                };
              })
            : [];
      } catch (error) {
        console.error(error);
      }
    },
    async getVenue() {
      try {
        const { status, information } = await this.getVenueTypes();
        this.venues = information;
      } catch (error) {
        console.error(error);
      }
    },
    async getETypes() {
      try {
        const { status, information } = await this.getEventTypes();
        this.eventTypes = information;
      } catch (error) {
        console.error(error);
      }
    },
    getLocation(data) {
      this.gMapDataReq = false;
      this.info.gMapData = data;
    },
    selectValue() {
      this.show = false;
    },
    isMoment(date) {
      return moment(date).startOf("day").format("Do MMM ,YYYY");
    },
    handleGenres(genre) {
      if (this.info.selectedGenres.some((elem) => elem.id === genre.id)) {
        this.genresPopup = false;
        return;
      } else {
        this.info.selectedGenres.push(genre);
      }
      this.selectedGenresReq = false;
      this.genresPopup = false;
    },
    handleVenue(venue) {
      this.info.selectedVenue = venue;
      this.venuePopup = false;
      this.selectedVenueReq = false;
    },
    handlePrivacy(data) {
      this.activePrivacy = data;
    },
    handleEvent(event) {
      this.selectedEventReq = false;
      this.info.selectedEvent = event;
    },
    // handleArtist(title, img, id) {
    //   this.pageImg = img;
    //   this.info.selectedArtist = title;
    //   this.info.page_id = id;
    //   this.pageId = id;
    //   this.selectedArtistReq = false;
    // },
    removeGenre(id) {
      this.info.selectedGenres = this.info.selectedGenres.filter(
        (elem) => elem.id !== id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_Privacy_and_category {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 30px;
  p{
    margin-bottom: 7px;
  }
  .left-select-co {
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px 0 10px;
    cursor: pointer;
    position: relative;
    span,
    p {
      margin: 0;
      color: rgba(7, 21, 38, 0.6);
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
    }
    span {
      background: #e6eaf0;
      border-radius: 2px;
      min-width: 80px;
      height: 20px;
      padding: 0 7px 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 10px;
    }
    .genres-popup {
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
      border-radius: 4px;
      width: 100%;
      position: absolute;
      z-index: 999;
      padding: 10px 0;
      left: 0;
      top: 0;
      .ps {
        max-height: 310px;
      }
      .item {
        justify-content: space-between;
        color: #071526;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueNormal;
        padding: 7px 20px;
        transition: all 0.3s;
        &:hover {
          background: #D1433A1A;
          color: #D1433A;
        }
      }
    }
  }
}
.wr_EME_BasicInfo {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.required {
  font-size: 10px !important;
  color: red !important;
  font-family: HelveticaNeueBold;
}

.right-cover {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 30px;
  h4 {
    font-family: HelveticaNeueBold;
    font-weight: 700;
    font-size: 13px;
    color: #47505b;
  }
}

.upload {
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f7f7f7;
  justify-content: center;
  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    background: #D1433A1A;
    border: none;
    svg {
      position: absolute;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .imgInfo {
    margin-bottom: 20px;

    h3 {
      font-family: HelveticaNeueMedium;
      color: #071526;
      font-size: 13px !important;
      margin: 67px 0px 0px 40px;
      opacity: 0.9;
    }

    ul {
      padding: 10px 0px 0px 55px !important;
      margin-right: 100px;
      font-size: 12px;
    }
  }
}

.imgMustBe {
  margin: 30px 0 0 0;
  padding: 20px 34px 20px 20px;
  background: #D1433A1A;
  border: 1px solid #D1433A1A;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;

  .i {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #D1433A;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-error {
    color: red;
    size: 13px;
    font-weight: 400;
  }
}
.saveBtn {
  margin: 20px 0 0 auto;
  width: 200px;
  height: 40px;
  font-size: 13px;
}
.required {
  font-size: 10px !important;
  color: red !important;
  font-family: HelveticaNeueBold;
}
.mx-datepicker {
  opacity: 0;
}
.events-container-info {
  label {
    margin-bottom: 0 !important;
  }
  h4 {
    color: #47505b;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
  }
  p {
    margin-bottom: 0;
  }
  h3 {
    color: #071526;
    font-size: 18px;
    line-height: 22px;
    font-family: HelveticaNeueBold;
  }
  .left {
    padding: 30px;
    background-color: #fff;
    max-width: 100%;
    &-title {
      display: flex;
      justify-content: space-between;
      p {
        color: #47505b;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        margin-bottom: 7px;
      }
      input {
        width: 340px;
        max-width: 100%;
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        height: 30px;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        padding-left: 10px;
      }
      input::placeholder {
        color: rgba(7, 21, 38, 0.6);
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueMedium;
      }
    }
    &-date {
      justify-content: space-between;
      margin-top: 17px;
    }
    &-select {
      justify-content: space-between;
      margin-top: 17px;
      &-co {
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        width: 340px;
        max-width: 100%;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13px 0 10px;
        cursor: pointer;
        position: relative;
        span,
        p {
          color: rgba(7, 21, 38, 0.6);
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueMedium;
        }
        span {
          background: #e6eaf0;
          border-radius: 2px;
          min-width: 80px;
          height: 20px;
          padding: 0 7px 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 10px;
        }
        .genres-popup {
          background: #ffffff;
          box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
          border-radius: 4px;
          width: 100%;
          position: absolute;
          z-index: 999;
          padding: 10px 0;
          left: 0;
          top: 0;
          .ps {
            max-height: 310px;
          }
          .item {
            justify-content: space-between;
            color: #071526;
            font-size: 13px;
            line-height: 16px;
            font-family: HelveticaNeueNormal;
            padding: 7px 20px;
            transition: all 0.3s;
            &:hover {
              background: #D1433A1A;
              color: #D1433A;
            }
          }
        }
      }
    }
    &-editor {
      margin-top: 27px;
    }
    &-actions {
      display: flex;
      margin-top: 30px;
      button {
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        border-radius: 4px;
        text-transform: uppercase;
      }
      .create {
        background: #D1433A;
        width: 130px;
        max-width: 100%;
        color: #fff;
        border: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        svg {
          margin-left: 8px;
        }
      }
      .SaveDraft {
        border: 1px solid #D1433A;
        color: #D1433A;
        width: 130px;
        background: #ffffff;
      }
      .cancel {
        border: none;
        outline: none;
        width: 130px;
        max-width: 100%;
        background: #e6eaf0;
        color: #47505b;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.date-picker-container {
  border-radius: 4px;
  border: 1px solid #e6eaf0;
  width: 190px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  p {
    color: #47505b;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueNormal;
  }
}
.newEvent-editor {
  height: 170px;
  border-radius: 4px !important;
  border: 1px solid #e6eaf0 !important;
}

.flex {
  display: flex;
}
.genres-select-wraper {
  display: flex;
  flex-wrap: wrap;
}
svg {
  transition: all 0.3s;
}
</style>

<style lang="scss">
// gmap
.left-title {
  .pac-target-input {
    width: 340px;
    max-width: 100%;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    height: 30px;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
    padding-left: 10px;
    &::placeholder {
      color: rgba(7, 21, 38, 0.6);
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
    }
  }
}
</style>
<style lang="scss">
.newEvent-editor {
  .cke_toolbar:nth-child(1) {
    display: none;
  }
  .cke_toolbar:nth-child(2) {
    display: none;
  }
  .cke_toolbar:nth-child(3) {
    display: none;
  }
  .cke_toolbar:nth-child(4) {
    display: none;
  }
  .cke_toolbar:nth-child(5) {
    display: none;
  }
  .cke_toolbar:nth-child(6) {
    display: none;
  }
  .cke_toolbar:nth-child(9) {
    display: none;
  }
  .cke_toolbar:nth-child(10) {
    display: none;
  }
  .cke_toolbar:nth-child(11) {
    display: none;
  }
  .cke_toolbar:nth-child(8) {
    display: block !important;
    .cke_toolgroup :nth-child(3) {
      display: none;
    }
    .cke_toolgroup :nth-child(5) {
      display: none;
    }
  }
}
</style>
<style scoped>
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4ccd6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4ccd6;
}
</style>
