<template>
  <div class="">
    <div class="media-tabs">
      <ul class="nav nav-tabs5" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#Photos" role="tab"
             aria-controls="home" aria-selected="true">Photos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#Albums" role="tab"
             aria-controls="profile" aria-selected="false">Albums</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Videos" role="tab"
             aria-controls="contact" aria-selected="false">Videos</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="Photos" role="tabpanel" aria-labelledby="home-tab">
          <media-photos :edit="edit" :user_id="user_id" />
        </div>
        <div class="tab-pane fade" id="Albums" role="tabpanel" aria-labelledby="profile-tab">
          <media-albums :user_id="user_id" />
        </div>
        <div class="tab-pane fade" id="Videos" role="tabpanel" aria-labelledby="contact-tab">
          <media-videos :user_id="user_id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script  type="application/javascript">
import mediaPhotos from "@/components/PageManager/Pages/ArtistMoreSection/media/photos/index.vue";
import mediaAlbums from "@/components/PageManager/Pages/ArtistMoreSection/media/albums/index.vue";
import mediaVideos from "@/components/PageManager/Pages/ArtistMoreSection/media/videos/index.vue";

export default {
  name: "Media",
  props:{
    current_page_user:String,
    user_id:{
      type:Number,
      Default:null,
    },
    edit:{
      type:Boolean,
      default:true
    }
  },
  components: {
    mediaPhotos,
    mediaAlbums,
    mediaVideos
  }
}
</script>

<style scoped>

</style>
