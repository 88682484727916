<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "/public/css/vue2-date-picker.scss";
import moment from "moment/moment";

export default {
  name: "DatePickerComponent",

  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    mainClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "date",
    },
    placeholder: {
      type: String,
      default: "Date",
    },
    format: {
      type: String,
      default: "D MMM,YYYY",
    },
    showWeekNumber: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Boolean,
      default: true,
    },
    popupClass: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "DONE",
    },
  },

  data() {
    return {
      datePicker: this.value,
    };
  },

  watch: {
    value(newVal) {
      this.datePicker = newVal;
    },
    datePicker(newVal) {
      this.$emit("dateInput", newVal);
    },
  },

  components: {
    DatePicker,
  },

  methods: {
    selectValue(date) {
      this.parseValue(date);
    },

    parseValue(date) {
      if (date) {
        this.output.year = moment(date).year();
        this.output.startOfWeek = moment(date)
          .startOf("week")
          .format("DD-MM-YYYY");
        this.output.endOfWeek = moment(date).endOf("week").format("DD-MM-YYYY");
        this.output.dateSelected = moment(date).format();
        this.displayText = moment(date).format("Do MMM,YYYY");
        this.callDate = moment(date).format(" Do-MMM-YYYY");
        this.$emit("selectedValue", this.output);
      }
      return this.output;
    },

    handleClickOutside(event) {
      const picker = this.$refs.datePickerWrapper;
      if (picker && !picker.contains(event.target) && this.open && this.datePicker) {
        this.open = false;
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<template>
  <div ref="datePickerWrapper">
    <DatePicker
      :class="mainClass"
      v-model="datePicker"
      :type="type"
      :placeholder="placeholder"
      :format="format"
      :show-week-number="showWeekNumber"
      :open="open"
      :confirm="confirm"
      :popup-class="popupClass"
      :confirm-text="confirmText"
      @confirm="selectValue"
    />
  </div>
</template>

<style lang="scss">
@import "../../../../assets/style/events/datepicker.scss";
</style>
